import { VNode, h } from "preact";
import { InputLine } from "./InputLine.js";
import { UIFormProps } from "./FormProvider.js";

export function InputInteger<T extends object, K extends keyof T>(
  props: UIFormProps<T, K>,
): VNode {
  return (
    <InputLine
      type="number"
      converter={{
        //@ts-ignore
        fromStringUI: (v): number => {
          return !v ? 0 : Number.parseInt(v, 10);
        },
        //@ts-ignore
        toStringUI: (v?: number): string => {
          return v === undefined ? "" : String(v);
        },
      }}
      {...props}
    />
  );
}
