/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
import {
  HttpStatusCode,
  TalerError,
  assertUnreachable,
} from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { ErrorLoadingMerchant } from "../../../components/ErrorLoadingMerchant.js";
import { Loading } from "../../../components/exception/loading.js";
import { NotificationCard } from "../../../components/menu/index.js";
import { useSessionContext } from "../../../context/session.js";
import { useInstanceDetails } from "../../../hooks/instance.js";
import { Notification } from "../../../utils/types.js";
import { LoginPage } from "../../login/index.js";
import { NotFoundPageOrAdminCreate } from "../../notfound/index.js";
import { DetailPage } from "./DetailPage.js";

interface Props {
  onChange: () => void;
  onCancel: () => void;
}

export default function Token({ onChange, onCancel }: Props): VNode {
  const { i18n } = useTranslationContext();
  const { logIn, lib } = useSessionContext();
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const result = useInstanceDetails();

  if (!result) return <Loading />;
  if (result instanceof TalerError) {
    return <ErrorLoadingMerchant error={result} />;
  }
  if (result.type === "fail") {
    switch (result.case) {
      case HttpStatusCode.Unauthorized: {
        return <LoginPage />;
      }
      case HttpStatusCode.NotFound: {
        return <NotFoundPageOrAdminCreate />;
      }
      default: {
        assertUnreachable(result);
      }
    }
  }

  const hasToken = result.body.auth.method === "token";

  return (
    <Fragment>
      <NotificationCard notification={notif} />
      <DetailPage
        onBack={onCancel}
        hasToken={hasToken}
        onClearToken={async (currentToken): Promise<void> => {
          try {
            const resp = await lib.instance.updateCurrentInstanceAuthentication(
              currentToken,
              {
                method: "external",
              },
            );
            if (resp.type === "ok") {
              onChange();
            } else {
              return setNotif({
                message: i18n.str`Failed to clear token`,
                type: "ERROR",
                description: resp.detail?.hint,
              });
            }
          } catch (error) {
            return setNotif({
              message: i18n.str`Failed to clear token`,
              type: "ERROR",
              description: error instanceof Error ? error.message : String(error),
            });
          }
        }}
        onNewToken={async (currentToken, newToken): Promise<void> => {
          try {
            {
              const resp =
                await lib.instance.updateCurrentInstanceAuthentication(
                  currentToken,
                  {
                    token: newToken,
                    method: "token",
                  },
                );
              if (resp.type === "fail") {
                return setNotif({
                  message: i18n.str`Failed to set new token`,
                  type: "ERROR",
                  description: resp.detail?.hint,
                });
              }
            }
            const resp = await lib.authenticate.createAccessTokenBearer(
              newToken,
              {
                scope: "write",
                duration: {
                  d_us: "forever",
                },
                refreshable: true,
              },
            );
            if (resp.type === "ok") {
              logIn(resp.body.token);
              return onChange();
            } else {
              return setNotif({
                message: i18n.str`Failed to set new token`,
                type: "ERROR",
              });
            }
          } catch (error) {
              return setNotif({
                message: i18n.str`Failed to set new token`,
                type: "ERROR",
                description: error instanceof Error ? error.message : String(error),
              });
          }
        }}
      />
    </Fragment>
  );
}
