/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  HttpStatusCode,
  createRFC8959AccessTokenEncoded,
} from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { ComponentChildren, Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { NotificationCard } from "../../components/menu/index.js";
import { useSessionContext } from "../../context/session.js";
import { Notification } from "../../utils/types.js";

interface Props {}

const tokenRequest = {
  scope: "write",
  duration: {
    d_us: "forever" as const,
  },
  refreshable: true,
};

export function LoginPage(_p: Props): VNode {
  const [token, setToken] = useState("");
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { lib, state, logIn } = useSessionContext();

  const { i18n } = useTranslationContext();

  async function doLoginImpl() {
    const result = await lib.authenticate.createAccessTokenBearer(
      createRFC8959AccessTokenEncoded(token),
      tokenRequest,
    );
    if (result.type === "ok") {
      const { token } = result.body;
      logIn(token);
      return;
    } else {
      switch (result.case) {
        case HttpStatusCode.Unauthorized: {
          setNotif({
            message: i18n.str`Your password is incorrect`,
            type: "ERROR",
          });
          return;
        }
        case HttpStatusCode.NotFound: {
          setNotif({
            message: i18n.str`Your instance cannot be found`,
            type: "ERROR",
          });
          return;
        }
      }
    }
  }

  return (
    <Fragment>
      <NotificationCard notification={notif} />
      <div class="columns is-centered" style={{ margin: "auto" }}>
        <div class="column is-two-thirds ">
          <div class="modal-card" style={{ width: "100%", margin: 0 }}>
            <header
              class="modal-card-head"
              style={{ border: "1px solid", borderBottom: 0 }}
            >
              <p class="modal-card-title">{i18n.str`Login required`}</p>
            </header>
            <section
              class="modal-card-body"
              style={{ border: "1px solid", borderTop: 0, borderBottom: 0 }}
            >
              <i18n.Translate>
                Please enter your access token for <b>"{state.instance}"</b>.
              </i18n.Translate>

              <div class="field is-horizontal">
                <div class="field-label is-normal">
                  <label class="label">
                    <i18n.Translate>Access Token</i18n.Translate>
                  </label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <p class="control is-expanded">
                      <input
                        class="input"
                        type="password"
                        placeholder={"current access token"}
                        name="token"
                        onKeyPress={(e) =>
                          e.keyCode === 13 ? doLoginImpl() : null
                        }
                        value={token}
                        onInput={(e): void => setToken(e?.currentTarget.value)}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </section>
            <footer
              class="modal-card-foot "
              style={{
                justifyContent: "space-between",
                border: "1px solid",
                borderTop: 0,
              }}
            >
              <div />
              <AsyncButton type="is-info" onClick={doLoginImpl}>
                <i18n.Translate>Confirm</i18n.Translate>
              </AsyncButton>
            </footer>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

function AsyncButton({
  onClick,
  disabled,
  type = "",
  children,
}: {
  type?: string;
  disabled?: boolean;
  onClick: () => Promise<void>;
  children: ComponentChildren;
}): VNode {
  const [running, setRunning] = useState(false);
  return (
    <button
      class={"button " + type}
      disabled={disabled || running}
      onClick={() => {
        setRunning(true);
        onClick()
          .then(() => {
            setRunning(false);
          })
          .catch(() => {
            setRunning(false);
          });
      }}
    >
      {children}
    </button>
  );
}
