/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { TalerMerchantApi } from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { VNode, h } from "preact";
import { useState } from "preact/hooks";
import { AsyncButton } from "../../../../components/exception/AsyncButton.js";
import {
  FormErrors,
  FormProvider,
} from "../../../../components/form/FormProvider.js";
import { Input } from "../../../../components/form/Input.js";
import { undefinedIfEmpty } from "../../../../utils/table.js";

type Entity = TalerMerchantApi.CategoryCreateRequest;

interface Props {
  onCreate: (d: Entity) => Promise<void>;
  onBack?: () => void;
}

export function CreatePage({ onCreate, onBack }: Props): VNode {
  const { i18n } = useTranslationContext();

  const [state, setState] = useState<Partial<Entity>>({ name_i18n: {} });

  const errors = undefinedIfEmpty<FormErrors<Entity>>({
    name: !state.name
      ? i18n.str`Required`
      : !/[a-zA-Z0-9]*/.test(state.name)
        ? i18n.str`Invalid. Please insert only characters and numbers`
        : undefined,
  });

  const hasErrors = errors !== undefined;

  const submitForm = () => {
    if (hasErrors) return Promise.reject();
    return onCreate(state as Entity);
  };

  return (
    <div>
      <section class="section is-main-section">
        <div class="columns">
          <div class="column" />
          <div class="column is-four-fifths">
            <FormProvider
              object={state}
              valueHandler={setState}
              errors={errors}
            >
              <Input<Entity>
                name="name"
                label={i18n.str`Name`}
                tooltip={i18n.str`Category name`}
              />{" "}
            </FormProvider>

            <div class="buttons is-right mt-5">
              {onBack && (
                <button class="button" onClick={onBack}>
                  <i18n.Translate>Cancel</i18n.Translate>
                </button>
              )}
              <AsyncButton
                disabled={hasErrors}
                data-tooltip={
                  hasErrors
                    ? i18n.str`Need to complete marked fields`
                    : i18n.str`Confirm operation`
                }
                onClick={submitForm}
              >
                <i18n.Translate>Confirm</i18n.Translate>
              </AsyncButton>
            </div>
          </div>
          <div class="column" />
        </div>
      </section>
    </div>
  );
}
