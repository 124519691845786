/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  TalerMerchantApi,
  isRfc3548Base32Charset,
  randomRfc3548Base32Key,
} from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { AsyncButton } from "../../../../components/exception/AsyncButton.js";
import {
  FormErrors,
  FormProvider,
} from "../../../../components/form/FormProvider.js";
import { Input } from "../../../../components/form/Input.js";
import { InputSelector } from "../../../../components/form/InputSelector.js";
import { InputWithAddon } from "../../../../components/form/InputWithAddon.js";
import { undefinedIfEmpty } from "../../../../utils/table.js";

type Entity = TalerMerchantApi.OtpDeviceAddDetails;

interface Props {
  onCreate: (d: Entity) => Promise<void>;
  onBack?: () => void;
}

const algorithms = [0, 1, 2];
const algorithmsNames = ["off", "30s 8d TOTP-SHA1", "30s 8d eTOTP-SHA1"];

export function CreatePage({ onCreate, onBack }: Props): VNode {
  const { i18n } = useTranslationContext();

  const [state, setState] = useState<Partial<Entity>>({});

  const [showKey, setShowKey] = useState(false);

  const errors = undefinedIfEmpty<FormErrors<Entity>>({
    otp_device_id: !state.otp_device_id
      ? i18n.str`Required`
      : !/[a-zA-Z0-9]*/.test(state.otp_device_id)
        ? i18n.str`Invalid. Please enter characters and numbers only`
        : undefined,
    otp_algorithm: !state.otp_algorithm ? i18n.str`Required` : undefined,
    otp_key: !state.otp_key
      ? i18n.str`Required`
      : !isRfc3548Base32Charset(state.otp_key)
        ? i18n.str`Just letters and numbers from 2 to 7`
        : state.otp_key.length !== 32
          ? i18n.str`The size of the key must be 32 characters`
          : undefined,
    otp_device_description: !state.otp_device_description
      ? i18n.str`Required`
      : !/[a-zA-Z0-9]*/.test(state.otp_device_description)
        ? i18n.str`Invalid. Please enter characters and numbers only`
        : undefined,
  });

  const hasErrors = errors !== undefined;

  const submitForm = () => {
    if (hasErrors) return Promise.reject();
    return onCreate(state as TalerMerchantApi.OtpDeviceAddDetails);
  };

  return (
    <div>
      <section class="section is-main-section">
        <div class="columns">
          <div class="column" />
          <div class="column is-four-fifths">
            <FormProvider
              object={state}
              valueHandler={setState}
              errors={errors}
            >
              <Input<Entity>
                name="otp_device_id"
                label={i18n.str`ID`}
                tooltip={i18n.str`Internal ID on the system`}
              />
              <Input<Entity>
                name="otp_device_description"
                label={i18n.str`Description`}
                tooltip={i18n.str`Useful to identify the device physically`}
              />
              <InputSelector<Entity>
                name="otp_algorithm"
                label={i18n.str`Verification algorithm`}
                tooltip={i18n.str`Algorithm used to verify transactions in offline mode`}
                values={algorithms}
                toStr={(v) => algorithmsNames[v]}
                fromStr={(v) => Number(v)}
              />
              {state.otp_algorithm ? (
                <Fragment>
                  <InputWithAddon<Entity>
                    expand
                    name="otp_key"
                    label={i18n.str`Device key`}
                    inputType={showKey ? "text" : "password"}
                    help={i18n.str`Be sure to choose a password hard to guess, alternatively use the random generator`}
                    tooltip={i18n.str`Your device needs to match exactly the same value`}
                    fromStr={(v) => v.toUpperCase()}
                    addonAfterAction={() => {
                      setShowKey(!showKey);
                    }}
                    addonAfter={
                      <span class="icon">
                        {showKey ? (
                          <i class="mdi mdi-eye" />
                        ) : (
                          <i class="mdi mdi-eye-off" />
                        )}
                      </span>
                    }
                    side={
                      <button
                        data-tooltip={i18n.str`Generate random secret key`}
                        class="button is-info mr-3"
                        onClick={(e) => {
                          setState((s) => ({
                            ...s,
                            otp_key: randomRfc3548Base32Key(),
                          }));
                          e.preventDefault();
                        }}
                      >
                        <i18n.Translate>Random</i18n.Translate>
                      </button>
                    }
                  />
                </Fragment>
              ) : undefined}
            </FormProvider>

            <div class="buttons is-right mt-5">
              {onBack && (
                <button class="button" onClick={onBack}>
                  <i18n.Translate>Cancel</i18n.Translate>
                </button>
              )}
              <AsyncButton
                disabled={hasErrors}
                data-tooltip={
                  hasErrors
                    ? i18n.str`Need to complete marked fields`
                    : i18n.str`Confirm operation`
                }
                onClick={submitForm}
              >
                <i18n.Translate>Confirm</i18n.Translate>
              </AsyncButton>
            </div>
          </div>
          <div class="column" />
        </div>
      </section>
    </div>
  );
}
