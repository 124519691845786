/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/*eslint quote-props: ["error", "consistent"]*/
export const strings: {[s: string]: any} = {};

strings['de'] = {
  "domain": "messages",
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=2; plural=(n != 1);",
        "lang": ""
      },
      "Cancel": [
        ""
      ],
      "%1$s": [
        ""
      ],
      "Close": [
        ""
      ],
      "Continue": [
        ""
      ],
      "Clear": [
        ""
      ],
      "Confirm": [
        ""
      ],
      "is not the same as the current access token": [
        ""
      ],
      "cannot be empty": [
        ""
      ],
      "cannot be the same as the old token": [
        ""
      ],
      "is not the same": [
        ""
      ],
      "You are updating the access token from instance with id %1$s": [
        ""
      ],
      "Old access token": [
        ""
      ],
      "access token currently in use": [
        ""
      ],
      "New access token": [
        ""
      ],
      "next access token to be used": [
        ""
      ],
      "Repeat access token": [
        ""
      ],
      "confirm the same access token": [
        ""
      ],
      "Clearing the access token will mean public access to the instance": [
        ""
      ],
      "cannot be the same as the old access token": [
        ""
      ],
      "You are setting the access token for the new instance": [
        ""
      ],
      "With external authorization method no check will be done by the merchant backend": [
        ""
      ],
      "Set external authorization": [
        ""
      ],
      "Set access token": [
        ""
      ],
      "Operation in progress...": [
        ""
      ],
      "The operation will be automatically canceled after %1$s seconds": [
        ""
      ],
      "Instances": [
        ""
      ],
      "Delete": [
        ""
      ],
      "add new instance": [
        ""
      ],
      "ID": [
        ""
      ],
      "Name": [
        ""
      ],
      "Edit": [
        ""
      ],
      "Purge": [
        ""
      ],
      "There is no instances yet, add more pressing the + sign": [
        ""
      ],
      "Only show active instances": [
        ""
      ],
      "Active": [
        ""
      ],
      "Only show deleted instances": [
        ""
      ],
      "Deleted": [
        ""
      ],
      "Show all instances": [
        ""
      ],
      "All": [
        ""
      ],
      "Instance \"%1$s\" (ID: %2$s) has been deleted": [
        ""
      ],
      "Failed to delete instance": [
        ""
      ],
      "Instance '%1$s' (ID: %2$s) has been disabled": [
        ""
      ],
      "Failed to purge instance": [
        ""
      ],
      "Pending KYC verification": [
        ""
      ],
      "Timed out": [
        ""
      ],
      "Exchange": [
        ""
      ],
      "Target account": [
        ""
      ],
      "KYC URL": [
        ""
      ],
      "Code": [
        ""
      ],
      "Http Status": [
        ""
      ],
      "No pending kyc verification!": [
        ""
      ],
      "change value to unknown date": [
        ""
      ],
      "change value to empty": [
        ""
      ],
      "clear": [
        ""
      ],
      "change value to never": [
        ""
      ],
      "never": [
        ""
      ],
      "Country": [
        ""
      ],
      "Address": [
        ""
      ],
      "Building number": [
        ""
      ],
      "Building name": [
        ""
      ],
      "Street": [
        ""
      ],
      "Post code": [
        ""
      ],
      "Town location": [
        ""
      ],
      "Town": [
        ""
      ],
      "District": [
        ""
      ],
      "Country subdivision": [
        ""
      ],
      "Product id": [
        ""
      ],
      "Description": [
        ""
      ],
      "Product": [
        ""
      ],
      "search products by it's description or id": [
        ""
      ],
      "no products found with that description": [
        ""
      ],
      "You must enter a valid product identifier.": [
        ""
      ],
      "Quantity must be greater than 0!": [
        ""
      ],
      "This quantity exceeds remaining stock. Currently, only %1$s units remain unreserved in stock.": [
        ""
      ],
      "Quantity": [
        ""
      ],
      "how many products will be added": [
        ""
      ],
      "Add from inventory": [
        ""
      ],
      "Image should be smaller than 1 MB": [
        ""
      ],
      "Add": [
        ""
      ],
      "Remove": [
        ""
      ],
      "No taxes configured for this product.": [
        ""
      ],
      "Amount": [
        ""
      ],
      "Taxes can be in currencies that differ from the main currency used by the merchant.": [
        ""
      ],
      "Enter currency and value separated with a colon, e.g. &quot;USD:2.3&quot;.": [
        ""
      ],
      "Legal name of the tax, e.g. VAT or import duties.": [
        ""
      ],
      "add tax to the tax list": [
        ""
      ],
      "describe and add a product that is not in the inventory list": [
        ""
      ],
      "Add custom product": [
        ""
      ],
      "Complete information of the product": [
        ""
      ],
      "Image": [
        ""
      ],
      "photo of the product": [
        ""
      ],
      "full product description": [
        ""
      ],
      "Unit": [
        ""
      ],
      "name of the product unit": [
        ""
      ],
      "Price": [
        ""
      ],
      "amount in the current currency": [
        ""
      ],
      "Taxes": [
        ""
      ],
      "image": [
        ""
      ],
      "description": [
        ""
      ],
      "quantity": [
        ""
      ],
      "unit price": [
        ""
      ],
      "total price": [
        ""
      ],
      "required": [
        ""
      ],
      "not valid": [
        ""
      ],
      "must be greater than 0": [
        ""
      ],
      "not a valid json": [
        ""
      ],
      "should be in the future": [
        ""
      ],
      "refund deadline cannot be before pay deadline": [
        ""
      ],
      "wire transfer deadline cannot be before refund deadline": [
        ""
      ],
      "wire transfer deadline cannot be before pay deadline": [
        ""
      ],
      "should have a refund deadline": [
        ""
      ],
      "auto refund cannot be after refund deadline": [
        ""
      ],
      "Manage products in order": [
        ""
      ],
      "Manage list of products in the order.": [
        ""
      ],
      "Remove this product from the order.": [
        ""
      ],
      "Total price": [
        ""
      ],
      "total product price added up": [
        ""
      ],
      "Amount to be paid by the customer": [
        ""
      ],
      "Order price": [
        ""
      ],
      "final order price": [
        ""
      ],
      "Summary": [
        ""
      ],
      "Title of the order to be shown to the customer": [
        ""
      ],
      "Shipping and Fulfillment": [
        ""
      ],
      "Delivery date": [
        ""
      ],
      "Deadline for physical delivery assured by the merchant.": [
        ""
      ],
      "Location": [
        ""
      ],
      "address where the products will be delivered": [
        ""
      ],
      "Fulfillment URL": [
        ""
      ],
      "URL to which the user will be redirected after successful payment.": [
        ""
      ],
      "Taler payment options": [
        ""
      ],
      "Override default Taler payment settings for this order": [
        ""
      ],
      "Payment deadline": [
        ""
      ],
      "Deadline for the customer to pay for the offer before it expires. Inventory products will be reserved until this deadline.": [
        ""
      ],
      "Refund deadline": [
        ""
      ],
      "Time until which the order can be refunded by the merchant.": [
        ""
      ],
      "Wire transfer deadline": [
        ""
      ],
      "Deadline for the exchange to make the wire transfer.": [
        ""
      ],
      "Auto-refund deadline": [
        ""
      ],
      "Time until which the wallet will automatically check for refunds without user interaction.": [
        ""
      ],
      "Maximum deposit fee": [
        ""
      ],
      "Maximum deposit fees the merchant is willing to cover for this order. Higher deposit fees must be covered in full by the consumer.": [
        ""
      ],
      "Maximum wire fee": [
        ""
      ],
      "Maximum aggregate wire fees the merchant is willing to cover for this order. Wire fees exceeding this amount are to be covered by the customers.": [
        ""
      ],
      "Wire fee amortization": [
        ""
      ],
      "Factor by which wire fees exceeding the above threshold are divided to determine the share of excess wire fees to be paid explicitly by the consumer.": [
        ""
      ],
      "Create token": [
        ""
      ],
      "Uncheck this option if the merchant backend generated an order ID with enough entropy to prevent adversarial claims.": [
        ""
      ],
      "Minimum age required": [
        ""
      ],
      "Any value greater than 0 will limit the coins able be used to pay this contract. If empty the age restriction will be defined by the products": [
        ""
      ],
      "Min age defined by the producs is %1$s": [
        ""
      ],
      "Additional information": [
        ""
      ],
      "Custom information to be included in the contract for this order.": [
        ""
      ],
      "You must enter a value in JavaScript Object Notation (JSON).": [
        ""
      ],
      "days": [
        ""
      ],
      "hours": [
        ""
      ],
      "minutes": [
        ""
      ],
      "seconds": [
        ""
      ],
      "forever": [
        ""
      ],
      "%1$sM": [
        ""
      ],
      "%1$sY": [
        ""
      ],
      "%1$sd": [
        ""
      ],
      "%1$sh": [
        ""
      ],
      "%1$smin": [
        ""
      ],
      "%1$ssec": [
        ""
      ],
      "Orders": [
        ""
      ],
      "create order": [
        ""
      ],
      "load newer orders": [
        ""
      ],
      "Date": [
        ""
      ],
      "Refund": [
        ""
      ],
      "copy url": [
        ""
      ],
      "load older orders": [
        ""
      ],
      "No orders have been found matching your query!": [
        ""
      ],
      "duplicated": [
        ""
      ],
      "invalid format": [
        ""
      ],
      "this value exceed the refundable amount": [
        ""
      ],
      "date": [
        ""
      ],
      "amount": [
        ""
      ],
      "reason": [
        ""
      ],
      "amount to be refunded": [
        ""
      ],
      "Max refundable:": [
        ""
      ],
      "Reason": [
        ""
      ],
      "Choose one...": [
        ""
      ],
      "requested by the customer": [
        ""
      ],
      "other": [
        ""
      ],
      "why this order is being refunded": [
        ""
      ],
      "more information to give context": [
        ""
      ],
      "Contract Terms": [
        ""
      ],
      "human-readable description of the whole purchase": [
        ""
      ],
      "total price for the transaction": [
        ""
      ],
      "URL for this purchase": [
        ""
      ],
      "Max fee": [
        ""
      ],
      "maximum total deposit fee accepted by the merchant for this contract": [
        ""
      ],
      "Max wire fee": [
        ""
      ],
      "maximum wire fee accepted by the merchant": [
        ""
      ],
      "over how many customer transactions does the merchant expect to amortize wire fees on average": [
        ""
      ],
      "Created at": [
        ""
      ],
      "time when this contract was generated": [
        ""
      ],
      "after this deadline has passed no refunds will be accepted": [
        ""
      ],
      "after this deadline, the merchant won't accept payments for the contract": [
        ""
      ],
      "transfer deadline for the exchange": [
        ""
      ],
      "time indicating when the order should be delivered": [
        ""
      ],
      "where the order will be delivered": [
        ""
      ],
      "Auto-refund delay": [
        ""
      ],
      "how long the wallet should try to get an automatic refund for the purchase": [
        ""
      ],
      "Extra info": [
        ""
      ],
      "extra data that is only interpreted by the merchant frontend": [
        ""
      ],
      "Order": [
        ""
      ],
      "claimed": [
        ""
      ],
      "claimed at": [
        ""
      ],
      "Timeline": [
        ""
      ],
      "Payment details": [
        ""
      ],
      "Order status": [
        ""
      ],
      "Product list": [
        ""
      ],
      "paid": [
        ""
      ],
      "wired": [
        ""
      ],
      "refunded": [
        ""
      ],
      "refund order": [
        ""
      ],
      "not refundable": [
        ""
      ],
      "refund": [
        ""
      ],
      "Refunded amount": [
        ""
      ],
      "Refund taken": [
        ""
      ],
      "Status URL": [
        ""
      ],
      "Refund URI": [
        ""
      ],
      "unpaid": [
        ""
      ],
      "pay at": [
        ""
      ],
      "created at": [
        ""
      ],
      "Order status URL": [
        ""
      ],
      "Payment URI": [
        ""
      ],
      "Unknown order status. This is an error, please contact the administrator.": [
        ""
      ],
      "Back": [
        ""
      ],
      "refund created successfully": [
        ""
      ],
      "could not create the refund": [
        ""
      ],
      "select date to show nearby orders": [
        ""
      ],
      "order id": [
        ""
      ],
      "jump to order with the given order ID": [
        ""
      ],
      "remove all filters": [
        ""
      ],
      "only show paid orders": [
        ""
      ],
      "Paid": [
        ""
      ],
      "only show orders with refunds": [
        ""
      ],
      "Refunded": [
        ""
      ],
      "only show orders where customers paid, but wire payments from payment provider are still pending": [
        ""
      ],
      "Not wired": [
        ""
      ],
      "clear date filter": [
        ""
      ],
      "date (YYYY/MM/DD)": [
        ""
      ],
      "Enter an order id": [
        ""
      ],
      "order not found": [
        ""
      ],
      "could not get the order to refund": [
        ""
      ],
      "Loading...": [
        ""
      ],
      "click here to configure the stock of the product, leave it as is and the backend will not control stock": [
        ""
      ],
      "Manage stock": [
        ""
      ],
      "this product has been configured without stock control": [
        ""
      ],
      "Infinite": [
        ""
      ],
      "lost cannot be greater than current and incoming (max %1$s)": [
        ""
      ],
      "Incoming": [
        ""
      ],
      "Lost": [
        ""
      ],
      "Current": [
        ""
      ],
      "remove stock control for this product": [
        ""
      ],
      "without stock": [
        ""
      ],
      "Next restock": [
        ""
      ],
      "Delivery address": [
        ""
      ],
      "product identification to use in URLs (for internal use only)": [
        ""
      ],
      "illustration of the product for customers": [
        ""
      ],
      "product description for customers": [
        ""
      ],
      "Age restricted": [
        ""
      ],
      "is this product restricted for customer below certain age?": [
        ""
      ],
      "unit describing quantity of product sold (e.g. 2 kilograms, 5 liters, 3 items, 5 meters) for customers": [
        ""
      ],
      "sale price for customers, including taxes, for above units of the product": [
        ""
      ],
      "Stock": [
        ""
      ],
      "product inventory for products with finite supply (for internal use only)": [
        ""
      ],
      "taxes included in the product price, exposed to customers": [
        ""
      ],
      "Need to complete marked fields": [
        ""
      ],
      "could not create product": [
        ""
      ],
      "Products": [
        ""
      ],
      "add product to inventory": [
        ""
      ],
      "Sell": [
        ""
      ],
      "Profit": [
        ""
      ],
      "Sold": [
        ""
      ],
      "free": [
        ""
      ],
      "go to product update page": [
        ""
      ],
      "Update": [
        ""
      ],
      "remove this product from the database": [
        ""
      ],
      "update the product with new price": [
        ""
      ],
      "update product with new price": [
        ""
      ],
      "add more elements to the inventory": [
        ""
      ],
      "report elements lost in the inventory": [
        ""
      ],
      "new price for the product": [
        ""
      ],
      "the are value with errors": [
        ""
      ],
      "update product with new stock and price": [
        ""
      ],
      "There is no products yet, add more pressing the + sign": [
        ""
      ],
      "product updated successfully": [
        ""
      ],
      "could not update the product": [
        ""
      ],
      "product delete successfully": [
        ""
      ],
      "could not delete the product": [
        ""
      ],
      "Product id:": [
        ""
      ],
      "To complete the setup of the reserve, you must now initiate a wire transfer using the given wire transfer subject and crediting the specified amount to the indicated account of the exchange.": [
        ""
      ],
      "If your system supports RFC 8905, you can do this by opening this URI:": [
        ""
      ],
      "it should be greater than 0": [
        ""
      ],
      "must be a valid URL": [
        ""
      ],
      "Initial balance": [
        ""
      ],
      "balance prior to deposit": [
        ""
      ],
      "Exchange URL": [
        ""
      ],
      "URL of exchange": [
        ""
      ],
      "Next": [
        ""
      ],
      "Wire method": [
        ""
      ],
      "method to use for wire transfer": [
        ""
      ],
      "Select one wire method": [
        ""
      ],
      "could not create reserve": [
        ""
      ],
      "Valid until": [
        ""
      ],
      "Created balance": [
        ""
      ],
      "Exchange balance": [
        ""
      ],
      "Picked up": [
        ""
      ],
      "Committed": [
        ""
      ],
      "Account address": [
        ""
      ],
      "Subject": [
        ""
      ],
      "Tips": [
        ""
      ],
      "No tips has been authorized from this reserve": [
        ""
      ],
      "Authorized": [
        ""
      ],
      "Expiration": [
        ""
      ],
      "amount of tip": [
        ""
      ],
      "Justification": [
        ""
      ],
      "reason for the tip": [
        ""
      ],
      "URL after tip": [
        ""
      ],
      "URL to visit after tip payment": [
        ""
      ],
      "Reserves not yet funded": [
        ""
      ],
      "Reserves ready": [
        ""
      ],
      "add new reserve": [
        ""
      ],
      "Expires at": [
        ""
      ],
      "Initial": [
        ""
      ],
      "delete selected reserve from the database": [
        ""
      ],
      "authorize new tip from selected reserve": [
        ""
      ],
      "There is no ready reserves yet, add more pressing the + sign or fund them": [
        ""
      ],
      "Expected Balance": [
        ""
      ],
      "could not create the tip": [
        ""
      ],
      "should not be empty": [
        ""
      ],
      "should be greater that 0": [
        ""
      ],
      "can't be empty": [
        ""
      ],
      "to short": [
        ""
      ],
      "just letters and numbers from 2 to 7": [
        ""
      ],
      "size of the key should be 32": [
        ""
      ],
      "Identifier": [
        ""
      ],
      "Name of the template in URLs.": [
        ""
      ],
      "Describe what this template stands for": [
        ""
      ],
      "Fixed summary": [
        ""
      ],
      "If specified, this template will create order with the same summary": [
        ""
      ],
      "Fixed price": [
        ""
      ],
      "If specified, this template will create order with the same price": [
        ""
      ],
      "Minimum age": [
        ""
      ],
      "Is this contract restricted to some age?": [
        ""
      ],
      "Payment timeout": [
        ""
      ],
      "How much time has the customer to complete the payment once the order was created.": [
        ""
      ],
      "Verification algorithm": [
        ""
      ],
      "Algorithm to use to verify transaction in offline mode": [
        ""
      ],
      "Point-of-sale key": [
        ""
      ],
      "Useful to validate the purchase": [
        ""
      ],
      "generate random secret key": [
        ""
      ],
      "random": [
        ""
      ],
      "show secret key": [
        ""
      ],
      "hide secret key": [
        ""
      ],
      "hide": [
        ""
      ],
      "show": [
        ""
      ],
      "could not inform template": [
        ""
      ],
      "Amount is required": [
        ""
      ],
      "Order summary is required": [
        ""
      ],
      "New order for template": [
        ""
      ],
      "Amount of the order": [
        ""
      ],
      "Order summary": [
        ""
      ],
      "could not create order from template": [
        ""
      ],
      "Here you can specify a default value for fields that are not fixed. Default values can be edited by the customer before the payment.": [
        ""
      ],
      "Fixed amount": [
        ""
      ],
      "Default amount": [
        ""
      ],
      "Default summary": [
        ""
      ],
      "Print": [
        ""
      ],
      "Setup TOTP": [
        ""
      ],
      "Templates": [
        ""
      ],
      "add new templates": [
        ""
      ],
      "load more templates before the first one": [
        ""
      ],
      "load newer templates": [
        ""
      ],
      "delete selected templates from the database": [
        ""
      ],
      "use template to create new order": [
        ""
      ],
      "create qr code for the template": [
        ""
      ],
      "load more templates after the last one": [
        ""
      ],
      "load older templates": [
        ""
      ],
      "There is no templates yet, add more pressing the + sign": [
        ""
      ],
      "template delete successfully": [
        ""
      ],
      "could not delete the template": [
        ""
      ],
      "could not update template": [
        ""
      ],
      "should be one of '%1$s'": [
        ""
      ],
      "Webhook ID to use": [
        ""
      ],
      "Event": [
        ""
      ],
      "The event of the webhook: why the webhook is used": [
        ""
      ],
      "Method": [
        ""
      ],
      "Method used by the webhook": [
        ""
      ],
      "URL": [
        ""
      ],
      "URL of the webhook where the customer will be redirected": [
        ""
      ],
      "Header": [
        ""
      ],
      "Header template of the webhook": [
        ""
      ],
      "Body": [
        ""
      ],
      "Body template by the webhook": [
        ""
      ],
      "Webhooks": [
        ""
      ],
      "add new webhooks": [
        ""
      ],
      "load more webhooks before the first one": [
        ""
      ],
      "load newer webhooks": [
        ""
      ],
      "Event type": [
        ""
      ],
      "delete selected webhook from the database": [
        ""
      ],
      "load more webhooks after the last one": [
        ""
      ],
      "load older webhooks": [
        ""
      ],
      "There is no webhooks yet, add more pressing the + sign": [
        ""
      ],
      "webhook delete successfully": [
        ""
      ],
      "could not delete the webhook": [
        ""
      ],
      "check the id, does not look valid": [
        ""
      ],
      "should have 52 characters, current %1$s": [
        ""
      ],
      "URL doesn't have the right format": [
        ""
      ],
      "Credited bank account": [
        ""
      ],
      "Select one account": [
        ""
      ],
      "Bank account of the merchant where the payment was received": [
        ""
      ],
      "Wire transfer ID": [
        ""
      ],
      "unique identifier of the wire transfer used by the exchange, must be 52 characters long": [
        ""
      ],
      "Base URL of the exchange that made the transfer, should have been in the wire transfer subject": [
        ""
      ],
      "Amount credited": [
        ""
      ],
      "Actual amount that was wired to the merchant's bank account": [
        ""
      ],
      "could not inform transfer": [
        ""
      ],
      "Transfers": [
        ""
      ],
      "add new transfer": [
        ""
      ],
      "load more transfers before the first one": [
        ""
      ],
      "load newer transfers": [
        ""
      ],
      "Credit": [
        ""
      ],
      "Confirmed": [
        ""
      ],
      "Verified": [
        ""
      ],
      "Executed at": [
        ""
      ],
      "yes": [
        ""
      ],
      "no": [
        ""
      ],
      "unknown": [
        ""
      ],
      "delete selected transfer from the database": [
        ""
      ],
      "load more transfer after the last one": [
        ""
      ],
      "load older transfers": [
        ""
      ],
      "There is no transfer yet, add more pressing the + sign": [
        ""
      ],
      "filter by account address": [
        ""
      ],
      "only show wire transfers confirmed by the merchant": [
        ""
      ],
      "only show wire transfers claimed by the exchange": [
        ""
      ],
      "Unverified": [
        ""
      ],
      "is not valid": [
        ""
      ],
      "is not a number": [
        ""
      ],
      "must be 1 or greater": [
        ""
      ],
      "max 7 lines": [
        ""
      ],
      "change authorization configuration": [
        ""
      ],
      "Need to complete marked fields and choose authorization method": [
        ""
      ],
      "This is not a valid bitcoin address.": [
        ""
      ],
      "This is not a valid Ethereum address.": [
        ""
      ],
      "IBAN numbers usually have more that 4 digits": [
        ""
      ],
      "IBAN numbers usually have less that 34 digits": [
        ""
      ],
      "IBAN country code not found": [
        ""
      ],
      "IBAN number is not valid, checksum is wrong": [
        ""
      ],
      "Target type": [
        ""
      ],
      "Method to use for wire transfer": [
        ""
      ],
      "Routing": [
        ""
      ],
      "Routing number.": [
        ""
      ],
      "Account": [
        ""
      ],
      "Account number.": [
        ""
      ],
      "Business Identifier Code.": [
        ""
      ],
      "Bank Account Number.": [
        ""
      ],
      "Unified Payment Interface.": [
        ""
      ],
      "Bitcoin protocol.": [
        ""
      ],
      "Ethereum protocol.": [
        ""
      ],
      "Interledger protocol.": [
        ""
      ],
      "Host": [
        ""
      ],
      "Bank host.": [
        ""
      ],
      "Bank account.": [
        ""
      ],
      "Bank account owner's name.": [
        ""
      ],
      "No accounts yet.": [
        ""
      ],
      "Name of the instance in URLs. The 'default' instance is special in that it is used to administer other instances.": [
        ""
      ],
      "Business name": [
        ""
      ],
      "Legal name of the business represented by this instance.": [
        ""
      ],
      "Email": [
        ""
      ],
      "Contact email": [
        ""
      ],
      "Website URL": [
        ""
      ],
      "URL.": [
        ""
      ],
      "Logo": [
        ""
      ],
      "Logo image.": [
        ""
      ],
      "Bank account": [
        ""
      ],
      "URI specifying bank account for crediting revenue.": [
        ""
      ],
      "Default max deposit fee": [
        ""
      ],
      "Maximum deposit fees this merchant is willing to pay per order by default.": [
        ""
      ],
      "Default max wire fee": [
        ""
      ],
      "Maximum wire fees this merchant is willing to pay per wire transfer by default.": [
        ""
      ],
      "Default wire fee amortization": [
        ""
      ],
      "Number of orders excess wire transfer fees will be divided by to compute per order surcharge.": [
        ""
      ],
      "Physical location of the merchant.": [
        ""
      ],
      "Jurisdiction": [
        ""
      ],
      "Jurisdiction for legal disputes with the merchant.": [
        ""
      ],
      "Default payment delay": [
        ""
      ],
      "Time customers have to pay an order before the offer expires by default.": [
        ""
      ],
      "Default wire transfer delay": [
        ""
      ],
      "Maximum time an exchange is allowed to delay wiring funds to the merchant, enabling it to aggregate smaller payments into larger wire transfers and reducing wire fees.": [
        ""
      ],
      "Instance id": [
        ""
      ],
      "Change the authorization method use for this instance.": [
        ""
      ],
      "Manage access token": [
        ""
      ],
      "Failed to create instance": [
        ""
      ],
      "Login required": [
        ""
      ],
      "Please enter your access token.": [
        ""
      ],
      "Access Token": [
        ""
      ],
      "The request to the backend take too long and was cancelled": [
        ""
      ],
      "Diagnostic from %1$s is \"%2$s\"": [
        ""
      ],
      "The backend reported a problem: HTTP status #%1$s": [
        ""
      ],
      "Diagnostic from %1$s is '%2$s'": [
        ""
      ],
      "Access denied": [
        ""
      ],
      "The access token provided is invalid.": [
        ""
      ],
      "No 'default' instance configured yet.": [
        ""
      ],
      "Create a 'default' instance to begin using the merchant backoffice.": [
        ""
      ],
      "The access token provided is invalid": [
        ""
      ],
      "Hide for today": [
        ""
      ],
      "Instance": [
        ""
      ],
      "Settings": [
        ""
      ],
      "Connection": [
        ""
      ],
      "New": [
        ""
      ],
      "List": [
        ""
      ],
      "Log out": [
        ""
      ],
      "Check your token is valid": [
        ""
      ],
      "Couldn't access the server.": [
        ""
      ],
      "Could not infer instance id from url %1$s": [
        ""
      ],
      "Server not found": [
        ""
      ],
      "Server response with an error code": [
        ""
      ],
      "Got message %1$s from %2$s": [
        ""
      ],
      "Response from server is unreadable, http status: %1$s": [
        ""
      ],
      "Unexpected Error": [
        ""
      ],
      "The value %1$s is invalid for a payment url": [
        ""
      ],
      "add element to the list": [
        ""
      ],
      "add": [
        ""
      ],
      "Deleting": [
        ""
      ],
      "Changing": [
        ""
      ],
      "Order ID": [
        ""
      ],
      "Payment URL": [
        ""
      ]
    }
  }
};

strings['en'] = {
  "domain": "messages",
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=2; plural=(n != 1);",
        "lang": ""
      },
      "Cancel": [
        ""
      ],
      "%1$s": [
        ""
      ],
      "Close": [
        ""
      ],
      "Continue": [
        ""
      ],
      "Clear": [
        ""
      ],
      "Confirm": [
        ""
      ],
      "is not the same as the current access token": [
        ""
      ],
      "cannot be empty": [
        ""
      ],
      "cannot be the same as the old token": [
        ""
      ],
      "is not the same": [
        ""
      ],
      "You are updating the access token from instance with id %1$s": [
        ""
      ],
      "Old access token": [
        ""
      ],
      "access token currently in use": [
        ""
      ],
      "New access token": [
        ""
      ],
      "next access token to be used": [
        ""
      ],
      "Repeat access token": [
        ""
      ],
      "confirm the same access token": [
        ""
      ],
      "Clearing the access token will mean public access to the instance": [
        ""
      ],
      "cannot be the same as the old access token": [
        ""
      ],
      "You are setting the access token for the new instance": [
        ""
      ],
      "With external authorization method no check will be done by the merchant backend": [
        ""
      ],
      "Set external authorization": [
        ""
      ],
      "Set access token": [
        ""
      ],
      "Operation in progress...": [
        ""
      ],
      "The operation will be automatically canceled after %1$s seconds": [
        ""
      ],
      "Instances": [
        ""
      ],
      "Delete": [
        ""
      ],
      "add new instance": [
        ""
      ],
      "ID": [
        ""
      ],
      "Name": [
        ""
      ],
      "Edit": [
        ""
      ],
      "Purge": [
        ""
      ],
      "There is no instances yet, add more pressing the + sign": [
        ""
      ],
      "Only show active instances": [
        ""
      ],
      "Active": [
        ""
      ],
      "Only show deleted instances": [
        ""
      ],
      "Deleted": [
        ""
      ],
      "Show all instances": [
        ""
      ],
      "All": [
        ""
      ],
      "Instance \"%1$s\" (ID: %2$s) has been deleted": [
        ""
      ],
      "Failed to delete instance": [
        ""
      ],
      "Instance '%1$s' (ID: %2$s) has been disabled": [
        ""
      ],
      "Failed to purge instance": [
        ""
      ],
      "Pending KYC verification": [
        ""
      ],
      "Timed out": [
        ""
      ],
      "Exchange": [
        ""
      ],
      "Target account": [
        ""
      ],
      "KYC URL": [
        ""
      ],
      "Code": [
        ""
      ],
      "Http Status": [
        ""
      ],
      "No pending kyc verification!": [
        ""
      ],
      "change value to unknown date": [
        ""
      ],
      "change value to empty": [
        ""
      ],
      "clear": [
        ""
      ],
      "change value to never": [
        ""
      ],
      "never": [
        ""
      ],
      "Country": [
        ""
      ],
      "Address": [
        ""
      ],
      "Building number": [
        ""
      ],
      "Building name": [
        ""
      ],
      "Street": [
        ""
      ],
      "Post code": [
        ""
      ],
      "Town location": [
        ""
      ],
      "Town": [
        ""
      ],
      "District": [
        ""
      ],
      "Country subdivision": [
        ""
      ],
      "Product id": [
        ""
      ],
      "Description": [
        ""
      ],
      "Product": [
        ""
      ],
      "search products by it's description or id": [
        ""
      ],
      "no products found with that description": [
        ""
      ],
      "You must enter a valid product identifier.": [
        ""
      ],
      "Quantity must be greater than 0!": [
        ""
      ],
      "This quantity exceeds remaining stock. Currently, only %1$s units remain unreserved in stock.": [
        ""
      ],
      "Quantity": [
        ""
      ],
      "how many products will be added": [
        ""
      ],
      "Add from inventory": [
        ""
      ],
      "Image should be smaller than 1 MB": [
        ""
      ],
      "Add": [
        ""
      ],
      "Remove": [
        ""
      ],
      "No taxes configured for this product.": [
        ""
      ],
      "Amount": [
        ""
      ],
      "Taxes can be in currencies that differ from the main currency used by the merchant.": [
        ""
      ],
      "Enter currency and value separated with a colon, e.g. &quot;USD:2.3&quot;.": [
        ""
      ],
      "Legal name of the tax, e.g. VAT or import duties.": [
        ""
      ],
      "add tax to the tax list": [
        ""
      ],
      "describe and add a product that is not in the inventory list": [
        ""
      ],
      "Add custom product": [
        ""
      ],
      "Complete information of the product": [
        ""
      ],
      "Image": [
        ""
      ],
      "photo of the product": [
        ""
      ],
      "full product description": [
        ""
      ],
      "Unit": [
        ""
      ],
      "name of the product unit": [
        ""
      ],
      "Price": [
        ""
      ],
      "amount in the current currency": [
        ""
      ],
      "Taxes": [
        ""
      ],
      "image": [
        ""
      ],
      "description": [
        ""
      ],
      "quantity": [
        ""
      ],
      "unit price": [
        ""
      ],
      "total price": [
        ""
      ],
      "required": [
        ""
      ],
      "not valid": [
        ""
      ],
      "must be greater than 0": [
        ""
      ],
      "not a valid json": [
        ""
      ],
      "should be in the future": [
        ""
      ],
      "refund deadline cannot be before pay deadline": [
        ""
      ],
      "wire transfer deadline cannot be before refund deadline": [
        ""
      ],
      "wire transfer deadline cannot be before pay deadline": [
        ""
      ],
      "should have a refund deadline": [
        ""
      ],
      "auto refund cannot be after refund deadline": [
        ""
      ],
      "Manage products in order": [
        ""
      ],
      "Manage list of products in the order.": [
        ""
      ],
      "Remove this product from the order.": [
        ""
      ],
      "Total price": [
        ""
      ],
      "total product price added up": [
        ""
      ],
      "Amount to be paid by the customer": [
        ""
      ],
      "Order price": [
        ""
      ],
      "final order price": [
        ""
      ],
      "Summary": [
        ""
      ],
      "Title of the order to be shown to the customer": [
        ""
      ],
      "Shipping and Fulfillment": [
        ""
      ],
      "Delivery date": [
        ""
      ],
      "Deadline for physical delivery assured by the merchant.": [
        ""
      ],
      "Location": [
        ""
      ],
      "address where the products will be delivered": [
        ""
      ],
      "Fulfillment URL": [
        ""
      ],
      "URL to which the user will be redirected after successful payment.": [
        ""
      ],
      "Taler payment options": [
        ""
      ],
      "Override default Taler payment settings for this order": [
        ""
      ],
      "Payment deadline": [
        ""
      ],
      "Deadline for the customer to pay for the offer before it expires. Inventory products will be reserved until this deadline.": [
        ""
      ],
      "Refund deadline": [
        ""
      ],
      "Time until which the order can be refunded by the merchant.": [
        ""
      ],
      "Wire transfer deadline": [
        ""
      ],
      "Deadline for the exchange to make the wire transfer.": [
        ""
      ],
      "Auto-refund deadline": [
        ""
      ],
      "Time until which the wallet will automatically check for refunds without user interaction.": [
        ""
      ],
      "Maximum deposit fee": [
        ""
      ],
      "Maximum deposit fees the merchant is willing to cover for this order. Higher deposit fees must be covered in full by the consumer.": [
        ""
      ],
      "Maximum wire fee": [
        ""
      ],
      "Maximum aggregate wire fees the merchant is willing to cover for this order. Wire fees exceeding this amount are to be covered by the customers.": [
        ""
      ],
      "Wire fee amortization": [
        ""
      ],
      "Factor by which wire fees exceeding the above threshold are divided to determine the share of excess wire fees to be paid explicitly by the consumer.": [
        ""
      ],
      "Create token": [
        ""
      ],
      "Uncheck this option if the merchant backend generated an order ID with enough entropy to prevent adversarial claims.": [
        ""
      ],
      "Minimum age required": [
        ""
      ],
      "Any value greater than 0 will limit the coins able be used to pay this contract. If empty the age restriction will be defined by the products": [
        ""
      ],
      "Min age defined by the producs is %1$s": [
        ""
      ],
      "Additional information": [
        ""
      ],
      "Custom information to be included in the contract for this order.": [
        ""
      ],
      "You must enter a value in JavaScript Object Notation (JSON).": [
        ""
      ],
      "days": [
        ""
      ],
      "hours": [
        ""
      ],
      "minutes": [
        ""
      ],
      "seconds": [
        ""
      ],
      "forever": [
        ""
      ],
      "%1$sM": [
        ""
      ],
      "%1$sY": [
        ""
      ],
      "%1$sd": [
        ""
      ],
      "%1$sh": [
        ""
      ],
      "%1$smin": [
        ""
      ],
      "%1$ssec": [
        ""
      ],
      "Orders": [
        ""
      ],
      "create order": [
        ""
      ],
      "load newer orders": [
        ""
      ],
      "Date": [
        ""
      ],
      "Refund": [
        ""
      ],
      "copy url": [
        ""
      ],
      "load older orders": [
        ""
      ],
      "No orders have been found matching your query!": [
        ""
      ],
      "duplicated": [
        ""
      ],
      "invalid format": [
        ""
      ],
      "this value exceed the refundable amount": [
        ""
      ],
      "date": [
        ""
      ],
      "amount": [
        ""
      ],
      "reason": [
        ""
      ],
      "amount to be refunded": [
        ""
      ],
      "Max refundable:": [
        ""
      ],
      "Reason": [
        ""
      ],
      "Choose one...": [
        ""
      ],
      "requested by the customer": [
        ""
      ],
      "other": [
        ""
      ],
      "why this order is being refunded": [
        ""
      ],
      "more information to give context": [
        ""
      ],
      "Contract Terms": [
        ""
      ],
      "human-readable description of the whole purchase": [
        ""
      ],
      "total price for the transaction": [
        ""
      ],
      "URL for this purchase": [
        ""
      ],
      "Max fee": [
        ""
      ],
      "maximum total deposit fee accepted by the merchant for this contract": [
        ""
      ],
      "Max wire fee": [
        ""
      ],
      "maximum wire fee accepted by the merchant": [
        ""
      ],
      "over how many customer transactions does the merchant expect to amortize wire fees on average": [
        ""
      ],
      "Created at": [
        ""
      ],
      "time when this contract was generated": [
        ""
      ],
      "after this deadline has passed no refunds will be accepted": [
        ""
      ],
      "after this deadline, the merchant won't accept payments for the contract": [
        ""
      ],
      "transfer deadline for the exchange": [
        ""
      ],
      "time indicating when the order should be delivered": [
        ""
      ],
      "where the order will be delivered": [
        ""
      ],
      "Auto-refund delay": [
        ""
      ],
      "how long the wallet should try to get an automatic refund for the purchase": [
        ""
      ],
      "Extra info": [
        ""
      ],
      "extra data that is only interpreted by the merchant frontend": [
        ""
      ],
      "Order": [
        ""
      ],
      "claimed": [
        ""
      ],
      "claimed at": [
        ""
      ],
      "Timeline": [
        ""
      ],
      "Payment details": [
        ""
      ],
      "Order status": [
        ""
      ],
      "Product list": [
        ""
      ],
      "paid": [
        ""
      ],
      "wired": [
        ""
      ],
      "refunded": [
        ""
      ],
      "refund order": [
        ""
      ],
      "not refundable": [
        ""
      ],
      "refund": [
        ""
      ],
      "Refunded amount": [
        ""
      ],
      "Refund taken": [
        ""
      ],
      "Status URL": [
        ""
      ],
      "Refund URI": [
        ""
      ],
      "unpaid": [
        ""
      ],
      "pay at": [
        ""
      ],
      "created at": [
        ""
      ],
      "Order status URL": [
        ""
      ],
      "Payment URI": [
        ""
      ],
      "Unknown order status. This is an error, please contact the administrator.": [
        ""
      ],
      "Back": [
        ""
      ],
      "refund created successfully": [
        ""
      ],
      "could not create the refund": [
        ""
      ],
      "select date to show nearby orders": [
        ""
      ],
      "order id": [
        ""
      ],
      "jump to order with the given order ID": [
        ""
      ],
      "remove all filters": [
        ""
      ],
      "only show paid orders": [
        ""
      ],
      "Paid": [
        ""
      ],
      "only show orders with refunds": [
        ""
      ],
      "Refunded": [
        ""
      ],
      "only show orders where customers paid, but wire payments from payment provider are still pending": [
        ""
      ],
      "Not wired": [
        ""
      ],
      "clear date filter": [
        ""
      ],
      "date (YYYY/MM/DD)": [
        ""
      ],
      "Enter an order id": [
        ""
      ],
      "order not found": [
        ""
      ],
      "could not get the order to refund": [
        ""
      ],
      "Loading...": [
        ""
      ],
      "click here to configure the stock of the product, leave it as is and the backend will not control stock": [
        ""
      ],
      "Manage stock": [
        ""
      ],
      "this product has been configured without stock control": [
        ""
      ],
      "Infinite": [
        ""
      ],
      "lost cannot be greater than current and incoming (max %1$s)": [
        ""
      ],
      "Incoming": [
        ""
      ],
      "Lost": [
        ""
      ],
      "Current": [
        ""
      ],
      "remove stock control for this product": [
        ""
      ],
      "without stock": [
        ""
      ],
      "Next restock": [
        ""
      ],
      "Delivery address": [
        ""
      ],
      "product identification to use in URLs (for internal use only)": [
        ""
      ],
      "illustration of the product for customers": [
        ""
      ],
      "product description for customers": [
        ""
      ],
      "Age restricted": [
        ""
      ],
      "is this product restricted for customer below certain age?": [
        ""
      ],
      "unit describing quantity of product sold (e.g. 2 kilograms, 5 liters, 3 items, 5 meters) for customers": [
        ""
      ],
      "sale price for customers, including taxes, for above units of the product": [
        ""
      ],
      "Stock": [
        ""
      ],
      "product inventory for products with finite supply (for internal use only)": [
        ""
      ],
      "taxes included in the product price, exposed to customers": [
        ""
      ],
      "Need to complete marked fields": [
        ""
      ],
      "could not create product": [
        ""
      ],
      "Products": [
        ""
      ],
      "add product to inventory": [
        ""
      ],
      "Sell": [
        ""
      ],
      "Profit": [
        ""
      ],
      "Sold": [
        ""
      ],
      "free": [
        ""
      ],
      "go to product update page": [
        ""
      ],
      "Update": [
        ""
      ],
      "remove this product from the database": [
        ""
      ],
      "update the product with new price": [
        ""
      ],
      "update product with new price": [
        ""
      ],
      "add more elements to the inventory": [
        ""
      ],
      "report elements lost in the inventory": [
        ""
      ],
      "new price for the product": [
        ""
      ],
      "the are value with errors": [
        ""
      ],
      "update product with new stock and price": [
        ""
      ],
      "There is no products yet, add more pressing the + sign": [
        ""
      ],
      "product updated successfully": [
        ""
      ],
      "could not update the product": [
        ""
      ],
      "product delete successfully": [
        ""
      ],
      "could not delete the product": [
        ""
      ],
      "Product id:": [
        ""
      ],
      "To complete the setup of the reserve, you must now initiate a wire transfer using the given wire transfer subject and crediting the specified amount to the indicated account of the exchange.": [
        ""
      ],
      "If your system supports RFC 8905, you can do this by opening this URI:": [
        ""
      ],
      "it should be greater than 0": [
        ""
      ],
      "must be a valid URL": [
        ""
      ],
      "Initial balance": [
        ""
      ],
      "balance prior to deposit": [
        ""
      ],
      "Exchange URL": [
        ""
      ],
      "URL of exchange": [
        ""
      ],
      "Next": [
        ""
      ],
      "Wire method": [
        ""
      ],
      "method to use for wire transfer": [
        ""
      ],
      "Select one wire method": [
        ""
      ],
      "could not create reserve": [
        ""
      ],
      "Valid until": [
        ""
      ],
      "Created balance": [
        ""
      ],
      "Exchange balance": [
        ""
      ],
      "Picked up": [
        ""
      ],
      "Committed": [
        ""
      ],
      "Account address": [
        ""
      ],
      "Subject": [
        ""
      ],
      "Tips": [
        ""
      ],
      "No tips has been authorized from this reserve": [
        ""
      ],
      "Authorized": [
        ""
      ],
      "Expiration": [
        ""
      ],
      "amount of tip": [
        ""
      ],
      "Justification": [
        ""
      ],
      "reason for the tip": [
        ""
      ],
      "URL after tip": [
        ""
      ],
      "URL to visit after tip payment": [
        ""
      ],
      "Reserves not yet funded": [
        ""
      ],
      "Reserves ready": [
        ""
      ],
      "add new reserve": [
        ""
      ],
      "Expires at": [
        ""
      ],
      "Initial": [
        ""
      ],
      "delete selected reserve from the database": [
        ""
      ],
      "authorize new tip from selected reserve": [
        ""
      ],
      "There is no ready reserves yet, add more pressing the + sign or fund them": [
        ""
      ],
      "Expected Balance": [
        ""
      ],
      "could not create the tip": [
        ""
      ],
      "should not be empty": [
        ""
      ],
      "should be greater that 0": [
        ""
      ],
      "can't be empty": [
        ""
      ],
      "to short": [
        ""
      ],
      "just letters and numbers from 2 to 7": [
        ""
      ],
      "size of the key should be 32": [
        ""
      ],
      "Identifier": [
        ""
      ],
      "Name of the template in URLs.": [
        ""
      ],
      "Describe what this template stands for": [
        ""
      ],
      "Fixed summary": [
        ""
      ],
      "If specified, this template will create order with the same summary": [
        ""
      ],
      "Fixed price": [
        ""
      ],
      "If specified, this template will create order with the same price": [
        ""
      ],
      "Minimum age": [
        ""
      ],
      "Is this contract restricted to some age?": [
        ""
      ],
      "Payment timeout": [
        ""
      ],
      "How much time has the customer to complete the payment once the order was created.": [
        ""
      ],
      "Verification algorithm": [
        ""
      ],
      "Algorithm to use to verify transaction in offline mode": [
        ""
      ],
      "Point-of-sale key": [
        ""
      ],
      "Useful to validate the purchase": [
        ""
      ],
      "generate random secret key": [
        ""
      ],
      "random": [
        ""
      ],
      "show secret key": [
        ""
      ],
      "hide secret key": [
        ""
      ],
      "hide": [
        ""
      ],
      "show": [
        ""
      ],
      "could not inform template": [
        ""
      ],
      "Amount is required": [
        ""
      ],
      "Order summary is required": [
        ""
      ],
      "New order for template": [
        ""
      ],
      "Amount of the order": [
        ""
      ],
      "Order summary": [
        ""
      ],
      "could not create order from template": [
        ""
      ],
      "Here you can specify a default value for fields that are not fixed. Default values can be edited by the customer before the payment.": [
        ""
      ],
      "Fixed amount": [
        ""
      ],
      "Default amount": [
        ""
      ],
      "Default summary": [
        ""
      ],
      "Print": [
        ""
      ],
      "Setup TOTP": [
        ""
      ],
      "Templates": [
        ""
      ],
      "add new templates": [
        ""
      ],
      "load more templates before the first one": [
        ""
      ],
      "load newer templates": [
        ""
      ],
      "delete selected templates from the database": [
        ""
      ],
      "use template to create new order": [
        ""
      ],
      "create qr code for the template": [
        ""
      ],
      "load more templates after the last one": [
        ""
      ],
      "load older templates": [
        ""
      ],
      "There is no templates yet, add more pressing the + sign": [
        ""
      ],
      "template delete successfully": [
        ""
      ],
      "could not delete the template": [
        ""
      ],
      "could not update template": [
        ""
      ],
      "should be one of '%1$s'": [
        ""
      ],
      "Webhook ID to use": [
        ""
      ],
      "Event": [
        ""
      ],
      "The event of the webhook: why the webhook is used": [
        ""
      ],
      "Method": [
        ""
      ],
      "Method used by the webhook": [
        ""
      ],
      "URL": [
        ""
      ],
      "URL of the webhook where the customer will be redirected": [
        ""
      ],
      "Header": [
        ""
      ],
      "Header template of the webhook": [
        ""
      ],
      "Body": [
        ""
      ],
      "Body template by the webhook": [
        ""
      ],
      "Webhooks": [
        ""
      ],
      "add new webhooks": [
        ""
      ],
      "load more webhooks before the first one": [
        ""
      ],
      "load newer webhooks": [
        ""
      ],
      "Event type": [
        ""
      ],
      "delete selected webhook from the database": [
        ""
      ],
      "load more webhooks after the last one": [
        ""
      ],
      "load older webhooks": [
        ""
      ],
      "There is no webhooks yet, add more pressing the + sign": [
        ""
      ],
      "webhook delete successfully": [
        ""
      ],
      "could not delete the webhook": [
        ""
      ],
      "check the id, does not look valid": [
        ""
      ],
      "should have 52 characters, current %1$s": [
        ""
      ],
      "URL doesn't have the right format": [
        ""
      ],
      "Credited bank account": [
        ""
      ],
      "Select one account": [
        ""
      ],
      "Bank account of the merchant where the payment was received": [
        ""
      ],
      "Wire transfer ID": [
        ""
      ],
      "unique identifier of the wire transfer used by the exchange, must be 52 characters long": [
        ""
      ],
      "Base URL of the exchange that made the transfer, should have been in the wire transfer subject": [
        ""
      ],
      "Amount credited": [
        ""
      ],
      "Actual amount that was wired to the merchant's bank account": [
        ""
      ],
      "could not inform transfer": [
        ""
      ],
      "Transfers": [
        ""
      ],
      "add new transfer": [
        ""
      ],
      "load more transfers before the first one": [
        ""
      ],
      "load newer transfers": [
        ""
      ],
      "Credit": [
        ""
      ],
      "Confirmed": [
        ""
      ],
      "Verified": [
        ""
      ],
      "Executed at": [
        ""
      ],
      "yes": [
        ""
      ],
      "no": [
        ""
      ],
      "unknown": [
        ""
      ],
      "delete selected transfer from the database": [
        ""
      ],
      "load more transfer after the last one": [
        ""
      ],
      "load older transfers": [
        ""
      ],
      "There is no transfer yet, add more pressing the + sign": [
        ""
      ],
      "filter by account address": [
        ""
      ],
      "only show wire transfers confirmed by the merchant": [
        ""
      ],
      "only show wire transfers claimed by the exchange": [
        ""
      ],
      "Unverified": [
        ""
      ],
      "is not valid": [
        ""
      ],
      "is not a number": [
        ""
      ],
      "must be 1 or greater": [
        ""
      ],
      "max 7 lines": [
        ""
      ],
      "change authorization configuration": [
        ""
      ],
      "Need to complete marked fields and choose authorization method": [
        ""
      ],
      "This is not a valid bitcoin address.": [
        ""
      ],
      "This is not a valid Ethereum address.": [
        ""
      ],
      "IBAN numbers usually have more that 4 digits": [
        ""
      ],
      "IBAN numbers usually have less that 34 digits": [
        ""
      ],
      "IBAN country code not found": [
        ""
      ],
      "IBAN number is not valid, checksum is wrong": [
        ""
      ],
      "Target type": [
        ""
      ],
      "Method to use for wire transfer": [
        ""
      ],
      "Routing": [
        ""
      ],
      "Routing number.": [
        ""
      ],
      "Account": [
        ""
      ],
      "Account number.": [
        ""
      ],
      "Business Identifier Code.": [
        ""
      ],
      "Bank Account Number.": [
        ""
      ],
      "Unified Payment Interface.": [
        ""
      ],
      "Bitcoin protocol.": [
        ""
      ],
      "Ethereum protocol.": [
        ""
      ],
      "Interledger protocol.": [
        ""
      ],
      "Host": [
        ""
      ],
      "Bank host.": [
        ""
      ],
      "Bank account.": [
        ""
      ],
      "Bank account owner's name.": [
        ""
      ],
      "No accounts yet.": [
        ""
      ],
      "Name of the instance in URLs. The 'default' instance is special in that it is used to administer other instances.": [
        ""
      ],
      "Business name": [
        ""
      ],
      "Legal name of the business represented by this instance.": [
        ""
      ],
      "Email": [
        ""
      ],
      "Contact email": [
        ""
      ],
      "Website URL": [
        ""
      ],
      "URL.": [
        ""
      ],
      "Logo": [
        ""
      ],
      "Logo image.": [
        ""
      ],
      "Bank account": [
        ""
      ],
      "URI specifying bank account for crediting revenue.": [
        ""
      ],
      "Default max deposit fee": [
        ""
      ],
      "Maximum deposit fees this merchant is willing to pay per order by default.": [
        ""
      ],
      "Default max wire fee": [
        ""
      ],
      "Maximum wire fees this merchant is willing to pay per wire transfer by default.": [
        ""
      ],
      "Default wire fee amortization": [
        ""
      ],
      "Number of orders excess wire transfer fees will be divided by to compute per order surcharge.": [
        ""
      ],
      "Physical location of the merchant.": [
        ""
      ],
      "Jurisdiction": [
        ""
      ],
      "Jurisdiction for legal disputes with the merchant.": [
        ""
      ],
      "Default payment delay": [
        ""
      ],
      "Time customers have to pay an order before the offer expires by default.": [
        ""
      ],
      "Default wire transfer delay": [
        ""
      ],
      "Maximum time an exchange is allowed to delay wiring funds to the merchant, enabling it to aggregate smaller payments into larger wire transfers and reducing wire fees.": [
        ""
      ],
      "Instance id": [
        ""
      ],
      "Change the authorization method use for this instance.": [
        ""
      ],
      "Manage access token": [
        ""
      ],
      "Failed to create instance": [
        ""
      ],
      "Login required": [
        ""
      ],
      "Please enter your access token.": [
        ""
      ],
      "Access Token": [
        ""
      ],
      "The request to the backend take too long and was cancelled": [
        ""
      ],
      "Diagnostic from %1$s is \"%2$s\"": [
        ""
      ],
      "The backend reported a problem: HTTP status #%1$s": [
        ""
      ],
      "Diagnostic from %1$s is '%2$s'": [
        ""
      ],
      "Access denied": [
        ""
      ],
      "The access token provided is invalid.": [
        ""
      ],
      "No 'default' instance configured yet.": [
        ""
      ],
      "Create a 'default' instance to begin using the merchant backoffice.": [
        ""
      ],
      "The access token provided is invalid": [
        ""
      ],
      "Hide for today": [
        ""
      ],
      "Instance": [
        ""
      ],
      "Settings": [
        ""
      ],
      "Connection": [
        ""
      ],
      "New": [
        ""
      ],
      "List": [
        ""
      ],
      "Log out": [
        ""
      ],
      "Check your token is valid": [
        ""
      ],
      "Couldn't access the server.": [
        ""
      ],
      "Could not infer instance id from url %1$s": [
        ""
      ],
      "Server not found": [
        ""
      ],
      "Server response with an error code": [
        ""
      ],
      "Got message %1$s from %2$s": [
        ""
      ],
      "Response from server is unreadable, http status: %1$s": [
        ""
      ],
      "Unexpected Error": [
        ""
      ],
      "The value %1$s is invalid for a payment url": [
        ""
      ],
      "add element to the list": [
        ""
      ],
      "add": [
        ""
      ],
      "Deleting": [
        ""
      ],
      "Changing": [
        ""
      ],
      "Order ID": [
        ""
      ],
      "Payment URL": [
        ""
      ]
    }
  }
};

strings['es'] = {
  "domain": "messages",
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=2; plural=n != 1;",
        "lang": "es"
      },
      "Cancel": [
        "Cancelar"
      ],
      "%1$s": [
        "%1$s"
      ],
      "Close": [
        ""
      ],
      "Continue": [
        "Continuar"
      ],
      "Clear": [
        "Limpiar"
      ],
      "Confirm": [
        "Confirmar"
      ],
      "is not the same as the current access token": [
        "no es el mismo que el token de acceso actual"
      ],
      "cannot be empty": [
        "no puede ser vacío"
      ],
      "cannot be the same as the old token": [
        "no puede ser igual al viejo token"
      ],
      "is not the same": [
        "no son iguales"
      ],
      "You are updating the access token from instance with id %1$s": [
        "Está actualizando el token de acceso para la instancia con id %1$s"
      ],
      "Old access token": [
        "Viejo token de acceso"
      ],
      "access token currently in use": [
        "acceder al token en uso actualmente"
      ],
      "New access token": [
        "Nuevo token de acceso"
      ],
      "next access token to be used": [
        "siguiente token de acceso a usar"
      ],
      "Repeat access token": [
        "Repetir token de acceso"
      ],
      "confirm the same access token": [
        "confirmar el mismo token de acceso"
      ],
      "Clearing the access token will mean public access to the instance": [
        "Limpiar el token de acceso significa acceso público a la instancia"
      ],
      "cannot be the same as the old access token": [
        "no puede ser igual al anterior token de acceso"
      ],
      "You are setting the access token for the new instance": [
        "Está estableciendo el token de acceso para la nueva instancia"
      ],
      "With external authorization method no check will be done by the merchant backend": [
        "Con el método de autorización externa no se hará ninguna revisión por el backend del comerciante"
      ],
      "Set external authorization": [
        "Establecer autorización externa"
      ],
      "Set access token": [
        "Establecer token de acceso"
      ],
      "Operation in progress...": [
        "Operación en progreso..."
      ],
      "The operation will be automatically canceled after %1$s seconds": [
        "La operación será automáticamente cancelada luego de %1$s segundos"
      ],
      "Instances": [
        "Instancias"
      ],
      "Delete": [
        "Eliminar"
      ],
      "add new instance": [
        "agregar nueva instancia"
      ],
      "ID": [
        "ID"
      ],
      "Name": [
        "Nombre"
      ],
      "Edit": [
        "Editar"
      ],
      "Purge": [
        "Purgar"
      ],
      "There is no instances yet, add more pressing the + sign": [
        "Todavía no hay instancias, agregue más presionando el signo +"
      ],
      "Only show active instances": [
        "Solo mostrar instancias activas"
      ],
      "Active": [
        "Activo"
      ],
      "Only show deleted instances": [
        "Mostrar solo instancias eliminadas"
      ],
      "Deleted": [
        "Eliminado"
      ],
      "Show all instances": [
        "Mostrar todas las instancias"
      ],
      "All": [
        "Todo"
      ],
      "Instance \"%1$s\" (ID: %2$s) has been deleted": [
        "La instancia '%1$s' (ID: %2$s) fue eliminada"
      ],
      "Failed to delete instance": [
        "Fallo al eliminar instancia"
      ],
      "Instance '%1$s' (ID: %2$s) has been disabled": [
        "Instance '%1$s' (ID: %2$s) ha sido deshabilitada"
      ],
      "Failed to purge instance": [
        "Fallo al purgar la instancia"
      ],
      "Pending KYC verification": [
        "Verificación KYC pendiente"
      ],
      "Timed out": [
        "Expirado"
      ],
      "Exchange": [
        "Exchange"
      ],
      "Target account": [
        "Cuenta objetivo"
      ],
      "KYC URL": [
        "URL de KYC"
      ],
      "Code": [
        "Código"
      ],
      "Http Status": [
        "Estado http"
      ],
      "No pending kyc verification!": [
        "¡No hay verificación kyc pendiente!"
      ],
      "change value to unknown date": [
        "cambiar valor a fecha desconocida"
      ],
      "change value to empty": [
        "cambiar valor a vacío"
      ],
      "clear": [
        "limpiar"
      ],
      "change value to never": [
        "cambiar valor a nunca"
      ],
      "never": [
        "nunca"
      ],
      "Country": [
        "País"
      ],
      "Address": [
        "Dirección"
      ],
      "Building number": [
        "Número de edificio"
      ],
      "Building name": [
        "Nombre de edificio"
      ],
      "Street": [
        "Calle"
      ],
      "Post code": [
        "Código postal"
      ],
      "Town location": [
        "Ubicación de ciudad"
      ],
      "Town": [
        "Ciudad"
      ],
      "District": [
        "Distrito"
      ],
      "Country subdivision": [
        "Subdivisión de país"
      ],
      "Product id": [
        "Id de producto"
      ],
      "Description": [
        "Descripcion"
      ],
      "Product": [
        "Productos"
      ],
      "search products by it's description or id": [
        "buscar productos por su descripción o ID"
      ],
      "no products found with that description": [
        "no se encontraron productos con esa descripción"
      ],
      "You must enter a valid product identifier.": [
        "Debe ingresar un identificador de producto válido."
      ],
      "Quantity must be greater than 0!": [
        "¡Cantidad debe ser mayor que 0!"
      ],
      "This quantity exceeds remaining stock. Currently, only %1$s units remain unreserved in stock.": [
        "Esta cantidad excede las existencias restantes. Actualmente, solo quedan %1$s unidades sin reservar en las existencias."
      ],
      "Quantity": [
        "Cantidad"
      ],
      "how many products will be added": [
        "cuántos productos serán agregados"
      ],
      "Add from inventory": [
        "Agregar del inventario"
      ],
      "Image should be smaller than 1 MB": [
        "La imagen debe ser mas chica que 1 MB"
      ],
      "Add": [
        "Agregar"
      ],
      "Remove": [
        "Eliminar"
      ],
      "No taxes configured for this product.": [
        "Ningun impuesto configurado para este producto."
      ],
      "Amount": [
        "Monto"
      ],
      "Taxes can be in currencies that differ from the main currency used by the merchant.": [
        "Impuestos pueden estar en divisas que difieren de la principal divisa usada por el comerciante."
      ],
      "Enter currency and value separated with a colon, e.g. &quot;USD:2.3&quot;.": [
        "Ingrese divisa y valor separado por dos puntos, e.g. &quot;USD:2.3&quot;."
      ],
      "Legal name of the tax, e.g. VAT or import duties.": [
        "Nombre legal del impuesto, e.g. IVA o arancel."
      ],
      "add tax to the tax list": [
        "agregar impuesto a la lista de impuestos"
      ],
      "describe and add a product that is not in the inventory list": [
        "describa y agregue un producto que no está en la lista de inventarios"
      ],
      "Add custom product": [
        "Agregue un producto personalizado"
      ],
      "Complete information of the product": [
        "Complete información del producto"
      ],
      "Image": [
        "Imagen"
      ],
      "photo of the product": [
        "foto del producto"
      ],
      "full product description": [
        "descripción completa del producto"
      ],
      "Unit": [
        "Unidad"
      ],
      "name of the product unit": [
        "nombre de la unidad del producto"
      ],
      "Price": [
        "Precio"
      ],
      "amount in the current currency": [
        "monto de la divisa actual"
      ],
      "Taxes": [
        "Impuestos"
      ],
      "image": [
        "imagen"
      ],
      "description": [
        "descripción"
      ],
      "quantity": [
        "cantidad"
      ],
      "unit price": [
        "precio unitario"
      ],
      "total price": [
        "precio total"
      ],
      "required": [
        "requerido"
      ],
      "not valid": [
        "no es un json válido"
      ],
      "must be greater than 0": [
        "debe ser mayor que 0"
      ],
      "not a valid json": [
        "no es un json válido"
      ],
      "should be in the future": [
        "deberían ser en el futuro"
      ],
      "refund deadline cannot be before pay deadline": [
        "plazo de reembolso no puede ser antes que el plazo de pago"
      ],
      "wire transfer deadline cannot be before refund deadline": [
        "el plazo de la transferencia bancaria no puede ser antes que el plazo de reembolso"
      ],
      "wire transfer deadline cannot be before pay deadline": [
        "el plazo de la transferencia bancaria no puede ser antes que el plazo de pago"
      ],
      "should have a refund deadline": [
        "debería tener un plazo de reembolso"
      ],
      "auto refund cannot be after refund deadline": [
        "reembolso automático no puede ser después qu el plazo de reembolso"
      ],
      "Manage products in order": [
        "Manejar productos en orden"
      ],
      "Manage list of products in the order.": [
        "Manejar lista de productos en la orden."
      ],
      "Remove this product from the order.": [
        "Remover este producto de la orden."
      ],
      "Total price": [
        "Precio total"
      ],
      "total product price added up": [
        "precio total de producto agregado"
      ],
      "Amount to be paid by the customer": [
        "Monto a ser pagado por el cliente"
      ],
      "Order price": [
        "Precio de la orden"
      ],
      "final order price": [
        "Precio final de la orden"
      ],
      "Summary": [
        "Resumen"
      ],
      "Title of the order to be shown to the customer": [
        "Título de la orden a ser mostrado al cliente"
      ],
      "Shipping and Fulfillment": [
        "Envío y cumplimiento"
      ],
      "Delivery date": [
        "Fecha de entrega"
      ],
      "Deadline for physical delivery assured by the merchant.": [
        "Plazo para la entrega física asegurado por el comerciante."
      ],
      "Location": [
        "Ubicación"
      ],
      "address where the products will be delivered": [
        "dirección a donde los productos serán entregados"
      ],
      "Fulfillment URL": [
        "URL de cumplimiento"
      ],
      "URL to which the user will be redirected after successful payment.": [
        "URL al cual el usuario será redirigido luego de pago exitoso."
      ],
      "Taler payment options": [
        "Opciones de pago de Taler"
      ],
      "Override default Taler payment settings for this order": [
        "Sobreescribir pagos por omisión de Taler para esta orden"
      ],
      "Payment deadline": [
        "Plazo de pago"
      ],
      "Deadline for the customer to pay for the offer before it expires. Inventory products will be reserved until this deadline.": [
        "Plazo límite para que el cliente pague por la oferta antes de que expire. Productos del inventario serán reservados hasta este plazo límite."
      ],
      "Refund deadline": [
        "Plazo de reembolso"
      ],
      "Time until which the order can be refunded by the merchant.": [
        "Tiempo hasta el cual la orden puede ser reembolsada por el comerciante."
      ],
      "Wire transfer deadline": [
        "Plazo de la transferencia"
      ],
      "Deadline for the exchange to make the wire transfer.": [
        "Plazo para que el exchange haga la transferencia."
      ],
      "Auto-refund deadline": [
        "Plazo de reembolso automático"
      ],
      "Time until which the wallet will automatically check for refunds without user interaction.": [
        "Tiempo hasta el cual la billetera será automáticamente revisada por reembolsos win interación por parte del usuario."
      ],
      "Maximum deposit fee": [
        "Máxima tarifa de depósito"
      ],
      "Maximum deposit fees the merchant is willing to cover for this order. Higher deposit fees must be covered in full by the consumer.": [
        "Máxima tarifa de depósito que el comerciante esta dispuesto a cubir para esta orden. Mayores tarifas de depósito deben ser cubiertas completamente por el consumidor."
      ],
      "Maximum wire fee": [
        "Máxima tarifa de transferencia"
      ],
      "Maximum aggregate wire fees the merchant is willing to cover for this order. Wire fees exceeding this amount are to be covered by the customers.": [
        ""
      ],
      "Wire fee amortization": [
        "Amortización de comisión de transferencia"
      ],
      "Factor by which wire fees exceeding the above threshold are divided to determine the share of excess wire fees to be paid explicitly by the consumer.": [
        ""
      ],
      "Create token": [
        "Administrar token"
      ],
      "Uncheck this option if the merchant backend generated an order ID with enough entropy to prevent adversarial claims.": [
        ""
      ],
      "Minimum age required": [
        "Login necesario"
      ],
      "Any value greater than 0 will limit the coins able be used to pay this contract. If empty the age restriction will be defined by the products": [
        ""
      ],
      "Min age defined by the producs is %1$s": [
        ""
      ],
      "Additional information": [
        "Información extra"
      ],
      "Custom information to be included in the contract for this order.": [
        ""
      ],
      "You must enter a value in JavaScript Object Notation (JSON).": [
        ""
      ],
      "days": [
        "días"
      ],
      "hours": [
        "horas"
      ],
      "minutes": [
        "minutos"
      ],
      "seconds": [
        "segundos"
      ],
      "forever": [
        "nunca"
      ],
      "%1$sM": [
        ""
      ],
      "%1$sY": [
        ""
      ],
      "%1$sd": [
        ""
      ],
      "%1$sh": [
        ""
      ],
      "%1$smin": [
        ""
      ],
      "%1$ssec": [
        ""
      ],
      "Orders": [
        "Órdenes"
      ],
      "create order": [
        "creado"
      ],
      "load newer orders": [
        "cargar nuevas ordenes"
      ],
      "Date": [
        "Fecha"
      ],
      "Refund": [
        "Devolución"
      ],
      "copy url": [
        "copiar url"
      ],
      "load older orders": [
        "cargar viejas ordenes"
      ],
      "No orders have been found matching your query!": [
        "¡No se encontraron órdenes que emparejen su búsqueda!"
      ],
      "duplicated": [
        "duplicado"
      ],
      "invalid format": [
        "formato inválido"
      ],
      "this value exceed the refundable amount": [
        "este monto excede el monto reembolsable"
      ],
      "date": [
        "fecha"
      ],
      "amount": [
        "monto"
      ],
      "reason": [
        "razón"
      ],
      "amount to be refunded": [
        "monto a ser reembolsado"
      ],
      "Max refundable:": [
        "Máximo reembolzable:"
      ],
      "Reason": [
        "Razón"
      ],
      "Choose one...": [
        "Elija uno..."
      ],
      "requested by the customer": [
        "pedido por el consumidor"
      ],
      "other": [
        "otro"
      ],
      "why this order is being refunded": [
        "por qué esta orden está siendo reembolsada"
      ],
      "more information to give context": [
        "más información para dar contexto"
      ],
      "Contract Terms": [
        "Términos de contrato"
      ],
      "human-readable description of the whole purchase": [
        "descripción legible de toda la compra"
      ],
      "total price for the transaction": [
        "precio total de la transacción"
      ],
      "URL for this purchase": [
        "URL para esta compra"
      ],
      "Max fee": [
        "Máxima comisión"
      ],
      "maximum total deposit fee accepted by the merchant for this contract": [
        ""
      ],
      "Max wire fee": [
        "Impuesto de transferencia máximo"
      ],
      "maximum wire fee accepted by the merchant": [
        ""
      ],
      "over how many customer transactions does the merchant expect to amortize wire fees on average": [
        ""
      ],
      "Created at": [
        "Creado en"
      ],
      "time when this contract was generated": [
        ""
      ],
      "after this deadline has passed no refunds will be accepted": [
        ""
      ],
      "after this deadline, the merchant won't accept payments for the contract": [
        ""
      ],
      "transfer deadline for the exchange": [
        ""
      ],
      "time indicating when the order should be delivered": [
        ""
      ],
      "where the order will be delivered": [
        ""
      ],
      "Auto-refund delay": [
        "Plazo de reembolso automático"
      ],
      "how long the wallet should try to get an automatic refund for the purchase": [
        ""
      ],
      "Extra info": [
        "Información extra"
      ],
      "extra data that is only interpreted by the merchant frontend": [
        ""
      ],
      "Order": [
        "Orden"
      ],
      "claimed": [
        "reclamado"
      ],
      "claimed at": [
        "reclamado"
      ],
      "Timeline": [
        "Cronología"
      ],
      "Payment details": [
        "Detalles de pago"
      ],
      "Order status": [
        "Estado de orden"
      ],
      "Product list": [
        "Lista de producto"
      ],
      "paid": [
        "pagados"
      ],
      "wired": [
        "transferido"
      ],
      "refunded": [
        "reembolzado"
      ],
      "refund order": [
        "reembolzado"
      ],
      "not refundable": [
        "Máximo reembolzable:"
      ],
      "refund": [
        "reembolzar"
      ],
      "Refunded amount": [
        "Monto reembolzado"
      ],
      "Refund taken": [
        "Reembolzado"
      ],
      "Status URL": [
        "URL de estado de orden"
      ],
      "Refund URI": [
        "Devolución"
      ],
      "unpaid": [
        "impago"
      ],
      "pay at": [
        "pagar en"
      ],
      "created at": [
        "creado"
      ],
      "Order status URL": [
        "URL de estado de orden"
      ],
      "Payment URI": [
        "URI de pago"
      ],
      "Unknown order status. This is an error, please contact the administrator.": [
        "Estado de orden desconocido. Esto es un error, por favor contacte a su administrador."
      ],
      "Back": [
        ""
      ],
      "refund created successfully": [
        "reembolzo creado satisfactoriamente"
      ],
      "could not create the refund": [
        "No se pudo create el reembolso"
      ],
      "select date to show nearby orders": [
        ""
      ],
      "order id": [
        "ir a id de orden"
      ],
      "jump to order with the given order ID": [
        ""
      ],
      "remove all filters": [
        ""
      ],
      "only show paid orders": [
        ""
      ],
      "Paid": [
        "Pagado"
      ],
      "only show orders with refunds": [
        "No se pudo create el reembolso"
      ],
      "Refunded": [
        "Reembolzado"
      ],
      "only show orders where customers paid, but wire payments from payment provider are still pending": [
        ""
      ],
      "Not wired": [
        "No transferido"
      ],
      "clear date filter": [
        ""
      ],
      "date (YYYY/MM/DD)": [
        ""
      ],
      "Enter an order id": [
        "ir a id de orden"
      ],
      "order not found": [
        "Servidor no encontrado"
      ],
      "could not get the order to refund": [
        "No se pudo create el reembolso"
      ],
      "Loading...": [
        "Cargando..."
      ],
      "click here to configure the stock of the product, leave it as is and the backend will not control stock": [
        ""
      ],
      "Manage stock": [
        "Administrar stock"
      ],
      "this product has been configured without stock control": [
        ""
      ],
      "Infinite": [
        "Inifinito"
      ],
      "lost cannot be greater than current and incoming (max %1$s)": [
        "la pérdida no puede ser mayor al stock actual + entrante (max %1$s )"
      ],
      "Incoming": [
        "Ingresando"
      ],
      "Lost": [
        "Perdido"
      ],
      "Current": [
        "Actual"
      ],
      "remove stock control for this product": [
        ""
      ],
      "without stock": [
        "sin stock"
      ],
      "Next restock": [
        "Próximo reabastecimiento"
      ],
      "Delivery address": [
        "Dirección de entrega"
      ],
      "product identification to use in URLs (for internal use only)": [
        ""
      ],
      "illustration of the product for customers": [
        ""
      ],
      "product description for customers": [
        ""
      ],
      "Age restricted": [
        ""
      ],
      "is this product restricted for customer below certain age?": [
        ""
      ],
      "unit describing quantity of product sold (e.g. 2 kilograms, 5 liters, 3 items, 5 meters) for customers": [
        ""
      ],
      "sale price for customers, including taxes, for above units of the product": [
        ""
      ],
      "Stock": [
        "Existencias"
      ],
      "product inventory for products with finite supply (for internal use only)": [
        ""
      ],
      "taxes included in the product price, exposed to customers": [
        ""
      ],
      "Need to complete marked fields": [
        ""
      ],
      "could not create product": [
        "no se pudo crear el producto"
      ],
      "Products": [
        "Productos"
      ],
      "add product to inventory": [
        ""
      ],
      "Sell": [
        "Venta"
      ],
      "Profit": [
        "Ganancia"
      ],
      "Sold": [
        "Vendido"
      ],
      "free": [
        "Gratis"
      ],
      "go to product update page": [
        "producto actualizado correctamente"
      ],
      "Update": [
        "Actualizar"
      ],
      "remove this product from the database": [
        ""
      ],
      "update the product with new price": [
        ""
      ],
      "update product with new price": [
        ""
      ],
      "add more elements to the inventory": [
        ""
      ],
      "report elements lost in the inventory": [
        ""
      ],
      "new price for the product": [
        "no se pudo actualizar el producto"
      ],
      "the are value with errors": [
        ""
      ],
      "update product with new stock and price": [
        ""
      ],
      "There is no products yet, add more pressing the + sign": [
        "No hay propinas todavía, agregar mas presionando el signo +"
      ],
      "product updated successfully": [
        "producto actualizado correctamente"
      ],
      "could not update the product": [
        "no se pudo actualizar el producto"
      ],
      "product delete successfully": [
        "producto fue eliminado correctamente"
      ],
      "could not delete the product": [
        "no se pudo eliminar el producto"
      ],
      "Product id:": [
        "Id de producto"
      ],
      "To complete the setup of the reserve, you must now initiate a wire transfer using the given wire transfer subject and crediting the specified amount to the indicated account of the exchange.": [
        ""
      ],
      "If your system supports RFC 8905, you can do this by opening this URI:": [
        ""
      ],
      "it should be greater than 0": [
        "Debe ser mayor a 0"
      ],
      "must be a valid URL": [
        ""
      ],
      "Initial balance": [
        "Instancia"
      ],
      "balance prior to deposit": [
        ""
      ],
      "Exchange URL": [
        "URL del Exchange"
      ],
      "URL of exchange": [
        ""
      ],
      "Next": [
        "Siguiente"
      ],
      "Wire method": [
        ""
      ],
      "method to use for wire transfer": [
        "no se pudo informar la transferencia"
      ],
      "Select one wire method": [
        ""
      ],
      "could not create reserve": [
        "No se pudo create el reembolso"
      ],
      "Valid until": [
        "Válido hasta"
      ],
      "Created balance": [
        "creado"
      ],
      "Exchange balance": [
        "Monto inicial"
      ],
      "Picked up": [
        ""
      ],
      "Committed": [
        "Monto confirmado"
      ],
      "Account address": [
        "Dirección de cuenta"
      ],
      "Subject": [
        "Asunto"
      ],
      "Tips": [
        "Propinas"
      ],
      "No tips has been authorized from this reserve": [
        ""
      ],
      "Authorized": [
        "Token de autorización"
      ],
      "Expiration": [
        "Información extra"
      ],
      "amount of tip": [
        "monto"
      ],
      "Justification": [
        "Jurisdicción"
      ],
      "reason for the tip": [
        ""
      ],
      "URL after tip": [
        ""
      ],
      "URL to visit after tip payment": [
        ""
      ],
      "Reserves not yet funded": [
        "Servidor no encontrado"
      ],
      "Reserves ready": [
        ""
      ],
      "add new reserve": [
        "cargar nuevas transferencias"
      ],
      "Expires at": [
        ""
      ],
      "Initial": [
        ""
      ],
      "delete selected reserve from the database": [
        ""
      ],
      "authorize new tip from selected reserve": [
        ""
      ],
      "There is no ready reserves yet, add more pressing the + sign or fund them": [
        "No hay transferencias todavía, agregar mas presionando el signo +"
      ],
      "Expected Balance": [
        "Ejecutado en"
      ],
      "could not create the tip": [
        "No se pudo create el reembolso"
      ],
      "should not be empty": [
        "no puede ser vacío"
      ],
      "should be greater that 0": [
        "Debe ser mayor a 0"
      ],
      "can't be empty": [
        "no puede ser vacío"
      ],
      "to short": [
        ""
      ],
      "just letters and numbers from 2 to 7": [
        ""
      ],
      "size of the key should be 32": [
        ""
      ],
      "Identifier": [
        ""
      ],
      "Name of the template in URLs.": [
        ""
      ],
      "Describe what this template stands for": [
        ""
      ],
      "Fixed summary": [
        "Estado de orden"
      ],
      "If specified, this template will create order with the same summary": [
        ""
      ],
      "Fixed price": [
        "precio unitario"
      ],
      "If specified, this template will create order with the same price": [
        ""
      ],
      "Minimum age": [
        "Edad mínima"
      ],
      "Is this contract restricted to some age?": [
        ""
      ],
      "Payment timeout": [
        "Opciones de pago"
      ],
      "How much time has the customer to complete the payment once the order was created.": [
        ""
      ],
      "Verification algorithm": [
        ""
      ],
      "Algorithm to use to verify transaction in offline mode": [
        ""
      ],
      "Point-of-sale key": [
        ""
      ],
      "Useful to validate the purchase": [
        ""
      ],
      "generate random secret key": [
        ""
      ],
      "random": [
        ""
      ],
      "show secret key": [
        ""
      ],
      "hide secret key": [
        ""
      ],
      "hide": [
        ""
      ],
      "show": [
        ""
      ],
      "could not inform template": [
        "no se pudo informar la transferencia"
      ],
      "Amount is required": [
        "Login necesario"
      ],
      "Order summary is required": [
        ""
      ],
      "New order for template": [
        "cargar viejas transferencias"
      ],
      "Amount of the order": [
        ""
      ],
      "Order summary": [
        "Estado de orden"
      ],
      "could not create order from template": [
        "No se pudo create el reembolso"
      ],
      "Here you can specify a default value for fields that are not fixed. Default values can be edited by the customer before the payment.": [
        ""
      ],
      "Fixed amount": [
        "Monto reembolzado"
      ],
      "Default amount": [
        "Monto reembolzado"
      ],
      "Default summary": [
        "Estado de orden"
      ],
      "Print": [
        ""
      ],
      "Setup TOTP": [
        ""
      ],
      "Templates": [
        ""
      ],
      "add new templates": [
        ""
      ],
      "load more templates before the first one": [
        ""
      ],
      "load newer templates": [
        "cargar nuevas transferencias"
      ],
      "delete selected templates from the database": [
        ""
      ],
      "use template to create new order": [
        ""
      ],
      "create qr code for the template": [
        "No se pudo create el reembolso"
      ],
      "load more templates after the last one": [
        ""
      ],
      "load older templates": [
        "cargar viejas transferencias"
      ],
      "There is no templates yet, add more pressing the + sign": [
        "No hay propinas todavía, agregar mas presionando el signo +"
      ],
      "template delete successfully": [
        "producto fue eliminado correctamente"
      ],
      "could not delete the template": [
        "no se pudo eliminar el producto"
      ],
      "could not update template": [
        "no se pudo actualizar el producto"
      ],
      "should be one of '%1$s'": [
        "deberían ser iguales"
      ],
      "Webhook ID to use": [
        ""
      ],
      "Event": [
        ""
      ],
      "The event of the webhook: why the webhook is used": [
        ""
      ],
      "Method": [
        ""
      ],
      "Method used by the webhook": [
        ""
      ],
      "URL": [
        "URL"
      ],
      "URL of the webhook where the customer will be redirected": [
        ""
      ],
      "Header": [
        ""
      ],
      "Header template of the webhook": [
        ""
      ],
      "Body": [
        ""
      ],
      "Body template by the webhook": [
        ""
      ],
      "Webhooks": [
        ""
      ],
      "add new webhooks": [
        ""
      ],
      "load more webhooks before the first one": [
        ""
      ],
      "load newer webhooks": [
        "cargar nuevas ordenes"
      ],
      "Event type": [
        ""
      ],
      "delete selected webhook from the database": [
        ""
      ],
      "load more webhooks after the last one": [
        ""
      ],
      "load older webhooks": [
        "cargar viejas ordenes"
      ],
      "There is no webhooks yet, add more pressing the + sign": [
        "No hay propinas todavía, agregar mas presionando el signo +"
      ],
      "webhook delete successfully": [
        "producto fue eliminado correctamente"
      ],
      "could not delete the webhook": [
        "no se pudo eliminar el producto"
      ],
      "check the id, does not look valid": [
        "verificar el id, no parece válido"
      ],
      "should have 52 characters, current %1$s": [
        "debería tener 52 caracteres, actualmente %1$s"
      ],
      "URL doesn't have the right format": [
        "La URL no tiene el formato correcto"
      ],
      "Credited bank account": [
        ""
      ],
      "Select one account": [
        ""
      ],
      "Bank account of the merchant where the payment was received": [
        ""
      ],
      "Wire transfer ID": [
        "Id de transferencia"
      ],
      "unique identifier of the wire transfer used by the exchange, must be 52 characters long": [
        ""
      ],
      "Base URL of the exchange that made the transfer, should have been in the wire transfer subject": [
        ""
      ],
      "Amount credited": [
        ""
      ],
      "Actual amount that was wired to the merchant's bank account": [
        ""
      ],
      "could not inform transfer": [
        "no se pudo informar la transferencia"
      ],
      "Transfers": [
        "Transferencias"
      ],
      "add new transfer": [
        "cargar nuevas transferencias"
      ],
      "load more transfers before the first one": [
        ""
      ],
      "load newer transfers": [
        "cargar nuevas transferencias"
      ],
      "Credit": [
        "Crédito"
      ],
      "Confirmed": [
        "Confirmado"
      ],
      "Verified": [
        "Verificado"
      ],
      "Executed at": [
        "Ejecutado en"
      ],
      "yes": [
        "si"
      ],
      "no": [
        "no"
      ],
      "unknown": [
        "desconocido"
      ],
      "delete selected transfer from the database": [
        "eliminar transferencia seleccionada de la base de datos"
      ],
      "load more transfer after the last one": [
        "cargue más transferencia luego de la última"
      ],
      "load older transfers": [
        "cargar viejas transferencias"
      ],
      "There is no transfer yet, add more pressing the + sign": [
        "No hay transferencias todavía, agregar mas presionando el signo +"
      ],
      "filter by account address": [
        "Dirección de cuenta"
      ],
      "only show wire transfers confirmed by the merchant": [
        ""
      ],
      "only show wire transfers claimed by the exchange": [
        ""
      ],
      "Unverified": [
        "Verificado"
      ],
      "is not valid": [
        ""
      ],
      "is not a number": [
        "Número de edificio"
      ],
      "must be 1 or greater": [
        "debe ser 1 o mayor"
      ],
      "max 7 lines": [
        "máximo 7 líneas"
      ],
      "change authorization configuration": [
        "cambiar configuración de autorización"
      ],
      "Need to complete marked fields and choose authorization method": [
        "Necesita completar campos marcados y escoger un método de autorización"
      ],
      "This is not a valid bitcoin address.": [
        "Esta no es una dirección de bitcoin válida."
      ],
      "This is not a valid Ethereum address.": [
        "Esta no es una dirección de Ethereum válida."
      ],
      "IBAN numbers usually have more that 4 digits": [
        "Números IBAN usualmente tienen más de 4 dígitos"
      ],
      "IBAN numbers usually have less that 34 digits": [
        "Número IBAN usualmente tienen menos de 34 dígitos"
      ],
      "IBAN country code not found": [
        "Código IBAN de país no encontrado"
      ],
      "IBAN number is not valid, checksum is wrong": [
        "Número IBAN no es válido, la suma de verificación es incorrecta"
      ],
      "Target type": [
        "Tipo objetivo"
      ],
      "Method to use for wire transfer": [
        "Método a usar para la transferencia"
      ],
      "Routing": [
        "Enrutamiento"
      ],
      "Routing number.": [
        "Número de enrutamiento."
      ],
      "Account": [
        "Cuenta"
      ],
      "Account number.": [
        "Dirección de cuenta"
      ],
      "Business Identifier Code.": [
        ""
      ],
      "Bank Account Number.": [
        ""
      ],
      "Unified Payment Interface.": [
        "Interfaz de pago unificado."
      ],
      "Bitcoin protocol.": [
        ""
      ],
      "Ethereum protocol.": [
        ""
      ],
      "Interledger protocol.": [
        ""
      ],
      "Host": [
        ""
      ],
      "Bank host.": [
        ""
      ],
      "Bank account.": [
        ""
      ],
      "Bank account owner's name.": [
        ""
      ],
      "No accounts yet.": [
        ""
      ],
      "Name of the instance in URLs. The 'default' instance is special in that it is used to administer other instances.": [
        ""
      ],
      "Business name": [
        "Nombre de edificio"
      ],
      "Legal name of the business represented by this instance.": [
        ""
      ],
      "Email": [
        ""
      ],
      "Contact email": [
        ""
      ],
      "Website URL": [
        "URL de sitio web"
      ],
      "URL.": [
        ""
      ],
      "Logo": [
        ""
      ],
      "Logo image.": [
        ""
      ],
      "Bank account": [
        "Cuenta bancaria"
      ],
      "URI specifying bank account for crediting revenue.": [
        ""
      ],
      "Default max deposit fee": [
        "Impuesto máximo de deposito por omisión"
      ],
      "Maximum deposit fees this merchant is willing to pay per order by default.": [
        ""
      ],
      "Default max wire fee": [
        "Impuesto máximo de transferencia por omisión"
      ],
      "Maximum wire fees this merchant is willing to pay per wire transfer by default.": [
        ""
      ],
      "Default wire fee amortization": [
        "Amortización de impuesto de transferencia por omisión"
      ],
      "Number of orders excess wire transfer fees will be divided by to compute per order surcharge.": [
        ""
      ],
      "Physical location of the merchant.": [
        ""
      ],
      "Jurisdiction": [
        "Jurisdicción"
      ],
      "Jurisdiction for legal disputes with the merchant.": [
        "Jurisdicción para disputas legales con el comerciante."
      ],
      "Default payment delay": [
        "Retrazo de pago por omisión"
      ],
      "Time customers have to pay an order before the offer expires by default.": [
        ""
      ],
      "Default wire transfer delay": [
        "Retrazo de transferencia por omisión"
      ],
      "Maximum time an exchange is allowed to delay wiring funds to the merchant, enabling it to aggregate smaller payments into larger wire transfers and reducing wire fees.": [
        ""
      ],
      "Instance id": [
        "ID de instancia"
      ],
      "Change the authorization method use for this instance.": [
        "Limpiar el token de autorización significa acceso público a la instancia"
      ],
      "Manage access token": [
        "Administrar token de acceso"
      ],
      "Failed to create instance": [
        "Fallo al crear la instancia"
      ],
      "Login required": [
        "Login necesario"
      ],
      "Please enter your access token.": [
        ""
      ],
      "Access Token": [
        "Acceso denegado"
      ],
      "The request to the backend take too long and was cancelled": [
        ""
      ],
      "Diagnostic from %1$s is \"%2$s\"": [
        ""
      ],
      "The backend reported a problem: HTTP status #%1$s": [
        "Servidir reporto un problema: HTTP status #%1$s"
      ],
      "Diagnostic from %1$s is '%2$s'": [
        ""
      ],
      "Access denied": [
        "Acceso denegado"
      ],
      "The access token provided is invalid.": [
        ""
      ],
      "No 'default' instance configured yet.": [
        "Sin instancia default"
      ],
      "Create a 'default' instance to begin using the merchant backoffice.": [
        ""
      ],
      "The access token provided is invalid": [
        ""
      ],
      "Hide for today": [
        ""
      ],
      "Instance": [
        "Instancia"
      ],
      "Settings": [
        "Configuración"
      ],
      "Connection": [
        "Conexión"
      ],
      "New": [
        "Nuevo"
      ],
      "List": [
        "Lista"
      ],
      "Log out": [
        "Salir"
      ],
      "Check your token is valid": [
        "Verifica que el token sea valido"
      ],
      "Couldn't access the server.": [
        "No se pudo acceder al servidor."
      ],
      "Could not infer instance id from url %1$s": [
        "No se pudo inferir el id de la instancia con la url %1$s"
      ],
      "Server not found": [
        "Servidor no encontrado"
      ],
      "Server response with an error code": [
        ""
      ],
      "Got message %1$s from %2$s": [
        "Recibimos el mensaje %1$s desde %2$s"
      ],
      "Response from server is unreadable, http status: %1$s": [
        ""
      ],
      "Unexpected Error": [
        "Error inesperado"
      ],
      "The value %1$s is invalid for a payment url": [
        "El valor %1$s es invalido para una URL de pago"
      ],
      "add element to the list": [
        "agregar elemento a la lista"
      ],
      "add": [
        "Agregar"
      ],
      "Deleting": [
        "Borrando"
      ],
      "Changing": [
        "Cambiando"
      ],
      "Order ID": [
        "ID de pedido"
      ],
      "Payment URL": [
        "URL de pago"
      ]
    }
  }
};

strings['fr'] = {
  "domain": "messages",
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=2; plural=(n != 1);",
        "lang": ""
      },
      "Cancel": [
        ""
      ],
      "%1$s": [
        ""
      ],
      "Close": [
        ""
      ],
      "Continue": [
        ""
      ],
      "Clear": [
        ""
      ],
      "Confirm": [
        ""
      ],
      "is not the same as the current access token": [
        ""
      ],
      "cannot be empty": [
        ""
      ],
      "cannot be the same as the old token": [
        ""
      ],
      "is not the same": [
        ""
      ],
      "You are updating the access token from instance with id %1$s": [
        ""
      ],
      "Old access token": [
        ""
      ],
      "access token currently in use": [
        ""
      ],
      "New access token": [
        ""
      ],
      "next access token to be used": [
        ""
      ],
      "Repeat access token": [
        ""
      ],
      "confirm the same access token": [
        ""
      ],
      "Clearing the access token will mean public access to the instance": [
        ""
      ],
      "cannot be the same as the old access token": [
        ""
      ],
      "You are setting the access token for the new instance": [
        ""
      ],
      "With external authorization method no check will be done by the merchant backend": [
        ""
      ],
      "Set external authorization": [
        ""
      ],
      "Set access token": [
        ""
      ],
      "Operation in progress...": [
        ""
      ],
      "The operation will be automatically canceled after %1$s seconds": [
        ""
      ],
      "Instances": [
        ""
      ],
      "Delete": [
        ""
      ],
      "add new instance": [
        ""
      ],
      "ID": [
        ""
      ],
      "Name": [
        ""
      ],
      "Edit": [
        ""
      ],
      "Purge": [
        ""
      ],
      "There is no instances yet, add more pressing the + sign": [
        ""
      ],
      "Only show active instances": [
        ""
      ],
      "Active": [
        ""
      ],
      "Only show deleted instances": [
        ""
      ],
      "Deleted": [
        ""
      ],
      "Show all instances": [
        ""
      ],
      "All": [
        ""
      ],
      "Instance \"%1$s\" (ID: %2$s) has been deleted": [
        ""
      ],
      "Failed to delete instance": [
        ""
      ],
      "Instance '%1$s' (ID: %2$s) has been disabled": [
        ""
      ],
      "Failed to purge instance": [
        ""
      ],
      "Pending KYC verification": [
        ""
      ],
      "Timed out": [
        ""
      ],
      "Exchange": [
        ""
      ],
      "Target account": [
        ""
      ],
      "KYC URL": [
        ""
      ],
      "Code": [
        ""
      ],
      "Http Status": [
        ""
      ],
      "No pending kyc verification!": [
        ""
      ],
      "change value to unknown date": [
        ""
      ],
      "change value to empty": [
        ""
      ],
      "clear": [
        ""
      ],
      "change value to never": [
        ""
      ],
      "never": [
        ""
      ],
      "Country": [
        ""
      ],
      "Address": [
        ""
      ],
      "Building number": [
        ""
      ],
      "Building name": [
        ""
      ],
      "Street": [
        ""
      ],
      "Post code": [
        ""
      ],
      "Town location": [
        ""
      ],
      "Town": [
        ""
      ],
      "District": [
        ""
      ],
      "Country subdivision": [
        ""
      ],
      "Product id": [
        ""
      ],
      "Description": [
        ""
      ],
      "Product": [
        ""
      ],
      "search products by it's description or id": [
        ""
      ],
      "no products found with that description": [
        ""
      ],
      "You must enter a valid product identifier.": [
        ""
      ],
      "Quantity must be greater than 0!": [
        ""
      ],
      "This quantity exceeds remaining stock. Currently, only %1$s units remain unreserved in stock.": [
        ""
      ],
      "Quantity": [
        ""
      ],
      "how many products will be added": [
        ""
      ],
      "Add from inventory": [
        ""
      ],
      "Image should be smaller than 1 MB": [
        ""
      ],
      "Add": [
        ""
      ],
      "Remove": [
        ""
      ],
      "No taxes configured for this product.": [
        ""
      ],
      "Amount": [
        ""
      ],
      "Taxes can be in currencies that differ from the main currency used by the merchant.": [
        ""
      ],
      "Enter currency and value separated with a colon, e.g. &quot;USD:2.3&quot;.": [
        ""
      ],
      "Legal name of the tax, e.g. VAT or import duties.": [
        ""
      ],
      "add tax to the tax list": [
        ""
      ],
      "describe and add a product that is not in the inventory list": [
        ""
      ],
      "Add custom product": [
        ""
      ],
      "Complete information of the product": [
        ""
      ],
      "Image": [
        ""
      ],
      "photo of the product": [
        ""
      ],
      "full product description": [
        ""
      ],
      "Unit": [
        ""
      ],
      "name of the product unit": [
        ""
      ],
      "Price": [
        ""
      ],
      "amount in the current currency": [
        ""
      ],
      "Taxes": [
        ""
      ],
      "image": [
        ""
      ],
      "description": [
        ""
      ],
      "quantity": [
        ""
      ],
      "unit price": [
        ""
      ],
      "total price": [
        ""
      ],
      "required": [
        ""
      ],
      "not valid": [
        ""
      ],
      "must be greater than 0": [
        ""
      ],
      "not a valid json": [
        ""
      ],
      "should be in the future": [
        ""
      ],
      "refund deadline cannot be before pay deadline": [
        ""
      ],
      "wire transfer deadline cannot be before refund deadline": [
        ""
      ],
      "wire transfer deadline cannot be before pay deadline": [
        ""
      ],
      "should have a refund deadline": [
        ""
      ],
      "auto refund cannot be after refund deadline": [
        ""
      ],
      "Manage products in order": [
        ""
      ],
      "Manage list of products in the order.": [
        ""
      ],
      "Remove this product from the order.": [
        ""
      ],
      "Total price": [
        ""
      ],
      "total product price added up": [
        ""
      ],
      "Amount to be paid by the customer": [
        ""
      ],
      "Order price": [
        ""
      ],
      "final order price": [
        ""
      ],
      "Summary": [
        ""
      ],
      "Title of the order to be shown to the customer": [
        ""
      ],
      "Shipping and Fulfillment": [
        ""
      ],
      "Delivery date": [
        ""
      ],
      "Deadline for physical delivery assured by the merchant.": [
        ""
      ],
      "Location": [
        ""
      ],
      "address where the products will be delivered": [
        ""
      ],
      "Fulfillment URL": [
        ""
      ],
      "URL to which the user will be redirected after successful payment.": [
        ""
      ],
      "Taler payment options": [
        ""
      ],
      "Override default Taler payment settings for this order": [
        ""
      ],
      "Payment deadline": [
        ""
      ],
      "Deadline for the customer to pay for the offer before it expires. Inventory products will be reserved until this deadline.": [
        ""
      ],
      "Refund deadline": [
        ""
      ],
      "Time until which the order can be refunded by the merchant.": [
        ""
      ],
      "Wire transfer deadline": [
        ""
      ],
      "Deadline for the exchange to make the wire transfer.": [
        ""
      ],
      "Auto-refund deadline": [
        ""
      ],
      "Time until which the wallet will automatically check for refunds without user interaction.": [
        ""
      ],
      "Maximum deposit fee": [
        ""
      ],
      "Maximum deposit fees the merchant is willing to cover for this order. Higher deposit fees must be covered in full by the consumer.": [
        ""
      ],
      "Maximum wire fee": [
        ""
      ],
      "Maximum aggregate wire fees the merchant is willing to cover for this order. Wire fees exceeding this amount are to be covered by the customers.": [
        ""
      ],
      "Wire fee amortization": [
        ""
      ],
      "Factor by which wire fees exceeding the above threshold are divided to determine the share of excess wire fees to be paid explicitly by the consumer.": [
        ""
      ],
      "Create token": [
        ""
      ],
      "Uncheck this option if the merchant backend generated an order ID with enough entropy to prevent adversarial claims.": [
        ""
      ],
      "Minimum age required": [
        ""
      ],
      "Any value greater than 0 will limit the coins able be used to pay this contract. If empty the age restriction will be defined by the products": [
        ""
      ],
      "Min age defined by the producs is %1$s": [
        ""
      ],
      "Additional information": [
        ""
      ],
      "Custom information to be included in the contract for this order.": [
        ""
      ],
      "You must enter a value in JavaScript Object Notation (JSON).": [
        ""
      ],
      "days": [
        ""
      ],
      "hours": [
        ""
      ],
      "minutes": [
        ""
      ],
      "seconds": [
        ""
      ],
      "forever": [
        ""
      ],
      "%1$sM": [
        ""
      ],
      "%1$sY": [
        ""
      ],
      "%1$sd": [
        ""
      ],
      "%1$sh": [
        ""
      ],
      "%1$smin": [
        ""
      ],
      "%1$ssec": [
        ""
      ],
      "Orders": [
        ""
      ],
      "create order": [
        ""
      ],
      "load newer orders": [
        ""
      ],
      "Date": [
        ""
      ],
      "Refund": [
        ""
      ],
      "copy url": [
        ""
      ],
      "load older orders": [
        ""
      ],
      "No orders have been found matching your query!": [
        ""
      ],
      "duplicated": [
        ""
      ],
      "invalid format": [
        ""
      ],
      "this value exceed the refundable amount": [
        ""
      ],
      "date": [
        ""
      ],
      "amount": [
        ""
      ],
      "reason": [
        ""
      ],
      "amount to be refunded": [
        ""
      ],
      "Max refundable:": [
        ""
      ],
      "Reason": [
        ""
      ],
      "Choose one...": [
        ""
      ],
      "requested by the customer": [
        ""
      ],
      "other": [
        ""
      ],
      "why this order is being refunded": [
        ""
      ],
      "more information to give context": [
        ""
      ],
      "Contract Terms": [
        ""
      ],
      "human-readable description of the whole purchase": [
        ""
      ],
      "total price for the transaction": [
        ""
      ],
      "URL for this purchase": [
        ""
      ],
      "Max fee": [
        ""
      ],
      "maximum total deposit fee accepted by the merchant for this contract": [
        ""
      ],
      "Max wire fee": [
        ""
      ],
      "maximum wire fee accepted by the merchant": [
        ""
      ],
      "over how many customer transactions does the merchant expect to amortize wire fees on average": [
        ""
      ],
      "Created at": [
        ""
      ],
      "time when this contract was generated": [
        ""
      ],
      "after this deadline has passed no refunds will be accepted": [
        ""
      ],
      "after this deadline, the merchant won't accept payments for the contract": [
        ""
      ],
      "transfer deadline for the exchange": [
        ""
      ],
      "time indicating when the order should be delivered": [
        ""
      ],
      "where the order will be delivered": [
        ""
      ],
      "Auto-refund delay": [
        ""
      ],
      "how long the wallet should try to get an automatic refund for the purchase": [
        ""
      ],
      "Extra info": [
        ""
      ],
      "extra data that is only interpreted by the merchant frontend": [
        ""
      ],
      "Order": [
        ""
      ],
      "claimed": [
        ""
      ],
      "claimed at": [
        ""
      ],
      "Timeline": [
        ""
      ],
      "Payment details": [
        ""
      ],
      "Order status": [
        ""
      ],
      "Product list": [
        ""
      ],
      "paid": [
        ""
      ],
      "wired": [
        ""
      ],
      "refunded": [
        ""
      ],
      "refund order": [
        ""
      ],
      "not refundable": [
        ""
      ],
      "refund": [
        ""
      ],
      "Refunded amount": [
        ""
      ],
      "Refund taken": [
        ""
      ],
      "Status URL": [
        ""
      ],
      "Refund URI": [
        ""
      ],
      "unpaid": [
        ""
      ],
      "pay at": [
        ""
      ],
      "created at": [
        ""
      ],
      "Order status URL": [
        ""
      ],
      "Payment URI": [
        ""
      ],
      "Unknown order status. This is an error, please contact the administrator.": [
        ""
      ],
      "Back": [
        ""
      ],
      "refund created successfully": [
        ""
      ],
      "could not create the refund": [
        ""
      ],
      "select date to show nearby orders": [
        ""
      ],
      "order id": [
        ""
      ],
      "jump to order with the given order ID": [
        ""
      ],
      "remove all filters": [
        ""
      ],
      "only show paid orders": [
        ""
      ],
      "Paid": [
        ""
      ],
      "only show orders with refunds": [
        ""
      ],
      "Refunded": [
        ""
      ],
      "only show orders where customers paid, but wire payments from payment provider are still pending": [
        ""
      ],
      "Not wired": [
        ""
      ],
      "clear date filter": [
        ""
      ],
      "date (YYYY/MM/DD)": [
        ""
      ],
      "Enter an order id": [
        ""
      ],
      "order not found": [
        ""
      ],
      "could not get the order to refund": [
        ""
      ],
      "Loading...": [
        ""
      ],
      "click here to configure the stock of the product, leave it as is and the backend will not control stock": [
        ""
      ],
      "Manage stock": [
        ""
      ],
      "this product has been configured without stock control": [
        ""
      ],
      "Infinite": [
        ""
      ],
      "lost cannot be greater than current and incoming (max %1$s)": [
        ""
      ],
      "Incoming": [
        ""
      ],
      "Lost": [
        ""
      ],
      "Current": [
        ""
      ],
      "remove stock control for this product": [
        ""
      ],
      "without stock": [
        ""
      ],
      "Next restock": [
        ""
      ],
      "Delivery address": [
        ""
      ],
      "product identification to use in URLs (for internal use only)": [
        ""
      ],
      "illustration of the product for customers": [
        ""
      ],
      "product description for customers": [
        ""
      ],
      "Age restricted": [
        ""
      ],
      "is this product restricted for customer below certain age?": [
        ""
      ],
      "unit describing quantity of product sold (e.g. 2 kilograms, 5 liters, 3 items, 5 meters) for customers": [
        ""
      ],
      "sale price for customers, including taxes, for above units of the product": [
        ""
      ],
      "Stock": [
        ""
      ],
      "product inventory for products with finite supply (for internal use only)": [
        ""
      ],
      "taxes included in the product price, exposed to customers": [
        ""
      ],
      "Need to complete marked fields": [
        ""
      ],
      "could not create product": [
        ""
      ],
      "Products": [
        ""
      ],
      "add product to inventory": [
        ""
      ],
      "Sell": [
        ""
      ],
      "Profit": [
        ""
      ],
      "Sold": [
        ""
      ],
      "free": [
        ""
      ],
      "go to product update page": [
        ""
      ],
      "Update": [
        ""
      ],
      "remove this product from the database": [
        ""
      ],
      "update the product with new price": [
        ""
      ],
      "update product with new price": [
        ""
      ],
      "add more elements to the inventory": [
        ""
      ],
      "report elements lost in the inventory": [
        ""
      ],
      "new price for the product": [
        ""
      ],
      "the are value with errors": [
        ""
      ],
      "update product with new stock and price": [
        ""
      ],
      "There is no products yet, add more pressing the + sign": [
        ""
      ],
      "product updated successfully": [
        ""
      ],
      "could not update the product": [
        ""
      ],
      "product delete successfully": [
        ""
      ],
      "could not delete the product": [
        ""
      ],
      "Product id:": [
        ""
      ],
      "To complete the setup of the reserve, you must now initiate a wire transfer using the given wire transfer subject and crediting the specified amount to the indicated account of the exchange.": [
        ""
      ],
      "If your system supports RFC 8905, you can do this by opening this URI:": [
        ""
      ],
      "it should be greater than 0": [
        ""
      ],
      "must be a valid URL": [
        ""
      ],
      "Initial balance": [
        ""
      ],
      "balance prior to deposit": [
        ""
      ],
      "Exchange URL": [
        ""
      ],
      "URL of exchange": [
        ""
      ],
      "Next": [
        ""
      ],
      "Wire method": [
        ""
      ],
      "method to use for wire transfer": [
        ""
      ],
      "Select one wire method": [
        ""
      ],
      "could not create reserve": [
        ""
      ],
      "Valid until": [
        ""
      ],
      "Created balance": [
        ""
      ],
      "Exchange balance": [
        ""
      ],
      "Picked up": [
        ""
      ],
      "Committed": [
        ""
      ],
      "Account address": [
        ""
      ],
      "Subject": [
        ""
      ],
      "Tips": [
        ""
      ],
      "No tips has been authorized from this reserve": [
        ""
      ],
      "Authorized": [
        ""
      ],
      "Expiration": [
        ""
      ],
      "amount of tip": [
        ""
      ],
      "Justification": [
        ""
      ],
      "reason for the tip": [
        ""
      ],
      "URL after tip": [
        ""
      ],
      "URL to visit after tip payment": [
        ""
      ],
      "Reserves not yet funded": [
        ""
      ],
      "Reserves ready": [
        ""
      ],
      "add new reserve": [
        ""
      ],
      "Expires at": [
        ""
      ],
      "Initial": [
        ""
      ],
      "delete selected reserve from the database": [
        ""
      ],
      "authorize new tip from selected reserve": [
        ""
      ],
      "There is no ready reserves yet, add more pressing the + sign or fund them": [
        ""
      ],
      "Expected Balance": [
        ""
      ],
      "could not create the tip": [
        ""
      ],
      "should not be empty": [
        ""
      ],
      "should be greater that 0": [
        ""
      ],
      "can't be empty": [
        ""
      ],
      "to short": [
        ""
      ],
      "just letters and numbers from 2 to 7": [
        ""
      ],
      "size of the key should be 32": [
        ""
      ],
      "Identifier": [
        ""
      ],
      "Name of the template in URLs.": [
        ""
      ],
      "Describe what this template stands for": [
        ""
      ],
      "Fixed summary": [
        ""
      ],
      "If specified, this template will create order with the same summary": [
        ""
      ],
      "Fixed price": [
        ""
      ],
      "If specified, this template will create order with the same price": [
        ""
      ],
      "Minimum age": [
        ""
      ],
      "Is this contract restricted to some age?": [
        ""
      ],
      "Payment timeout": [
        ""
      ],
      "How much time has the customer to complete the payment once the order was created.": [
        ""
      ],
      "Verification algorithm": [
        ""
      ],
      "Algorithm to use to verify transaction in offline mode": [
        ""
      ],
      "Point-of-sale key": [
        ""
      ],
      "Useful to validate the purchase": [
        ""
      ],
      "generate random secret key": [
        ""
      ],
      "random": [
        ""
      ],
      "show secret key": [
        ""
      ],
      "hide secret key": [
        ""
      ],
      "hide": [
        ""
      ],
      "show": [
        ""
      ],
      "could not inform template": [
        ""
      ],
      "Amount is required": [
        ""
      ],
      "Order summary is required": [
        ""
      ],
      "New order for template": [
        ""
      ],
      "Amount of the order": [
        ""
      ],
      "Order summary": [
        ""
      ],
      "could not create order from template": [
        ""
      ],
      "Here you can specify a default value for fields that are not fixed. Default values can be edited by the customer before the payment.": [
        ""
      ],
      "Fixed amount": [
        ""
      ],
      "Default amount": [
        ""
      ],
      "Default summary": [
        ""
      ],
      "Print": [
        ""
      ],
      "Setup TOTP": [
        ""
      ],
      "Templates": [
        ""
      ],
      "add new templates": [
        ""
      ],
      "load more templates before the first one": [
        ""
      ],
      "load newer templates": [
        ""
      ],
      "delete selected templates from the database": [
        ""
      ],
      "use template to create new order": [
        ""
      ],
      "create qr code for the template": [
        ""
      ],
      "load more templates after the last one": [
        ""
      ],
      "load older templates": [
        ""
      ],
      "There is no templates yet, add more pressing the + sign": [
        ""
      ],
      "template delete successfully": [
        ""
      ],
      "could not delete the template": [
        ""
      ],
      "could not update template": [
        ""
      ],
      "should be one of '%1$s'": [
        ""
      ],
      "Webhook ID to use": [
        ""
      ],
      "Event": [
        ""
      ],
      "The event of the webhook: why the webhook is used": [
        ""
      ],
      "Method": [
        ""
      ],
      "Method used by the webhook": [
        ""
      ],
      "URL": [
        ""
      ],
      "URL of the webhook where the customer will be redirected": [
        ""
      ],
      "Header": [
        ""
      ],
      "Header template of the webhook": [
        ""
      ],
      "Body": [
        ""
      ],
      "Body template by the webhook": [
        ""
      ],
      "Webhooks": [
        ""
      ],
      "add new webhooks": [
        ""
      ],
      "load more webhooks before the first one": [
        ""
      ],
      "load newer webhooks": [
        ""
      ],
      "Event type": [
        ""
      ],
      "delete selected webhook from the database": [
        ""
      ],
      "load more webhooks after the last one": [
        ""
      ],
      "load older webhooks": [
        ""
      ],
      "There is no webhooks yet, add more pressing the + sign": [
        ""
      ],
      "webhook delete successfully": [
        ""
      ],
      "could not delete the webhook": [
        ""
      ],
      "check the id, does not look valid": [
        ""
      ],
      "should have 52 characters, current %1$s": [
        ""
      ],
      "URL doesn't have the right format": [
        ""
      ],
      "Credited bank account": [
        ""
      ],
      "Select one account": [
        ""
      ],
      "Bank account of the merchant where the payment was received": [
        ""
      ],
      "Wire transfer ID": [
        ""
      ],
      "unique identifier of the wire transfer used by the exchange, must be 52 characters long": [
        ""
      ],
      "Base URL of the exchange that made the transfer, should have been in the wire transfer subject": [
        ""
      ],
      "Amount credited": [
        ""
      ],
      "Actual amount that was wired to the merchant's bank account": [
        ""
      ],
      "could not inform transfer": [
        ""
      ],
      "Transfers": [
        ""
      ],
      "add new transfer": [
        ""
      ],
      "load more transfers before the first one": [
        ""
      ],
      "load newer transfers": [
        ""
      ],
      "Credit": [
        ""
      ],
      "Confirmed": [
        ""
      ],
      "Verified": [
        ""
      ],
      "Executed at": [
        ""
      ],
      "yes": [
        ""
      ],
      "no": [
        ""
      ],
      "unknown": [
        ""
      ],
      "delete selected transfer from the database": [
        ""
      ],
      "load more transfer after the last one": [
        ""
      ],
      "load older transfers": [
        ""
      ],
      "There is no transfer yet, add more pressing the + sign": [
        ""
      ],
      "filter by account address": [
        ""
      ],
      "only show wire transfers confirmed by the merchant": [
        ""
      ],
      "only show wire transfers claimed by the exchange": [
        ""
      ],
      "Unverified": [
        ""
      ],
      "is not valid": [
        ""
      ],
      "is not a number": [
        ""
      ],
      "must be 1 or greater": [
        ""
      ],
      "max 7 lines": [
        ""
      ],
      "change authorization configuration": [
        ""
      ],
      "Need to complete marked fields and choose authorization method": [
        ""
      ],
      "This is not a valid bitcoin address.": [
        ""
      ],
      "This is not a valid Ethereum address.": [
        ""
      ],
      "IBAN numbers usually have more that 4 digits": [
        ""
      ],
      "IBAN numbers usually have less that 34 digits": [
        ""
      ],
      "IBAN country code not found": [
        ""
      ],
      "IBAN number is not valid, checksum is wrong": [
        ""
      ],
      "Target type": [
        ""
      ],
      "Method to use for wire transfer": [
        ""
      ],
      "Routing": [
        ""
      ],
      "Routing number.": [
        ""
      ],
      "Account": [
        ""
      ],
      "Account number.": [
        ""
      ],
      "Business Identifier Code.": [
        ""
      ],
      "Bank Account Number.": [
        ""
      ],
      "Unified Payment Interface.": [
        ""
      ],
      "Bitcoin protocol.": [
        ""
      ],
      "Ethereum protocol.": [
        ""
      ],
      "Interledger protocol.": [
        ""
      ],
      "Host": [
        ""
      ],
      "Bank host.": [
        ""
      ],
      "Bank account.": [
        ""
      ],
      "Bank account owner's name.": [
        ""
      ],
      "No accounts yet.": [
        ""
      ],
      "Name of the instance in URLs. The 'default' instance is special in that it is used to administer other instances.": [
        ""
      ],
      "Business name": [
        ""
      ],
      "Legal name of the business represented by this instance.": [
        ""
      ],
      "Email": [
        ""
      ],
      "Contact email": [
        ""
      ],
      "Website URL": [
        ""
      ],
      "URL.": [
        ""
      ],
      "Logo": [
        ""
      ],
      "Logo image.": [
        ""
      ],
      "Bank account": [
        ""
      ],
      "URI specifying bank account for crediting revenue.": [
        ""
      ],
      "Default max deposit fee": [
        ""
      ],
      "Maximum deposit fees this merchant is willing to pay per order by default.": [
        ""
      ],
      "Default max wire fee": [
        ""
      ],
      "Maximum wire fees this merchant is willing to pay per wire transfer by default.": [
        ""
      ],
      "Default wire fee amortization": [
        ""
      ],
      "Number of orders excess wire transfer fees will be divided by to compute per order surcharge.": [
        ""
      ],
      "Physical location of the merchant.": [
        ""
      ],
      "Jurisdiction": [
        ""
      ],
      "Jurisdiction for legal disputes with the merchant.": [
        ""
      ],
      "Default payment delay": [
        ""
      ],
      "Time customers have to pay an order before the offer expires by default.": [
        ""
      ],
      "Default wire transfer delay": [
        ""
      ],
      "Maximum time an exchange is allowed to delay wiring funds to the merchant, enabling it to aggregate smaller payments into larger wire transfers and reducing wire fees.": [
        ""
      ],
      "Instance id": [
        ""
      ],
      "Change the authorization method use for this instance.": [
        ""
      ],
      "Manage access token": [
        ""
      ],
      "Failed to create instance": [
        ""
      ],
      "Login required": [
        ""
      ],
      "Please enter your access token.": [
        ""
      ],
      "Access Token": [
        ""
      ],
      "The request to the backend take too long and was cancelled": [
        ""
      ],
      "Diagnostic from %1$s is \"%2$s\"": [
        ""
      ],
      "The backend reported a problem: HTTP status #%1$s": [
        ""
      ],
      "Diagnostic from %1$s is '%2$s'": [
        ""
      ],
      "Access denied": [
        ""
      ],
      "The access token provided is invalid.": [
        ""
      ],
      "No 'default' instance configured yet.": [
        ""
      ],
      "Create a 'default' instance to begin using the merchant backoffice.": [
        ""
      ],
      "The access token provided is invalid": [
        ""
      ],
      "Hide for today": [
        ""
      ],
      "Instance": [
        ""
      ],
      "Settings": [
        ""
      ],
      "Connection": [
        ""
      ],
      "New": [
        ""
      ],
      "List": [
        ""
      ],
      "Log out": [
        ""
      ],
      "Check your token is valid": [
        ""
      ],
      "Couldn't access the server.": [
        ""
      ],
      "Could not infer instance id from url %1$s": [
        ""
      ],
      "Server not found": [
        ""
      ],
      "Server response with an error code": [
        ""
      ],
      "Got message %1$s from %2$s": [
        ""
      ],
      "Response from server is unreadable, http status: %1$s": [
        ""
      ],
      "Unexpected Error": [
        ""
      ],
      "The value %1$s is invalid for a payment url": [
        ""
      ],
      "add element to the list": [
        ""
      ],
      "add": [
        ""
      ],
      "Deleting": [
        ""
      ],
      "Changing": [
        ""
      ],
      "Order ID": [
        ""
      ],
      "Payment URL": [
        ""
      ]
    }
  }
};

strings['it'] = {
  "domain": "messages",
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=2; plural=(n != 1);",
        "lang": ""
      },
      "Cancel": [
        ""
      ],
      "%1$s": [
        ""
      ],
      "Close": [
        ""
      ],
      "Continue": [
        ""
      ],
      "Clear": [
        ""
      ],
      "Confirm": [
        ""
      ],
      "is not the same as the current access token": [
        ""
      ],
      "cannot be empty": [
        ""
      ],
      "cannot be the same as the old token": [
        ""
      ],
      "is not the same": [
        ""
      ],
      "You are updating the access token from instance with id %1$s": [
        ""
      ],
      "Old access token": [
        ""
      ],
      "access token currently in use": [
        ""
      ],
      "New access token": [
        ""
      ],
      "next access token to be used": [
        ""
      ],
      "Repeat access token": [
        ""
      ],
      "confirm the same access token": [
        ""
      ],
      "Clearing the access token will mean public access to the instance": [
        ""
      ],
      "cannot be the same as the old access token": [
        ""
      ],
      "You are setting the access token for the new instance": [
        ""
      ],
      "With external authorization method no check will be done by the merchant backend": [
        ""
      ],
      "Set external authorization": [
        ""
      ],
      "Set access token": [
        ""
      ],
      "Operation in progress...": [
        ""
      ],
      "The operation will be automatically canceled after %1$s seconds": [
        ""
      ],
      "Instances": [
        ""
      ],
      "Delete": [
        ""
      ],
      "add new instance": [
        ""
      ],
      "ID": [
        ""
      ],
      "Name": [
        ""
      ],
      "Edit": [
        ""
      ],
      "Purge": [
        ""
      ],
      "There is no instances yet, add more pressing the + sign": [
        ""
      ],
      "Only show active instances": [
        ""
      ],
      "Active": [
        ""
      ],
      "Only show deleted instances": [
        ""
      ],
      "Deleted": [
        ""
      ],
      "Show all instances": [
        ""
      ],
      "All": [
        ""
      ],
      "Instance \"%1$s\" (ID: %2$s) has been deleted": [
        ""
      ],
      "Failed to delete instance": [
        ""
      ],
      "Instance '%1$s' (ID: %2$s) has been disabled": [
        ""
      ],
      "Failed to purge instance": [
        ""
      ],
      "Pending KYC verification": [
        ""
      ],
      "Timed out": [
        ""
      ],
      "Exchange": [
        ""
      ],
      "Target account": [
        ""
      ],
      "KYC URL": [
        ""
      ],
      "Code": [
        ""
      ],
      "Http Status": [
        ""
      ],
      "No pending kyc verification!": [
        ""
      ],
      "change value to unknown date": [
        ""
      ],
      "change value to empty": [
        ""
      ],
      "clear": [
        ""
      ],
      "change value to never": [
        ""
      ],
      "never": [
        ""
      ],
      "Country": [
        ""
      ],
      "Address": [
        ""
      ],
      "Building number": [
        ""
      ],
      "Building name": [
        ""
      ],
      "Street": [
        ""
      ],
      "Post code": [
        ""
      ],
      "Town location": [
        ""
      ],
      "Town": [
        ""
      ],
      "District": [
        ""
      ],
      "Country subdivision": [
        ""
      ],
      "Product id": [
        ""
      ],
      "Description": [
        ""
      ],
      "Product": [
        ""
      ],
      "search products by it's description or id": [
        ""
      ],
      "no products found with that description": [
        ""
      ],
      "You must enter a valid product identifier.": [
        ""
      ],
      "Quantity must be greater than 0!": [
        ""
      ],
      "This quantity exceeds remaining stock. Currently, only %1$s units remain unreserved in stock.": [
        ""
      ],
      "Quantity": [
        ""
      ],
      "how many products will be added": [
        ""
      ],
      "Add from inventory": [
        ""
      ],
      "Image should be smaller than 1 MB": [
        ""
      ],
      "Add": [
        ""
      ],
      "Remove": [
        ""
      ],
      "No taxes configured for this product.": [
        ""
      ],
      "Amount": [
        ""
      ],
      "Taxes can be in currencies that differ from the main currency used by the merchant.": [
        ""
      ],
      "Enter currency and value separated with a colon, e.g. &quot;USD:2.3&quot;.": [
        ""
      ],
      "Legal name of the tax, e.g. VAT or import duties.": [
        ""
      ],
      "add tax to the tax list": [
        ""
      ],
      "describe and add a product that is not in the inventory list": [
        ""
      ],
      "Add custom product": [
        ""
      ],
      "Complete information of the product": [
        ""
      ],
      "Image": [
        ""
      ],
      "photo of the product": [
        ""
      ],
      "full product description": [
        ""
      ],
      "Unit": [
        ""
      ],
      "name of the product unit": [
        ""
      ],
      "Price": [
        ""
      ],
      "amount in the current currency": [
        ""
      ],
      "Taxes": [
        ""
      ],
      "image": [
        ""
      ],
      "description": [
        ""
      ],
      "quantity": [
        ""
      ],
      "unit price": [
        ""
      ],
      "total price": [
        ""
      ],
      "required": [
        ""
      ],
      "not valid": [
        ""
      ],
      "must be greater than 0": [
        ""
      ],
      "not a valid json": [
        ""
      ],
      "should be in the future": [
        ""
      ],
      "refund deadline cannot be before pay deadline": [
        ""
      ],
      "wire transfer deadline cannot be before refund deadline": [
        ""
      ],
      "wire transfer deadline cannot be before pay deadline": [
        ""
      ],
      "should have a refund deadline": [
        ""
      ],
      "auto refund cannot be after refund deadline": [
        ""
      ],
      "Manage products in order": [
        ""
      ],
      "Manage list of products in the order.": [
        ""
      ],
      "Remove this product from the order.": [
        ""
      ],
      "Total price": [
        ""
      ],
      "total product price added up": [
        ""
      ],
      "Amount to be paid by the customer": [
        ""
      ],
      "Order price": [
        ""
      ],
      "final order price": [
        ""
      ],
      "Summary": [
        ""
      ],
      "Title of the order to be shown to the customer": [
        ""
      ],
      "Shipping and Fulfillment": [
        ""
      ],
      "Delivery date": [
        ""
      ],
      "Deadline for physical delivery assured by the merchant.": [
        ""
      ],
      "Location": [
        ""
      ],
      "address where the products will be delivered": [
        ""
      ],
      "Fulfillment URL": [
        ""
      ],
      "URL to which the user will be redirected after successful payment.": [
        ""
      ],
      "Taler payment options": [
        ""
      ],
      "Override default Taler payment settings for this order": [
        ""
      ],
      "Payment deadline": [
        ""
      ],
      "Deadline for the customer to pay for the offer before it expires. Inventory products will be reserved until this deadline.": [
        ""
      ],
      "Refund deadline": [
        ""
      ],
      "Time until which the order can be refunded by the merchant.": [
        ""
      ],
      "Wire transfer deadline": [
        ""
      ],
      "Deadline for the exchange to make the wire transfer.": [
        ""
      ],
      "Auto-refund deadline": [
        ""
      ],
      "Time until which the wallet will automatically check for refunds without user interaction.": [
        ""
      ],
      "Maximum deposit fee": [
        ""
      ],
      "Maximum deposit fees the merchant is willing to cover for this order. Higher deposit fees must be covered in full by the consumer.": [
        ""
      ],
      "Maximum wire fee": [
        ""
      ],
      "Maximum aggregate wire fees the merchant is willing to cover for this order. Wire fees exceeding this amount are to be covered by the customers.": [
        ""
      ],
      "Wire fee amortization": [
        ""
      ],
      "Factor by which wire fees exceeding the above threshold are divided to determine the share of excess wire fees to be paid explicitly by the consumer.": [
        ""
      ],
      "Create token": [
        ""
      ],
      "Uncheck this option if the merchant backend generated an order ID with enough entropy to prevent adversarial claims.": [
        ""
      ],
      "Minimum age required": [
        ""
      ],
      "Any value greater than 0 will limit the coins able be used to pay this contract. If empty the age restriction will be defined by the products": [
        ""
      ],
      "Min age defined by the producs is %1$s": [
        ""
      ],
      "Additional information": [
        ""
      ],
      "Custom information to be included in the contract for this order.": [
        ""
      ],
      "You must enter a value in JavaScript Object Notation (JSON).": [
        ""
      ],
      "days": [
        ""
      ],
      "hours": [
        ""
      ],
      "minutes": [
        ""
      ],
      "seconds": [
        ""
      ],
      "forever": [
        ""
      ],
      "%1$sM": [
        ""
      ],
      "%1$sY": [
        ""
      ],
      "%1$sd": [
        ""
      ],
      "%1$sh": [
        ""
      ],
      "%1$smin": [
        ""
      ],
      "%1$ssec": [
        ""
      ],
      "Orders": [
        ""
      ],
      "create order": [
        ""
      ],
      "load newer orders": [
        ""
      ],
      "Date": [
        ""
      ],
      "Refund": [
        ""
      ],
      "copy url": [
        ""
      ],
      "load older orders": [
        ""
      ],
      "No orders have been found matching your query!": [
        ""
      ],
      "duplicated": [
        ""
      ],
      "invalid format": [
        ""
      ],
      "this value exceed the refundable amount": [
        ""
      ],
      "date": [
        ""
      ],
      "amount": [
        ""
      ],
      "reason": [
        ""
      ],
      "amount to be refunded": [
        ""
      ],
      "Max refundable:": [
        ""
      ],
      "Reason": [
        ""
      ],
      "Choose one...": [
        ""
      ],
      "requested by the customer": [
        ""
      ],
      "other": [
        ""
      ],
      "why this order is being refunded": [
        ""
      ],
      "more information to give context": [
        ""
      ],
      "Contract Terms": [
        ""
      ],
      "human-readable description of the whole purchase": [
        ""
      ],
      "total price for the transaction": [
        ""
      ],
      "URL for this purchase": [
        ""
      ],
      "Max fee": [
        ""
      ],
      "maximum total deposit fee accepted by the merchant for this contract": [
        ""
      ],
      "Max wire fee": [
        ""
      ],
      "maximum wire fee accepted by the merchant": [
        ""
      ],
      "over how many customer transactions does the merchant expect to amortize wire fees on average": [
        ""
      ],
      "Created at": [
        ""
      ],
      "time when this contract was generated": [
        ""
      ],
      "after this deadline has passed no refunds will be accepted": [
        ""
      ],
      "after this deadline, the merchant won't accept payments for the contract": [
        ""
      ],
      "transfer deadline for the exchange": [
        ""
      ],
      "time indicating when the order should be delivered": [
        ""
      ],
      "where the order will be delivered": [
        ""
      ],
      "Auto-refund delay": [
        ""
      ],
      "how long the wallet should try to get an automatic refund for the purchase": [
        ""
      ],
      "Extra info": [
        ""
      ],
      "extra data that is only interpreted by the merchant frontend": [
        ""
      ],
      "Order": [
        ""
      ],
      "claimed": [
        ""
      ],
      "claimed at": [
        ""
      ],
      "Timeline": [
        ""
      ],
      "Payment details": [
        ""
      ],
      "Order status": [
        ""
      ],
      "Product list": [
        ""
      ],
      "paid": [
        ""
      ],
      "wired": [
        ""
      ],
      "refunded": [
        ""
      ],
      "refund order": [
        ""
      ],
      "not refundable": [
        ""
      ],
      "refund": [
        ""
      ],
      "Refunded amount": [
        ""
      ],
      "Refund taken": [
        ""
      ],
      "Status URL": [
        ""
      ],
      "Refund URI": [
        ""
      ],
      "unpaid": [
        ""
      ],
      "pay at": [
        ""
      ],
      "created at": [
        ""
      ],
      "Order status URL": [
        ""
      ],
      "Payment URI": [
        ""
      ],
      "Unknown order status. This is an error, please contact the administrator.": [
        ""
      ],
      "Back": [
        ""
      ],
      "refund created successfully": [
        ""
      ],
      "could not create the refund": [
        ""
      ],
      "select date to show nearby orders": [
        ""
      ],
      "order id": [
        ""
      ],
      "jump to order with the given order ID": [
        ""
      ],
      "remove all filters": [
        ""
      ],
      "only show paid orders": [
        ""
      ],
      "Paid": [
        ""
      ],
      "only show orders with refunds": [
        ""
      ],
      "Refunded": [
        ""
      ],
      "only show orders where customers paid, but wire payments from payment provider are still pending": [
        ""
      ],
      "Not wired": [
        ""
      ],
      "clear date filter": [
        ""
      ],
      "date (YYYY/MM/DD)": [
        ""
      ],
      "Enter an order id": [
        ""
      ],
      "order not found": [
        ""
      ],
      "could not get the order to refund": [
        ""
      ],
      "Loading...": [
        ""
      ],
      "click here to configure the stock of the product, leave it as is and the backend will not control stock": [
        ""
      ],
      "Manage stock": [
        ""
      ],
      "this product has been configured without stock control": [
        ""
      ],
      "Infinite": [
        ""
      ],
      "lost cannot be greater than current and incoming (max %1$s)": [
        ""
      ],
      "Incoming": [
        ""
      ],
      "Lost": [
        ""
      ],
      "Current": [
        ""
      ],
      "remove stock control for this product": [
        ""
      ],
      "without stock": [
        ""
      ],
      "Next restock": [
        ""
      ],
      "Delivery address": [
        ""
      ],
      "product identification to use in URLs (for internal use only)": [
        ""
      ],
      "illustration of the product for customers": [
        ""
      ],
      "product description for customers": [
        ""
      ],
      "Age restricted": [
        ""
      ],
      "is this product restricted for customer below certain age?": [
        ""
      ],
      "unit describing quantity of product sold (e.g. 2 kilograms, 5 liters, 3 items, 5 meters) for customers": [
        ""
      ],
      "sale price for customers, including taxes, for above units of the product": [
        ""
      ],
      "Stock": [
        ""
      ],
      "product inventory for products with finite supply (for internal use only)": [
        ""
      ],
      "taxes included in the product price, exposed to customers": [
        ""
      ],
      "Need to complete marked fields": [
        ""
      ],
      "could not create product": [
        ""
      ],
      "Products": [
        ""
      ],
      "add product to inventory": [
        ""
      ],
      "Sell": [
        ""
      ],
      "Profit": [
        ""
      ],
      "Sold": [
        ""
      ],
      "free": [
        ""
      ],
      "go to product update page": [
        ""
      ],
      "Update": [
        ""
      ],
      "remove this product from the database": [
        ""
      ],
      "update the product with new price": [
        ""
      ],
      "update product with new price": [
        ""
      ],
      "add more elements to the inventory": [
        ""
      ],
      "report elements lost in the inventory": [
        ""
      ],
      "new price for the product": [
        ""
      ],
      "the are value with errors": [
        ""
      ],
      "update product with new stock and price": [
        ""
      ],
      "There is no products yet, add more pressing the + sign": [
        ""
      ],
      "product updated successfully": [
        ""
      ],
      "could not update the product": [
        ""
      ],
      "product delete successfully": [
        ""
      ],
      "could not delete the product": [
        ""
      ],
      "Product id:": [
        ""
      ],
      "To complete the setup of the reserve, you must now initiate a wire transfer using the given wire transfer subject and crediting the specified amount to the indicated account of the exchange.": [
        ""
      ],
      "If your system supports RFC 8905, you can do this by opening this URI:": [
        ""
      ],
      "it should be greater than 0": [
        ""
      ],
      "must be a valid URL": [
        ""
      ],
      "Initial balance": [
        ""
      ],
      "balance prior to deposit": [
        ""
      ],
      "Exchange URL": [
        ""
      ],
      "URL of exchange": [
        ""
      ],
      "Next": [
        ""
      ],
      "Wire method": [
        ""
      ],
      "method to use for wire transfer": [
        ""
      ],
      "Select one wire method": [
        ""
      ],
      "could not create reserve": [
        ""
      ],
      "Valid until": [
        ""
      ],
      "Created balance": [
        ""
      ],
      "Exchange balance": [
        ""
      ],
      "Picked up": [
        ""
      ],
      "Committed": [
        ""
      ],
      "Account address": [
        ""
      ],
      "Subject": [
        ""
      ],
      "Tips": [
        ""
      ],
      "No tips has been authorized from this reserve": [
        ""
      ],
      "Authorized": [
        ""
      ],
      "Expiration": [
        ""
      ],
      "amount of tip": [
        ""
      ],
      "Justification": [
        ""
      ],
      "reason for the tip": [
        ""
      ],
      "URL after tip": [
        ""
      ],
      "URL to visit after tip payment": [
        ""
      ],
      "Reserves not yet funded": [
        ""
      ],
      "Reserves ready": [
        ""
      ],
      "add new reserve": [
        ""
      ],
      "Expires at": [
        ""
      ],
      "Initial": [
        ""
      ],
      "delete selected reserve from the database": [
        ""
      ],
      "authorize new tip from selected reserve": [
        ""
      ],
      "There is no ready reserves yet, add more pressing the + sign or fund them": [
        ""
      ],
      "Expected Balance": [
        ""
      ],
      "could not create the tip": [
        ""
      ],
      "should not be empty": [
        ""
      ],
      "should be greater that 0": [
        ""
      ],
      "can't be empty": [
        ""
      ],
      "to short": [
        ""
      ],
      "just letters and numbers from 2 to 7": [
        ""
      ],
      "size of the key should be 32": [
        ""
      ],
      "Identifier": [
        ""
      ],
      "Name of the template in URLs.": [
        ""
      ],
      "Describe what this template stands for": [
        ""
      ],
      "Fixed summary": [
        ""
      ],
      "If specified, this template will create order with the same summary": [
        ""
      ],
      "Fixed price": [
        ""
      ],
      "If specified, this template will create order with the same price": [
        ""
      ],
      "Minimum age": [
        ""
      ],
      "Is this contract restricted to some age?": [
        ""
      ],
      "Payment timeout": [
        ""
      ],
      "How much time has the customer to complete the payment once the order was created.": [
        ""
      ],
      "Verification algorithm": [
        ""
      ],
      "Algorithm to use to verify transaction in offline mode": [
        ""
      ],
      "Point-of-sale key": [
        ""
      ],
      "Useful to validate the purchase": [
        ""
      ],
      "generate random secret key": [
        ""
      ],
      "random": [
        ""
      ],
      "show secret key": [
        ""
      ],
      "hide secret key": [
        ""
      ],
      "hide": [
        ""
      ],
      "show": [
        ""
      ],
      "could not inform template": [
        ""
      ],
      "Amount is required": [
        ""
      ],
      "Order summary is required": [
        ""
      ],
      "New order for template": [
        ""
      ],
      "Amount of the order": [
        ""
      ],
      "Order summary": [
        ""
      ],
      "could not create order from template": [
        ""
      ],
      "Here you can specify a default value for fields that are not fixed. Default values can be edited by the customer before the payment.": [
        ""
      ],
      "Fixed amount": [
        ""
      ],
      "Default amount": [
        ""
      ],
      "Default summary": [
        ""
      ],
      "Print": [
        ""
      ],
      "Setup TOTP": [
        ""
      ],
      "Templates": [
        ""
      ],
      "add new templates": [
        ""
      ],
      "load more templates before the first one": [
        ""
      ],
      "load newer templates": [
        ""
      ],
      "delete selected templates from the database": [
        ""
      ],
      "use template to create new order": [
        ""
      ],
      "create qr code for the template": [
        ""
      ],
      "load more templates after the last one": [
        ""
      ],
      "load older templates": [
        ""
      ],
      "There is no templates yet, add more pressing the + sign": [
        ""
      ],
      "template delete successfully": [
        ""
      ],
      "could not delete the template": [
        ""
      ],
      "could not update template": [
        ""
      ],
      "should be one of '%1$s'": [
        ""
      ],
      "Webhook ID to use": [
        ""
      ],
      "Event": [
        ""
      ],
      "The event of the webhook: why the webhook is used": [
        ""
      ],
      "Method": [
        ""
      ],
      "Method used by the webhook": [
        ""
      ],
      "URL": [
        ""
      ],
      "URL of the webhook where the customer will be redirected": [
        ""
      ],
      "Header": [
        ""
      ],
      "Header template of the webhook": [
        ""
      ],
      "Body": [
        ""
      ],
      "Body template by the webhook": [
        ""
      ],
      "Webhooks": [
        ""
      ],
      "add new webhooks": [
        ""
      ],
      "load more webhooks before the first one": [
        ""
      ],
      "load newer webhooks": [
        ""
      ],
      "Event type": [
        ""
      ],
      "delete selected webhook from the database": [
        ""
      ],
      "load more webhooks after the last one": [
        ""
      ],
      "load older webhooks": [
        ""
      ],
      "There is no webhooks yet, add more pressing the + sign": [
        ""
      ],
      "webhook delete successfully": [
        ""
      ],
      "could not delete the webhook": [
        ""
      ],
      "check the id, does not look valid": [
        ""
      ],
      "should have 52 characters, current %1$s": [
        ""
      ],
      "URL doesn't have the right format": [
        ""
      ],
      "Credited bank account": [
        ""
      ],
      "Select one account": [
        ""
      ],
      "Bank account of the merchant where the payment was received": [
        ""
      ],
      "Wire transfer ID": [
        ""
      ],
      "unique identifier of the wire transfer used by the exchange, must be 52 characters long": [
        ""
      ],
      "Base URL of the exchange that made the transfer, should have been in the wire transfer subject": [
        ""
      ],
      "Amount credited": [
        ""
      ],
      "Actual amount that was wired to the merchant's bank account": [
        ""
      ],
      "could not inform transfer": [
        ""
      ],
      "Transfers": [
        ""
      ],
      "add new transfer": [
        ""
      ],
      "load more transfers before the first one": [
        ""
      ],
      "load newer transfers": [
        ""
      ],
      "Credit": [
        ""
      ],
      "Confirmed": [
        ""
      ],
      "Verified": [
        ""
      ],
      "Executed at": [
        ""
      ],
      "yes": [
        ""
      ],
      "no": [
        ""
      ],
      "unknown": [
        ""
      ],
      "delete selected transfer from the database": [
        ""
      ],
      "load more transfer after the last one": [
        ""
      ],
      "load older transfers": [
        ""
      ],
      "There is no transfer yet, add more pressing the + sign": [
        ""
      ],
      "filter by account address": [
        ""
      ],
      "only show wire transfers confirmed by the merchant": [
        ""
      ],
      "only show wire transfers claimed by the exchange": [
        ""
      ],
      "Unverified": [
        ""
      ],
      "is not valid": [
        ""
      ],
      "is not a number": [
        ""
      ],
      "must be 1 or greater": [
        ""
      ],
      "max 7 lines": [
        ""
      ],
      "change authorization configuration": [
        ""
      ],
      "Need to complete marked fields and choose authorization method": [
        ""
      ],
      "This is not a valid bitcoin address.": [
        ""
      ],
      "This is not a valid Ethereum address.": [
        ""
      ],
      "IBAN numbers usually have more that 4 digits": [
        ""
      ],
      "IBAN numbers usually have less that 34 digits": [
        ""
      ],
      "IBAN country code not found": [
        ""
      ],
      "IBAN number is not valid, checksum is wrong": [
        ""
      ],
      "Target type": [
        ""
      ],
      "Method to use for wire transfer": [
        ""
      ],
      "Routing": [
        ""
      ],
      "Routing number.": [
        ""
      ],
      "Account": [
        ""
      ],
      "Account number.": [
        ""
      ],
      "Business Identifier Code.": [
        ""
      ],
      "Bank Account Number.": [
        ""
      ],
      "Unified Payment Interface.": [
        ""
      ],
      "Bitcoin protocol.": [
        ""
      ],
      "Ethereum protocol.": [
        ""
      ],
      "Interledger protocol.": [
        ""
      ],
      "Host": [
        ""
      ],
      "Bank host.": [
        ""
      ],
      "Bank account.": [
        ""
      ],
      "Bank account owner's name.": [
        ""
      ],
      "No accounts yet.": [
        ""
      ],
      "Name of the instance in URLs. The 'default' instance is special in that it is used to administer other instances.": [
        ""
      ],
      "Business name": [
        ""
      ],
      "Legal name of the business represented by this instance.": [
        ""
      ],
      "Email": [
        ""
      ],
      "Contact email": [
        ""
      ],
      "Website URL": [
        ""
      ],
      "URL.": [
        ""
      ],
      "Logo": [
        ""
      ],
      "Logo image.": [
        ""
      ],
      "Bank account": [
        ""
      ],
      "URI specifying bank account for crediting revenue.": [
        ""
      ],
      "Default max deposit fee": [
        ""
      ],
      "Maximum deposit fees this merchant is willing to pay per order by default.": [
        ""
      ],
      "Default max wire fee": [
        ""
      ],
      "Maximum wire fees this merchant is willing to pay per wire transfer by default.": [
        ""
      ],
      "Default wire fee amortization": [
        ""
      ],
      "Number of orders excess wire transfer fees will be divided by to compute per order surcharge.": [
        ""
      ],
      "Physical location of the merchant.": [
        ""
      ],
      "Jurisdiction": [
        ""
      ],
      "Jurisdiction for legal disputes with the merchant.": [
        ""
      ],
      "Default payment delay": [
        ""
      ],
      "Time customers have to pay an order before the offer expires by default.": [
        ""
      ],
      "Default wire transfer delay": [
        ""
      ],
      "Maximum time an exchange is allowed to delay wiring funds to the merchant, enabling it to aggregate smaller payments into larger wire transfers and reducing wire fees.": [
        ""
      ],
      "Instance id": [
        ""
      ],
      "Change the authorization method use for this instance.": [
        ""
      ],
      "Manage access token": [
        ""
      ],
      "Failed to create instance": [
        ""
      ],
      "Login required": [
        ""
      ],
      "Please enter your access token.": [
        ""
      ],
      "Access Token": [
        ""
      ],
      "The request to the backend take too long and was cancelled": [
        ""
      ],
      "Diagnostic from %1$s is \"%2$s\"": [
        ""
      ],
      "The backend reported a problem: HTTP status #%1$s": [
        ""
      ],
      "Diagnostic from %1$s is '%2$s'": [
        ""
      ],
      "Access denied": [
        ""
      ],
      "The access token provided is invalid.": [
        ""
      ],
      "No 'default' instance configured yet.": [
        ""
      ],
      "Create a 'default' instance to begin using the merchant backoffice.": [
        ""
      ],
      "The access token provided is invalid": [
        ""
      ],
      "Hide for today": [
        ""
      ],
      "Instance": [
        ""
      ],
      "Settings": [
        ""
      ],
      "Connection": [
        ""
      ],
      "New": [
        ""
      ],
      "List": [
        ""
      ],
      "Log out": [
        ""
      ],
      "Check your token is valid": [
        ""
      ],
      "Couldn't access the server.": [
        ""
      ],
      "Could not infer instance id from url %1$s": [
        ""
      ],
      "Server not found": [
        ""
      ],
      "Server response with an error code": [
        ""
      ],
      "Got message %1$s from %2$s": [
        ""
      ],
      "Response from server is unreadable, http status: %1$s": [
        ""
      ],
      "Unexpected Error": [
        ""
      ],
      "The value %1$s is invalid for a payment url": [
        ""
      ],
      "add element to the list": [
        ""
      ],
      "add": [
        ""
      ],
      "Deleting": [
        ""
      ],
      "Changing": [
        ""
      ],
      "Order ID": [
        ""
      ],
      "Payment URL": [
        ""
      ]
    }
  }
};

strings['sv'] = {
  "domain": "messages",
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=2; plural=(n != 1);",
        "lang": ""
      },
      "Cancel": [
        ""
      ],
      "%1$s": [
        ""
      ],
      "Close": [
        ""
      ],
      "Continue": [
        ""
      ],
      "Clear": [
        ""
      ],
      "Confirm": [
        ""
      ],
      "is not the same as the current access token": [
        ""
      ],
      "cannot be empty": [
        ""
      ],
      "cannot be the same as the old token": [
        ""
      ],
      "is not the same": [
        ""
      ],
      "You are updating the access token from instance with id %1$s": [
        ""
      ],
      "Old access token": [
        ""
      ],
      "access token currently in use": [
        ""
      ],
      "New access token": [
        ""
      ],
      "next access token to be used": [
        ""
      ],
      "Repeat access token": [
        ""
      ],
      "confirm the same access token": [
        ""
      ],
      "Clearing the access token will mean public access to the instance": [
        ""
      ],
      "cannot be the same as the old access token": [
        ""
      ],
      "You are setting the access token for the new instance": [
        ""
      ],
      "With external authorization method no check will be done by the merchant backend": [
        ""
      ],
      "Set external authorization": [
        ""
      ],
      "Set access token": [
        ""
      ],
      "Operation in progress...": [
        ""
      ],
      "The operation will be automatically canceled after %1$s seconds": [
        ""
      ],
      "Instances": [
        ""
      ],
      "Delete": [
        ""
      ],
      "add new instance": [
        ""
      ],
      "ID": [
        ""
      ],
      "Name": [
        ""
      ],
      "Edit": [
        ""
      ],
      "Purge": [
        ""
      ],
      "There is no instances yet, add more pressing the + sign": [
        ""
      ],
      "Only show active instances": [
        ""
      ],
      "Active": [
        ""
      ],
      "Only show deleted instances": [
        ""
      ],
      "Deleted": [
        ""
      ],
      "Show all instances": [
        ""
      ],
      "All": [
        ""
      ],
      "Instance \"%1$s\" (ID: %2$s) has been deleted": [
        ""
      ],
      "Failed to delete instance": [
        ""
      ],
      "Instance '%1$s' (ID: %2$s) has been disabled": [
        ""
      ],
      "Failed to purge instance": [
        ""
      ],
      "Pending KYC verification": [
        ""
      ],
      "Timed out": [
        ""
      ],
      "Exchange": [
        ""
      ],
      "Target account": [
        ""
      ],
      "KYC URL": [
        ""
      ],
      "Code": [
        ""
      ],
      "Http Status": [
        ""
      ],
      "No pending kyc verification!": [
        ""
      ],
      "change value to unknown date": [
        ""
      ],
      "change value to empty": [
        ""
      ],
      "clear": [
        ""
      ],
      "change value to never": [
        ""
      ],
      "never": [
        ""
      ],
      "Country": [
        ""
      ],
      "Address": [
        ""
      ],
      "Building number": [
        ""
      ],
      "Building name": [
        ""
      ],
      "Street": [
        ""
      ],
      "Post code": [
        ""
      ],
      "Town location": [
        ""
      ],
      "Town": [
        ""
      ],
      "District": [
        ""
      ],
      "Country subdivision": [
        ""
      ],
      "Product id": [
        ""
      ],
      "Description": [
        ""
      ],
      "Product": [
        ""
      ],
      "search products by it's description or id": [
        ""
      ],
      "no products found with that description": [
        ""
      ],
      "You must enter a valid product identifier.": [
        ""
      ],
      "Quantity must be greater than 0!": [
        ""
      ],
      "This quantity exceeds remaining stock. Currently, only %1$s units remain unreserved in stock.": [
        ""
      ],
      "Quantity": [
        ""
      ],
      "how many products will be added": [
        ""
      ],
      "Add from inventory": [
        ""
      ],
      "Image should be smaller than 1 MB": [
        ""
      ],
      "Add": [
        ""
      ],
      "Remove": [
        ""
      ],
      "No taxes configured for this product.": [
        ""
      ],
      "Amount": [
        ""
      ],
      "Taxes can be in currencies that differ from the main currency used by the merchant.": [
        ""
      ],
      "Enter currency and value separated with a colon, e.g. &quot;USD:2.3&quot;.": [
        ""
      ],
      "Legal name of the tax, e.g. VAT or import duties.": [
        ""
      ],
      "add tax to the tax list": [
        ""
      ],
      "describe and add a product that is not in the inventory list": [
        ""
      ],
      "Add custom product": [
        ""
      ],
      "Complete information of the product": [
        ""
      ],
      "Image": [
        ""
      ],
      "photo of the product": [
        ""
      ],
      "full product description": [
        ""
      ],
      "Unit": [
        ""
      ],
      "name of the product unit": [
        ""
      ],
      "Price": [
        ""
      ],
      "amount in the current currency": [
        ""
      ],
      "Taxes": [
        ""
      ],
      "image": [
        ""
      ],
      "description": [
        ""
      ],
      "quantity": [
        ""
      ],
      "unit price": [
        ""
      ],
      "total price": [
        ""
      ],
      "required": [
        ""
      ],
      "not valid": [
        ""
      ],
      "must be greater than 0": [
        ""
      ],
      "not a valid json": [
        ""
      ],
      "should be in the future": [
        ""
      ],
      "refund deadline cannot be before pay deadline": [
        ""
      ],
      "wire transfer deadline cannot be before refund deadline": [
        ""
      ],
      "wire transfer deadline cannot be before pay deadline": [
        ""
      ],
      "should have a refund deadline": [
        ""
      ],
      "auto refund cannot be after refund deadline": [
        ""
      ],
      "Manage products in order": [
        ""
      ],
      "Manage list of products in the order.": [
        ""
      ],
      "Remove this product from the order.": [
        ""
      ],
      "Total price": [
        ""
      ],
      "total product price added up": [
        ""
      ],
      "Amount to be paid by the customer": [
        ""
      ],
      "Order price": [
        ""
      ],
      "final order price": [
        ""
      ],
      "Summary": [
        ""
      ],
      "Title of the order to be shown to the customer": [
        ""
      ],
      "Shipping and Fulfillment": [
        ""
      ],
      "Delivery date": [
        ""
      ],
      "Deadline for physical delivery assured by the merchant.": [
        ""
      ],
      "Location": [
        ""
      ],
      "address where the products will be delivered": [
        ""
      ],
      "Fulfillment URL": [
        ""
      ],
      "URL to which the user will be redirected after successful payment.": [
        ""
      ],
      "Taler payment options": [
        ""
      ],
      "Override default Taler payment settings for this order": [
        ""
      ],
      "Payment deadline": [
        ""
      ],
      "Deadline for the customer to pay for the offer before it expires. Inventory products will be reserved until this deadline.": [
        ""
      ],
      "Refund deadline": [
        ""
      ],
      "Time until which the order can be refunded by the merchant.": [
        ""
      ],
      "Wire transfer deadline": [
        ""
      ],
      "Deadline for the exchange to make the wire transfer.": [
        ""
      ],
      "Auto-refund deadline": [
        ""
      ],
      "Time until which the wallet will automatically check for refunds without user interaction.": [
        ""
      ],
      "Maximum deposit fee": [
        ""
      ],
      "Maximum deposit fees the merchant is willing to cover for this order. Higher deposit fees must be covered in full by the consumer.": [
        ""
      ],
      "Maximum wire fee": [
        ""
      ],
      "Maximum aggregate wire fees the merchant is willing to cover for this order. Wire fees exceeding this amount are to be covered by the customers.": [
        ""
      ],
      "Wire fee amortization": [
        ""
      ],
      "Factor by which wire fees exceeding the above threshold are divided to determine the share of excess wire fees to be paid explicitly by the consumer.": [
        ""
      ],
      "Create token": [
        ""
      ],
      "Uncheck this option if the merchant backend generated an order ID with enough entropy to prevent adversarial claims.": [
        ""
      ],
      "Minimum age required": [
        ""
      ],
      "Any value greater than 0 will limit the coins able be used to pay this contract. If empty the age restriction will be defined by the products": [
        ""
      ],
      "Min age defined by the producs is %1$s": [
        ""
      ],
      "Additional information": [
        ""
      ],
      "Custom information to be included in the contract for this order.": [
        ""
      ],
      "You must enter a value in JavaScript Object Notation (JSON).": [
        ""
      ],
      "days": [
        ""
      ],
      "hours": [
        ""
      ],
      "minutes": [
        ""
      ],
      "seconds": [
        ""
      ],
      "forever": [
        ""
      ],
      "%1$sM": [
        ""
      ],
      "%1$sY": [
        ""
      ],
      "%1$sd": [
        ""
      ],
      "%1$sh": [
        ""
      ],
      "%1$smin": [
        ""
      ],
      "%1$ssec": [
        ""
      ],
      "Orders": [
        ""
      ],
      "create order": [
        ""
      ],
      "load newer orders": [
        ""
      ],
      "Date": [
        ""
      ],
      "Refund": [
        ""
      ],
      "copy url": [
        ""
      ],
      "load older orders": [
        ""
      ],
      "No orders have been found matching your query!": [
        ""
      ],
      "duplicated": [
        ""
      ],
      "invalid format": [
        ""
      ],
      "this value exceed the refundable amount": [
        ""
      ],
      "date": [
        ""
      ],
      "amount": [
        ""
      ],
      "reason": [
        ""
      ],
      "amount to be refunded": [
        ""
      ],
      "Max refundable:": [
        ""
      ],
      "Reason": [
        ""
      ],
      "Choose one...": [
        ""
      ],
      "requested by the customer": [
        ""
      ],
      "other": [
        ""
      ],
      "why this order is being refunded": [
        ""
      ],
      "more information to give context": [
        ""
      ],
      "Contract Terms": [
        ""
      ],
      "human-readable description of the whole purchase": [
        ""
      ],
      "total price for the transaction": [
        ""
      ],
      "URL for this purchase": [
        ""
      ],
      "Max fee": [
        ""
      ],
      "maximum total deposit fee accepted by the merchant for this contract": [
        ""
      ],
      "Max wire fee": [
        ""
      ],
      "maximum wire fee accepted by the merchant": [
        ""
      ],
      "over how many customer transactions does the merchant expect to amortize wire fees on average": [
        ""
      ],
      "Created at": [
        ""
      ],
      "time when this contract was generated": [
        ""
      ],
      "after this deadline has passed no refunds will be accepted": [
        ""
      ],
      "after this deadline, the merchant won't accept payments for the contract": [
        ""
      ],
      "transfer deadline for the exchange": [
        ""
      ],
      "time indicating when the order should be delivered": [
        ""
      ],
      "where the order will be delivered": [
        ""
      ],
      "Auto-refund delay": [
        ""
      ],
      "how long the wallet should try to get an automatic refund for the purchase": [
        ""
      ],
      "Extra info": [
        ""
      ],
      "extra data that is only interpreted by the merchant frontend": [
        ""
      ],
      "Order": [
        ""
      ],
      "claimed": [
        ""
      ],
      "claimed at": [
        ""
      ],
      "Timeline": [
        ""
      ],
      "Payment details": [
        ""
      ],
      "Order status": [
        ""
      ],
      "Product list": [
        ""
      ],
      "paid": [
        ""
      ],
      "wired": [
        ""
      ],
      "refunded": [
        ""
      ],
      "refund order": [
        ""
      ],
      "not refundable": [
        ""
      ],
      "refund": [
        ""
      ],
      "Refunded amount": [
        ""
      ],
      "Refund taken": [
        ""
      ],
      "Status URL": [
        ""
      ],
      "Refund URI": [
        ""
      ],
      "unpaid": [
        ""
      ],
      "pay at": [
        ""
      ],
      "created at": [
        ""
      ],
      "Order status URL": [
        ""
      ],
      "Payment URI": [
        ""
      ],
      "Unknown order status. This is an error, please contact the administrator.": [
        ""
      ],
      "Back": [
        ""
      ],
      "refund created successfully": [
        ""
      ],
      "could not create the refund": [
        ""
      ],
      "select date to show nearby orders": [
        ""
      ],
      "order id": [
        ""
      ],
      "jump to order with the given order ID": [
        ""
      ],
      "remove all filters": [
        ""
      ],
      "only show paid orders": [
        ""
      ],
      "Paid": [
        ""
      ],
      "only show orders with refunds": [
        ""
      ],
      "Refunded": [
        ""
      ],
      "only show orders where customers paid, but wire payments from payment provider are still pending": [
        ""
      ],
      "Not wired": [
        ""
      ],
      "clear date filter": [
        ""
      ],
      "date (YYYY/MM/DD)": [
        ""
      ],
      "Enter an order id": [
        ""
      ],
      "order not found": [
        ""
      ],
      "could not get the order to refund": [
        ""
      ],
      "Loading...": [
        ""
      ],
      "click here to configure the stock of the product, leave it as is and the backend will not control stock": [
        ""
      ],
      "Manage stock": [
        ""
      ],
      "this product has been configured without stock control": [
        ""
      ],
      "Infinite": [
        ""
      ],
      "lost cannot be greater than current and incoming (max %1$s)": [
        ""
      ],
      "Incoming": [
        ""
      ],
      "Lost": [
        ""
      ],
      "Current": [
        ""
      ],
      "remove stock control for this product": [
        ""
      ],
      "without stock": [
        ""
      ],
      "Next restock": [
        ""
      ],
      "Delivery address": [
        ""
      ],
      "product identification to use in URLs (for internal use only)": [
        ""
      ],
      "illustration of the product for customers": [
        ""
      ],
      "product description for customers": [
        ""
      ],
      "Age restricted": [
        ""
      ],
      "is this product restricted for customer below certain age?": [
        ""
      ],
      "unit describing quantity of product sold (e.g. 2 kilograms, 5 liters, 3 items, 5 meters) for customers": [
        ""
      ],
      "sale price for customers, including taxes, for above units of the product": [
        ""
      ],
      "Stock": [
        ""
      ],
      "product inventory for products with finite supply (for internal use only)": [
        ""
      ],
      "taxes included in the product price, exposed to customers": [
        ""
      ],
      "Need to complete marked fields": [
        ""
      ],
      "could not create product": [
        ""
      ],
      "Products": [
        ""
      ],
      "add product to inventory": [
        ""
      ],
      "Sell": [
        ""
      ],
      "Profit": [
        ""
      ],
      "Sold": [
        ""
      ],
      "free": [
        ""
      ],
      "go to product update page": [
        ""
      ],
      "Update": [
        ""
      ],
      "remove this product from the database": [
        ""
      ],
      "update the product with new price": [
        ""
      ],
      "update product with new price": [
        ""
      ],
      "add more elements to the inventory": [
        ""
      ],
      "report elements lost in the inventory": [
        ""
      ],
      "new price for the product": [
        ""
      ],
      "the are value with errors": [
        ""
      ],
      "update product with new stock and price": [
        ""
      ],
      "There is no products yet, add more pressing the + sign": [
        ""
      ],
      "product updated successfully": [
        ""
      ],
      "could not update the product": [
        ""
      ],
      "product delete successfully": [
        ""
      ],
      "could not delete the product": [
        ""
      ],
      "Product id:": [
        ""
      ],
      "To complete the setup of the reserve, you must now initiate a wire transfer using the given wire transfer subject and crediting the specified amount to the indicated account of the exchange.": [
        ""
      ],
      "If your system supports RFC 8905, you can do this by opening this URI:": [
        ""
      ],
      "it should be greater than 0": [
        ""
      ],
      "must be a valid URL": [
        ""
      ],
      "Initial balance": [
        ""
      ],
      "balance prior to deposit": [
        ""
      ],
      "Exchange URL": [
        ""
      ],
      "URL of exchange": [
        ""
      ],
      "Next": [
        ""
      ],
      "Wire method": [
        ""
      ],
      "method to use for wire transfer": [
        ""
      ],
      "Select one wire method": [
        ""
      ],
      "could not create reserve": [
        ""
      ],
      "Valid until": [
        ""
      ],
      "Created balance": [
        ""
      ],
      "Exchange balance": [
        ""
      ],
      "Picked up": [
        ""
      ],
      "Committed": [
        ""
      ],
      "Account address": [
        ""
      ],
      "Subject": [
        ""
      ],
      "Tips": [
        ""
      ],
      "No tips has been authorized from this reserve": [
        ""
      ],
      "Authorized": [
        ""
      ],
      "Expiration": [
        ""
      ],
      "amount of tip": [
        ""
      ],
      "Justification": [
        ""
      ],
      "reason for the tip": [
        ""
      ],
      "URL after tip": [
        ""
      ],
      "URL to visit after tip payment": [
        ""
      ],
      "Reserves not yet funded": [
        ""
      ],
      "Reserves ready": [
        ""
      ],
      "add new reserve": [
        ""
      ],
      "Expires at": [
        ""
      ],
      "Initial": [
        ""
      ],
      "delete selected reserve from the database": [
        ""
      ],
      "authorize new tip from selected reserve": [
        ""
      ],
      "There is no ready reserves yet, add more pressing the + sign or fund them": [
        ""
      ],
      "Expected Balance": [
        ""
      ],
      "could not create the tip": [
        ""
      ],
      "should not be empty": [
        ""
      ],
      "should be greater that 0": [
        ""
      ],
      "can't be empty": [
        ""
      ],
      "to short": [
        ""
      ],
      "just letters and numbers from 2 to 7": [
        ""
      ],
      "size of the key should be 32": [
        ""
      ],
      "Identifier": [
        ""
      ],
      "Name of the template in URLs.": [
        ""
      ],
      "Describe what this template stands for": [
        ""
      ],
      "Fixed summary": [
        ""
      ],
      "If specified, this template will create order with the same summary": [
        ""
      ],
      "Fixed price": [
        ""
      ],
      "If specified, this template will create order with the same price": [
        ""
      ],
      "Minimum age": [
        ""
      ],
      "Is this contract restricted to some age?": [
        ""
      ],
      "Payment timeout": [
        ""
      ],
      "How much time has the customer to complete the payment once the order was created.": [
        ""
      ],
      "Verification algorithm": [
        ""
      ],
      "Algorithm to use to verify transaction in offline mode": [
        ""
      ],
      "Point-of-sale key": [
        ""
      ],
      "Useful to validate the purchase": [
        ""
      ],
      "generate random secret key": [
        ""
      ],
      "random": [
        ""
      ],
      "show secret key": [
        ""
      ],
      "hide secret key": [
        ""
      ],
      "hide": [
        ""
      ],
      "show": [
        ""
      ],
      "could not inform template": [
        ""
      ],
      "Amount is required": [
        ""
      ],
      "Order summary is required": [
        ""
      ],
      "New order for template": [
        ""
      ],
      "Amount of the order": [
        ""
      ],
      "Order summary": [
        ""
      ],
      "could not create order from template": [
        ""
      ],
      "Here you can specify a default value for fields that are not fixed. Default values can be edited by the customer before the payment.": [
        ""
      ],
      "Fixed amount": [
        ""
      ],
      "Default amount": [
        ""
      ],
      "Default summary": [
        ""
      ],
      "Print": [
        ""
      ],
      "Setup TOTP": [
        ""
      ],
      "Templates": [
        ""
      ],
      "add new templates": [
        ""
      ],
      "load more templates before the first one": [
        ""
      ],
      "load newer templates": [
        ""
      ],
      "delete selected templates from the database": [
        ""
      ],
      "use template to create new order": [
        ""
      ],
      "create qr code for the template": [
        ""
      ],
      "load more templates after the last one": [
        ""
      ],
      "load older templates": [
        ""
      ],
      "There is no templates yet, add more pressing the + sign": [
        ""
      ],
      "template delete successfully": [
        ""
      ],
      "could not delete the template": [
        ""
      ],
      "could not update template": [
        ""
      ],
      "should be one of '%1$s'": [
        ""
      ],
      "Webhook ID to use": [
        ""
      ],
      "Event": [
        ""
      ],
      "The event of the webhook: why the webhook is used": [
        ""
      ],
      "Method": [
        ""
      ],
      "Method used by the webhook": [
        ""
      ],
      "URL": [
        ""
      ],
      "URL of the webhook where the customer will be redirected": [
        ""
      ],
      "Header": [
        ""
      ],
      "Header template of the webhook": [
        ""
      ],
      "Body": [
        ""
      ],
      "Body template by the webhook": [
        ""
      ],
      "Webhooks": [
        ""
      ],
      "add new webhooks": [
        ""
      ],
      "load more webhooks before the first one": [
        ""
      ],
      "load newer webhooks": [
        ""
      ],
      "Event type": [
        ""
      ],
      "delete selected webhook from the database": [
        ""
      ],
      "load more webhooks after the last one": [
        ""
      ],
      "load older webhooks": [
        ""
      ],
      "There is no webhooks yet, add more pressing the + sign": [
        ""
      ],
      "webhook delete successfully": [
        ""
      ],
      "could not delete the webhook": [
        ""
      ],
      "check the id, does not look valid": [
        ""
      ],
      "should have 52 characters, current %1$s": [
        ""
      ],
      "URL doesn't have the right format": [
        ""
      ],
      "Credited bank account": [
        ""
      ],
      "Select one account": [
        ""
      ],
      "Bank account of the merchant where the payment was received": [
        ""
      ],
      "Wire transfer ID": [
        ""
      ],
      "unique identifier of the wire transfer used by the exchange, must be 52 characters long": [
        ""
      ],
      "Base URL of the exchange that made the transfer, should have been in the wire transfer subject": [
        ""
      ],
      "Amount credited": [
        ""
      ],
      "Actual amount that was wired to the merchant's bank account": [
        ""
      ],
      "could not inform transfer": [
        ""
      ],
      "Transfers": [
        ""
      ],
      "add new transfer": [
        ""
      ],
      "load more transfers before the first one": [
        ""
      ],
      "load newer transfers": [
        ""
      ],
      "Credit": [
        ""
      ],
      "Confirmed": [
        ""
      ],
      "Verified": [
        ""
      ],
      "Executed at": [
        ""
      ],
      "yes": [
        ""
      ],
      "no": [
        ""
      ],
      "unknown": [
        ""
      ],
      "delete selected transfer from the database": [
        ""
      ],
      "load more transfer after the last one": [
        ""
      ],
      "load older transfers": [
        ""
      ],
      "There is no transfer yet, add more pressing the + sign": [
        ""
      ],
      "filter by account address": [
        ""
      ],
      "only show wire transfers confirmed by the merchant": [
        ""
      ],
      "only show wire transfers claimed by the exchange": [
        ""
      ],
      "Unverified": [
        ""
      ],
      "is not valid": [
        ""
      ],
      "is not a number": [
        ""
      ],
      "must be 1 or greater": [
        ""
      ],
      "max 7 lines": [
        ""
      ],
      "change authorization configuration": [
        ""
      ],
      "Need to complete marked fields and choose authorization method": [
        ""
      ],
      "This is not a valid bitcoin address.": [
        ""
      ],
      "This is not a valid Ethereum address.": [
        ""
      ],
      "IBAN numbers usually have more that 4 digits": [
        ""
      ],
      "IBAN numbers usually have less that 34 digits": [
        ""
      ],
      "IBAN country code not found": [
        ""
      ],
      "IBAN number is not valid, checksum is wrong": [
        ""
      ],
      "Target type": [
        ""
      ],
      "Method to use for wire transfer": [
        ""
      ],
      "Routing": [
        ""
      ],
      "Routing number.": [
        ""
      ],
      "Account": [
        ""
      ],
      "Account number.": [
        ""
      ],
      "Business Identifier Code.": [
        ""
      ],
      "Bank Account Number.": [
        ""
      ],
      "Unified Payment Interface.": [
        ""
      ],
      "Bitcoin protocol.": [
        ""
      ],
      "Ethereum protocol.": [
        ""
      ],
      "Interledger protocol.": [
        ""
      ],
      "Host": [
        ""
      ],
      "Bank host.": [
        ""
      ],
      "Bank account.": [
        ""
      ],
      "Bank account owner's name.": [
        ""
      ],
      "No accounts yet.": [
        ""
      ],
      "Name of the instance in URLs. The 'default' instance is special in that it is used to administer other instances.": [
        ""
      ],
      "Business name": [
        ""
      ],
      "Legal name of the business represented by this instance.": [
        ""
      ],
      "Email": [
        ""
      ],
      "Contact email": [
        ""
      ],
      "Website URL": [
        ""
      ],
      "URL.": [
        ""
      ],
      "Logo": [
        ""
      ],
      "Logo image.": [
        ""
      ],
      "Bank account": [
        ""
      ],
      "URI specifying bank account for crediting revenue.": [
        ""
      ],
      "Default max deposit fee": [
        ""
      ],
      "Maximum deposit fees this merchant is willing to pay per order by default.": [
        ""
      ],
      "Default max wire fee": [
        ""
      ],
      "Maximum wire fees this merchant is willing to pay per wire transfer by default.": [
        ""
      ],
      "Default wire fee amortization": [
        ""
      ],
      "Number of orders excess wire transfer fees will be divided by to compute per order surcharge.": [
        ""
      ],
      "Physical location of the merchant.": [
        ""
      ],
      "Jurisdiction": [
        ""
      ],
      "Jurisdiction for legal disputes with the merchant.": [
        ""
      ],
      "Default payment delay": [
        ""
      ],
      "Time customers have to pay an order before the offer expires by default.": [
        ""
      ],
      "Default wire transfer delay": [
        ""
      ],
      "Maximum time an exchange is allowed to delay wiring funds to the merchant, enabling it to aggregate smaller payments into larger wire transfers and reducing wire fees.": [
        ""
      ],
      "Instance id": [
        ""
      ],
      "Change the authorization method use for this instance.": [
        ""
      ],
      "Manage access token": [
        ""
      ],
      "Failed to create instance": [
        ""
      ],
      "Login required": [
        ""
      ],
      "Please enter your access token.": [
        ""
      ],
      "Access Token": [
        ""
      ],
      "The request to the backend take too long and was cancelled": [
        ""
      ],
      "Diagnostic from %1$s is \"%2$s\"": [
        ""
      ],
      "The backend reported a problem: HTTP status #%1$s": [
        ""
      ],
      "Diagnostic from %1$s is '%2$s'": [
        ""
      ],
      "Access denied": [
        ""
      ],
      "The access token provided is invalid.": [
        ""
      ],
      "No 'default' instance configured yet.": [
        ""
      ],
      "Create a 'default' instance to begin using the merchant backoffice.": [
        ""
      ],
      "The access token provided is invalid": [
        ""
      ],
      "Hide for today": [
        ""
      ],
      "Instance": [
        ""
      ],
      "Settings": [
        ""
      ],
      "Connection": [
        ""
      ],
      "New": [
        ""
      ],
      "List": [
        ""
      ],
      "Log out": [
        ""
      ],
      "Check your token is valid": [
        ""
      ],
      "Couldn't access the server.": [
        ""
      ],
      "Could not infer instance id from url %1$s": [
        ""
      ],
      "Server not found": [
        ""
      ],
      "Server response with an error code": [
        ""
      ],
      "Got message %1$s from %2$s": [
        ""
      ],
      "Response from server is unreadable, http status: %1$s": [
        ""
      ],
      "Unexpected Error": [
        ""
      ],
      "The value %1$s is invalid for a payment url": [
        ""
      ],
      "add element to the list": [
        ""
      ],
      "add": [
        ""
      ],
      "Deleting": [
        ""
      ],
      "Changing": [
        ""
      ],
      "Order ID": [
        ""
      ],
      "Payment URL": [
        ""
      ]
    }
  }
};

