/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Christian Blättler
 */

import {
  HttpStatusCode,
  TalerError,
  TalerMerchantApi,
  assertUnreachable,
} from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { VNode, h } from "preact";
import { useState } from "preact/hooks";
import { ErrorLoadingMerchant } from "../../../../components/ErrorLoadingMerchant.js";
import { Loading } from "../../../../components/exception/loading.js";
import { NotificationCard } from "../../../../components/menu/index.js";
import { ConfirmModal } from "../../../../components/modal/index.js";
import { useSessionContext } from "../../../../context/session.js";
import { useInstanceTokenFamilies } from "../../../../hooks/tokenfamily.js";
import { Notification } from "../../../../utils/types.js";
import { LoginPage } from "../../../login/index.js";
import { NotFoundPageOrAdminCreate } from "../../../notfound/index.js";
import { CardTable } from "./Table.js";

interface Props {
  onUnauthorized: () => VNode;
  onNotFound: () => VNode;
  onCreate: () => void;
  onSelect: (slug: string) => void;
}
export default function TokenFamilyList({ onCreate, onSelect }: Props): VNode {
  const result = useInstanceTokenFamilies();
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { lib, state } = useSessionContext();
  const [deleting, setDeleting] =
    useState<TalerMerchantApi.TokenFamilySummary | null>(null);

  const { i18n } = useTranslationContext();

  if (!result) return <Loading />;
  if (result instanceof TalerError) {
    return <ErrorLoadingMerchant error={result} />;
  }
  if (result.type === "fail") {
    switch (result.case) {
      case HttpStatusCode.NotFound: {
        return <NotFoundPageOrAdminCreate />;
      }
      case HttpStatusCode.Unauthorized: {
        return <LoginPage />;
      }
      default: {
        assertUnreachable(result);
      }
    }
  }

  return (
    <section class="section is-main-section">
      <NotificationCard notification={notif} />

      <CardTable
        instances={result.body.token_families}
        onCreate={onCreate}
        onUpdate={async (slug, fam) => {
          try {
            const resp = await lib.instance.updateTokenFamily(
              state.token,
              slug,
              fam,
            );
            if (resp.type === "ok") {
              setNotif({
                message: i18n.str`Token family updated successfully`,
                type: "SUCCESS",
              });
            } else {
              setNotif({
                message: i18n.str`Could not update the token family`,
                type: "ERROR",
                description: resp.detail?.hint,
              });
            }
          } catch (error) {
            setNotif({
              message: i18n.str`Could not update the token family`,
              type: "ERROR",
              description: error instanceof Error ? error.message : undefined,
            });
          }
          return;
        }}
        onSelect={(tokenFamily) => onSelect(tokenFamily.slug)}
        onDelete={(fam) => setDeleting(fam)}
      />

      {deleting && (
        <ConfirmModal
          label={`Delete token family`}
          description={`Delete the token family "${deleting.name}"`}
          danger
          active
          onCancel={() => setDeleting(null)}
          onConfirm={async (): Promise<void> => {
            try {
              const resp = await lib.instance.deleteTokenFamily(
                state.token,
                deleting.slug,
              );
              if (resp.type === "ok") {
                setNotif({
                  message: i18n.str`Token family "${deleting.name}" (SLUG: ${deleting.slug}) has been deleted`,
                  type: "SUCCESS",
                });
              } else {
                setNotif({
                  message: i18n.str`Failed to delete token family`,
                  type: "ERROR",
                  description: resp.detail?.hint,
                });
              }
            } catch (error) {
              setNotif({
                message: i18n.str`Failed to delete token family`,
                type: "ERROR",
                description: error instanceof Error ? error.message : undefined,
              });
            }
            setDeleting(null);
          }}
        >
          <p>
            <i18n.Translate>
              If you delete the <b>&quot;{deleting.name}&quot;</b> token family
              (Slug: <b>{deleting.slug}</b>), all issued tokens will become
              invalid.
            </i18n.Translate>
          </p>
          <p class="warning">
            <i18n.Translate>
              Deleting a token family{" "}
              <b>
                <i18n.Translate>can't be undone</i18n.Translate>
              </b>
              .
            </i18n.Translate>
          </p>
        </ConfirmModal>
      )}
    </section>
  );
}
