/*
 This file is part of GNU Anastasis
 (C) 2021-2022 Anastasis SARL

 GNU Anastasis is free software; you can redistribute it and/or modify it under the
 terms of the GNU Affero General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Anastasis is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License along with
 GNU Anastasis; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import {
  StorageState,
  buildStorageKey,
  useLocalStorage,
} from "./useLocalStorage.js";

const MIN_LANG_COVERAGE_THRESHOLD = 90;
/**
 * choose the best from the browser config based on the completeness
 * on the translation
 */
function getBrowserLang(completeness: Record<string, number>): string | undefined {
  if (typeof window === "undefined") return undefined;

  if (window.navigator.language) {
    if (completeness[window.navigator.language] >= MIN_LANG_COVERAGE_THRESHOLD) {
      return window.navigator.language
    }
  }
  if (window.navigator.languages) {
    const match = Object.entries(completeness).filter(([code, value]) => {
      if (value < MIN_LANG_COVERAGE_THRESHOLD) return false; //do not consider langs below 90%
      return window.navigator.languages.findIndex(l => l.startsWith(code)) !== -1
    }).map(([code, value]) => ({ code, value }))

    if (match.length > 0) {
      let max = match[0]
      match.forEach(v => {
        if (v.value > max.value) {
          max = v
        }
      })
      return max.code
    }
  };

  return undefined;
}

const langPreferenceKey = buildStorageKey("lang-preference");

export function useLang(initial: string | undefined, completeness: Record<string, number>): Required<StorageState> {
  const defaultValue = (getBrowserLang(completeness) || initial || "en").substring(0, 2);
  return useLocalStorage(langPreferenceKey, defaultValue);
}
