import { VNode, h } from "preact";
import { UIFormProps } from "./FormProvider.js";
import { noHandlerPropsAndNoContextForField } from "./InputArray.js";
import { LabelWithTooltipMaybeRequired } from "./InputLine.js";
import { useField } from "./useField.js";

export function InputToggle<T extends object, K extends keyof T>(
  props: { threeState: boolean } & UIFormProps<T, K>,
): VNode {
  const {
    name,
    label,
    tooltip,
    help,
    placeholder,
    required,
    before,
    after,
    converter,
    threeState,
  } = props;
  //FIXME: remove deprecated
  const fieldCtx = useField<T, K>(props.name);
  const { value, onChange } =
    props.handler ?? fieldCtx ?? noHandlerPropsAndNoContextForField(props.name);

  const isOn = !!value;
  return (
    <div class="sm:col-span-6">
      <div class="flex items-center justify-between">
        <LabelWithTooltipMaybeRequired
          label={label}
          required={required}
          tooltip={tooltip}
        />
        <button
          type="button"
          data-state={isOn ? "on" : value === undefined ? "undefined" : "off"}
          class="bg-indigo-600 data-[state=off]:bg-gray-200 data-[state=undefined]:bg-gray-200  relative inline-flex h-6 w-12 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2"
          role="switch"
          aria-labelledby="availability-label"
          aria-describedby="availability-description"
          onClick={() => {
            if (value === false && threeState) {
              return onChange(undefined as any);
            } else {
              return onChange(!isOn as any);
            }
          }}
        >
          <span
            data-state={isOn ? "on" : value === undefined ? "undefined" : "off"}
            class="translate-x-6 data-[state=off]:translate-x-0 data-[state=undefined]:translate-x-3 pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
            ></span>
        </button>
      </div>
    </div>
  );
}
