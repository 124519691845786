/*
 This file is part of GNU Taler
 (C) 2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import {
  TransactionType,
  PaymentStatus,
  TransactionMajorState,
} from "./transactions-types.js";
import { RefreshReason } from "./wallet-types.js";

/**
 * Sample transaction list entries.
 */
export const sampleWalletCoreTransactions = [
  {
    type: TransactionType.Payment,
    txState: {
      major: TransactionMajorState.Done,
    },
    amountRaw: "KUDOS:10",
    amountEffective: "KUDOS:10",
    totalRefundRaw: "KUDOS:0",
    totalRefundEffective: "KUDOS:0",
    status: PaymentStatus.Paid,
    refundPending: undefined,
    posConfirmation: undefined,
    pending: false,
    refunds: [],
    timestamp: {
      t_s: 1677166045,
    },
    transactionId:
      "txn:payment:NRRD9KJ8970P5HDAGPW1MBA6HZHB1XMFKF5M3CNR6WA0GT98DHY0",
    proposalId: "NRRD9KJ8970P5HDAGPW1MBA6HZHB1XMFKF5M3CNR6WA0GT98DHY0",
    info: {
      merchant: {
        name: "woocommerce",
        website: "woocommerce.demo.taler.net",
        email: "foo@example.com",
        address: {},
        jurisdiction: {},
      },
      orderId: "wc_order_KQCRldghIgDRB-100",
      products: [
        {
          description: "Using GCC",
          quantity: 1,
          price: "KUDOS:10",
          product_id: "28",
        },
      ],
      summary: "WooTalerShop #100",
      contractTermsHash:
        "A02E1M6ARWKBJ87K2TV4S6WQ4X5YH7BRVR6MYCHCTVAED8MBXTFD6PZ5Q50Y7Z5K18PYBTDA14NQ56XPC1VCQW1EVRWTSB7ZYT65B5G",
      fulfillmentUrl:
        "https://woocommerce.demo.taler.net/?wc-api=wc_gnutaler_gateway&order_id=wc_order_KQCRldghIgDRB-100",
    },
    refundQueryActive: false,
    frozen: false,
  },
  {
    type: TransactionType.Refresh,
    txState: {
      major: TransactionMajorState.Pending,
    },
    refreshReason: RefreshReason.PayMerchant,
    amountEffective: "KUDOS:0",
    amountRaw: "KUDOS:0",
    refreshInputAmount: "KUDOS:1.5",
    refreshOutputAmount: "KUDOS:1.4",
    originatingTransactionId:
      "txn:proposal:ZCGBZFE8KZ1CBYYGSC3ZC8E40KVJWV16VYCTHGC8FFSVZ5HD24BG",
    pending: true,
    timestamp: {
      t_s: 1681376214,
    },
    transactionId:
      "txn:refresh:QQSWHHXCRQ269G0E3RW14JMC6F7NFDYDW26NSFHRTXSKDS6CMCZ0",
    frozen: false,
    error: {
      code: 7029,
      when: {
        t_ms: 1681376473665,
      },
      hint: "Error (WALLET_REFRESH_GROUP_INCOMPLETE)",
      numErrors: 1,
      errors: [
        {
          code: 7001,
          when: {
            t_ms: 1681376473189,
          },
          hint: "unexpected exception (message: exchange wire fee signature invalid)",
          stack:
            "    at validateWireInfo (../taler-wallet-core-qjs.mjs:23166)\n",
        },
      ],
    },
  },
];
