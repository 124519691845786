// Ported in 2014 by Dmitry Chestnykh and Devi Mandiri.
// TypeScript port in 2019 by Florian Dold.
// Public domain.
//
// Implementation derived from TweetNaCl version 20140427.
// See for details: http://tweetnacl.cr.yp.to/

const gf = function (init: number[] = []): Float64Array {
  const r = new Float64Array(16);
  if (init) for (let i = 0; i < init.length; i++) r[i] = init[i];
  return r;
};

//  Pluggable, initialized in high-level API below.
let randombytes = function (x: Uint8Array, n: number): void {
  throw new Error("no PRNG");
};

const _9 = new Uint8Array(32);
_9[0] = 9;

// prettier-ignore
const gf0 = gf();
const gf1 = gf([1]);
const _121665 = gf([0xdb41, 1]);
const D = gf([
  0x78a3, 0x1359, 0x4dca, 0x75eb, 0xd8ab, 0x4141, 0x0a4d, 0x0070, 0xe898,
  0x7779, 0x4079, 0x8cc7, 0xfe73, 0x2b6f, 0x6cee, 0x5203,
]);
const D2 = gf([
  0xf159, 0x26b2, 0x9b94, 0xebd6, 0xb156, 0x8283, 0x149a, 0x00e0, 0xd130,
  0xeef3, 0x80f2, 0x198e, 0xfce7, 0x56df, 0xd9dc, 0x2406,
]);
const X = gf([
  0xd51a, 0x8f25, 0x2d60, 0xc956, 0xa7b2, 0x9525, 0xc760, 0x692c, 0xdc5c,
  0xfdd6, 0xe231, 0xc0a4, 0x53fe, 0xcd6e, 0x36d3, 0x2169,
]);
const Y = gf([
  0x6658, 0x6666, 0x6666, 0x6666, 0x6666, 0x6666, 0x6666, 0x6666, 0x6666,
  0x6666, 0x6666, 0x6666, 0x6666, 0x6666, 0x6666, 0x6666,
]);
const I = gf([
  0xa0b0, 0x4a0e, 0x1b27, 0xc4ee, 0xe478, 0xad2f, 0x1806, 0x2f43, 0xd7a7,
  0x3dfb, 0x0099, 0x2b4d, 0xdf0b, 0x4fc1, 0x2480, 0x2b83,
]);

function ts64(x: Uint8Array, i: number, h: number, l: number): void {
  x[i] = (h >> 24) & 0xff;
  x[i + 1] = (h >> 16) & 0xff;
  x[i + 2] = (h >> 8) & 0xff;
  x[i + 3] = h & 0xff;
  x[i + 4] = (l >> 24) & 0xff;
  x[i + 5] = (l >> 16) & 0xff;
  x[i + 6] = (l >> 8) & 0xff;
  x[i + 7] = l & 0xff;
}

function vn(
  x: Uint8Array,
  xi: number,
  y: Uint8Array,
  yi: number,
  n: number,
): number {
  let i,
    d = 0;
  for (i = 0; i < n; i++) d |= x[xi + i] ^ y[yi + i];
  return (1 & ((d - 1) >>> 8)) - 1;
}

function crypto_verify_16(
  x: Uint8Array,
  xi: number,
  y: Uint8Array,
  yi: number,
) {
  return vn(x, xi, y, yi, 16);
}

function crypto_verify_32(
  x: Uint8Array,
  xi: number,
  y: Uint8Array,
  yi: number,
): number {
  return vn(x, xi, y, yi, 32);
}

function core_salsa20(
  o: Uint8Array,
  p: Uint8Array,
  k: Uint8Array,
  c: Uint8Array,
) {
  var j0 =
      (c[0] & 0xff) |
      ((c[1] & 0xff) << 8) |
      ((c[2] & 0xff) << 16) |
      ((c[3] & 0xff) << 24),
    j1 =
      (k[0] & 0xff) |
      ((k[1] & 0xff) << 8) |
      ((k[2] & 0xff) << 16) |
      ((k[3] & 0xff) << 24),
    j2 =
      (k[4] & 0xff) |
      ((k[5] & 0xff) << 8) |
      ((k[6] & 0xff) << 16) |
      ((k[7] & 0xff) << 24),
    j3 =
      (k[8] & 0xff) |
      ((k[9] & 0xff) << 8) |
      ((k[10] & 0xff) << 16) |
      ((k[11] & 0xff) << 24),
    j4 =
      (k[12] & 0xff) |
      ((k[13] & 0xff) << 8) |
      ((k[14] & 0xff) << 16) |
      ((k[15] & 0xff) << 24),
    j5 =
      (c[4] & 0xff) |
      ((c[5] & 0xff) << 8) |
      ((c[6] & 0xff) << 16) |
      ((c[7] & 0xff) << 24),
    j6 =
      (p[0] & 0xff) |
      ((p[1] & 0xff) << 8) |
      ((p[2] & 0xff) << 16) |
      ((p[3] & 0xff) << 24),
    j7 =
      (p[4] & 0xff) |
      ((p[5] & 0xff) << 8) |
      ((p[6] & 0xff) << 16) |
      ((p[7] & 0xff) << 24),
    j8 =
      (p[8] & 0xff) |
      ((p[9] & 0xff) << 8) |
      ((p[10] & 0xff) << 16) |
      ((p[11] & 0xff) << 24),
    j9 =
      (p[12] & 0xff) |
      ((p[13] & 0xff) << 8) |
      ((p[14] & 0xff) << 16) |
      ((p[15] & 0xff) << 24),
    j10 =
      (c[8] & 0xff) |
      ((c[9] & 0xff) << 8) |
      ((c[10] & 0xff) << 16) |
      ((c[11] & 0xff) << 24),
    j11 =
      (k[16] & 0xff) |
      ((k[17] & 0xff) << 8) |
      ((k[18] & 0xff) << 16) |
      ((k[19] & 0xff) << 24),
    j12 =
      (k[20] & 0xff) |
      ((k[21] & 0xff) << 8) |
      ((k[22] & 0xff) << 16) |
      ((k[23] & 0xff) << 24),
    j13 =
      (k[24] & 0xff) |
      ((k[25] & 0xff) << 8) |
      ((k[26] & 0xff) << 16) |
      ((k[27] & 0xff) << 24),
    j14 =
      (k[28] & 0xff) |
      ((k[29] & 0xff) << 8) |
      ((k[30] & 0xff) << 16) |
      ((k[31] & 0xff) << 24),
    j15 =
      (c[12] & 0xff) |
      ((c[13] & 0xff) << 8) |
      ((c[14] & 0xff) << 16) |
      ((c[15] & 0xff) << 24);

  var x0 = j0,
    x1 = j1,
    x2 = j2,
    x3 = j3,
    x4 = j4,
    x5 = j5,
    x6 = j6,
    x7 = j7,
    x8 = j8,
    x9 = j9,
    x10 = j10,
    x11 = j11,
    x12 = j12,
    x13 = j13,
    x14 = j14,
    x15 = j15,
    u;

  for (var i = 0; i < 20; i += 2) {
    u = (x0 + x12) | 0;
    x4 ^= (u << 7) | (u >>> (32 - 7));
    u = (x4 + x0) | 0;
    x8 ^= (u << 9) | (u >>> (32 - 9));
    u = (x8 + x4) | 0;
    x12 ^= (u << 13) | (u >>> (32 - 13));
    u = (x12 + x8) | 0;
    x0 ^= (u << 18) | (u >>> (32 - 18));

    u = (x5 + x1) | 0;
    x9 ^= (u << 7) | (u >>> (32 - 7));
    u = (x9 + x5) | 0;
    x13 ^= (u << 9) | (u >>> (32 - 9));
    u = (x13 + x9) | 0;
    x1 ^= (u << 13) | (u >>> (32 - 13));
    u = (x1 + x13) | 0;
    x5 ^= (u << 18) | (u >>> (32 - 18));

    u = (x10 + x6) | 0;
    x14 ^= (u << 7) | (u >>> (32 - 7));
    u = (x14 + x10) | 0;
    x2 ^= (u << 9) | (u >>> (32 - 9));
    u = (x2 + x14) | 0;
    x6 ^= (u << 13) | (u >>> (32 - 13));
    u = (x6 + x2) | 0;
    x10 ^= (u << 18) | (u >>> (32 - 18));

    u = (x15 + x11) | 0;
    x3 ^= (u << 7) | (u >>> (32 - 7));
    u = (x3 + x15) | 0;
    x7 ^= (u << 9) | (u >>> (32 - 9));
    u = (x7 + x3) | 0;
    x11 ^= (u << 13) | (u >>> (32 - 13));
    u = (x11 + x7) | 0;
    x15 ^= (u << 18) | (u >>> (32 - 18));

    u = (x0 + x3) | 0;
    x1 ^= (u << 7) | (u >>> (32 - 7));
    u = (x1 + x0) | 0;
    x2 ^= (u << 9) | (u >>> (32 - 9));
    u = (x2 + x1) | 0;
    x3 ^= (u << 13) | (u >>> (32 - 13));
    u = (x3 + x2) | 0;
    x0 ^= (u << 18) | (u >>> (32 - 18));

    u = (x5 + x4) | 0;
    x6 ^= (u << 7) | (u >>> (32 - 7));
    u = (x6 + x5) | 0;
    x7 ^= (u << 9) | (u >>> (32 - 9));
    u = (x7 + x6) | 0;
    x4 ^= (u << 13) | (u >>> (32 - 13));
    u = (x4 + x7) | 0;
    x5 ^= (u << 18) | (u >>> (32 - 18));

    u = (x10 + x9) | 0;
    x11 ^= (u << 7) | (u >>> (32 - 7));
    u = (x11 + x10) | 0;
    x8 ^= (u << 9) | (u >>> (32 - 9));
    u = (x8 + x11) | 0;
    x9 ^= (u << 13) | (u >>> (32 - 13));
    u = (x9 + x8) | 0;
    x10 ^= (u << 18) | (u >>> (32 - 18));

    u = (x15 + x14) | 0;
    x12 ^= (u << 7) | (u >>> (32 - 7));
    u = (x12 + x15) | 0;
    x13 ^= (u << 9) | (u >>> (32 - 9));
    u = (x13 + x12) | 0;
    x14 ^= (u << 13) | (u >>> (32 - 13));
    u = (x14 + x13) | 0;
    x15 ^= (u << 18) | (u >>> (32 - 18));
  }
  x0 = (x0 + j0) | 0;
  x1 = (x1 + j1) | 0;
  x2 = (x2 + j2) | 0;
  x3 = (x3 + j3) | 0;
  x4 = (x4 + j4) | 0;
  x5 = (x5 + j5) | 0;
  x6 = (x6 + j6) | 0;
  x7 = (x7 + j7) | 0;
  x8 = (x8 + j8) | 0;
  x9 = (x9 + j9) | 0;
  x10 = (x10 + j10) | 0;
  x11 = (x11 + j11) | 0;
  x12 = (x12 + j12) | 0;
  x13 = (x13 + j13) | 0;
  x14 = (x14 + j14) | 0;
  x15 = (x15 + j15) | 0;

  o[0] = (x0 >>> 0) & 0xff;
  o[1] = (x0 >>> 8) & 0xff;
  o[2] = (x0 >>> 16) & 0xff;
  o[3] = (x0 >>> 24) & 0xff;

  o[4] = (x1 >>> 0) & 0xff;
  o[5] = (x1 >>> 8) & 0xff;
  o[6] = (x1 >>> 16) & 0xff;
  o[7] = (x1 >>> 24) & 0xff;

  o[8] = (x2 >>> 0) & 0xff;
  o[9] = (x2 >>> 8) & 0xff;
  o[10] = (x2 >>> 16) & 0xff;
  o[11] = (x2 >>> 24) & 0xff;

  o[12] = (x3 >>> 0) & 0xff;
  o[13] = (x3 >>> 8) & 0xff;
  o[14] = (x3 >>> 16) & 0xff;
  o[15] = (x3 >>> 24) & 0xff;

  o[16] = (x4 >>> 0) & 0xff;
  o[17] = (x4 >>> 8) & 0xff;
  o[18] = (x4 >>> 16) & 0xff;
  o[19] = (x4 >>> 24) & 0xff;

  o[20] = (x5 >>> 0) & 0xff;
  o[21] = (x5 >>> 8) & 0xff;
  o[22] = (x5 >>> 16) & 0xff;
  o[23] = (x5 >>> 24) & 0xff;

  o[24] = (x6 >>> 0) & 0xff;
  o[25] = (x6 >>> 8) & 0xff;
  o[26] = (x6 >>> 16) & 0xff;
  o[27] = (x6 >>> 24) & 0xff;

  o[28] = (x7 >>> 0) & 0xff;
  o[29] = (x7 >>> 8) & 0xff;
  o[30] = (x7 >>> 16) & 0xff;
  o[31] = (x7 >>> 24) & 0xff;

  o[32] = (x8 >>> 0) & 0xff;
  o[33] = (x8 >>> 8) & 0xff;
  o[34] = (x8 >>> 16) & 0xff;
  o[35] = (x8 >>> 24) & 0xff;

  o[36] = (x9 >>> 0) & 0xff;
  o[37] = (x9 >>> 8) & 0xff;
  o[38] = (x9 >>> 16) & 0xff;
  o[39] = (x9 >>> 24) & 0xff;

  o[40] = (x10 >>> 0) & 0xff;
  o[41] = (x10 >>> 8) & 0xff;
  o[42] = (x10 >>> 16) & 0xff;
  o[43] = (x10 >>> 24) & 0xff;

  o[44] = (x11 >>> 0) & 0xff;
  o[45] = (x11 >>> 8) & 0xff;
  o[46] = (x11 >>> 16) & 0xff;
  o[47] = (x11 >>> 24) & 0xff;

  o[48] = (x12 >>> 0) & 0xff;
  o[49] = (x12 >>> 8) & 0xff;
  o[50] = (x12 >>> 16) & 0xff;
  o[51] = (x12 >>> 24) & 0xff;

  o[52] = (x13 >>> 0) & 0xff;
  o[53] = (x13 >>> 8) & 0xff;
  o[54] = (x13 >>> 16) & 0xff;
  o[55] = (x13 >>> 24) & 0xff;

  o[56] = (x14 >>> 0) & 0xff;
  o[57] = (x14 >>> 8) & 0xff;
  o[58] = (x14 >>> 16) & 0xff;
  o[59] = (x14 >>> 24) & 0xff;

  o[60] = (x15 >>> 0) & 0xff;
  o[61] = (x15 >>> 8) & 0xff;
  o[62] = (x15 >>> 16) & 0xff;
  o[63] = (x15 >>> 24) & 0xff;
}

function core_hsalsa20(
  o: Uint8Array,
  p: Uint8Array,
  k: Uint8Array,
  c: Uint8Array,
) {
  var j0 =
      (c[0] & 0xff) |
      ((c[1] & 0xff) << 8) |
      ((c[2] & 0xff) << 16) |
      ((c[3] & 0xff) << 24),
    j1 =
      (k[0] & 0xff) |
      ((k[1] & 0xff) << 8) |
      ((k[2] & 0xff) << 16) |
      ((k[3] & 0xff) << 24),
    j2 =
      (k[4] & 0xff) |
      ((k[5] & 0xff) << 8) |
      ((k[6] & 0xff) << 16) |
      ((k[7] & 0xff) << 24),
    j3 =
      (k[8] & 0xff) |
      ((k[9] & 0xff) << 8) |
      ((k[10] & 0xff) << 16) |
      ((k[11] & 0xff) << 24),
    j4 =
      (k[12] & 0xff) |
      ((k[13] & 0xff) << 8) |
      ((k[14] & 0xff) << 16) |
      ((k[15] & 0xff) << 24),
    j5 =
      (c[4] & 0xff) |
      ((c[5] & 0xff) << 8) |
      ((c[6] & 0xff) << 16) |
      ((c[7] & 0xff) << 24),
    j6 =
      (p[0] & 0xff) |
      ((p[1] & 0xff) << 8) |
      ((p[2] & 0xff) << 16) |
      ((p[3] & 0xff) << 24),
    j7 =
      (p[4] & 0xff) |
      ((p[5] & 0xff) << 8) |
      ((p[6] & 0xff) << 16) |
      ((p[7] & 0xff) << 24),
    j8 =
      (p[8] & 0xff) |
      ((p[9] & 0xff) << 8) |
      ((p[10] & 0xff) << 16) |
      ((p[11] & 0xff) << 24),
    j9 =
      (p[12] & 0xff) |
      ((p[13] & 0xff) << 8) |
      ((p[14] & 0xff) << 16) |
      ((p[15] & 0xff) << 24),
    j10 =
      (c[8] & 0xff) |
      ((c[9] & 0xff) << 8) |
      ((c[10] & 0xff) << 16) |
      ((c[11] & 0xff) << 24),
    j11 =
      (k[16] & 0xff) |
      ((k[17] & 0xff) << 8) |
      ((k[18] & 0xff) << 16) |
      ((k[19] & 0xff) << 24),
    j12 =
      (k[20] & 0xff) |
      ((k[21] & 0xff) << 8) |
      ((k[22] & 0xff) << 16) |
      ((k[23] & 0xff) << 24),
    j13 =
      (k[24] & 0xff) |
      ((k[25] & 0xff) << 8) |
      ((k[26] & 0xff) << 16) |
      ((k[27] & 0xff) << 24),
    j14 =
      (k[28] & 0xff) |
      ((k[29] & 0xff) << 8) |
      ((k[30] & 0xff) << 16) |
      ((k[31] & 0xff) << 24),
    j15 =
      (c[12] & 0xff) |
      ((c[13] & 0xff) << 8) |
      ((c[14] & 0xff) << 16) |
      ((c[15] & 0xff) << 24);

  var x0 = j0,
    x1 = j1,
    x2 = j2,
    x3 = j3,
    x4 = j4,
    x5 = j5,
    x6 = j6,
    x7 = j7,
    x8 = j8,
    x9 = j9,
    x10 = j10,
    x11 = j11,
    x12 = j12,
    x13 = j13,
    x14 = j14,
    x15 = j15,
    u;

  for (var i = 0; i < 20; i += 2) {
    u = (x0 + x12) | 0;
    x4 ^= (u << 7) | (u >>> (32 - 7));
    u = (x4 + x0) | 0;
    x8 ^= (u << 9) | (u >>> (32 - 9));
    u = (x8 + x4) | 0;
    x12 ^= (u << 13) | (u >>> (32 - 13));
    u = (x12 + x8) | 0;
    x0 ^= (u << 18) | (u >>> (32 - 18));

    u = (x5 + x1) | 0;
    x9 ^= (u << 7) | (u >>> (32 - 7));
    u = (x9 + x5) | 0;
    x13 ^= (u << 9) | (u >>> (32 - 9));
    u = (x13 + x9) | 0;
    x1 ^= (u << 13) | (u >>> (32 - 13));
    u = (x1 + x13) | 0;
    x5 ^= (u << 18) | (u >>> (32 - 18));

    u = (x10 + x6) | 0;
    x14 ^= (u << 7) | (u >>> (32 - 7));
    u = (x14 + x10) | 0;
    x2 ^= (u << 9) | (u >>> (32 - 9));
    u = (x2 + x14) | 0;
    x6 ^= (u << 13) | (u >>> (32 - 13));
    u = (x6 + x2) | 0;
    x10 ^= (u << 18) | (u >>> (32 - 18));

    u = (x15 + x11) | 0;
    x3 ^= (u << 7) | (u >>> (32 - 7));
    u = (x3 + x15) | 0;
    x7 ^= (u << 9) | (u >>> (32 - 9));
    u = (x7 + x3) | 0;
    x11 ^= (u << 13) | (u >>> (32 - 13));
    u = (x11 + x7) | 0;
    x15 ^= (u << 18) | (u >>> (32 - 18));

    u = (x0 + x3) | 0;
    x1 ^= (u << 7) | (u >>> (32 - 7));
    u = (x1 + x0) | 0;
    x2 ^= (u << 9) | (u >>> (32 - 9));
    u = (x2 + x1) | 0;
    x3 ^= (u << 13) | (u >>> (32 - 13));
    u = (x3 + x2) | 0;
    x0 ^= (u << 18) | (u >>> (32 - 18));

    u = (x5 + x4) | 0;
    x6 ^= (u << 7) | (u >>> (32 - 7));
    u = (x6 + x5) | 0;
    x7 ^= (u << 9) | (u >>> (32 - 9));
    u = (x7 + x6) | 0;
    x4 ^= (u << 13) | (u >>> (32 - 13));
    u = (x4 + x7) | 0;
    x5 ^= (u << 18) | (u >>> (32 - 18));

    u = (x10 + x9) | 0;
    x11 ^= (u << 7) | (u >>> (32 - 7));
    u = (x11 + x10) | 0;
    x8 ^= (u << 9) | (u >>> (32 - 9));
    u = (x8 + x11) | 0;
    x9 ^= (u << 13) | (u >>> (32 - 13));
    u = (x9 + x8) | 0;
    x10 ^= (u << 18) | (u >>> (32 - 18));

    u = (x15 + x14) | 0;
    x12 ^= (u << 7) | (u >>> (32 - 7));
    u = (x12 + x15) | 0;
    x13 ^= (u << 9) | (u >>> (32 - 9));
    u = (x13 + x12) | 0;
    x14 ^= (u << 13) | (u >>> (32 - 13));
    u = (x14 + x13) | 0;
    x15 ^= (u << 18) | (u >>> (32 - 18));
  }

  o[0] = (x0 >>> 0) & 0xff;
  o[1] = (x0 >>> 8) & 0xff;
  o[2] = (x0 >>> 16) & 0xff;
  o[3] = (x0 >>> 24) & 0xff;

  o[4] = (x5 >>> 0) & 0xff;
  o[5] = (x5 >>> 8) & 0xff;
  o[6] = (x5 >>> 16) & 0xff;
  o[7] = (x5 >>> 24) & 0xff;

  o[8] = (x10 >>> 0) & 0xff;
  o[9] = (x10 >>> 8) & 0xff;
  o[10] = (x10 >>> 16) & 0xff;
  o[11] = (x10 >>> 24) & 0xff;

  o[12] = (x15 >>> 0) & 0xff;
  o[13] = (x15 >>> 8) & 0xff;
  o[14] = (x15 >>> 16) & 0xff;
  o[15] = (x15 >>> 24) & 0xff;

  o[16] = (x6 >>> 0) & 0xff;
  o[17] = (x6 >>> 8) & 0xff;
  o[18] = (x6 >>> 16) & 0xff;
  o[19] = (x6 >>> 24) & 0xff;

  o[20] = (x7 >>> 0) & 0xff;
  o[21] = (x7 >>> 8) & 0xff;
  o[22] = (x7 >>> 16) & 0xff;
  o[23] = (x7 >>> 24) & 0xff;

  o[24] = (x8 >>> 0) & 0xff;
  o[25] = (x8 >>> 8) & 0xff;
  o[26] = (x8 >>> 16) & 0xff;
  o[27] = (x8 >>> 24) & 0xff;

  o[28] = (x9 >>> 0) & 0xff;
  o[29] = (x9 >>> 8) & 0xff;
  o[30] = (x9 >>> 16) & 0xff;
  o[31] = (x9 >>> 24) & 0xff;
}

var sigma = new Uint8Array([
  101, 120, 112, 97, 110, 100, 32, 51, 50, 45, 98, 121, 116, 101, 32, 107,
]);
// "expand 32-byte k"

function crypto_stream_salsa20_xor(
  c: Uint8Array,
  cpos: number,
  m: Uint8Array,
  mpos: number,
  b: number,
  n: Uint8Array,
  k: Uint8Array,
) {
  var z = new Uint8Array(16),
    x = new Uint8Array(64);
  var u, i;
  for (i = 0; i < 16; i++) z[i] = 0;
  for (i = 0; i < 8; i++) z[i] = n[i];
  while (b >= 64) {
    core_salsa20(x, z, k, sigma);
    for (i = 0; i < 64; i++) c[cpos + i] = m[mpos + i] ^ x[i];
    u = 1;
    for (i = 8; i < 16; i++) {
      u = (u + (z[i] & 0xff)) | 0;
      z[i] = u & 0xff;
      u >>>= 8;
    }
    b -= 64;
    cpos += 64;
    mpos += 64;
  }
  if (b > 0) {
    core_salsa20(x, z, k, sigma);
    for (i = 0; i < b; i++) c[cpos + i] = m[mpos + i] ^ x[i];
  }
  return 0;
}

function crypto_stream_salsa20(
  c: Uint8Array,
  cpos: number,
  b: number,
  n: Uint8Array,
  k: Uint8Array,
) {
  var z = new Uint8Array(16),
    x = new Uint8Array(64);
  var u, i;
  for (i = 0; i < 16; i++) z[i] = 0;
  for (i = 0; i < 8; i++) z[i] = n[i];
  while (b >= 64) {
    core_salsa20(x, z, k, sigma);
    for (i = 0; i < 64; i++) c[cpos + i] = x[i];
    u = 1;
    for (i = 8; i < 16; i++) {
      u = (u + (z[i] & 0xff)) | 0;
      z[i] = u & 0xff;
      u >>>= 8;
    }
    b -= 64;
    cpos += 64;
  }
  if (b > 0) {
    core_salsa20(x, z, k, sigma);
    for (i = 0; i < b; i++) c[cpos + i] = x[i];
  }
  return 0;
}

function crypto_stream(
  c: Uint8Array,
  cpos: number,
  d: number,
  n: Uint8Array,
  k: Uint8Array,
) {
  var s = new Uint8Array(32);
  core_hsalsa20(s, n, k, sigma);
  var sn = new Uint8Array(8);
  for (var i = 0; i < 8; i++) sn[i] = n[i + 16];
  return crypto_stream_salsa20(c, cpos, d, sn, s);
}

function crypto_stream_xor(
  c: Uint8Array,
  cpos: number,
  m: Uint8Array,
  mpos: number,
  d: number,
  n: Uint8Array,
  k: Uint8Array,
) {
  var s = new Uint8Array(32);
  core_hsalsa20(s, n, k, sigma);
  var sn = new Uint8Array(8);
  for (var i = 0; i < 8; i++) sn[i] = n[i + 16];
  return crypto_stream_salsa20_xor(c, cpos, m, mpos, d, sn, s);
}

/*
 * Port of Andrew Moon's Poly1305-donna-16. Public domain.
 * https://github.com/floodyberry/poly1305-donna
 */

class poly1305 {
  buffer = new Uint8Array(16);
  r = new Uint16Array(10);
  h = new Uint16Array(10);
  pad = new Uint16Array(8);
  leftover = 0;
  fin = 0;

  constructor(key: Uint8Array) {
    var t0, t1, t2, t3, t4, t5, t6, t7;

    t0 = (key[0] & 0xff) | ((key[1] & 0xff) << 8);
    this.r[0] = t0 & 0x1fff;
    t1 = (key[2] & 0xff) | ((key[3] & 0xff) << 8);
    this.r[1] = ((t0 >>> 13) | (t1 << 3)) & 0x1fff;
    t2 = (key[4] & 0xff) | ((key[5] & 0xff) << 8);
    this.r[2] = ((t1 >>> 10) | (t2 << 6)) & 0x1f03;
    t3 = (key[6] & 0xff) | ((key[7] & 0xff) << 8);
    this.r[3] = ((t2 >>> 7) | (t3 << 9)) & 0x1fff;
    t4 = (key[8] & 0xff) | ((key[9] & 0xff) << 8);
    this.r[4] = ((t3 >>> 4) | (t4 << 12)) & 0x00ff;
    this.r[5] = (t4 >>> 1) & 0x1ffe;
    t5 = (key[10] & 0xff) | ((key[11] & 0xff) << 8);
    this.r[6] = ((t4 >>> 14) | (t5 << 2)) & 0x1fff;
    t6 = (key[12] & 0xff) | ((key[13] & 0xff) << 8);
    this.r[7] = ((t5 >>> 11) | (t6 << 5)) & 0x1f81;
    t7 = (key[14] & 0xff) | ((key[15] & 0xff) << 8);
    this.r[8] = ((t6 >>> 8) | (t7 << 8)) & 0x1fff;
    this.r[9] = (t7 >>> 5) & 0x007f;

    this.pad[0] = (key[16] & 0xff) | ((key[17] & 0xff) << 8);
    this.pad[1] = (key[18] & 0xff) | ((key[19] & 0xff) << 8);
    this.pad[2] = (key[20] & 0xff) | ((key[21] & 0xff) << 8);
    this.pad[3] = (key[22] & 0xff) | ((key[23] & 0xff) << 8);
    this.pad[4] = (key[24] & 0xff) | ((key[25] & 0xff) << 8);
    this.pad[5] = (key[26] & 0xff) | ((key[27] & 0xff) << 8);
    this.pad[6] = (key[28] & 0xff) | ((key[29] & 0xff) << 8);
    this.pad[7] = (key[30] & 0xff) | ((key[31] & 0xff) << 8);
  }

  blocks(m: Uint8Array, mpos: number, bytes: number) {
    var hibit = this.fin ? 0 : 1 << 11;
    var t0, t1, t2, t3, t4, t5, t6, t7, c;
    var d0, d1, d2, d3, d4, d5, d6, d7, d8, d9;

    var h0 = this.h[0],
      h1 = this.h[1],
      h2 = this.h[2],
      h3 = this.h[3],
      h4 = this.h[4],
      h5 = this.h[5],
      h6 = this.h[6],
      h7 = this.h[7],
      h8 = this.h[8],
      h9 = this.h[9];

    var r0 = this.r[0],
      r1 = this.r[1],
      r2 = this.r[2],
      r3 = this.r[3],
      r4 = this.r[4],
      r5 = this.r[5],
      r6 = this.r[6],
      r7 = this.r[7],
      r8 = this.r[8],
      r9 = this.r[9];

    while (bytes >= 16) {
      t0 = (m[mpos + 0] & 0xff) | ((m[mpos + 1] & 0xff) << 8);
      h0 += t0 & 0x1fff;
      t1 = (m[mpos + 2] & 0xff) | ((m[mpos + 3] & 0xff) << 8);
      h1 += ((t0 >>> 13) | (t1 << 3)) & 0x1fff;
      t2 = (m[mpos + 4] & 0xff) | ((m[mpos + 5] & 0xff) << 8);
      h2 += ((t1 >>> 10) | (t2 << 6)) & 0x1fff;
      t3 = (m[mpos + 6] & 0xff) | ((m[mpos + 7] & 0xff) << 8);
      h3 += ((t2 >>> 7) | (t3 << 9)) & 0x1fff;
      t4 = (m[mpos + 8] & 0xff) | ((m[mpos + 9] & 0xff) << 8);
      h4 += ((t3 >>> 4) | (t4 << 12)) & 0x1fff;
      h5 += (t4 >>> 1) & 0x1fff;
      t5 = (m[mpos + 10] & 0xff) | ((m[mpos + 11] & 0xff) << 8);
      h6 += ((t4 >>> 14) | (t5 << 2)) & 0x1fff;
      t6 = (m[mpos + 12] & 0xff) | ((m[mpos + 13] & 0xff) << 8);
      h7 += ((t5 >>> 11) | (t6 << 5)) & 0x1fff;
      t7 = (m[mpos + 14] & 0xff) | ((m[mpos + 15] & 0xff) << 8);
      h8 += ((t6 >>> 8) | (t7 << 8)) & 0x1fff;
      h9 += (t7 >>> 5) | hibit;

      c = 0;

      d0 = c;
      d0 += h0 * r0;
      d0 += h1 * (5 * r9);
      d0 += h2 * (5 * r8);
      d0 += h3 * (5 * r7);
      d0 += h4 * (5 * r6);
      c = d0 >>> 13;
      d0 &= 0x1fff;
      d0 += h5 * (5 * r5);
      d0 += h6 * (5 * r4);
      d0 += h7 * (5 * r3);
      d0 += h8 * (5 * r2);
      d0 += h9 * (5 * r1);
      c += d0 >>> 13;
      d0 &= 0x1fff;

      d1 = c;
      d1 += h0 * r1;
      d1 += h1 * r0;
      d1 += h2 * (5 * r9);
      d1 += h3 * (5 * r8);
      d1 += h4 * (5 * r7);
      c = d1 >>> 13;
      d1 &= 0x1fff;
      d1 += h5 * (5 * r6);
      d1 += h6 * (5 * r5);
      d1 += h7 * (5 * r4);
      d1 += h8 * (5 * r3);
      d1 += h9 * (5 * r2);
      c += d1 >>> 13;
      d1 &= 0x1fff;

      d2 = c;
      d2 += h0 * r2;
      d2 += h1 * r1;
      d2 += h2 * r0;
      d2 += h3 * (5 * r9);
      d2 += h4 * (5 * r8);
      c = d2 >>> 13;
      d2 &= 0x1fff;
      d2 += h5 * (5 * r7);
      d2 += h6 * (5 * r6);
      d2 += h7 * (5 * r5);
      d2 += h8 * (5 * r4);
      d2 += h9 * (5 * r3);
      c += d2 >>> 13;
      d2 &= 0x1fff;

      d3 = c;
      d3 += h0 * r3;
      d3 += h1 * r2;
      d3 += h2 * r1;
      d3 += h3 * r0;
      d3 += h4 * (5 * r9);
      c = d3 >>> 13;
      d3 &= 0x1fff;
      d3 += h5 * (5 * r8);
      d3 += h6 * (5 * r7);
      d3 += h7 * (5 * r6);
      d3 += h8 * (5 * r5);
      d3 += h9 * (5 * r4);
      c += d3 >>> 13;
      d3 &= 0x1fff;

      d4 = c;
      d4 += h0 * r4;
      d4 += h1 * r3;
      d4 += h2 * r2;
      d4 += h3 * r1;
      d4 += h4 * r0;
      c = d4 >>> 13;
      d4 &= 0x1fff;
      d4 += h5 * (5 * r9);
      d4 += h6 * (5 * r8);
      d4 += h7 * (5 * r7);
      d4 += h8 * (5 * r6);
      d4 += h9 * (5 * r5);
      c += d4 >>> 13;
      d4 &= 0x1fff;

      d5 = c;
      d5 += h0 * r5;
      d5 += h1 * r4;
      d5 += h2 * r3;
      d5 += h3 * r2;
      d5 += h4 * r1;
      c = d5 >>> 13;
      d5 &= 0x1fff;
      d5 += h5 * r0;
      d5 += h6 * (5 * r9);
      d5 += h7 * (5 * r8);
      d5 += h8 * (5 * r7);
      d5 += h9 * (5 * r6);
      c += d5 >>> 13;
      d5 &= 0x1fff;

      d6 = c;
      d6 += h0 * r6;
      d6 += h1 * r5;
      d6 += h2 * r4;
      d6 += h3 * r3;
      d6 += h4 * r2;
      c = d6 >>> 13;
      d6 &= 0x1fff;
      d6 += h5 * r1;
      d6 += h6 * r0;
      d6 += h7 * (5 * r9);
      d6 += h8 * (5 * r8);
      d6 += h9 * (5 * r7);
      c += d6 >>> 13;
      d6 &= 0x1fff;

      d7 = c;
      d7 += h0 * r7;
      d7 += h1 * r6;
      d7 += h2 * r5;
      d7 += h3 * r4;
      d7 += h4 * r3;
      c = d7 >>> 13;
      d7 &= 0x1fff;
      d7 += h5 * r2;
      d7 += h6 * r1;
      d7 += h7 * r0;
      d7 += h8 * (5 * r9);
      d7 += h9 * (5 * r8);
      c += d7 >>> 13;
      d7 &= 0x1fff;

      d8 = c;
      d8 += h0 * r8;
      d8 += h1 * r7;
      d8 += h2 * r6;
      d8 += h3 * r5;
      d8 += h4 * r4;
      c = d8 >>> 13;
      d8 &= 0x1fff;
      d8 += h5 * r3;
      d8 += h6 * r2;
      d8 += h7 * r1;
      d8 += h8 * r0;
      d8 += h9 * (5 * r9);
      c += d8 >>> 13;
      d8 &= 0x1fff;

      d9 = c;
      d9 += h0 * r9;
      d9 += h1 * r8;
      d9 += h2 * r7;
      d9 += h3 * r6;
      d9 += h4 * r5;
      c = d9 >>> 13;
      d9 &= 0x1fff;
      d9 += h5 * r4;
      d9 += h6 * r3;
      d9 += h7 * r2;
      d9 += h8 * r1;
      d9 += h9 * r0;
      c += d9 >>> 13;
      d9 &= 0x1fff;

      c = ((c << 2) + c) | 0;
      c = (c + d0) | 0;
      d0 = c & 0x1fff;
      c = c >>> 13;
      d1 += c;

      h0 = d0;
      h1 = d1;
      h2 = d2;
      h3 = d3;
      h4 = d4;
      h5 = d5;
      h6 = d6;
      h7 = d7;
      h8 = d8;
      h9 = d9;

      mpos += 16;
      bytes -= 16;
    }
    this.h[0] = h0;
    this.h[1] = h1;
    this.h[2] = h2;
    this.h[3] = h3;
    this.h[4] = h4;
    this.h[5] = h5;
    this.h[6] = h6;
    this.h[7] = h7;
    this.h[8] = h8;
    this.h[9] = h9;
  }

  finish(mac: Uint8Array, macpos: number) {
    var g = new Uint16Array(10);
    var c, mask, f, i;

    if (this.leftover) {
      i = this.leftover;
      this.buffer[i++] = 1;
      for (; i < 16; i++) this.buffer[i] = 0;
      this.fin = 1;
      this.blocks(this.buffer, 0, 16);
    }

    c = this.h[1] >>> 13;
    this.h[1] &= 0x1fff;
    for (i = 2; i < 10; i++) {
      this.h[i] += c;
      c = this.h[i] >>> 13;
      this.h[i] &= 0x1fff;
    }
    this.h[0] += c * 5;
    c = this.h[0] >>> 13;
    this.h[0] &= 0x1fff;
    this.h[1] += c;
    c = this.h[1] >>> 13;
    this.h[1] &= 0x1fff;
    this.h[2] += c;

    g[0] = this.h[0] + 5;
    c = g[0] >>> 13;
    g[0] &= 0x1fff;
    for (i = 1; i < 10; i++) {
      g[i] = this.h[i] + c;
      c = g[i] >>> 13;
      g[i] &= 0x1fff;
    }
    g[9] -= 1 << 13;

    mask = (c ^ 1) - 1;
    for (i = 0; i < 10; i++) g[i] &= mask;
    mask = ~mask;
    for (i = 0; i < 10; i++) this.h[i] = (this.h[i] & mask) | g[i];

    this.h[0] = (this.h[0] | (this.h[1] << 13)) & 0xffff;
    this.h[1] = ((this.h[1] >>> 3) | (this.h[2] << 10)) & 0xffff;
    this.h[2] = ((this.h[2] >>> 6) | (this.h[3] << 7)) & 0xffff;
    this.h[3] = ((this.h[3] >>> 9) | (this.h[4] << 4)) & 0xffff;
    this.h[4] =
      ((this.h[4] >>> 12) | (this.h[5] << 1) | (this.h[6] << 14)) & 0xffff;
    this.h[5] = ((this.h[6] >>> 2) | (this.h[7] << 11)) & 0xffff;
    this.h[6] = ((this.h[7] >>> 5) | (this.h[8] << 8)) & 0xffff;
    this.h[7] = ((this.h[8] >>> 8) | (this.h[9] << 5)) & 0xffff;

    f = this.h[0] + this.pad[0];
    this.h[0] = f & 0xffff;
    for (i = 1; i < 8; i++) {
      f = (((this.h[i] + this.pad[i]) | 0) + (f >>> 16)) | 0;
      this.h[i] = f & 0xffff;
    }

    mac[macpos + 0] = (this.h[0] >>> 0) & 0xff;
    mac[macpos + 1] = (this.h[0] >>> 8) & 0xff;
    mac[macpos + 2] = (this.h[1] >>> 0) & 0xff;
    mac[macpos + 3] = (this.h[1] >>> 8) & 0xff;
    mac[macpos + 4] = (this.h[2] >>> 0) & 0xff;
    mac[macpos + 5] = (this.h[2] >>> 8) & 0xff;
    mac[macpos + 6] = (this.h[3] >>> 0) & 0xff;
    mac[macpos + 7] = (this.h[3] >>> 8) & 0xff;
    mac[macpos + 8] = (this.h[4] >>> 0) & 0xff;
    mac[macpos + 9] = (this.h[4] >>> 8) & 0xff;
    mac[macpos + 10] = (this.h[5] >>> 0) & 0xff;
    mac[macpos + 11] = (this.h[5] >>> 8) & 0xff;
    mac[macpos + 12] = (this.h[6] >>> 0) & 0xff;
    mac[macpos + 13] = (this.h[6] >>> 8) & 0xff;
    mac[macpos + 14] = (this.h[7] >>> 0) & 0xff;
    mac[macpos + 15] = (this.h[7] >>> 8) & 0xff;
  }

  update(m: Uint8Array, mpos: number, bytes: number) {
    let i: number;
    let want: number;
    if (this.leftover) {
      want = 16 - this.leftover;
      if (want > bytes) want = bytes;
      for (i = 0; i < want; i++) this.buffer[this.leftover + i] = m[mpos + i];
      bytes -= want;
      mpos += want;
      this.leftover += want;
      if (this.leftover < 16) return;
      this.blocks(this.buffer, 0, 16);
      this.leftover = 0;
    }

    if (bytes >= 16) {
      want = bytes - (bytes % 16);
      this.blocks(m, mpos, want);
      mpos += want;
      bytes -= want;
    }

    if (bytes) {
      for (i = 0; i < bytes; i++) this.buffer[this.leftover + i] = m[mpos + i];
      this.leftover += bytes;
    }
  }
}

function crypto_onetimeauth(
  out: Uint8Array,
  outpos: number,
  m: any,
  mpos: number,
  n: number,
  k: Uint8Array,
) {
  var s = new poly1305(k);
  s.update(m, mpos, n);
  s.finish(out, outpos);
  return 0;
}

function crypto_onetimeauth_verify(
  h: Uint8Array,
  hpos: number,
  m: any,
  mpos: number,
  n: number,
  k: Uint8Array,
) {
  var x = new Uint8Array(16);
  crypto_onetimeauth(x, 0, m, mpos, n, k);
  return crypto_verify_16(h, hpos, x, 0);
}

function crypto_secretbox(
  c: Uint8Array,
  m: Uint8Array,
  d: number,
  n: Uint8Array,
  k: Uint8Array,
): number {
  var i;
  if (d < 32) return -1;
  crypto_stream_xor(c, 0, m, 0, d, n, k);
  crypto_onetimeauth(c, 16, c, 32, d - 32, c);
  for (i = 0; i < 16; i++) c[i] = 0;
  return 0;
}

function crypto_secretbox_open(
  m: Uint8Array,
  c: Uint8Array,
  d: number,
  n: Uint8Array,
  k: Uint8Array,
): number {
  var i;
  var x = new Uint8Array(32);
  if (d < 32) return -1;
  crypto_stream(x, 0, 32, n, k);
  if (crypto_onetimeauth_verify(c, 16, c, 32, d - 32, x) !== 0) return -1;
  crypto_stream_xor(m, 0, c, 0, d, n, k);
  for (i = 0; i < 32; i++) m[i] = 0;
  return 0;
}

function set25519(r: Float64Array, a: Float64Array): void {
  let i;
  for (i = 0; i < 16; i++) r[i] = a[i] | 0;
}

function car25519(o: Float64Array): void {
  let i,
    v,
    c = 1;
  for (i = 0; i < 16; i++) {
    v = o[i] + c + 65535;
    c = Math.floor(v / 65536);
    o[i] = v - c * 65536;
  }
  o[0] += c - 1 + 37 * (c - 1);
}

function sel25519(p: Float64Array, q: Float64Array, b: number): void {
  let t;
  const c = ~(b - 1);
  for (let i = 0; i < 16; i++) {
    t = c & (p[i] ^ q[i]);
    p[i] ^= t;
    q[i] ^= t;
  }
}

function pack25519(o: Uint8Array, n: Float64Array): void {
  let i, j, b;
  const m = gf(),
    t = gf();
  for (i = 0; i < 16; i++) t[i] = n[i];
  car25519(t);
  car25519(t);
  car25519(t);
  for (j = 0; j < 2; j++) {
    m[0] = t[0] - 0xffed;
    for (i = 1; i < 15; i++) {
      m[i] = t[i] - 0xffff - ((m[i - 1] >> 16) & 1);
      m[i - 1] &= 0xffff;
    }
    m[15] = t[15] - 0x7fff - ((m[14] >> 16) & 1);
    b = (m[15] >> 16) & 1;
    m[14] &= 0xffff;
    sel25519(t, m, 1 - b);
  }
  for (i = 0; i < 16; i++) {
    o[2 * i] = t[i] & 0xff;
    o[2 * i + 1] = t[i] >> 8;
  }
}

function neq25519(a: Float64Array, b: Float64Array): number {
  const c = new Uint8Array(32),
    d = new Uint8Array(32);
  pack25519(c, a);
  pack25519(d, b);
  return crypto_verify_32(c, 0, d, 0);
}

function par25519(a: Float64Array): number {
  const d = new Uint8Array(32);
  pack25519(d, a);
  return d[0] & 1;
}

function unpack25519(o: Float64Array, n: Uint8Array): void {
  let i;
  for (i = 0; i < 16; i++) o[i] = n[2 * i] + (n[2 * i + 1] << 8);
  o[15] &= 0x7fff;
}

function A(o: Float64Array, a: Float64Array, b: Float64Array): void {
  for (let i = 0; i < 16; i++) o[i] = a[i] + b[i];
}

function Z(o: Float64Array, a: Float64Array, b: Float64Array): void {
  for (let i = 0; i < 16; i++) o[i] = a[i] - b[i];
}

function M(o: Float64Array, a: Float64Array, b: Float64Array): void {
  let v,
    c,
    t0 = 0,
    t1 = 0,
    t2 = 0,
    t3 = 0,
    t4 = 0,
    t5 = 0,
    t6 = 0,
    t7 = 0,
    t8 = 0,
    t9 = 0,
    t10 = 0,
    t11 = 0,
    t12 = 0,
    t13 = 0,
    t14 = 0,
    t15 = 0,
    t16 = 0,
    t17 = 0,
    t18 = 0,
    t19 = 0,
    t20 = 0,
    t21 = 0,
    t22 = 0,
    t23 = 0,
    t24 = 0,
    t25 = 0,
    t26 = 0,
    t27 = 0,
    t28 = 0,
    t29 = 0,
    t30 = 0;
  const b0 = b[0],
    b1 = b[1],
    b2 = b[2],
    b3 = b[3],
    b4 = b[4],
    b5 = b[5],
    b6 = b[6],
    b7 = b[7],
    b8 = b[8],
    b9 = b[9],
    b10 = b[10],
    b11 = b[11],
    b12 = b[12],
    b13 = b[13],
    b14 = b[14],
    b15 = b[15];

  v = a[0];
  t0 += v * b0;
  t1 += v * b1;
  t2 += v * b2;
  t3 += v * b3;
  t4 += v * b4;
  t5 += v * b5;
  t6 += v * b6;
  t7 += v * b7;
  t8 += v * b8;
  t9 += v * b9;
  t10 += v * b10;
  t11 += v * b11;
  t12 += v * b12;
  t13 += v * b13;
  t14 += v * b14;
  t15 += v * b15;
  v = a[1];
  t1 += v * b0;
  t2 += v * b1;
  t3 += v * b2;
  t4 += v * b3;
  t5 += v * b4;
  t6 += v * b5;
  t7 += v * b6;
  t8 += v * b7;
  t9 += v * b8;
  t10 += v * b9;
  t11 += v * b10;
  t12 += v * b11;
  t13 += v * b12;
  t14 += v * b13;
  t15 += v * b14;
  t16 += v * b15;
  v = a[2];
  t2 += v * b0;
  t3 += v * b1;
  t4 += v * b2;
  t5 += v * b3;
  t6 += v * b4;
  t7 += v * b5;
  t8 += v * b6;
  t9 += v * b7;
  t10 += v * b8;
  t11 += v * b9;
  t12 += v * b10;
  t13 += v * b11;
  t14 += v * b12;
  t15 += v * b13;
  t16 += v * b14;
  t17 += v * b15;
  v = a[3];
  t3 += v * b0;
  t4 += v * b1;
  t5 += v * b2;
  t6 += v * b3;
  t7 += v * b4;
  t8 += v * b5;
  t9 += v * b6;
  t10 += v * b7;
  t11 += v * b8;
  t12 += v * b9;
  t13 += v * b10;
  t14 += v * b11;
  t15 += v * b12;
  t16 += v * b13;
  t17 += v * b14;
  t18 += v * b15;
  v = a[4];
  t4 += v * b0;
  t5 += v * b1;
  t6 += v * b2;
  t7 += v * b3;
  t8 += v * b4;
  t9 += v * b5;
  t10 += v * b6;
  t11 += v * b7;
  t12 += v * b8;
  t13 += v * b9;
  t14 += v * b10;
  t15 += v * b11;
  t16 += v * b12;
  t17 += v * b13;
  t18 += v * b14;
  t19 += v * b15;
  v = a[5];
  t5 += v * b0;
  t6 += v * b1;
  t7 += v * b2;
  t8 += v * b3;
  t9 += v * b4;
  t10 += v * b5;
  t11 += v * b6;
  t12 += v * b7;
  t13 += v * b8;
  t14 += v * b9;
  t15 += v * b10;
  t16 += v * b11;
  t17 += v * b12;
  t18 += v * b13;
  t19 += v * b14;
  t20 += v * b15;
  v = a[6];
  t6 += v * b0;
  t7 += v * b1;
  t8 += v * b2;
  t9 += v * b3;
  t10 += v * b4;
  t11 += v * b5;
  t12 += v * b6;
  t13 += v * b7;
  t14 += v * b8;
  t15 += v * b9;
  t16 += v * b10;
  t17 += v * b11;
  t18 += v * b12;
  t19 += v * b13;
  t20 += v * b14;
  t21 += v * b15;
  v = a[7];
  t7 += v * b0;
  t8 += v * b1;
  t9 += v * b2;
  t10 += v * b3;
  t11 += v * b4;
  t12 += v * b5;
  t13 += v * b6;
  t14 += v * b7;
  t15 += v * b8;
  t16 += v * b9;
  t17 += v * b10;
  t18 += v * b11;
  t19 += v * b12;
  t20 += v * b13;
  t21 += v * b14;
  t22 += v * b15;
  v = a[8];
  t8 += v * b0;
  t9 += v * b1;
  t10 += v * b2;
  t11 += v * b3;
  t12 += v * b4;
  t13 += v * b5;
  t14 += v * b6;
  t15 += v * b7;
  t16 += v * b8;
  t17 += v * b9;
  t18 += v * b10;
  t19 += v * b11;
  t20 += v * b12;
  t21 += v * b13;
  t22 += v * b14;
  t23 += v * b15;
  v = a[9];
  t9 += v * b0;
  t10 += v * b1;
  t11 += v * b2;
  t12 += v * b3;
  t13 += v * b4;
  t14 += v * b5;
  t15 += v * b6;
  t16 += v * b7;
  t17 += v * b8;
  t18 += v * b9;
  t19 += v * b10;
  t20 += v * b11;
  t21 += v * b12;
  t22 += v * b13;
  t23 += v * b14;
  t24 += v * b15;
  v = a[10];
  t10 += v * b0;
  t11 += v * b1;
  t12 += v * b2;
  t13 += v * b3;
  t14 += v * b4;
  t15 += v * b5;
  t16 += v * b6;
  t17 += v * b7;
  t18 += v * b8;
  t19 += v * b9;
  t20 += v * b10;
  t21 += v * b11;
  t22 += v * b12;
  t23 += v * b13;
  t24 += v * b14;
  t25 += v * b15;
  v = a[11];
  t11 += v * b0;
  t12 += v * b1;
  t13 += v * b2;
  t14 += v * b3;
  t15 += v * b4;
  t16 += v * b5;
  t17 += v * b6;
  t18 += v * b7;
  t19 += v * b8;
  t20 += v * b9;
  t21 += v * b10;
  t22 += v * b11;
  t23 += v * b12;
  t24 += v * b13;
  t25 += v * b14;
  t26 += v * b15;
  v = a[12];
  t12 += v * b0;
  t13 += v * b1;
  t14 += v * b2;
  t15 += v * b3;
  t16 += v * b4;
  t17 += v * b5;
  t18 += v * b6;
  t19 += v * b7;
  t20 += v * b8;
  t21 += v * b9;
  t22 += v * b10;
  t23 += v * b11;
  t24 += v * b12;
  t25 += v * b13;
  t26 += v * b14;
  t27 += v * b15;
  v = a[13];
  t13 += v * b0;
  t14 += v * b1;
  t15 += v * b2;
  t16 += v * b3;
  t17 += v * b4;
  t18 += v * b5;
  t19 += v * b6;
  t20 += v * b7;
  t21 += v * b8;
  t22 += v * b9;
  t23 += v * b10;
  t24 += v * b11;
  t25 += v * b12;
  t26 += v * b13;
  t27 += v * b14;
  t28 += v * b15;
  v = a[14];
  t14 += v * b0;
  t15 += v * b1;
  t16 += v * b2;
  t17 += v * b3;
  t18 += v * b4;
  t19 += v * b5;
  t20 += v * b6;
  t21 += v * b7;
  t22 += v * b8;
  t23 += v * b9;
  t24 += v * b10;
  t25 += v * b11;
  t26 += v * b12;
  t27 += v * b13;
  t28 += v * b14;
  t29 += v * b15;
  v = a[15];
  t15 += v * b0;
  t16 += v * b1;
  t17 += v * b2;
  t18 += v * b3;
  t19 += v * b4;
  t20 += v * b5;
  t21 += v * b6;
  t22 += v * b7;
  t23 += v * b8;
  t24 += v * b9;
  t25 += v * b10;
  t26 += v * b11;
  t27 += v * b12;
  t28 += v * b13;
  t29 += v * b14;
  t30 += v * b15;

  t0 += 38 * t16;
  t1 += 38 * t17;
  t2 += 38 * t18;
  t3 += 38 * t19;
  t4 += 38 * t20;
  t5 += 38 * t21;
  t6 += 38 * t22;
  t7 += 38 * t23;
  t8 += 38 * t24;
  t9 += 38 * t25;
  t10 += 38 * t26;
  t11 += 38 * t27;
  t12 += 38 * t28;
  t13 += 38 * t29;
  t14 += 38 * t30;
  // t15 left as is

  // first car
  c = 1;
  v = t0 + c + 65535;
  c = Math.floor(v / 65536);
  t0 = v - c * 65536;
  v = t1 + c + 65535;
  c = Math.floor(v / 65536);
  t1 = v - c * 65536;
  v = t2 + c + 65535;
  c = Math.floor(v / 65536);
  t2 = v - c * 65536;
  v = t3 + c + 65535;
  c = Math.floor(v / 65536);
  t3 = v - c * 65536;
  v = t4 + c + 65535;
  c = Math.floor(v / 65536);
  t4 = v - c * 65536;
  v = t5 + c + 65535;
  c = Math.floor(v / 65536);
  t5 = v - c * 65536;
  v = t6 + c + 65535;
  c = Math.floor(v / 65536);
  t6 = v - c * 65536;
  v = t7 + c + 65535;
  c = Math.floor(v / 65536);
  t7 = v - c * 65536;
  v = t8 + c + 65535;
  c = Math.floor(v / 65536);
  t8 = v - c * 65536;
  v = t9 + c + 65535;
  c = Math.floor(v / 65536);
  t9 = v - c * 65536;
  v = t10 + c + 65535;
  c = Math.floor(v / 65536);
  t10 = v - c * 65536;
  v = t11 + c + 65535;
  c = Math.floor(v / 65536);
  t11 = v - c * 65536;
  v = t12 + c + 65535;
  c = Math.floor(v / 65536);
  t12 = v - c * 65536;
  v = t13 + c + 65535;
  c = Math.floor(v / 65536);
  t13 = v - c * 65536;
  v = t14 + c + 65535;
  c = Math.floor(v / 65536);
  t14 = v - c * 65536;
  v = t15 + c + 65535;
  c = Math.floor(v / 65536);
  t15 = v - c * 65536;
  t0 += c - 1 + 37 * (c - 1);

  // second car
  c = 1;
  v = t0 + c + 65535;
  c = Math.floor(v / 65536);
  t0 = v - c * 65536;
  v = t1 + c + 65535;
  c = Math.floor(v / 65536);
  t1 = v - c * 65536;
  v = t2 + c + 65535;
  c = Math.floor(v / 65536);
  t2 = v - c * 65536;
  v = t3 + c + 65535;
  c = Math.floor(v / 65536);
  t3 = v - c * 65536;
  v = t4 + c + 65535;
  c = Math.floor(v / 65536);
  t4 = v - c * 65536;
  v = t5 + c + 65535;
  c = Math.floor(v / 65536);
  t5 = v - c * 65536;
  v = t6 + c + 65535;
  c = Math.floor(v / 65536);
  t6 = v - c * 65536;
  v = t7 + c + 65535;
  c = Math.floor(v / 65536);
  t7 = v - c * 65536;
  v = t8 + c + 65535;
  c = Math.floor(v / 65536);
  t8 = v - c * 65536;
  v = t9 + c + 65535;
  c = Math.floor(v / 65536);
  t9 = v - c * 65536;
  v = t10 + c + 65535;
  c = Math.floor(v / 65536);
  t10 = v - c * 65536;
  v = t11 + c + 65535;
  c = Math.floor(v / 65536);
  t11 = v - c * 65536;
  v = t12 + c + 65535;
  c = Math.floor(v / 65536);
  t12 = v - c * 65536;
  v = t13 + c + 65535;
  c = Math.floor(v / 65536);
  t13 = v - c * 65536;
  v = t14 + c + 65535;
  c = Math.floor(v / 65536);
  t14 = v - c * 65536;
  v = t15 + c + 65535;
  c = Math.floor(v / 65536);
  t15 = v - c * 65536;
  t0 += c - 1 + 37 * (c - 1);

  o[0] = t0;
  o[1] = t1;
  o[2] = t2;
  o[3] = t3;
  o[4] = t4;
  o[5] = t5;
  o[6] = t6;
  o[7] = t7;
  o[8] = t8;
  o[9] = t9;
  o[10] = t10;
  o[11] = t11;
  o[12] = t12;
  o[13] = t13;
  o[14] = t14;
  o[15] = t15;
}

function S(o: Float64Array, a: Float64Array): void {
  M(o, a, a);
}

function inv25519(o: Float64Array, i: Float64Array): void {
  const c = gf();
  let a;
  for (a = 0; a < 16; a++) c[a] = i[a];
  for (a = 253; a >= 0; a--) {
    S(c, c);
    if (a !== 2 && a !== 4) M(c, c, i);
  }
  for (a = 0; a < 16; a++) o[a] = c[a];
}

function pow2523(o: Float64Array, i: Float64Array): void {
  const c = gf();
  let a;
  for (a = 0; a < 16; a++) c[a] = i[a];
  for (a = 250; a >= 0; a--) {
    S(c, c);
    if (a !== 1) M(c, c, i);
  }
  for (a = 0; a < 16; a++) o[a] = c[a];
}

function crypto_scalarmult(
  q: Uint8Array,
  n: Uint8Array,
  p: Uint8Array,
): number {
  const z = new Uint8Array(32);
  const x = new Float64Array(80);
  let r;
  let i;
  const a = gf(),
    b = gf(),
    c = gf(),
    d = gf(),
    e = gf(),
    f = gf();
  for (i = 0; i < 31; i++) z[i] = n[i];
  z[31] = (n[31] & 127) | 64;
  z[0] &= 248;
  unpack25519(x, p);
  for (i = 0; i < 16; i++) {
    b[i] = x[i];
    d[i] = a[i] = c[i] = 0;
  }
  a[0] = d[0] = 1;
  for (i = 254; i >= 0; --i) {
    r = (z[i >>> 3] >>> (i & 7)) & 1;
    sel25519(a, b, r);
    sel25519(c, d, r);
    A(e, a, c);
    Z(a, a, c);
    A(c, b, d);
    Z(b, b, d);
    S(d, e);
    S(f, a);
    M(a, c, a);
    M(c, b, e);
    A(e, a, c);
    Z(a, a, c);
    S(b, a);
    Z(c, d, f);
    M(a, c, _121665);
    A(a, a, d);
    M(c, c, a);
    M(a, d, f);
    M(d, b, x);
    S(b, e);
    sel25519(a, b, r);
    sel25519(c, d, r);
  }
  for (i = 0; i < 16; i++) {
    x[i + 16] = a[i];
    x[i + 32] = c[i];
    x[i + 48] = b[i];
    x[i + 64] = d[i];
  }
  const x32 = x.subarray(32);
  const x16 = x.subarray(16);
  inv25519(x32, x32);
  M(x16, x16, x32);
  pack25519(q, x16);
  return 0;
}

function crypto_scalarmult_base(q: Uint8Array, n: Uint8Array): number {
  return crypto_scalarmult(q, n, _9);
}

export function crypto_scalarmult_noclamp(
  q: Uint8Array,
  n: Uint8Array,
  p: Uint8Array,
): number {
  const z = new Uint8Array(32);
  const x = new Float64Array(80);
  let r;
  let i;
  const a = gf(),
    b = gf(),
    c = gf(),
    d = gf(),
    e = gf(),
    f = gf();
  for (i = 0; i < 31; i++) z[i] = n[i];
  unpack25519(x, p);
  for (i = 0; i < 16; i++) {
    b[i] = x[i];
    d[i] = a[i] = c[i] = 0;
  }
  a[0] = d[0] = 1;
  for (i = 254; i >= 0; --i) {
    r = (z[i >>> 3] >>> (i & 7)) & 1;
    sel25519(a, b, r);
    sel25519(c, d, r);
    A(e, a, c);
    Z(a, a, c);
    A(c, b, d);
    Z(b, b, d);
    S(d, e);
    S(f, a);
    M(a, c, a);
    M(c, b, e);
    A(e, a, c);
    Z(a, a, c);
    S(b, a);
    Z(c, d, f);
    M(a, c, _121665);
    A(a, a, d);
    M(c, c, a);
    M(a, d, f);
    M(d, b, x);
    S(b, e);
    sel25519(a, b, r);
    sel25519(c, d, r);
  }
  for (i = 0; i < 16; i++) {
    x[i + 16] = a[i];
    x[i + 32] = c[i];
    x[i + 48] = b[i];
    x[i + 64] = d[i];
  }
  const x32 = x.subarray(32);
  const x16 = x.subarray(16);
  inv25519(x32, x32);
  M(x16, x16, x32);
  pack25519(q, x16);
  return 0;
}

export function crypto_scalarmult_base_noclamp(
  q: Uint8Array,
  n: Uint8Array,
): number {
  return crypto_scalarmult_noclamp(q, n, _9);
}

// prettier-ignore
const K = [
  0x428a2f98, 0xd728ae22, 0x71374491, 0x23ef65cd,
  0xb5c0fbcf, 0xec4d3b2f, 0xe9b5dba5, 0x8189dbbc,
  0x3956c25b, 0xf348b538, 0x59f111f1, 0xb605d019,
  0x923f82a4, 0xaf194f9b, 0xab1c5ed5, 0xda6d8118,
  0xd807aa98, 0xa3030242, 0x12835b01, 0x45706fbe,
  0x243185be, 0x4ee4b28c, 0x550c7dc3, 0xd5ffb4e2,
  0x72be5d74, 0xf27b896f, 0x80deb1fe, 0x3b1696b1,
  0x9bdc06a7, 0x25c71235, 0xc19bf174, 0xcf692694,
  0xe49b69c1, 0x9ef14ad2, 0xefbe4786, 0x384f25e3,
  0x0fc19dc6, 0x8b8cd5b5, 0x240ca1cc, 0x77ac9c65,
  0x2de92c6f, 0x592b0275, 0x4a7484aa, 0x6ea6e483,
  0x5cb0a9dc, 0xbd41fbd4, 0x76f988da, 0x831153b5,
  0x983e5152, 0xee66dfab, 0xa831c66d, 0x2db43210,
  0xb00327c8, 0x98fb213f, 0xbf597fc7, 0xbeef0ee4,
  0xc6e00bf3, 0x3da88fc2, 0xd5a79147, 0x930aa725,
  0x06ca6351, 0xe003826f, 0x14292967, 0x0a0e6e70,
  0x27b70a85, 0x46d22ffc, 0x2e1b2138, 0x5c26c926,
  0x4d2c6dfc, 0x5ac42aed, 0x53380d13, 0x9d95b3df,
  0x650a7354, 0x8baf63de, 0x766a0abb, 0x3c77b2a8,
  0x81c2c92e, 0x47edaee6, 0x92722c85, 0x1482353b,
  0xa2bfe8a1, 0x4cf10364, 0xa81a664b, 0xbc423001,
  0xc24b8b70, 0xd0f89791, 0xc76c51a3, 0x0654be30,
  0xd192e819, 0xd6ef5218, 0xd6990624, 0x5565a910,
  0xf40e3585, 0x5771202a, 0x106aa070, 0x32bbd1b8,
  0x19a4c116, 0xb8d2d0c8, 0x1e376c08, 0x5141ab53,
  0x2748774c, 0xdf8eeb99, 0x34b0bcb5, 0xe19b48a8,
  0x391c0cb3, 0xc5c95a63, 0x4ed8aa4a, 0xe3418acb,
  0x5b9cca4f, 0x7763e373, 0x682e6ff3, 0xd6b2b8a3,
  0x748f82ee, 0x5defb2fc, 0x78a5636f, 0x43172f60,
  0x84c87814, 0xa1f0ab72, 0x8cc70208, 0x1a6439ec,
  0x90befffa, 0x23631e28, 0xa4506ceb, 0xde82bde9,
  0xbef9a3f7, 0xb2c67915, 0xc67178f2, 0xe372532b,
  0xca273ece, 0xea26619c, 0xd186b8c7, 0x21c0c207,
  0xeada7dd6, 0xcde0eb1e, 0xf57d4f7f, 0xee6ed178,
  0x06f067aa, 0x72176fba, 0x0a637dc5, 0xa2c898a6,
  0x113f9804, 0xbef90dae, 0x1b710b35, 0x131c471b,
  0x28db77f5, 0x23047d84, 0x32caab7b, 0x40c72493,
  0x3c9ebe0a, 0x15c9bebc, 0x431d67c4, 0x9c100d4c,
  0x4cc5d4be, 0xcb3e42b6, 0x597f299c, 0xfc657e2a,
  0x5fcb6fab, 0x3ad6faec, 0x6c44198c, 0x4a475817
];

function crypto_hashblocks_hl(
  hh: Int32Array,
  hl: Int32Array,
  m: Uint8Array,
  n: number,
): number {
  const wh = new Int32Array(16),
    wl = new Int32Array(16);
  let bh0,
    bh1,
    bh2,
    bh3,
    bh4,
    bh5,
    bh6,
    bh7,
    bl0,
    bl1,
    bl2,
    bl3,
    bl4,
    bl5,
    bl6,
    bl7,
    th,
    tl,
    i,
    j,
    h,
    l,
    a,
    b,
    c,
    d;

  let ah0 = hh[0],
    ah1 = hh[1],
    ah2 = hh[2],
    ah3 = hh[3],
    ah4 = hh[4],
    ah5 = hh[5],
    ah6 = hh[6],
    ah7 = hh[7],
    al0 = hl[0],
    al1 = hl[1],
    al2 = hl[2],
    al3 = hl[3],
    al4 = hl[4],
    al5 = hl[5],
    al6 = hl[6],
    al7 = hl[7];

  let pos = 0;
  while (n >= 128) {
    for (i = 0; i < 16; i++) {
      j = 8 * i + pos;
      wh[i] = (m[j + 0] << 24) | (m[j + 1] << 16) | (m[j + 2] << 8) | m[j + 3];
      wl[i] = (m[j + 4] << 24) | (m[j + 5] << 16) | (m[j + 6] << 8) | m[j + 7];
    }
    for (i = 0; i < 80; i++) {
      bh0 = ah0;
      bh1 = ah1;
      bh2 = ah2;
      bh3 = ah3;
      bh4 = ah4;
      bh5 = ah5;
      bh6 = ah6;
      bh7 = ah7;

      bl0 = al0;
      bl1 = al1;
      bl2 = al2;
      bl3 = al3;
      bl4 = al4;
      bl5 = al5;
      bl6 = al6;
      bl7 = al7;

      // add
      h = ah7;
      l = al7;

      a = l & 0xffff;
      b = l >>> 16;
      c = h & 0xffff;
      d = h >>> 16;

      // Sigma1
      h =
        ((ah4 >>> 14) | (al4 << (32 - 14))) ^
        ((ah4 >>> 18) | (al4 << (32 - 18))) ^
        ((al4 >>> (41 - 32)) | (ah4 << (32 - (41 - 32))));
      l =
        ((al4 >>> 14) | (ah4 << (32 - 14))) ^
        ((al4 >>> 18) | (ah4 << (32 - 18))) ^
        ((ah4 >>> (41 - 32)) | (al4 << (32 - (41 - 32))));

      a += l & 0xffff;
      b += l >>> 16;
      c += h & 0xffff;
      d += h >>> 16;

      // Ch
      h = (ah4 & ah5) ^ (~ah4 & ah6);
      l = (al4 & al5) ^ (~al4 & al6);

      a += l & 0xffff;
      b += l >>> 16;
      c += h & 0xffff;
      d += h >>> 16;

      // K
      h = K[i * 2];
      l = K[i * 2 + 1];

      a += l & 0xffff;
      b += l >>> 16;
      c += h & 0xffff;
      d += h >>> 16;

      // w
      h = wh[i % 16];
      l = wl[i % 16];

      a += l & 0xffff;
      b += l >>> 16;
      c += h & 0xffff;
      d += h >>> 16;

      b += a >>> 16;
      c += b >>> 16;
      d += c >>> 16;

      th = (c & 0xffff) | (d << 16);
      tl = (a & 0xffff) | (b << 16);

      // add
      h = th;
      l = tl;

      a = l & 0xffff;
      b = l >>> 16;
      c = h & 0xffff;
      d = h >>> 16;

      // Sigma0
      h =
        ((ah0 >>> 28) | (al0 << (32 - 28))) ^
        ((al0 >>> (34 - 32)) | (ah0 << (32 - (34 - 32)))) ^
        ((al0 >>> (39 - 32)) | (ah0 << (32 - (39 - 32))));
      l =
        ((al0 >>> 28) | (ah0 << (32 - 28))) ^
        ((ah0 >>> (34 - 32)) | (al0 << (32 - (34 - 32)))) ^
        ((ah0 >>> (39 - 32)) | (al0 << (32 - (39 - 32))));

      a += l & 0xffff;
      b += l >>> 16;
      c += h & 0xffff;
      d += h >>> 16;

      // Maj
      h = (ah0 & ah1) ^ (ah0 & ah2) ^ (ah1 & ah2);
      l = (al0 & al1) ^ (al0 & al2) ^ (al1 & al2);

      a += l & 0xffff;
      b += l >>> 16;
      c += h & 0xffff;
      d += h >>> 16;

      b += a >>> 16;
      c += b >>> 16;
      d += c >>> 16;

      bh7 = (c & 0xffff) | (d << 16);
      bl7 = (a & 0xffff) | (b << 16);

      // add
      h = bh3;
      l = bl3;

      a = l & 0xffff;
      b = l >>> 16;
      c = h & 0xffff;
      d = h >>> 16;

      h = th;
      l = tl;

      a += l & 0xffff;
      b += l >>> 16;
      c += h & 0xffff;
      d += h >>> 16;

      b += a >>> 16;
      c += b >>> 16;
      d += c >>> 16;

      bh3 = (c & 0xffff) | (d << 16);
      bl3 = (a & 0xffff) | (b << 16);

      ah1 = bh0;
      ah2 = bh1;
      ah3 = bh2;
      ah4 = bh3;
      ah5 = bh4;
      ah6 = bh5;
      ah7 = bh6;
      ah0 = bh7;

      al1 = bl0;
      al2 = bl1;
      al3 = bl2;
      al4 = bl3;
      al5 = bl4;
      al6 = bl5;
      al7 = bl6;
      al0 = bl7;

      if (i % 16 === 15) {
        for (j = 0; j < 16; j++) {
          // add
          h = wh[j];
          l = wl[j];

          a = l & 0xffff;
          b = l >>> 16;
          c = h & 0xffff;
          d = h >>> 16;

          h = wh[(j + 9) % 16];
          l = wl[(j + 9) % 16];

          a += l & 0xffff;
          b += l >>> 16;
          c += h & 0xffff;
          d += h >>> 16;

          // sigma0
          th = wh[(j + 1) % 16];
          tl = wl[(j + 1) % 16];
          h =
            ((th >>> 1) | (tl << (32 - 1))) ^
            ((th >>> 8) | (tl << (32 - 8))) ^
            (th >>> 7);
          l =
            ((tl >>> 1) | (th << (32 - 1))) ^
            ((tl >>> 8) | (th << (32 - 8))) ^
            ((tl >>> 7) | (th << (32 - 7)));

          a += l & 0xffff;
          b += l >>> 16;
          c += h & 0xffff;
          d += h >>> 16;

          // sigma1
          th = wh[(j + 14) % 16];
          tl = wl[(j + 14) % 16];
          h =
            ((th >>> 19) | (tl << (32 - 19))) ^
            ((tl >>> (61 - 32)) | (th << (32 - (61 - 32)))) ^
            (th >>> 6);
          l =
            ((tl >>> 19) | (th << (32 - 19))) ^
            ((th >>> (61 - 32)) | (tl << (32 - (61 - 32)))) ^
            ((tl >>> 6) | (th << (32 - 6)));

          a += l & 0xffff;
          b += l >>> 16;
          c += h & 0xffff;
          d += h >>> 16;

          b += a >>> 16;
          c += b >>> 16;
          d += c >>> 16;

          wh[j] = (c & 0xffff) | (d << 16);
          wl[j] = (a & 0xffff) | (b << 16);
        }
      }
    }

    // add
    h = ah0;
    l = al0;

    a = l & 0xffff;
    b = l >>> 16;
    c = h & 0xffff;
    d = h >>> 16;

    h = hh[0];
    l = hl[0];

    a += l & 0xffff;
    b += l >>> 16;
    c += h & 0xffff;
    d += h >>> 16;

    b += a >>> 16;
    c += b >>> 16;
    d += c >>> 16;

    hh[0] = ah0 = (c & 0xffff) | (d << 16);
    hl[0] = al0 = (a & 0xffff) | (b << 16);

    h = ah1;
    l = al1;

    a = l & 0xffff;
    b = l >>> 16;
    c = h & 0xffff;
    d = h >>> 16;

    h = hh[1];
    l = hl[1];

    a += l & 0xffff;
    b += l >>> 16;
    c += h & 0xffff;
    d += h >>> 16;

    b += a >>> 16;
    c += b >>> 16;
    d += c >>> 16;

    hh[1] = ah1 = (c & 0xffff) | (d << 16);
    hl[1] = al1 = (a & 0xffff) | (b << 16);

    h = ah2;
    l = al2;

    a = l & 0xffff;
    b = l >>> 16;
    c = h & 0xffff;
    d = h >>> 16;

    h = hh[2];
    l = hl[2];

    a += l & 0xffff;
    b += l >>> 16;
    c += h & 0xffff;
    d += h >>> 16;

    b += a >>> 16;
    c += b >>> 16;
    d += c >>> 16;

    hh[2] = ah2 = (c & 0xffff) | (d << 16);
    hl[2] = al2 = (a & 0xffff) | (b << 16);

    h = ah3;
    l = al3;

    a = l & 0xffff;
    b = l >>> 16;
    c = h & 0xffff;
    d = h >>> 16;

    h = hh[3];
    l = hl[3];

    a += l & 0xffff;
    b += l >>> 16;
    c += h & 0xffff;
    d += h >>> 16;

    b += a >>> 16;
    c += b >>> 16;
    d += c >>> 16;

    hh[3] = ah3 = (c & 0xffff) | (d << 16);
    hl[3] = al3 = (a & 0xffff) | (b << 16);

    h = ah4;
    l = al4;

    a = l & 0xffff;
    b = l >>> 16;
    c = h & 0xffff;
    d = h >>> 16;

    h = hh[4];
    l = hl[4];

    a += l & 0xffff;
    b += l >>> 16;
    c += h & 0xffff;
    d += h >>> 16;

    b += a >>> 16;
    c += b >>> 16;
    d += c >>> 16;

    hh[4] = ah4 = (c & 0xffff) | (d << 16);
    hl[4] = al4 = (a & 0xffff) | (b << 16);

    h = ah5;
    l = al5;

    a = l & 0xffff;
    b = l >>> 16;
    c = h & 0xffff;
    d = h >>> 16;

    h = hh[5];
    l = hl[5];

    a += l & 0xffff;
    b += l >>> 16;
    c += h & 0xffff;
    d += h >>> 16;

    b += a >>> 16;
    c += b >>> 16;
    d += c >>> 16;

    hh[5] = ah5 = (c & 0xffff) | (d << 16);
    hl[5] = al5 = (a & 0xffff) | (b << 16);

    h = ah6;
    l = al6;

    a = l & 0xffff;
    b = l >>> 16;
    c = h & 0xffff;
    d = h >>> 16;

    h = hh[6];
    l = hl[6];

    a += l & 0xffff;
    b += l >>> 16;
    c += h & 0xffff;
    d += h >>> 16;

    b += a >>> 16;
    c += b >>> 16;
    d += c >>> 16;

    hh[6] = ah6 = (c & 0xffff) | (d << 16);
    hl[6] = al6 = (a & 0xffff) | (b << 16);

    h = ah7;
    l = al7;

    a = l & 0xffff;
    b = l >>> 16;
    c = h & 0xffff;
    d = h >>> 16;

    h = hh[7];
    l = hl[7];

    a += l & 0xffff;
    b += l >>> 16;
    c += h & 0xffff;
    d += h >>> 16;

    b += a >>> 16;
    c += b >>> 16;
    d += c >>> 16;

    hh[7] = ah7 = (c & 0xffff) | (d << 16);
    hl[7] = al7 = (a & 0xffff) | (b << 16);

    pos += 128;
    n -= 128;
  }

  return n;
}

function crypto_hash(out: Uint8Array, m: Uint8Array, n: number): number {
  const hh = new Int32Array(8);
  const hl = new Int32Array(8);
  const x = new Uint8Array(256);
  const b = n;

  hh[0] = 0x6a09e667;
  hh[1] = 0xbb67ae85;
  hh[2] = 0x3c6ef372;
  hh[3] = 0xa54ff53a;
  hh[4] = 0x510e527f;
  hh[5] = 0x9b05688c;
  hh[6] = 0x1f83d9ab;
  hh[7] = 0x5be0cd19;

  hl[0] = 0xf3bcc908;
  hl[1] = 0x84caa73b;
  hl[2] = 0xfe94f82b;
  hl[3] = 0x5f1d36f1;
  hl[4] = 0xade682d1;
  hl[5] = 0x2b3e6c1f;
  hl[6] = 0xfb41bd6b;
  hl[7] = 0x137e2179;

  crypto_hashblocks_hl(hh, hl, m, n);
  n %= 128;

  for (let i = 0; i < n; i++) x[i] = m[b - n + i];
  x[n] = 128;

  n = 256 - 128 * (n < 112 ? 1 : 0);
  x[n - 9] = 0;
  ts64(x, n - 8, (b / 0x20000000) | 0, b << 3);
  crypto_hashblocks_hl(hh, hl, x, n);

  for (let i = 0; i < 8; i++) ts64(out, 8 * i, hh[i], hl[i]);

  return 0;
}

/**
 * Incremental version of crypto_hash.
 */
export class HashState {
  private hh = new Int32Array(8);
  private hl = new Int32Array(8);

  private next = new Uint8Array(128);
  private p = 0;
  private total = 0;

  constructor() {
    this.hh[0] = 0x6a09e667;
    this.hh[1] = 0xbb67ae85;
    this.hh[2] = 0x3c6ef372;
    this.hh[3] = 0xa54ff53a;
    this.hh[4] = 0x510e527f;
    this.hh[5] = 0x9b05688c;
    this.hh[6] = 0x1f83d9ab;
    this.hh[7] = 0x5be0cd19;

    this.hl[0] = 0xf3bcc908;
    this.hl[1] = 0x84caa73b;
    this.hl[2] = 0xfe94f82b;
    this.hl[3] = 0x5f1d36f1;
    this.hl[4] = 0xade682d1;
    this.hl[5] = 0x2b3e6c1f;
    this.hl[6] = 0xfb41bd6b;
    this.hl[7] = 0x137e2179;
  }

  update(data: Uint8Array): HashState {
    this.total += data.length;
    let i = 0;
    while (i < data.length) {
      const r = 128 - this.p;
      if (r > data.length - i) {
        for (let j = 0; i + j < data.length; j++) {
          this.next[this.p + j] = data[i + j];
        }
        this.p += data.length - i;
        break;
      } else {
        for (let j = 0; this.p + j < 128; j++) {
          this.next[this.p + j] = data[i + j];
        }
        crypto_hashblocks_hl(this.hh, this.hl, this.next, 128);
        i += 128 - this.p;
        this.p = 0;
      }
    }
    return this;
  }

  finish(): Uint8Array {
    const out = new Uint8Array(64);
    let n = this.p;
    const x = new Uint8Array(256);
    const b = this.total;
    for (let i = 0; i < n; i++) x[i] = this.next[i];
    x[n] = 128;

    n = 256 - 128 * (n < 112 ? 1 : 0);
    x[n - 9] = 0;
    ts64(x, n - 8, (b / 0x20000000) | 0, b << 3);
    crypto_hashblocks_hl(this.hh, this.hl, x, n);

    for (let i = 0; i < 8; i++) ts64(out, 8 * i, this.hh[i], this.hl[i]);
    return out;
  }
}

function add(p: Float64Array[], q: Float64Array[]): void {
  const a = gf(),
    b = gf(),
    c = gf(),
    d = gf(),
    e = gf(),
    f = gf(),
    g = gf(),
    h = gf(),
    t = gf();

  Z(a, p[1], p[0]);
  Z(t, q[1], q[0]);
  M(a, a, t);
  A(b, p[0], p[1]);
  A(t, q[0], q[1]);
  M(b, b, t);
  M(c, p[3], q[3]);
  M(c, c, D2);
  M(d, p[2], q[2]);
  A(d, d, d);
  Z(e, b, a);
  Z(f, d, c);
  A(g, d, c);
  A(h, b, a);

  M(p[0], e, f);
  M(p[1], h, g);
  M(p[2], g, f);
  M(p[3], e, h);
}

function cswap(p: Float64Array[], q: Float64Array[], b: number): void {
  let i;
  for (i = 0; i < 4; i++) {
    sel25519(p[i], q[i], b);
  }
}

function pack(r: Uint8Array, p: Float64Array[]): void {
  const tx = gf(),
    ty = gf(),
    zi = gf();
  inv25519(zi, p[2]);
  M(tx, p[0], zi);
  M(ty, p[1], zi);
  pack25519(r, ty);
  r[31] ^= par25519(tx) << 7;
}

/**
 * Ed25519 scalar multiplication
 */
function scalarmult(p: Float64Array[], q: Float64Array[], s: Uint8Array): void {
  let b, i;
  set25519(p[0], gf0);
  set25519(p[1], gf1);
  set25519(p[2], gf1);
  set25519(p[3], gf0);
  for (i = 255; i >= 0; --i) {
    b = (s[(i / 8) | 0] >> (i & 7)) & 1;
    cswap(p, q, b);
    add(q, p);
    add(p, p);
    cswap(p, q, b);
  }
}

function scalarbase(p: Float64Array[], s: Uint8Array): void {
  const q = [gf(), gf(), gf(), gf()];
  set25519(q[0], X);
  set25519(q[1], Y);
  set25519(q[2], gf1);
  M(q[3], X, Y);
  scalarmult(p, q, s);
}

function crypto_sign_keypair(
  pk: Uint8Array,
  sk: Uint8Array,
  seeded: boolean,
): number {
  const d = new Uint8Array(64);
  const p = [gf(), gf(), gf(), gf()];

  if (!seeded) randombytes(sk, 32);
  crypto_hash(d, sk, 32);
  d[0] &= 248;
  d[31] &= 127;
  d[31] |= 64;

  scalarbase(p, d);
  pack(pk, p);

  for (let i = 0; i < 32; i++) sk[i + 32] = pk[i];
  return 0;
}

export const L = new Float64Array([
  0xed, 0xd3, 0xf5, 0x5c, 0x1a, 0x63, 0x12, 0x58, 0xd6, 0x9c, 0xf7, 0xa2, 0xde,
  0xf9, 0xde, 0x14, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0x10,
]);

function modL(r: Uint8Array, x: Float64Array): void {
  let carry, i, j, k;
  for (i = 63; i >= 32; --i) {
    carry = 0;
    for (j = i - 32, k = i - 12; j < k; ++j) {
      x[j] += carry - 16 * x[i] * L[j - (i - 32)];
      carry = Math.floor((x[j] + 128) / 256);
      x[j] -= carry * 256;
    }
    x[j] += carry;
    x[i] = 0;
  }
  carry = 0;
  for (j = 0; j < 32; j++) {
    x[j] += carry - (x[31] >> 4) * L[j];
    carry = x[j] >> 8;
    x[j] &= 255;
  }
  for (j = 0; j < 32; j++) x[j] -= carry * L[j];
  for (i = 0; i < 32; i++) {
    x[i + 1] += x[i] >> 8;
    r[i] = x[i] & 255;
  }
}

function reduce(r: Uint8Array): void {
  const x = new Float64Array(64);
  for (let i = 0; i < 64; i++) x[i] = r[i];
  for (let i = 0; i < 64; i++) r[i] = 0;
  modL(r, x);
}

// Note: difference from C - smlen returned, not passed as argument.
function crypto_sign(
  sm: Uint8Array,
  m: Uint8Array,
  n: number,
  sk: Uint8Array,
): number {
  const d = new Uint8Array(64),
    h = new Uint8Array(64),
    r = new Uint8Array(64);
  let i, j;
  const x = new Float64Array(64);
  const p = [gf(), gf(), gf(), gf()];

  crypto_hash(d, sk, 32);
  d[0] &= 248;
  d[31] &= 127;
  d[31] |= 64;

  const smlen = n + 64;
  for (i = 0; i < n; i++) sm[64 + i] = m[i];
  for (i = 0; i < 32; i++) sm[32 + i] = d[32 + i];

  crypto_hash(r, sm.subarray(32), n + 32);
  reduce(r);
  scalarbase(p, r);
  pack(sm, p);

  for (i = 32; i < 64; i++) sm[i] = sk[i];
  crypto_hash(h, sm, n + 64);
  reduce(h);

  for (i = 0; i < 64; i++) x[i] = 0;
  for (i = 0; i < 32; i++) x[i] = r[i];
  for (i = 0; i < 32; i++) {
    for (j = 0; j < 32; j++) {
      x[i + j] += h[i] * d[j];
    }
  }

  modL(sm.subarray(32), x);
  return smlen;
}

function unpackpos(r: Float64Array[], p: Uint8Array): number {
  // FIXME: implement directly
  const q = [gf(), gf(), gf(), gf()];
  if (unpackneg(q, p)) return -1;
  const scalar0 = new Uint8Array(32);
  const scalar1 = new Uint8Array(32);
  scalar1[0] = 1;
  const scalarNeg1 = crypto_core_ed25519_scalar_sub(scalar0, scalar1);
  scalarmult(r, q, scalarNeg1);
  return 0;
}

function unpackneg(r: Float64Array[], p: Uint8Array): number {
  const t = gf();
  const chk = gf();
  const num = gf();
  const den = gf();
  const den2 = gf();
  const den4 = gf();
  const den6 = gf();

  set25519(r[2], gf1);
  unpack25519(r[1], p);
  S(num, r[1]);
  M(den, num, D);
  Z(num, num, r[2]);
  A(den, r[2], den);

  S(den2, den);
  S(den4, den2);
  M(den6, den4, den2);
  M(t, den6, num);
  M(t, t, den);

  pow2523(t, t);
  M(t, t, num);
  M(t, t, den);
  M(t, t, den);
  M(r[0], t, den);

  S(chk, r[0]);
  M(chk, chk, den);
  if (neq25519(chk, num)) M(r[0], r[0], I);

  S(chk, r[0]);
  M(chk, chk, den);
  if (neq25519(chk, num)) return -1;

  if (par25519(r[0]) === p[31] >> 7) Z(r[0], gf0, r[0]);

  M(r[3], r[0], r[1]);
  return 0;
}

export function crypto_scalarmult_ed25519_base_noclamp(
  s: Uint8Array,
): Uint8Array {
  const r = new Uint8Array(32);
  const p = [gf(), gf(), gf(), gf()];

  scalarbase(p, s);
  pack(r, p);
  return r;
}

export function crypto_scalarmult_ed25519_noclamp(
  s: Uint8Array,
  q: Uint8Array,
): Uint8Array {
  const r = new Uint8Array(32);
  const p = [gf(), gf(), gf(), gf()];
  const ql = [gf(), gf(), gf(), gf()];

  if (unpackpos(ql, q)) throw new Error();
  scalarmult(p, ql, s);
  pack(r, p);
  return r;
}

export function crypto_core_ed25519_add(
  p1: Uint8Array,
  p2: Uint8Array,
): Uint8Array {
  const q1 = [gf(), gf(), gf(), gf()];
  const q2 = [gf(), gf(), gf(), gf()];
  const res = new Uint8Array(32);
  if (unpackpos(q1, p1)) throw new Error();
  if (unpackpos(q2, p2)) throw new Error();
  add(q1, q2);
  pack(res, q1);
  return res;
}

function crypto_sign_open(
  m: Uint8Array,
  sm: Uint8Array,
  n: number,
  pk: Uint8Array,
): number {
  let i, mlen;
  const t = new Uint8Array(32),
    h = new Uint8Array(64);
  const p = [gf(), gf(), gf(), gf()],
    q = [gf(), gf(), gf(), gf()];

  mlen = -1;
  if (n < 64) return -1;

  if (unpackneg(q, pk)) return -1;

  for (i = 0; i < n; i++) m[i] = sm[i];
  for (i = 0; i < 32; i++) m[i + 32] = pk[i];
  crypto_hash(h, m, n);
  reduce(h);
  scalarmult(p, q, h);

  scalarbase(q, sm.subarray(32));
  add(p, q);
  pack(t, p);

  n -= 64;
  if (crypto_verify_32(sm, 0, t, 0)) {
    for (i = 0; i < n; i++) m[i] = 0;
    return -1;
  }

  for (i = 0; i < n; i++) m[i] = sm[i + 64];
  mlen = n;
  return mlen;
}

const crypto_secretbox_KEYBYTES = 32;
const crypto_secretbox_NONCEBYTES = 24;
const crypto_secretbox_ZEROBYTES = 32;
const crypto_secretbox_BOXZEROBYTES = 16;
const crypto_scalarmult_BYTES = 32;
const crypto_scalarmult_SCALARBYTES = 32;
const crypto_sign_BYTES = 64;
const crypto_sign_PUBLICKEYBYTES = 32;
const crypto_sign_SECRETKEYBYTES = 64;
const crypto_sign_SEEDBYTES = 32;
const crypto_hash_BYTES = 64;

/* High-level API */

function checkLengths(k: Uint8Array, n: Uint8Array) {
  if (k.length !== crypto_secretbox_KEYBYTES) throw new Error("bad key size");
  if (n.length !== crypto_secretbox_NONCEBYTES)
    throw new Error("bad nonce size");
}

function checkArrayTypes(...args: Uint8Array[]): void {
  for (let i = 0; i < args.length; i++) {
    if (!(args[i] instanceof Uint8Array))
      throw new TypeError("unexpected type, use Uint8Array");
  }
}

export function randomBytes(n: number): Uint8Array {
  const b = new Uint8Array(n);
  randombytes(b, n);
  return b;
}

export function scalarMult(n: Uint8Array, p: Uint8Array): Uint8Array {
  checkArrayTypes(n, p);
  if (n.length !== crypto_scalarmult_SCALARBYTES) throw new Error("bad n size");
  if (p.length !== crypto_scalarmult_BYTES) throw new Error("bad p size");
  const q = new Uint8Array(crypto_scalarmult_BYTES);
  crypto_scalarmult(q, n, p);
  return q;
}

export function scalarMult_base(n: Uint8Array): Uint8Array {
  checkArrayTypes(n);
  if (n.length !== crypto_scalarmult_SCALARBYTES) throw new Error("bad n size");
  const q = new Uint8Array(crypto_scalarmult_BYTES);
  crypto_scalarmult_base(q, n);
  return q;
}

export const scalarMult_scalarLength = crypto_scalarmult_SCALARBYTES;
export const scalarMult_groupElementLength = crypto_scalarmult_BYTES;

export function sign(msg: Uint8Array, secretKey: Uint8Array): Uint8Array {
  checkArrayTypes(msg, secretKey);
  if (secretKey.length !== crypto_sign_SECRETKEYBYTES)
    throw new Error("bad secret key size");
  const signedMsg = new Uint8Array(crypto_sign_BYTES + msg.length);
  crypto_sign(signedMsg, msg, msg.length, secretKey);
  return signedMsg;
}

export function sign_open(
  signedMsg: Uint8Array,
  publicKey: Uint8Array,
): Uint8Array | null {
  checkArrayTypes(signedMsg, publicKey);
  if (publicKey.length !== crypto_sign_PUBLICKEYBYTES)
    throw new Error("bad public key size");
  const tmp = new Uint8Array(signedMsg.length);
  const mlen = crypto_sign_open(tmp, signedMsg, signedMsg.length, publicKey);
  if (mlen < 0) return null;
  const m = new Uint8Array(mlen);
  for (let i = 0; i < m.length; i++) m[i] = tmp[i];
  return m;
}

export function sign_detached(
  msg: Uint8Array,
  secretKey: Uint8Array,
): Uint8Array {
  const signedMsg = sign(msg, secretKey);
  const sig = new Uint8Array(crypto_sign_BYTES);
  for (let i = 0; i < sig.length; i++) sig[i] = signedMsg[i];
  return sig;
}

export function sign_detached_verify(
  msg: Uint8Array,
  sig: Uint8Array,
  publicKey: Uint8Array,
): boolean {
  checkArrayTypes(msg, sig, publicKey);
  if (sig.length !== crypto_sign_BYTES) throw new Error("bad signature size");
  if (publicKey.length !== crypto_sign_PUBLICKEYBYTES)
    throw new Error("bad public key size");
  const sm = new Uint8Array(crypto_sign_BYTES + msg.length);
  const m = new Uint8Array(crypto_sign_BYTES + msg.length);
  let i;
  for (i = 0; i < crypto_sign_BYTES; i++) sm[i] = sig[i];
  for (i = 0; i < msg.length; i++) sm[i + crypto_sign_BYTES] = msg[i];
  return crypto_sign_open(m, sm, sm.length, publicKey) >= 0;
}

export function sign_keyPair(): {
  publicKey: Uint8Array;
  secretKey: Uint8Array;
} {
  const pk = new Uint8Array(crypto_sign_PUBLICKEYBYTES);
  const sk = new Uint8Array(crypto_sign_SECRETKEYBYTES);
  crypto_sign_keypair(pk, sk, false);
  return { publicKey: pk, secretKey: sk };
}

export function x25519_edwards_keyPair_fromSecretKey(
  secretKey: Uint8Array,
): Uint8Array {
  const p = [gf(), gf(), gf(), gf()];
  const pk = new Uint8Array(32);

  const d = new Uint8Array(64);
  if (secretKey.length != 32) {
    throw new Error("bad secret key size");
  }
  d.set(secretKey, 0);

  d[0] &= 248;
  d[31] &= 127;
  d[31] |= 64;

  scalarbase(p, d);
  pack(pk, p);

  return pk;
}

export function crypto_sign_keyPair_fromSecretKey(secretKey: Uint8Array): {
  publicKey: Uint8Array;
  secretKey: Uint8Array;
} {
  checkArrayTypes(secretKey);
  if (secretKey.length !== crypto_sign_SECRETKEYBYTES)
    throw new Error("bad secret key size");
  const pk = new Uint8Array(crypto_sign_PUBLICKEYBYTES);
  for (let i = 0; i < pk.length; i++) pk[i] = secretKey[32 + i];
  return { publicKey: pk, secretKey: new Uint8Array(secretKey) };
}

export function crypto_sign_keyPair_fromSeed(seed: Uint8Array): {
  publicKey: Uint8Array;
  secretKey: Uint8Array;
} {
  checkArrayTypes(seed);
  if (seed.length !== crypto_sign_SEEDBYTES)
    throw new Error(`bad seed size: ${seed.length}`);
  const pk = new Uint8Array(crypto_sign_PUBLICKEYBYTES);
  const sk = new Uint8Array(crypto_sign_SECRETKEYBYTES);
  for (let i = 0; i < 32; i++) sk[i] = seed[i];
  crypto_sign_keypair(pk, sk, true);
  return { publicKey: pk, secretKey: sk };
}

export const sign_publicKeyLength = crypto_sign_PUBLICKEYBYTES;
export const sign_secretKeyLength = crypto_sign_SECRETKEYBYTES;
export const sign_seedLength = crypto_sign_SEEDBYTES;
export const sign_signatureLength = crypto_sign_BYTES;

export function hash(msg: Uint8Array): Uint8Array {
  checkArrayTypes(msg);
  const h = new Uint8Array(crypto_hash_BYTES);
  crypto_hash(h, msg, msg.length);
  return h;
}

export const hash_hashLength = crypto_hash_BYTES;

export function verify(x: Uint8Array, y: Uint8Array): boolean {
  checkArrayTypes(x, y);
  // Zero length arguments are considered not equal.
  if (x.length === 0 || y.length === 0) return false;
  if (x.length !== y.length) return false;
  return vn(x, 0, y, 0, x.length) === 0 ? true : false;
}

export function setPRNG(fn: (x: Uint8Array, n: number) => void): void {
  randombytes = fn;
}

export function sign_ed25519_pk_to_curve25519(
  ed25519_pk: Uint8Array,
): Uint8Array {
  const ge_a = [gf(), gf(), gf(), gf()];
  const x = gf();
  const one_minus_y = gf();
  const x25519_pk = new Uint8Array(32);

  if (unpackneg(ge_a, ed25519_pk)) {
    throw Error("invalid public key");
  }

  set25519(one_minus_y, gf1);
  Z(one_minus_y, one_minus_y, ge_a[1]);

  set25519(x, gf1);
  A(x, x, ge_a[1]);

  inv25519(one_minus_y, one_minus_y);
  M(x, x, one_minus_y);
  pack25519(x25519_pk, x);

  return x25519_pk;
}

export function secretbox(
  msg: Uint8Array,
  nonce: Uint8Array,
  key: Uint8Array,
): Uint8Array {
  checkArrayTypes(msg, nonce, key);
  checkLengths(key, nonce);
  var m = new Uint8Array(crypto_secretbox_ZEROBYTES + msg.length);
  var c = new Uint8Array(m.length);
  for (var i = 0; i < msg.length; i++)
    m[i + crypto_secretbox_ZEROBYTES] = msg[i];
  crypto_secretbox(c, m, m.length, nonce, key);
  return c.subarray(crypto_secretbox_BOXZEROBYTES);
}

export function secretbox_open(
  box: Uint8Array,
  nonce: Uint8Array,
  key: Uint8Array,
): Uint8Array | undefined {
  checkArrayTypes(box, nonce, key);
  checkLengths(key, nonce);
  var c = new Uint8Array(crypto_secretbox_BOXZEROBYTES + box.length);
  var m = new Uint8Array(c.length);
  for (var i = 0; i < box.length; i++)
    c[i + crypto_secretbox_BOXZEROBYTES] = box[i];
  if (c.length < 32) return undefined;
  if (crypto_secretbox_open(m, c, c.length, nonce, key) !== 0) return undefined;
  return m.subarray(crypto_secretbox_ZEROBYTES);
}

export function crypto_core_ed25519_scalar_add(
  x: Uint8Array,
  y: Uint8Array,
): Uint8Array {
  const z = new Float64Array(64);
  for (let i = 0; i < 32; i++) {
    z[i] = x[i] + y[i];
  }
  const o = new Uint8Array(32);
  modL(o, z);
  return o;
}

/**
 * Reduce a scalar "s" to "s mod L".  The input can be up to 64 bytes long.
 */
export function crypto_core_ed25519_scalar_reduce(x: Uint8Array): Uint8Array {
  const len = x.length;
  const z = new Float64Array(64);
  for (let i = 0; i < len; i++) z[i] = x[i];
  const o = new Uint8Array(32);
  modL(o, z);
  return o;
}

export function crypto_core_ed25519_scalar_sub(
  x: Uint8Array,
  y: Uint8Array,
): Uint8Array {
  const z = new Float64Array(64);
  for (let i = 0; i < 32; i++) {
    z[i] = x[i] - y[i];
  }
  const o = new Uint8Array(32);
  modL(o, z);
  return o;
}

export function crypto_edx25519_private_key_create(): Uint8Array {
  const seed = new Uint8Array(32);
  randombytes(seed, 32);
  return crypto_edx25519_private_key_create_from_seed(seed);
}

export function crypto_edx25519_private_key_create_from_seed(
  seed: Uint8Array,
): Uint8Array {
  const pk = hash(seed);
  pk[0] &= 248;
  pk[31] &= 127;
  pk[31] |= 64;
  return pk;
}

export function crypto_edx25519_get_public(priv: Uint8Array): Uint8Array {
  return crypto_scalarmult_ed25519_base_noclamp(priv.subarray(0, 32));
}

export function crypto_edx25519_sign_detached(
  m: Uint8Array,
  skx: Uint8Array,
  pkx: Uint8Array,
): Uint8Array {
  const n: number = m.length;
  const h = new Uint8Array(64);
  const r = new Uint8Array(64);
  let i, j;
  const x = new Float64Array(64);
  const p = [gf(), gf(), gf(), gf()];

  const sm = new Uint8Array(n + 64);

  for (i = 0; i < n; i++) sm[64 + i] = m[i];
  for (i = 0; i < 32; i++) sm[32 + i] = skx[32 + i];

  crypto_hash(r, sm.subarray(32), n + 32);
  reduce(r);
  scalarbase(p, r);
  pack(sm, p);

  for (i = 32; i < 64; i++) sm[i] = pkx[i - 32];
  crypto_hash(h, sm, n + 64);
  reduce(h);

  for (i = 0; i < 64; i++) x[i] = 0;
  for (i = 0; i < 32; i++) x[i] = r[i];
  for (i = 0; i < 32; i++) {
    for (j = 0; j < 32; j++) {
      x[i + j] += h[i] * skx[j];
    }
  }

  modL(sm.subarray(32), x);
  return sm.subarray(0, 64);
}

export function crypto_edx25519_sign_detached_verify(
  msg: Uint8Array,
  sig: Uint8Array,
  publicKey: Uint8Array,
): boolean {
  checkArrayTypes(msg, sig, publicKey);
  if (sig.length !== crypto_sign_BYTES) throw new Error("bad signature size");
  if (publicKey.length !== crypto_sign_PUBLICKEYBYTES)
    throw new Error("bad public key size");
  const sm = new Uint8Array(crypto_sign_BYTES + msg.length);
  const m = new Uint8Array(crypto_sign_BYTES + msg.length);
  let i;
  for (i = 0; i < crypto_sign_BYTES; i++) sm[i] = sig[i];
  for (i = 0; i < msg.length; i++) sm[i + crypto_sign_BYTES] = msg[i];
  return crypto_sign_open(m, sm, sm.length, publicKey) >= 0;
}
