/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  HttpStatusCode,
  TalerError,
  TalerMerchantApi,
  assertUnreachable,
} from "@gnu-taler/taler-util";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { ErrorLoadingMerchant } from "../../../../components/ErrorLoadingMerchant.js";
import { Loading } from "../../../../components/exception/loading.js";
import { NotificationCard } from "../../../../components/menu/index.js";
import { useSessionContext } from "../../../../context/session.js";
import { useInstanceDetails } from "../../../../hooks/instance.js";
import { useInstanceProducts } from "../../../../hooks/product.js";
import { Notification } from "../../../../utils/types.js";
import { LoginPage } from "../../../login/index.js";
import { NotFoundPageOrAdminCreate } from "../../../notfound/index.js";
import { CreatePage } from "./CreatePage.js";
import { useTranslationContext } from "@gnu-taler/web-util/browser";

export type Entity = {
  request: TalerMerchantApi.PostOrderRequest;
  response: TalerMerchantApi.PostOrderResponse;
};
interface Props {
  onBack?: () => void;
  onConfirm: (id: string) => void;
}
export default function OrderCreate({ onConfirm, onBack }: Props): VNode {
  const { state, lib } = useSessionContext();
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const detailsResult = useInstanceDetails();
  // FIXME: if the product list is big the will bring a lot of info
  const inventoryResult = useInstanceProducts();
  const { i18n } = useTranslationContext();

  if (!detailsResult) return <Loading />;
  if (detailsResult instanceof TalerError) {
    return <ErrorLoadingMerchant error={detailsResult} />;
  }
  if (detailsResult.type === "fail") {
    switch (detailsResult.case) {
      case HttpStatusCode.Unauthorized: {
        return <LoginPage />;
      }
      case HttpStatusCode.NotFound: {
        return <NotFoundPageOrAdminCreate />;
      }
      default: {
        assertUnreachable(detailsResult);
      }
    }
  }
  if (!inventoryResult) return <Loading />;
  if (inventoryResult instanceof TalerError) {
    return <ErrorLoadingMerchant error={inventoryResult} />;
  }
  if (inventoryResult.type === "fail") {
    switch (inventoryResult.case) {
      case HttpStatusCode.NotFound: {
        return <NotFoundPageOrAdminCreate />;
      }
      case HttpStatusCode.Unauthorized: {
        return <LoginPage />;
      }
      default: {
        assertUnreachable(inventoryResult);
      }
    }
  }

  return (
    <Fragment>
      <NotificationCard notification={notif} />

      <CreatePage
        onBack={onBack}
        onCreate={(request: TalerMerchantApi.PostOrderRequest) => {
          lib.instance
            .createOrder(state.token, request)
            .then((resp) => {
              if (resp.type === "ok") {
                return onConfirm(resp.body.order_id);
              } else {
                switch (resp.case) {
                  case HttpStatusCode.UnavailableForLegalReasons: {
                    setNotif({
                      message: i18n.str`Could not create order`,
                      type: "ERROR",
                      description: i18n.str`No exchange would accept a payment because of KYC requirements.`,
                    });
                    return;
                  }
                  case HttpStatusCode.Unauthorized:
                  case HttpStatusCode.NotFound:
                  case HttpStatusCode.Conflict: {
                    setNotif({
                      message: i18n.str`Could not create order`,
                      type: "ERROR",
                      description: resp.detail?.hint,
                    });
                    return;
                  }
                  case HttpStatusCode.Gone: {
                    setNotif({
                      message: i18n.str`Could not create order`,
                      type: "ERROR",
                      description: i18n.str`No more stock for product with ID "${resp.body.product_id}".`,
                    });
                    return;
                  }
                  default: {
                    assertUnreachable(resp);
                  }
                }
              }
            })
            .catch((error) => {
              setNotif({
                message: i18n.str`Could not create order`,
                type: "ERROR",
                description:
                  error instanceof Error ? error.message : String(error),
              });
            });
        }}
        instanceConfig={detailsResult.body}
        instanceInventory={inventoryResult.body}
      />
    </Fragment>
  );
}
