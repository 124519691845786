/*
 This file is part of GNU Anastasis
 (C) 2021-2022 Anastasis SARL

 GNU Anastasis is free software; you can redistribute it and/or modify it under the
 terms of the GNU Affero General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Anastasis is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU Affero General Public License for more details.

 You should have received a copy of the GNU Affero General Public License along with
 GNU Anastasis; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { useEffect, useState } from "preact/hooks";
import { ObservableMap, memoryMap } from "../utils/observable.js";
import { StorageKey, StorageState } from "./useLocalStorage.js";

const storage: ObservableMap<string, any> = memoryMap<any>();

//with initial value
export function useMemoryStorage<Type = string>(
  key: string,
  defaultValue: Type,
): Required<StorageState<Type>>;
//with initial value
export function useMemoryStorage<Type = string>(
  key: string,
): StorageState<Type>;
// impl
export function useMemoryStorage<Type = string>(
  key: string,
  defaultValue?: Type,
): StorageState<Type> {
  const [storedValue, setStoredValue] = useState<Type | undefined>(
    (): Type | undefined => {
      const prev = storage.get(key);
      return prev === undefined ? defaultValue : prev;
    },
  );

  useEffect(() => {
    return storage.onUpdate(key, () => {
      const newValue = storage.get(key);
      setStoredValue(newValue === undefined ? defaultValue : newValue);
    });
  }, [key]);

  const setValue = (value?: Type): void => {
    if (value === undefined) {
      storage.delete(key);
    } else {
      storage.set(key, value);
    }
  };

  return {
    value: storedValue,
    update: setValue,
    reset: () => {
      setValue(defaultValue);
    },
  };
}
