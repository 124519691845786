import { ComponentChildren, VNode, h } from "preact";

export function Dialog({ children, onClose }: { onClose?: () => void; children: ComponentChildren }): VNode {
  return <div class="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true" onClick={onClose}>
    <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

    <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
      <div class="flex min-h-full items-center justify-center p-4 text-center ">
        <div class="relative transform overflow-hidden rounded-lg bg-white p-1 text-left shadow-xl transition-all" onClick={(e) => e.stopPropagation()}>
          {children}
        </div>
      </div>
    </div>
  </div>
}
