/*
 This file is part of GNU Taler
 (C) 2021-2023 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/*eslint quote-props: ["error", "consistent"]*/
export const strings: {[s: string]: any} = {};

strings['uk'] = {
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=3; plural=n%10==1 && n%100!=11 ? 0 : n%10>=2 && n%10<=4 && (n%100<10 || n%100>=20) ? 1 : 2;",
        "lang": "uk"
      },
      "The request reached a timeout, check your connection.": [
        ""
      ],
      "The request was cancelled.": [
        ""
      ],
      "A lot of request were made to the same server and this action was throttled.": [
        ""
      ],
      "The response of the request is malformed.": [
        ""
      ],
      "Could not complete the request due to a network problem.": [
        ""
      ],
      "Unexpected request error.": [
        "Несподівана помилка"
      ],
      "Unexpected error.": [
        "Несподівана помилка"
      ],
      "Cancel": [
        "Скасувати"
      ],
      "%1$s": [
        "%1$s"
      ],
      "Close": [
        "Закрити"
      ],
      "Continue": [
        "Продовжити"
      ],
      "Clear": [
        "Очистити"
      ],
      "Confirm": [
        "Підтвердити"
      ],
      "Required": [
        "обовʼязково"
      ],
      "Letter must be a JSON string": [
        ""
      ],
      "JSON string is invalid": [
        ""
      ],
      "Import": [
        ""
      ],
      "Importing an account from the bank": [
        ""
      ],
      "You can export your account settings from the Libeufin Bank's account profile. Paste the content in the next field.": [
        ""
      ],
      "Account information": [
        "Додаткова інформація"
      ],
      "Correct form": [
        ""
      ],
      "Comparing account details": [
        ""
      ],
      "Testing against the account info URL succeeded but the account information reported is different with the account details form.": [
        ""
      ],
      "Field": [
        ""
      ],
      "In the form": [
        ""
      ],
      "Reported": [
        ""
      ],
      "Type": [
        ""
      ],
      "IBAN": [
        ""
      ],
      "Address": [
        "Адреса"
      ],
      "Host": [
        "Хост"
      ],
      "Account id": [
        "Рахунок"
      ],
      "Owner's name": [
        "Назва бізнесу"
      ],
      "Account": [
        "Рахунок"
      ],
      "Bank host": [
        "Хост банку"
      ],
      "Bank account": [
        "Банківський рахунок"
      ],
      "BIC": [
        ""
      ],
      "Ok": [
        ""
      ],
      "Validate bank account: %1$s": [
        "Перевірка дійсності банківського рахунку: %1$s"
      ],
      "You need to make a bank transfer with the specified subject to validate that you are the owner of the account.": [
        ""
      ],
      "Step 1:": [
        ""
      ],
      "Copy this string and paste it into the field 'Subject' (resp. 'Purpose') in your banking app or bank website": [
        ""
      ],
      "Subject": [
        "Призначення"
      ],
      "Step 2:": [
        ""
      ],
      "Copy and paste this IBAN and the name into the receiver fields in your banking app or website": [
        ""
      ],
      "Receiver name": [
        "Дата доставки"
      ],
      "Step 3:": [
        ""
      ],
      "Select the smallest possible amount for the wire transfer in your banking app or on your online banking website.": [
        ""
      ],
      "Make sure ALL data is correct, including the subject and you are using your selected bank account. You can use the copy buttons (%1$s) to prevent typing errors or the \"payto://\" URI below to copy just one value.": [
        ""
      ],
      "Alternatively, if your bank already supports the standard 'PayTo URI', you can use this %1$s link instead": [
        ""
      ],
      "If you delete the instance named %1$s (ID: %2$s), the merchant will no longer be able to process orders or refunds": [
        ""
      ],
      "This action deletes the instance private key, but preserves all transaction data. You can still access that data after deleting the instance.": [
        ""
      ],
      "Deleting an instance %1$s .": [
        ""
      ],
      "If you purge the instance named %1$s (ID: %2$s), you will also delete all it&apos;s transaction data.": [
        ""
      ],
      "The instance will disappear from your list, and you will no longer be able to access it&apos;s data.": [
        ""
      ],
      "Purging an instance %1$s .": [
        ""
      ],
      "Is not the same as the current access token": [
        "не співпадає з поточним токеном доступу"
      ],
      "Can't be the same as the old token": [
        "не може бути таким самим, як старий токен"
      ],
      "Is not the same": [
        "не співпадає"
      ],
      "You are updating the access token for the instance with ID %1$s": [
        "Ви оновлюєте токен доступу з інстанції з ідентифікатором %1$s"
      ],
      "Old access token": [
        "Старий токен доступу"
      ],
      "Access token currently in use": [
        "токен доступу, який зараз використовується"
      ],
      "New access token": [
        "Новий токен доступу"
      ],
      "Next access token to be used": [
        "наступний токен доступу, який буде використано"
      ],
      "Repeat access token": [
        "Повторіть токен доступу"
      ],
      "Confirm the same access token": [
        "підтвердити той самий токен доступу"
      ],
      "Clearing the access token will mean public access to the instance": [
        "Видалення токена доступу означатиме публічний доступ до системи"
      ],
      "Can't be the same as the old access token": [
        "не може бути таким самим, як старий токен доступу"
      ],
      "You are setting the access token for the new instance": [
        "Ви встановлюєте токен доступу для нової інстанції"
      ],
      "With external authorization method no check will be done by the merchant backend": [
        "З зовнішнім методом авторизації перевірка не буде виконуватися бекендом продавця"
      ],
      "Set external authorization": [
        "Встановити зовнішню авторизацію"
      ],
      "Set access token": [
        "Встановити токен доступу"
      ],
      "Operation in progress...": [
        "Операція виконується..."
      ],
      "The operation will be automatically canceled after %1$s seconds": [
        "Операція буде автоматично скасована через %1$s секунд"
      ],
      "Your password is incorrect": [
        ""
      ],
      "Your instance cannot be found": [
        "замовлення не знайдено"
      ],
      "Login required": [
        "Потрібен вхід"
      ],
      "Please enter your access token for %1$s.": [
        "Будь ласка, введіть ваш токен доступу для %1$s."
      ],
      "Access Token": [
        "Токен доступу"
      ],
      "Instances": [
        "Інстанції"
      ],
      "Delete": [
        "Видалити"
      ],
      "Add new instance": [
        "додати нову інстанцію"
      ],
      "ID": [
        "Ідентифікатор"
      ],
      "Name": [
        "Назва"
      ],
      "Edit": [
        "Редагувати"
      ],
      "Purge": [
        "Очистити"
      ],
      "There are no instances yet, add more pressing the + sign": [
        "Ще немає інстанцій, додайте більше, натиснувши знак +"
      ],
      "Only show active instances": [
        "Показувати тільки активні інстанції"
      ],
      "Active": [
        "Активні"
      ],
      "Only show deleted instances": [
        "Показувати тільки видалені інстанції"
      ],
      "Deleted": [
        "Видалено"
      ],
      "Show all instances": [
        "Показати всі інстанції"
      ],
      "All": [
        "Всі"
      ],
      "Instance \"%1$s\" (ID: %2$s) has been deleted": [
        "Інстанція \"%1$s\" (ID: %2$s) була видалена"
      ],
      "Failed to delete instance": [
        "Не вдалося видалити інстанцію"
      ],
      "Instance '%1$s' (ID: %2$s) has been purged": [
        "Інстанція '%1$s' (ID: %2$s) була деактивована"
      ],
      "Failed to purge instance": [
        "Не вдалося очистити інстанцію"
      ],
      "Loading...": [
        "Завантаження..."
      ],
      "This is not a valid bitcoin address.": [
        "Це недійсна адреса біткойн."
      ],
      "This is not a valid Ethereum address.": [
        "Це недійсна адреса Ethereum."
      ],
      "This is not a valid host.": [
        "Це недійсна адреса біткойн."
      ],
      "IBAN numbers usually have more that 4 digits": [
        "Номера IBAN зазвичай мають більше 4-ьох цифр"
      ],
      "IBAN numbers usually have less that 34 digits": [
        "Номера IBAN зазвичай мають менше 34-ьох цифр"
      ],
      "IBAN country code not found": [
        "Код країни IBAN не знайдено"
      ],
      "IBAN number is invalid, checksum is wrong": [
        "Номер IBAN не коректний, контрольна сума не сходиться"
      ],
      "Choose one...": [
        "Виберіть одну..."
      ],
      "Method to use for wire transfer": [
        "Метод для використання при банківському переказі"
      ],
      "Routing": [
        "Маршрутизація"
      ],
      "Routing number.": [
        "Номер маршрутизації."
      ],
      "Account number.": [
        "Номер рахунку."
      ],
      "Code": [
        "Код"
      ],
      "Business Identifier Code.": [
        "Код ідентифікації бізнесу."
      ],
      "International Bank Account Number.": [
        "Номер банківського рахунку."
      ],
      "Unified Payment Interface.": [
        "Уніфікований інтерфейс платежів."
      ],
      "Bitcoin protocol.": [
        "Протокол біткойн."
      ],
      "Ethereum protocol.": [
        "Протокол Ethereum."
      ],
      "Interledger protocol.": [
        "Протокол Interledger."
      ],
      "Bank host.": [
        "Хост банку."
      ],
      "Without scheme and may include subpath:": [
        ""
      ],
      "Bank account.": [
        "Банківський рахунок."
      ],
      "Legal name of the person holding the account.": [
        "Офіційне ім'я власника рахунку."
      ],
      "It should match the bank account name.": [
        ""
      ],
      "Invalid url": [
        "недійсний формат"
      ],
      "URL must end with a '/'": [
        ""
      ],
      "URL must not contain params": [
        ""
      ],
      "URL must not hash param": [
        ""
      ],
      "The request to check the revenue API failed.": [
        ""
      ],
      "Server replied with \"bad request\".": [
        "Відповідь сервера з кодом помилки."
      ],
      "Unauthorized, check credentials.": [
        ""
      ],
      "The endpoint does not seem to be a Taler Revenue API.": [
        ""
      ],
      "The request was made correctly, but the bank's server did not respond with the appropriate value for 'credit_account', so we cannot confirm that it is the same bank account.": [
        ""
      ],
      "Account:": [
        "Рахунок:"
      ],
      "If the bank supports Taler Revenue API then you can add the endpoint URL below to keep the revenue information in sync.": [
        ""
      ],
      "Endpoint URL": [
        ""
      ],
      "From where the merchant can download information about incoming wire transfers to this account": [
        ""
      ],
      "Auth type": [
        "Тип події"
      ],
      "Choose the authentication type for the account info URL": [
        ""
      ],
      "Without authentication": [
        ""
      ],
      "With password": [
        ""
      ],
      "With token": [
        "Створити токен"
      ],
      "Do not change": [
        "URL обмінника"
      ],
      "Username": [
        ""
      ],
      "Username to access the account information.": [
        ""
      ],
      "Password": [
        ""
      ],
      "Password to access the account information.": [
        ""
      ],
      "Token": [
        ""
      ],
      "Access token to access the account information.": [
        ""
      ],
      "Match": [
        ""
      ],
      "Check where the information match against the server info.": [
        ""
      ],
      "Not verified": [
        "Неперевірений"
      ],
      "Last test was ok": [
        ""
      ],
      "Last test failed": [
        ""
      ],
      "Compare info from server with account form": [
        ""
      ],
      "Test": [
        ""
      ],
      "Need to complete marked fields": [
        "Необхідно заповнити позначені поля"
      ],
      "Confirm operation": [
        "Підтверджено"
      ],
      "Account details": [
        "Адреса рахунку"
      ],
      "Import from bank": [
        ""
      ],
      "Could not create account": [
        "не вдалося створити продукт"
      ],
      "No 'default' instance configured yet.": [
        "Інстанція 'default' ще не налаштована."
      ],
      "Create a 'default' instance to begin using the merchant backoffice.": [
        "Створіть інстанцію 'default', щоб почати використовувати бекофіс продавця."
      ],
      "Bank accounts": [
        "Банківський рахунок"
      ],
      "Add new account": [
        "Зарахований банківський рахунок"
      ],
      "Wire method: Bitcoin": [
        "Метод переказу"
      ],
      "SegWit 1": [
        ""
      ],
      "SegWit 2": [
        ""
      ],
      "Delete selected accounts from the database": [
        "видалити вибраний переказ з бази даних"
      ],
      "Wire method: x-taler-bank": [
        "Метод переказу"
      ],
      "Account name": [
        "Номер рахунку."
      ],
      "Wire method: IBAN": [
        "Метод переказу"
      ],
      "Other accounts": [
        "Цільовий рахунок"
      ],
      "Path": [
        ""
      ],
      "There are no accounts yet, add more pressing the + sign": [
        "Продуктів ще немає, додайте більше, натиснувши знак +"
      ],
      "You need to associate a bank account to receive revenue.": [
        "URI, що вказує на банківський рахунок для зарахування доходу."
      ],
      "Without this the you won't be able to create new orders.": [
        "використовувати шаблон для створення нового замовлення."
      ],
      "The bank account has been successfully deleted.": [
        "Банківський рахунок успішно видалено."
      ],
      "Could not delete the bank account": [
        "Не вдалося видалити банківський рахунок"
      ],
      "Could not update account": [
        "Не вдалося оновити обліковий запис"
      ],
      "Could not delete account": [
        "Не вдалося видалити обліковий запис"
      ],
      "Pending KYC verification": [
        "Очікування перевірки KYC"
      ],
      "Exchange": [
        "Exchange"
      ],
      "Reason": [
        "Причина"
      ],
      "Pending KYC process, click here to complete": [
        ""
      ],
      "The Payment Service Provider requires an account verification.": [
        ""
      ],
      "Http Status": [
        "HTTP статус"
      ],
      "No pending kyc verification!": [
        "Немає очікуваних перевірок KYC!"
      ],
      "Change value to unknown date": [
        "змінити значення на невідому дату"
      ],
      "Change value to empty": [
        "змінити значення на порожнє"
      ],
      "Change value to never": [
        "змінити значення на ніколи"
      ],
      "Never": [
        "ніколи"
      ],
      "days": [
        "дні"
      ],
      "hours": [
        "години"
      ],
      "minutes": [
        "хвилини"
      ],
      "seconds": [
        "секунди"
      ],
      "Forever": [
        "назавжди"
      ],
      "%1$sM": [
        "%1$sМ"
      ],
      "%1$sY": [
        "%1$sР"
      ],
      "%1$sd": [
        "%1$sдн."
      ],
      "%1$sh": [
        "%1$sг"
      ],
      "%1$smin": [
        "%1$sхв"
      ],
      "%1$ssec": [
        "%1$sсек"
      ],
      "Country": [
        "Країна"
      ],
      "Building number": [
        "Номер будинку"
      ],
      "Building name": [
        "Назва будинку"
      ],
      "Street": [
        "Вулиця"
      ],
      "Post code": [
        "Поштовий індекс"
      ],
      "Town location": [
        "Область міста"
      ],
      "Town": [
        "Місто"
      ],
      "District": [
        "Район"
      ],
      "Country subdivision": [
        "Регіон країни"
      ],
      "Description": [
        "Опис"
      ],
      "Enter description or id": [
        "Введіть ідентифікатор замовлення"
      ],
      "no match found with that description or id": [
        "продукти з таким описом не знайдено"
      ],
      "You must enter a valid product identifier.": [
        "Ви повинні ввести дійсний ідентифікатор продукту."
      ],
      "Quantity must be greater than 0!": [
        "Кількість має бути більше 0!"
      ],
      "This quantity exceeds remaining stock. Currently, only %1$s units remain unreserved in stock.": [
        "Ця кількість перевищує залишок на складі. Наразі на складі залишилося лише %1$s одиниць, які не зарезервовані."
      ],
      "Search product": [
        "нова ціна для продукту"
      ],
      "Quantity": [
        "Кількість"
      ],
      "How many products will be added": [
        "скільки продуктів буде додано"
      ],
      "Add from inventory": [
        "Додати зі складу"
      ],
      "Image must be smaller than 1 MB": [
        "Зображення повинно бути меншим за 1 МБ"
      ],
      "Add": [
        "Додати"
      ],
      "Remove": [
        "Видалити"
      ],
      "Invalid": [
        "недійсний"
      ],
      "This product has %1$s applicable taxes configured.": [
        ""
      ],
      "No taxes configured for this product.": [
        "Податки для цього продукту не налаштовані."
      ],
      "Amount": [
        "Сума"
      ],
      "Taxes can be in currencies that differ from the main currency used by the merchant.": [
        "Податки можуть бути в валютах, що відрізняються від основної валюти, яку використовує продавець."
      ],
      "Enter currency and value separated with a colon, e.g. &quot;USD:2.3&quot;.": [
        "Введіть валюту та значення, розділені двокрапкою, наприклад, &quot;USD:2.3&quot;."
      ],
      "Legal name of the tax, e.g. VAT or import duties.": [
        "Офіційна назва податку, наприклад, ПДВ або імпортні мита."
      ],
      "Add tax to the tax list": [
        "додати податок до списку податків"
      ],
      "Describe and add a product that is not in the inventory list": [
        "опишіть і додайте продукт, якого немає в списку інвентарю"
      ],
      "Add custom product": [
        "Додати новий продукт"
      ],
      "Complete information of the product": [
        "Повна інформація про продукт"
      ],
      "Must be a number": [
        "не є числом"
      ],
      "Must be grater than 0": [
        "має бути більше 0"
      ],
      "Image": [
        "Зображення"
      ],
      "Photo of the product.": [
        "фото продукту."
      ],
      "Full product description.": [
        "повний опис продукту."
      ],
      "Unit": [
        "Одиниця"
      ],
      "Name of the product unit.": [
        "назва одиниці продукту."
      ],
      "Price": [
        "Ціна"
      ],
      "Amount in the current currency.": [
        "сума в поточній валюті."
      ],
      "How many products will be added.": [
        "скільки продуктів буде додано."
      ],
      "Taxes": [
        "Податки"
      ],
      "Unit price": [
        "ціна за одиницю"
      ],
      "Total price": [
        "Загальна ціна"
      ],
      "Must be greater than 0": [
        "має бути більше 0"
      ],
      "Refund deadline can't be before pay deadline": [
        "термін повернення не може бути раніше терміну оплати"
      ],
      "Wire transfer deadline can't be before refund deadline": [
        "термін банківського переказу не може бути раніше терміну повернення"
      ],
      "Wire transfer deadline can't be before pay deadline": [
        "термін банківського переказу не може бути раніше терміну оплати"
      ],
      "Must have a refund deadline": [
        "повинен бути встановлений термін повернення"
      ],
      "Auto refund can't be after refund deadline": [
        "автоматичне повернення не може бути після терміну повернення"
      ],
      "Must be in the future": [
        "повинно бути в майбутньому"
      ],
      "Simple": [
        ""
      ],
      "Advanced": [
        ""
      ],
      "Manage products in order": [
        "Керування продуктами в замовленні"
      ],
      "%1$s products with a total price of %2$s.": [
        "%1$s товарів із загальною ціною %2$s."
      ],
      "Manage list of products in the order.": [
        "Керування списком продуктів у замовленні."
      ],
      "Remove this product from the order.": [
        "Видалити цей продукт із замовлення."
      ],
      "Total product price added up": [
        "загальна сума продукту"
      ],
      "Amount to be paid by the customer": [
        "Сума, яку має сплатити клієнт"
      ],
      "Order price": [
        "Ціна замовлення"
      ],
      "Final order price": [
        "кінцева ціна замовлення"
      ],
      "Summary": [
        "Підсумок"
      ],
      "Title of the order to be shown to the customer": [
        "Назва замовлення, яку буде показано клієнту"
      ],
      "Shipping and fulfillment": [
        "Доставка та виконання"
      ],
      "Delivery date": [
        "Дата доставки"
      ],
      "Deadline for physical delivery assured by the merchant.": [
        "Термін фізичної доставки, гарантований продавцем."
      ],
      "Location": [
        "Місцезнаходження"
      ],
      "Address where the products will be delivered": [
        "адреса, за якою будуть доставлені продукти"
      ],
      "Fulfillment URL": [
        "URL виконання"
      ],
      "URL to which the user will be redirected after successful payment.": [
        "URL, на який користувача буде перенаправлено після успішної оплати."
      ],
      "Taler payment options": [
        "Опції оплати Taler"
      ],
      "Override default Taler payment settings for this order": [
        "Перевизначити стандартні налаштування оплати Taler для цього замовлення"
      ],
      "Payment time": [
        "Тайм-аут оплати"
      ],
      "Time for the customer to pay for the offer before it expires. Inventory products will be reserved until this deadline. Time start to run after the order is created.": [
        "Термін, до якого клієнт повинен оплатити пропозицію, перш ніж вона закінчиться. Продукти з інвентарю будуть зарезервовані до цього терміну."
      ],
      "Default": [
        "Сума за замовчуванням"
      ],
      "Refund time": [
        "Повернення здійснено"
      ],
      "Time while the order can be refunded by the merchant. Time starts after the order is created.": [
        "Час, до якого замовлення може бути повернене продавцем."
      ],
      "Wire transfer time": [
        "Ідентифікатор банківського переказу"
      ],
      "Time for the exchange to make the wire transfer. Time starts after the order is created.": [
        "Термін, до якого обмінник повинен здійснити банківський переказ."
      ],
      "Auto-refund time": [
        "Затримка автоматичного повернення"
      ],
      "Time until which the wallet will automatically check for refunds without user interaction.": [
        "Час, до якого гаманець автоматично перевірятиме повернення коштів без взаємодії з користувачем."
      ],
      "Maximum fee": [
        "Максимальна комісія за переказ"
      ],
      "Maximum fees the merchant is willing to cover for this order. Higher deposit fees must be covered in full by the consumer.": [
        "Максимальна комісія за депозит, яку продавець готовий покрити для цього замовлення. Вищі комісії за депозит повинні бути повністю покриті споживачем."
      ],
      "Create token": [
        "Створити токен"
      ],
      "If the order ID is easy to guess the token will prevent users to steal orders from others.": [
        ""
      ],
      "Minimum age required": [
        "Мінімальний вік"
      ],
      "Any value greater than 0 will limit the coins able be used to pay this contract. If empty the age restriction will be defined by the products": [
        "Будь-яке значення більше 0 обмежуватиме монети, які можуть бути використані для оплати цього контракту. Якщо порожнє, вікове обмеження визначатиметься продуктами"
      ],
      "Min age defined by the producs is %1$s": [
        "Мінімальний вік, визначений продуктами, становить %1$s"
      ],
      "No product with age restriction in this order": [
        ""
      ],
      "Additional information": [
        "Додаткова інформація"
      ],
      "Custom information to be included in the contract for this order.": [
        "Спеціальна інформація, яка буде включена в контракт для цього замовлення."
      ],
      "You must enter a value in JavaScript Object Notation (JSON).": [
        "Ви повинні ввести значення у форматі JavaScript Object Notation (JSON)."
      ],
      "Custom field name": [
        "Назва будинку"
      ],
      "Disabled": [
        ""
      ],
      "No deadline": [
        "Термін повернення"
      ],
      "Deadline at %1$s": [
        ""
      ],
      "Could not create order": [
        "не вдалося створити резерв"
      ],
      "No exchange would accept a payment because of KYC requirements.": [
        ""
      ],
      "No more stock for product with ID \"%1$s\".": [
        ""
      ],
      "Orders": [
        "Замовлення"
      ],
      "Create order": [
        "створити замовлення"
      ],
      "Load first page": [
        ""
      ],
      "Date": [
        "Дата"
      ],
      "Refund": [
        "Повернення"
      ],
      "copy url": [
        "скопіювати url"
      ],
      "Load more orders after the last one": [
        "завантажити більше переказів після останнього"
      ],
      "Load next page": [
        ""
      ],
      "No orders have been found matching your query!": [
        "Замовлення, що відповідають вашому запиту, не знайдено!"
      ],
      "Duplicated": [
        "дубльовано"
      ],
      "This value exceed the refundable amount": [
        "ця сума перевищує суму, що підлягає поверненню"
      ],
      "Amount to be refunded": [
        "сума до повернення"
      ],
      "Max refundable:": [
        "Макс. сума для повернення:"
      ],
      "Requested by the customer": [
        "запитано клієнтом"
      ],
      "Other": [
        "інше"
      ],
      "Why this order is being refunded": [
        "чому це замовлення повертається"
      ],
      "More information to give context": [
        "додаткова інформація для надання контексту"
      ],
      "Contract terms": [
        "Умови контракту"
      ],
      "Human-readable description of the whole purchase": [
        "читабельний опис всієї покупки"
      ],
      "Total price for the transaction": [
        "загальна ціна за транзакцію"
      ],
      "URL for this purchase": [
        "URL для цієї покупки"
      ],
      "Max fee": [
        "Максимальна комісія"
      ],
      "Maximum total deposit fee accepted by the merchant for this contract": [
        "максимальна загальна комісія за депозит, прийнята продавцем для цього контракту"
      ],
      "Created at": [
        "Створено о"
      ],
      "Time when this contract was generated": [
        "час, коли цей контракт було згенеровано"
      ],
      "Refund deadline": [
        "Термін повернення"
      ],
      "After this deadline has passed no refunds will be accepted": [
        "після цього терміну повернення не приймаються"
      ],
      "Payment deadline": [
        "Термін оплати"
      ],
      "After this deadline, the merchant won't accept payments for the contract": [
        "після цього терміну продавець не прийматиме платежі за контрактом"
      ],
      "Wire transfer deadline": [
        "Термін банківського переказу"
      ],
      "Transfer deadline for the exchange": [
        "термін переказу для обмінника"
      ],
      "Time indicating when the order should be delivered": [
        "час, що вказує, коли замовлення має бути доставлене"
      ],
      "Where the order will be delivered": [
        "куди буде доставлене замовлення"
      ],
      "Auto-refund delay": [
        "Затримка автоматичного повернення"
      ],
      "How long the wallet should try to get an automatic refund for the purchase": [
        "скільки часу гаманець повинен намагатися отримати автоматичне повернення за покупку"
      ],
      "Extra info": [
        "Додаткова інформація"
      ],
      "Extra data that is only interpreted by the merchant frontend": [
        "додаткові дані, які інтерпретуються лише фронтендом продавця"
      ],
      "Order": [
        "Замовлення"
      ],
      "Claimed": [
        "отримано"
      ],
      "Claimed at": [
        "отримано о"
      ],
      "Timeline": [
        "Хронологія"
      ],
      "Payment details": [
        "Деталі оплати"
      ],
      "Order status": [
        "Статус замовлення"
      ],
      "Product list": [
        "Список продуктів"
      ],
      "Paid": [
        "Оплачено"
      ],
      "Wired": [
        "перераховано"
      ],
      "Refunded": [
        "Повернено"
      ],
      "Refund order": [
        "замовлення на повернення"
      ],
      "Not refundable": [
        "не підлягає поверненню"
      ],
      "Next event in": [
        ""
      ],
      "Refunded amount": [
        "Повернена сума"
      ],
      "Refund taken": [
        "Повернення здійснено"
      ],
      "Status URL": [
        "URL статусу"
      ],
      "Refund URI": [
        "URI повернення"
      ],
      "Unpaid": [
        "неоплачено"
      ],
      "Pay at": [
        "оплачено о"
      ],
      "Order status URL": [
        "URL статусу замовлення"
      ],
      "Payment URI": [
        "URI оплати"
      ],
      "Unknown order status. This is an error, please contact the administrator.": [
        "Невідомий статус замовлення. Це помилка, будь ласка, зв'яжіться з адміністратором."
      ],
      "Back": [
        "Назад"
      ],
      "Refund created successfully": [
        "повернення успішно створено"
      ],
      "Could not create the refund": [
        "не вдалося створити повернення"
      ],
      "There are pending KYC requirements.": [
        ""
      ],
      "Missing ID": [
        ""
      ],
      "Not found": [
        "замовлення не знайдено"
      ],
      "Select date to show nearby orders": [
        "виберіть дату, щоб показати найближчі замовлення"
      ],
      "Only show paid orders": [
        "показувати лише оплачені замовлення"
      ],
      "New": [
        "Новий"
      ],
      "Only show orders with refunds": [
        "показувати лише замовлення з поверненнями"
      ],
      "Only display orders that have already been paid for by buyers and for which the wire transfer by the payment service provider is still pending": [
        "показувати лише замовлення, де клієнти заплатили, але банківські перекази від постачальника платежів ще не виконані"
      ],
      "Not wired": [
        "Не перераховано"
      ],
      "Only display orders that have already been transferred by the payment service provider": [
        "показувати лише замовлення, де клієнти заплатили, але банківські перекази від постачальника платежів ще не виконані"
      ],
      "Completed": [
        "Видалено"
      ],
      "Remove all filters": [
        "видалити всі фільтри"
      ],
      "Clear date filter": [
        "очистити фільтр дати"
      ],
      "Jump to date (%1$s)": [
        ""
      ],
      "Jump to order with the given product ID": [
        "перейти до замовлення з зазначеним ідентифікатором"
      ],
      "Order id": [
        "ідентифікатор замовлення"
      ],
      "Invalid. Please enter characters and numbers only": [
        ""
      ],
      "Just letters and numbers from 2 to 7": [
        "лише літери та цифри від 2 до 7"
      ],
      "The size of the key must be 32 characters": [
        "розмір ключа повинен бути 32"
      ],
      "Internal ID on the system": [
        ""
      ],
      "Useful to identify the device physically": [
        ""
      ],
      "Verification algorithm": [
        "Алгоритм перевірки"
      ],
      "Algorithm used to verify transactions in offline mode": [
        "Алгоритм для використання для перевірки транзакції в офлайн-режимі"
      ],
      "Device key": [
        ""
      ],
      "Be sure to choose a password hard to guess, alternatively use the random generator": [
        ""
      ],
      "Your device needs to match exactly the same value": [
        ""
      ],
      "Generate random secret key": [
        "згенерувати випадковий секретний ключ"
      ],
      "Random": [
        "випадковий"
      ],
      "You can scan the next QR code with your device or save the key before continuing.": [
        ""
      ],
      "Device added successfully": [
        "повернення успішно створено"
      ],
      "Could not add device": [
        "не вдалося створити резерв"
      ],
      "OTP devices": [
        "додати новий резерв"
      ],
      "Add new devices": [
        "додати новий резерв"
      ],
      "Load more devices before the first one": [
        "завантажити більше шаблонів до першого"
      ],
      "Delete selected devices from the database": [
        "видалити вибраний резерв з бази даних"
      ],
      "Load more devices after the last one": [
        "завантажити більше шаблонів після останнього"
      ],
      "There are no devices to list yet, add more by pressing the + sign": [
        "Ще немає інстанцій, додайте більше, натиснувши знак +"
      ],
      "The device was successfully deleted": [
        "Банківський рахунок успішно видалено."
      ],
      "Could not delete the device": [
        "не вдалося видалити продукт"
      ],
      "Device:": [
        ""
      ],
      "Algorithm to use to verify transactions in offline mode": [
        "Алгоритм для використання для перевірки транзакції в офлайн-режимі"
      ],
      "Not modified": [
        "Не перераховано"
      ],
      "Be sure to be very hard to guess or use the random generator": [
        ""
      ],
      "Your device need to have exactly the same value": [
        ""
      ],
      "Change key": [
        ""
      ],
      "Could not update template": [
        "не вдалося оновити шаблон"
      ],
      "Template id is unknown": [
        ""
      ],
      "The provided information is inconsistent with the current state of the template": [
        ""
      ],
      "Click here to configure the stock of the product, leave it as is and the backend will not control stock.": [
        "натисніть тут, щоб налаштувати запас продукту, залиште як є, і бекенд не буде контролювати запас."
      ],
      "Manage stock": [
        "Керування запасами"
      ],
      "This product has been configured without stock control": [
        "цей продукт налаштований без контролю запасів"
      ],
      "Infinite": [
        "Нескінченний"
      ],
      "Shrinkage cannot exceed the current stock and incoming supplies (maximum %1$s)": [
        "втрати не можуть бути більшими за поточні та прибуваючі (макс %1$s)"
      ],
      "Incoming": [
        "Прибуття"
      ],
      "Lost": [
        "Втрачено"
      ],
      "Current": [
        "Поточний"
      ],
      "Remove stock control for this product": [
        "видалити контроль запасів для цього продукту"
      ],
      "without stock": [
        "без запасу"
      ],
      "Next restock": [
        "Наступне поповнення"
      ],
      "Warehouse address": [
        "Адреса рахунку"
      ],
      "Add element to the list": [
        "додати елемент до списку"
      ],
      "Invalid amount": [
        "Фіксована сума"
      ],
      "Product identification to use in URLs (for internal use only).": [
        "ідентифікація продукту для використання в URL (тільки для внутрішнього використання)."
      ],
      "Illustration of the product for customers.": [
        "ілюстрація продукту для клієнтів."
      ],
      "Product description for customers.": [
        "опис продукту для клієнтів."
      ],
      "Age restriction": [
        "Обмежений за віком"
      ],
      "Is this product restricted for customer below certain age?": [
        "цей продукт обмежений для клієнтів молодше певного віку?"
      ],
      "Minimum age of the customer": [
        "Мінімальний вік"
      ],
      "Unit name": [
        "Одиниця"
      ],
      "Unit describing quantity of product sold (e.g. 2 kilograms, 5 liters, 3 items, 5 meters) for customers.": [
        "одиниця, що описує кількість проданого продукту (наприклад, 2 кілограми, 5 літрів, 3 предмети, 5 метрів) для клієнтів."
      ],
      "Example: kg, items or liters": [
        ""
      ],
      "Price per unit": [
        ""
      ],
      "Sale price for customers, including taxes, for above units of the product.": [
        "ціна продажу для клієнтів, включаючи податки, за вищезазначені одиниці продукту."
      ],
      "Stock": [
        "Запас"
      ],
      "Inventory for products with finite supply (for internal use only).": [
        "інвентаризація продукту для продуктів з обмеженим запасом (тільки для внутрішнього використання)"
      ],
      "Taxes included in the product price, exposed to customers.": [
        "податки, включені в ціну продукту, показані клієнтам"
      ],
      "Categories": [
        ""
      ],
      "Search by category description or id": [
        "шукати продукти за їхнім описом або ідентифікатором"
      ],
      "Categories where this product will be listed on.": [
        "адреса, за якою будуть доставлені продукти."
      ],
      "Product created successfully": [
        "Продукт успішно створено"
      ],
      "Could not create product": [
        "Не вдалося створити продукт"
      ],
      "Inventory": [
        ""
      ],
      "Add product to inventory": [
        "додати продукт до інвентарю"
      ],
      "Sales": [
        ""
      ],
      "Sold": [
        "Продано"
      ],
      "Free": [
        ""
      ],
      "Go to product update page": [
        "перейти на сторінку оновлення продукту"
      ],
      "Update": [
        "Оновити"
      ],
      "Remove this product from the database": [
        "видалити цей продукт з бази даних"
      ],
      "Load more products after the last one": [
        "завантажити більше шаблонів після останнього"
      ],
      "Update the product with new price": [
        "оновити продукт з новою ціною"
      ],
      "Update product with new price": [
        "оновити продукт з новою ціною"
      ],
      "Confirm update": [
        "Підтверджено"
      ],
      "lost can't be greater that current + incoming (max %1$s)": [
        "втрати не можуть бути більшими за поточні та прибуваючі (макс %1$s)"
      ],
      "Add more elements to the inventory": [
        "додати більше елементів до інвентарю"
      ],
      "Report elements lost in the inventory": [
        "повідомити про втрату елементів в інвентарі"
      ],
      "New price for the product": [
        "нова ціна для продукту"
      ],
      "The are value with errors": [
        "є значення з помилками"
      ],
      "Update product with new stock and price": [
        "оновити продукт з новим запасом і ціною"
      ],
      "There are no products to list yet, add more by pressing the + sign": [
        "Продуктів ще немає, додайте більше, натиснувши знак +"
      ],
      "Jump to product with the given product ID": [
        "перейти до замовлення з зазначеним ідентифікатором"
      ],
      "Product id": [
        "Ідентифікатор продукту"
      ],
      "Product updated successfully": [
        "продукт успішно оновлено"
      ],
      "Could not update the product": [
        "не вдалося оновити продукт"
      ],
      "Product \"%1$s\" (ID: %2$s) has been deleted": [
        "Інстанція \"%1$s\" (ID: %2$s) була видалена"
      ],
      "Could not delete the product": [
        "не вдалося видалити продукт"
      ],
      "If you delete the product named %1$s (ID: %2$s ), the stock and related information will be lost": [
        ""
      ],
      "Deleting an product can't be undone.": [
        ""
      ],
      "Product id:": [
        "Ідентифікатор продукту:"
      ],
      "Product (ID: %1$s) has been updated": [
        "Продукт (ID: %1$s) оновлено"
      ],
      "Could not update product": [
        "не вдалося оновити продукт"
      ],
      "Must be greater that 0": [
        "має бути більше 0"
      ],
      "Too short": [
        "занадто короткий"
      ],
      "Identifier": [
        "Ідентифікатор"
      ],
      "Name of the template in URLs.": [
        "Назва шаблону в URL."
      ],
      "Describe what this template stands for": [
        "Опишіть, що представляє цей шаблон"
      ],
      "If specified here, this template will create orders with the same summary": [
        "Якщо вказано, цей шаблон створить замовлення з однаковим підсумком"
      ],
      "Summary is editable": [
        ""
      ],
      "Allow the user to change the summary.": [
        ""
      ],
      "If specified here, this template will create orders with the same price": [
        "Якщо вказано, цей шаблон створить замовлення з однаковою ціною"
      ],
      "Amount is editable": [
        "Зарахована сума"
      ],
      "Allow the user to select the amount to pay.": [
        ""
      ],
      "Currency is editable": [
        ""
      ],
      "Allow the user to change currency.": [
        ""
      ],
      "Supported currencies": [
        ""
      ],
      "Supported currencies: %1$s": [
        ""
      ],
      "Minimum age": [
        "Мінімальний вік"
      ],
      "Is this contract restricted to some age?": [
        "Чи обмежений цей контракт за віком?"
      ],
      "Payment timeout": [
        "Тайм-аут оплати"
      ],
      "How much time the customer has to complete the payment once the order was created.": [
        "Скільки часу у клієнта для завершення оплати після створення замовлення."
      ],
      "OTP device": [
        ""
      ],
      "Use to verify transactions in offline mode.": [
        "Алгоритм для використання для перевірки транзакції в офлайн-режимі."
      ],
      "No OTP device.": [
        ""
      ],
      "Add one first": [
        ""
      ],
      "No device": [
        ""
      ],
      "Template has been created": [
        ""
      ],
      "Could not create template": [
        "не вдалося оновити шаблон"
      ],
      "Templates": [
        "Шаблони"
      ],
      "Add new templates": [
        "додати нові шаблони"
      ],
      "Load more templates before the first one": [
        "завантажити більше шаблонів до першого"
      ],
      "Delete selected templates from the database": [
        "видалити вибрані шаблони з бази даних"
      ],
      "Use template to create new order": [
        "використовувати шаблон для створення нового замовлення"
      ],
      "Use template": [
        "додати нові шаблони"
      ],
      "Generate a QR code for the template.": [
        "створити QR-код для шаблону"
      ],
      "Load more templates after the last one": [
        "завантажити більше шаблонів після останнього"
      ],
      "There are no templates to list yet, add more by pressing the + sign": [
        "Шаблонів ще немає, додайте більше, натиснувши знак +"
      ],
      "Jump to template with the given template ID": [
        "перейти до замовлення з зазначеним ідентифікатором"
      ],
      "Template identification": [
        ""
      ],
      "Template \"%1$s\" (ID: %2$s) has been deleted": [
        "Інстанція \"%1$s\" (ID: %2$s) була видалена"
      ],
      "Failed to delete template": [
        "Не вдалося видалити інстанцію"
      ],
      "If you delete the template %1$s (ID: %2$s) you may loose information": [
        ""
      ],
      "Deleting an template": [
        "завантажити новіші шаблони"
      ],
      "can't be undone": [
        "не може бути порожнім"
      ],
      "Print": [
        "Друк"
      ],
      "If specified, this template will create order with the same summary": [
        "Якщо вказано, цей шаблон створить замовлення з однаковим підсумком"
      ],
      "If specified, this template will create orders with the same price": [
        "Якщо вказано, цей шаблон створить замовлення з однаковою ціною"
      ],
      "How much time has the customer to complete the payment once the order was created.": [
        "Скільки часу у клієнта для завершення оплати після створення замовлення."
      ],
      "Template (ID: %1$s) has been updated": [
        "Шаблон (ID: %1$s) оновлено"
      ],
      "An amount is required": [
        "Сума обов'язкова"
      ],
      "An order summary is required": [
        "Підсумок замовлення обов'язковий"
      ],
      "New order from template": [
        "Нове замовлення для шаблону"
      ],
      "Amount of the order": [
        "Сума замовлення"
      ],
      "Order summary": [
        "Підсумок замовлення"
      ],
      "Could not create order from template": [
        "не вдалося створити замовлення з шаблону"
      ],
      "You need your access token to perform the operation": [
        "Ви встановлюєте токен доступу для нової інстанції"
      ],
      "You are updating the access token from instance with id \"%1$s\"": [
        "Ви оновлюєте токен доступу з інстанції з ідентифікатором %1$s"
      ],
      "This instance does not have authentication token.": [
        ""
      ],
      "You can leave it empty if there is another layer of security.": [
        ""
      ],
      "Current access token": [
        "Встановити токен доступу"
      ],
      "Clearing the access token will mean public access to the instance.": [
        "Видалення токена доступу означатиме публічний доступ до системи."
      ],
      "Clear token": [
        "Чіткий токен"
      ],
      "Confirm change": [
        "Підтвердити зміну"
      ],
      "Failed to clear token": [
        "Не вдалося очистити токен"
      ],
      "Failed to set new token": [
        "Не вдалося встановити новий токен"
      ],
      "Slug": [
        ""
      ],
      "Token family slug to use in URLs (for internal use only)": [
        "ідентифікація продукту для використання в URL (тільки для внутрішнього використання)"
      ],
      "Kind": [
        ""
      ],
      "Token family kind": [
        ""
      ],
      "User-readable token family name": [
        ""
      ],
      "Token family description for customers": [
        "опис продукту для клієнтів"
      ],
      "Valid After": [
        "Дійсний до"
      ],
      "Token family can issue tokens after this date": [
        ""
      ],
      "Valid Before": [
        "недійсний формат"
      ],
      "Token family can issue tokens until this date": [
        ""
      ],
      "Duration": [
        "Термін дії"
      ],
      "Validity duration of a issued token": [
        ""
      ],
      "Token family created successfully.": [
        "повернення успішно створено"
      ],
      "Could not create token family.": [
        "не вдалося створити чайові"
      ],
      "Token Families": [
        ""
      ],
      "Add token family": [
        ""
      ],
      "Go to token family update page": [
        "перейти на сторінку оновлення продукту"
      ],
      "Remove this token family from the database": [
        "видалити цей продукт з бази даних"
      ],
      "There are no token families yet, add the first one by pressing the + sign.": [
        "Шаблонів ще немає, додайте більше, натиснувши знак +."
      ],
      "Token family updated successfully": [
        "Сімейство токенів успішно оновлено"
      ],
      "Could not update the token family": [
        "Не вдалося оновити сімейство токенів"
      ],
      "Token family \"%1$s\" (SLUG: %2$s) has been deleted": [
        "Сімейство токенів \"%1$s\" (SLUG: %2$s) видалено"
      ],
      "Failed to delete token family": [
        "Не вдалося видалити сімейство токенів"
      ],
      "If you delete the %1$s token family (Slug: %2$s), all issued tokens will become invalid.": [
        "Якщо ви видалите сімейство токенів %1$s (Slug: %2$s), всі випущені токени стануть недійсними."
      ],
      "Deleting a token family %1$s .": [
        "Видалення сімейства токенів %1$s ."
      ],
      "Token Family: %1$s": [
        "Сімейство токенів: %1$s"
      ],
      "Could not update token family": [
        "Не вдалося оновити сімейство токенів"
      ],
      "Please check the ID; it does not appear to be valid.": [
        "перевірте ідентифікатор, він виглядає недійсним"
      ],
      "Must have 52 characters, current %1$s": [
        "повинно бути 52 символи, поточний %1$s"
      ],
      "URL doesn't have the right format": [
        "URL має неправильний формат"
      ],
      "Credited bank account": [
        "Зарахований банківський рахунок"
      ],
      "Select an account": [
        "Виберіть один рахунок"
      ],
      "Bank account of the merchant where the payment was received": [
        "Банківський рахунок продавця, на який було отримано платіж"
      ],
      "Wire transfer ID": [
        "Ідентифікатор банківського переказу"
      ],
      "Unique identifier of the wire transfer used by the exchange, must be 52 characters long": [
        "унікальний ідентифікатор банківського переказу, що використовується обмінником, має бути довжиною 52 символи"
      ],
      "Exchange URL": [
        "URL обмінника"
      ],
      "Base URL of the exchange that made the transfer, should have been in the wire transfer subject": [
        "Основний URL обмінника, який здійснив переказ, має бути в призначенні банківського переказу"
      ],
      "Amount credited": [
        "Зарахована сума"
      ],
      "Actual amount that was wired to the merchant's bank account": [
        "Фактична сума, що була переказана на банківський рахунок продавця"
      ],
      "Wire transfer informed successfully": [
        "повернення успішно створено"
      ],
      "Could not inform transfer": [
        "не вдалося повідомити про переказ"
      ],
      "Wire transfers": [
        "Ідентифікатор банківського переказу"
      ],
      "Add new transfer": [
        "додати новий переказ"
      ],
      "Load more wire transfers preceding the first one": [
        "завантажити більше переказів до першого"
      ],
      "Credit": [
        "Кредит"
      ],
      "Confirmed": [
        "Підтверджено"
      ],
      "Verified": [
        "Перевірено"
      ],
      "Executed on": [
        "Виконано о"
      ],
      "yes": [
        "так"
      ],
      "no": [
        "ні"
      ],
      "never": [
        "ніколи"
      ],
      "unknown": [
        "невідомо"
      ],
      "Delete selected transfer from the database": [
        "видалити вибраний переказ з бази даних"
      ],
      "Load more transfers after the last one": [
        "завантажити більше переказів після останнього"
      ],
      "There are no transfers to list yet, add more by pressing the + sign": [
        "Переказів ще немає, додайте більше, натиснувши знак +"
      ],
      "All accounts": [
        "Рахунок"
      ],
      "Filter by account address": [
        "фільтрувати за адресою рахунку"
      ],
      "Only show wire transfers confirmed by the merchant": [
        "показувати лише перекази, підтверджені продавцем"
      ],
      "Only show wire transfers claimed by the exchange": [
        "показувати лише перекази, заявлені обмінником"
      ],
      "Unverified": [
        "Неперевірений"
      ],
      "Wire transfer \"%1$s...\" has been deleted": [
        "Банківський переказ \"%1$s...\" видалено"
      ],
      "Failed to delete transfer": [
        "Не вдалося видалити переказ"
      ],
      "Must be business or individual": [
        ""
      ],
      "Pay delay can't be greater than wire transfer delay": [
        ""
      ],
      "Max 7 lines": [
        "максимум 7 рядків"
      ],
      "Doesn't match": [
        ""
      ],
      "Enable access control": [
        "Управління токеном доступу"
      ],
      "Choose if the backend server should authenticate access.": [
        ""
      ],
      "Access control is not yet decided. This instance can't be created.": [
        ""
      ],
      "Authorization must be handled externally.": [
        ""
      ],
      "Authorization is handled by the backend server.": [
        ""
      ],
      "Need to complete marked fields and choose authorization method": [
        "Необхідно заповнити позначені поля та вибрати метод авторизації"
      ],
      "Name of the instance in URLs. The 'default' instance is special in that it is used to administer other instances.": [
        "Назва інстанції в URL. Інстанція 'default' є особливою, оскільки використовується для адміністрування інших інстанцій."
      ],
      "Business name": [
        "Назва бізнесу"
      ],
      "Legal name of the business represented by this instance.": [
        "Юридична назва бізнесу, який представляє ця інстанція."
      ],
      "Email": [
        "Email"
      ],
      "Contact email": [
        "Контактний email"
      ],
      "Website URL": [
        "URL вебсайту"
      ],
      "URL.": [
        "URL."
      ],
      "Logo": [
        "Логотип"
      ],
      "Logo image.": [
        "Зображення логотипу."
      ],
      "Physical location of the merchant.": [
        "Фізичне розташування продавця."
      ],
      "Jurisdiction": [
        "Юрисдикція"
      ],
      "Jurisdiction for legal disputes with the merchant.": [
        "Юрисдикція для правових спорів з продавцем."
      ],
      "Pay transaction fee": [
        ""
      ],
      "Cover the transaction cost or pass it on to the user.": [
        ""
      ],
      "Default payment delay": [
        "Затримка оплати за замовчуванням"
      ],
      "Time customers have to pay an order before the offer expires by default.": [
        "Час, який мають клієнти для оплати замовлення до закінчення терміну дії пропозиції за замовчуванням."
      ],
      "Default wire transfer delay": [
        "Затримка банківського переказу за замовчуванням"
      ],
      "Maximum time an exchange is allowed to delay wiring funds to the merchant, enabling it to aggregate smaller payments into larger wire transfers and reducing wire fees.": [
        "Максимальний час, на який обмінник може затримати переказ коштів продавцю, дозволяючи йому об'єднувати менші платежі у більші банківські перекази та знижуючи комісії за переказ."
      ],
      "Instance id": [
        "Ідентифікатор інстанції"
      ],
      "Failed to update instance": [
        "Не вдалося створити інстанцію"
      ],
      "Must be \"pay\" or \"refund\"": [
        ""
      ],
      "Must be one of '%1$s'": [
        "повинно бути одним із '%1$s'"
      ],
      "Webhook ID to use": [
        "ID вебхука для використання"
      ],
      "Event": [
        "Подія"
      ],
      "Pay": [
        ""
      ],
      "The event of the webhook: why the webhook is used": [
        "Подія вебхука: чому використовується вебхук"
      ],
      "Method": [
        "Метод"
      ],
      "GET": [
        ""
      ],
      "POST": [
        ""
      ],
      "PUT": [
        ""
      ],
      "PATCH": [
        ""
      ],
      "HEAD": [
        ""
      ],
      "Method used by the webhook": [
        "Метод, що використовується вебхуком"
      ],
      "URL": [
        "URL"
      ],
      "URL of the webhook where the customer will be redirected": [
        "URL вебхука, куди буде перенаправлений клієнт"
      ],
      "The text below supports the %1$s template engine. Any string between %2$s and %3$s will be replaced with the value of the corresponding variable.": [
        ""
      ],
      "For example %1$s will be replaced with the the order's price": [
        ""
      ],
      "The short list of variables are:": [
        ""
      ],
      "order's description": [
        "опис"
      ],
      "order's price": [
        "Ціна замовлення"
      ],
      "order's unique identification": [
        ""
      ],
      "the amount that was being refunded": [
        "сума до повернення"
      ],
      "the reason entered by the merchant staff for granting the refund": [
        ""
      ],
      "time of the refund in nanoseconds since 1970": [
        ""
      ],
      "Http body": [
        ""
      ],
      "Body template by the webhook": [
        "Шаблон тіла вебхука"
      ],
      "Webhook create successfully": [
        "вебхук успішно видалено"
      ],
      "Could not create the webhook": [
        "не вдалося видалити вебхук"
      ],
      "Could not create webhook": [
        "не вдалося видалити вебхук"
      ],
      "Webhooks": [
        "Вебхуки"
      ],
      "Add new webhooks": [
        "додати нові вебхуки"
      ],
      "Load more webhooks before the first one": [
        "завантажити більше вебхуків до першого"
      ],
      "Event type": [
        "Тип події"
      ],
      "Delete selected webhook from the database": [
        "видалити вибраний вебхук з бази даних"
      ],
      "Load more webhooks after the last one": [
        "завантажити більше вебхуків після останнього"
      ],
      "There are no webhooks to list yet, add more by pressing the + sign": [
        "Вебхуків ще немає, додайте більше, натиснувши знак +"
      ],
      "Webhook deleted successfully": [
        "вебхук успішно видалено"
      ],
      "Could not delete the webhook": [
        "не вдалося видалити вебхук"
      ],
      "Header": [
        "Заголовок"
      ],
      "Header template of the webhook": [
        "Шаблон заголовка вебхука"
      ],
      "Body": [
        "Тіло"
      ],
      "Webhook updated": [
        "ID вебхука для використання"
      ],
      "Could not update webhook": [
        "не вдалося видалити вебхук"
      ],
      "Language": [
        ""
      ],
      "Advance order creation": [
        ""
      ],
      "Shows more options in the order creation form": [
        ""
      ],
      "Advance instance settings": [
        ""
      ],
      "Shows more options in the instance settings form": [
        ""
      ],
      "Date format": [
        "недійсний формат"
      ],
      "How the date is going to be displayed": [
        ""
      ],
      "Developer mode": [
        ""
      ],
      "Shows more options and tools which are not intended for general audience.": [
        ""
      ],
      "Total products": [
        "Загальна ціна"
      ],
      "Delete selected category from the database": [
        "видалити вибраний переказ з бази даних"
      ],
      "There are no categories yet, add more pressing the + sign": [
        "Шаблонів ще немає, додайте більше, натиснувши знак +"
      ],
      "Category delete successfully": [
        "шаблон успішно видалено"
      ],
      "Could not delete the category": [
        "не вдалося видалити шаблон"
      ],
      "Invalid. Please insert only characters and numbers": [
        ""
      ],
      "Category name": [
        ""
      ],
      "Category added successfully": [
        "шаблон успішно видалено"
      ],
      "Could not add category": [
        "не вдалося створити продукт"
      ],
      "Id:": [
        ""
      ],
      "Name of the category": [
        "Назва категорії"
      ],
      "Products": [
        "Товари"
      ],
      "Search by product description or id": [
        "шукати продукти за їхнім описом або ідентифікатором"
      ],
      "Products that this category will list.": [
        ""
      ],
      "Could not update category": [
        "не вдалося оновити шаблон"
      ],
      "Category ID is unknown": [
        ""
      ],
      "Without this the merchant backend will refuse to create new orders.": [
        ""
      ],
      "Hide for today": [
        "Сховати на сьогодні"
      ],
      "KYC verification needed": [
        "Очікування перевірки KYC"
      ],
      "Some transfers are on hold until the KYC process is completed. Visit the KYC section in the left panel for more information": [
        ""
      ],
      "Configuration": [
        "Термін дії"
      ],
      "OTP Devices": [
        ""
      ],
      "Settings": [
        "Налаштування"
      ],
      "Access token": [
        "Токен доступу"
      ],
      "Connection": [
        "З'єднання"
      ],
      "Interface": [
        ""
      ],
      "List": [
        "Список"
      ],
      "Log out": [
        "Вийти"
      ],
      "Failed to create instance": [
        "Не вдалося створити інстанцію"
      ],
      "checking compatibility with server...": [
        ""
      ],
      "Contacting the server failed": [
        "Не вдалося підключитися до сервера."
      ],
      "The server version is not supported": [
        ""
      ],
      "Supported version \"%1$s\", server version \"%2$s\".": [
        ""
      ],
      "Deleting": [
        "Видалення"
      ],
      "Changing": [
        "Зміна"
      ],
      "Manage access token": [
        "Управління токеном доступу"
      ],
      "Business Name": [
        "Назва бізнесу"
      ],
      "Order ID": [
        "Ідентифікатор замовлення"
      ],
      "Payment URL": [
        "URL оплати"
      ]
    }
  },
  "domain": "messages",
  "plural_forms": "nplurals=3; plural=n%10==1 && n%100!=11 ? 0 : n%10>=2 && n%10<=4 && (n%100<10 || n%100>=20) ? 1 : 2;",
  "lang": "uk",
  "completeness": 75
};

strings['tr'] = {
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=2; plural=n != 1;",
        "lang": "tr"
      },
      "The request reached a timeout, check your connection.": [
        ""
      ],
      "The request was cancelled.": [
        ""
      ],
      "A lot of request were made to the same server and this action was throttled.": [
        ""
      ],
      "The response of the request is malformed.": [
        ""
      ],
      "Could not complete the request due to a network problem.": [
        ""
      ],
      "Unexpected request error.": [
        ""
      ],
      "Unexpected error.": [
        ""
      ],
      "Cancel": [
        "İptal"
      ],
      "%1$s": [
        "%1$s"
      ],
      "Close": [
        ""
      ],
      "Continue": [
        ""
      ],
      "Clear": [
        ""
      ],
      "Confirm": [
        ""
      ],
      "Required": [
        ""
      ],
      "Letter must be a JSON string": [
        ""
      ],
      "JSON string is invalid": [
        ""
      ],
      "Import": [
        ""
      ],
      "Importing an account from the bank": [
        ""
      ],
      "You can export your account settings from the Libeufin Bank's account profile. Paste the content in the next field.": [
        ""
      ],
      "Account information": [
        ""
      ],
      "Correct form": [
        ""
      ],
      "Comparing account details": [
        ""
      ],
      "Testing against the account info URL succeeded but the account information reported is different with the account details form.": [
        ""
      ],
      "Field": [
        ""
      ],
      "In the form": [
        ""
      ],
      "Reported": [
        ""
      ],
      "Type": [
        ""
      ],
      "IBAN": [
        ""
      ],
      "Address": [
        ""
      ],
      "Host": [
        ""
      ],
      "Account id": [
        "Hesap"
      ],
      "Owner's name": [
        ""
      ],
      "Account": [
        "Hesap"
      ],
      "Bank host": [
        "Banka hesabı"
      ],
      "Bank account": [
        "Banka hesabı"
      ],
      "BIC": [
        ""
      ],
      "Ok": [
        ""
      ],
      "Validate bank account: %1$s": [
        ""
      ],
      "You need to make a bank transfer with the specified subject to validate that you are the owner of the account.": [
        ""
      ],
      "Step 1:": [
        ""
      ],
      "Copy this string and paste it into the field 'Subject' (resp. 'Purpose') in your banking app or bank website": [
        ""
      ],
      "Subject": [
        "Konu"
      ],
      "Step 2:": [
        ""
      ],
      "Copy and paste this IBAN and the name into the receiver fields in your banking app or website": [
        ""
      ],
      "Receiver name": [
        "Teslim tarihi"
      ],
      "Step 3:": [
        ""
      ],
      "Select the smallest possible amount for the wire transfer in your banking app or on your online banking website.": [
        ""
      ],
      "Make sure ALL data is correct, including the subject and you are using your selected bank account. You can use the copy buttons (%1$s) to prevent typing errors or the \"payto://\" URI below to copy just one value.": [
        ""
      ],
      "Alternatively, if your bank already supports the standard 'PayTo URI', you can use this %1$s link instead": [
        ""
      ],
      "If you delete the instance named %1$s (ID: %2$s), the merchant will no longer be able to process orders or refunds": [
        ""
      ],
      "This action deletes the instance private key, but preserves all transaction data. You can still access that data after deleting the instance.": [
        ""
      ],
      "Deleting an instance %1$s .": [
        ""
      ],
      "If you purge the instance named %1$s (ID: %2$s), you will also delete all it&apos;s transaction data.": [
        ""
      ],
      "The instance will disappear from your list, and you will no longer be able to access it&apos;s data.": [
        ""
      ],
      "Purging an instance %1$s .": [
        ""
      ],
      "Is not the same as the current access token": [
        ""
      ],
      "Can't be the same as the old token": [
        ""
      ],
      "Is not the same": [
        ""
      ],
      "You are updating the access token for the instance with ID %1$s": [
        ""
      ],
      "Old access token": [
        ""
      ],
      "Access token currently in use": [
        ""
      ],
      "New access token": [
        ""
      ],
      "Next access token to be used": [
        ""
      ],
      "Repeat access token": [
        ""
      ],
      "Confirm the same access token": [
        ""
      ],
      "Clearing the access token will mean public access to the instance": [
        ""
      ],
      "Can't be the same as the old access token": [
        ""
      ],
      "You are setting the access token for the new instance": [
        ""
      ],
      "With external authorization method no check will be done by the merchant backend": [
        ""
      ],
      "Set external authorization": [
        ""
      ],
      "Set access token": [
        ""
      ],
      "Operation in progress...": [
        ""
      ],
      "The operation will be automatically canceled after %1$s seconds": [
        ""
      ],
      "Your password is incorrect": [
        ""
      ],
      "Your instance cannot be found": [
        ""
      ],
      "Login required": [
        ""
      ],
      "Please enter your access token for %1$s.": [
        ""
      ],
      "Access Token": [
        ""
      ],
      "Instances": [
        ""
      ],
      "Delete": [
        ""
      ],
      "Add new instance": [
        ""
      ],
      "ID": [
        ""
      ],
      "Name": [
        ""
      ],
      "Edit": [
        ""
      ],
      "Purge": [
        ""
      ],
      "There are no instances yet, add more pressing the + sign": [
        ""
      ],
      "Only show active instances": [
        ""
      ],
      "Active": [
        ""
      ],
      "Only show deleted instances": [
        ""
      ],
      "Deleted": [
        ""
      ],
      "Show all instances": [
        ""
      ],
      "All": [
        ""
      ],
      "Instance \"%1$s\" (ID: %2$s) has been deleted": [
        ""
      ],
      "Failed to delete instance": [
        ""
      ],
      "Instance '%1$s' (ID: %2$s) has been purged": [
        ""
      ],
      "Failed to purge instance": [
        ""
      ],
      "Loading...": [
        ""
      ],
      "This is not a valid bitcoin address.": [
        ""
      ],
      "This is not a valid Ethereum address.": [
        ""
      ],
      "This is not a valid host.": [
        ""
      ],
      "IBAN numbers usually have more that 4 digits": [
        ""
      ],
      "IBAN numbers usually have less that 34 digits": [
        ""
      ],
      "IBAN country code not found": [
        ""
      ],
      "IBAN number is invalid, checksum is wrong": [
        ""
      ],
      "Choose one...": [
        ""
      ],
      "Method to use for wire transfer": [
        ""
      ],
      "Routing": [
        ""
      ],
      "Routing number.": [
        ""
      ],
      "Account number.": [
        ""
      ],
      "Code": [
        ""
      ],
      "Business Identifier Code.": [
        ""
      ],
      "International Bank Account Number.": [
        ""
      ],
      "Unified Payment Interface.": [
        ""
      ],
      "Bitcoin protocol.": [
        ""
      ],
      "Ethereum protocol.": [
        ""
      ],
      "Interledger protocol.": [
        ""
      ],
      "Bank host.": [
        ""
      ],
      "Without scheme and may include subpath:": [
        ""
      ],
      "Bank account.": [
        ""
      ],
      "Legal name of the person holding the account.": [
        ""
      ],
      "It should match the bank account name.": [
        ""
      ],
      "Invalid url": [
        "Geçerlilik"
      ],
      "URL must end with a '/'": [
        ""
      ],
      "URL must not contain params": [
        ""
      ],
      "URL must not hash param": [
        ""
      ],
      "The request to check the revenue API failed.": [
        ""
      ],
      "Server replied with \"bad request\".": [
        ""
      ],
      "Unauthorized, check credentials.": [
        ""
      ],
      "The endpoint does not seem to be a Taler Revenue API.": [
        ""
      ],
      "The request was made correctly, but the bank's server did not respond with the appropriate value for 'credit_account', so we cannot confirm that it is the same bank account.": [
        ""
      ],
      "Account:": [
        "Hesap:"
      ],
      "If the bank supports Taler Revenue API then you can add the endpoint URL below to keep the revenue information in sync.": [
        ""
      ],
      "Endpoint URL": [
        ""
      ],
      "From where the merchant can download information about incoming wire transfers to this account": [
        ""
      ],
      "Auth type": [
        ""
      ],
      "Choose the authentication type for the account info URL": [
        ""
      ],
      "Without authentication": [
        ""
      ],
      "With password": [
        ""
      ],
      "With token": [
        ""
      ],
      "Do not change": [
        ""
      ],
      "Username": [
        ""
      ],
      "Username to access the account information.": [
        ""
      ],
      "Password": [
        ""
      ],
      "Password to access the account information.": [
        ""
      ],
      "Token": [
        ""
      ],
      "Access token to access the account information.": [
        ""
      ],
      "Match": [
        ""
      ],
      "Check where the information match against the server info.": [
        ""
      ],
      "Not verified": [
        ""
      ],
      "Last test was ok": [
        ""
      ],
      "Last test failed": [
        ""
      ],
      "Compare info from server with account form": [
        ""
      ],
      "Test": [
        ""
      ],
      "Need to complete marked fields": [
        ""
      ],
      "Confirm operation": [
        "Kasaba konumu"
      ],
      "Account details": [
        "Hesap"
      ],
      "Import from bank": [
        ""
      ],
      "Could not create account": [
        ""
      ],
      "No 'default' instance configured yet.": [
        ""
      ],
      "Create a 'default' instance to begin using the merchant backoffice.": [
        ""
      ],
      "Bank accounts": [
        "Banka hesabı"
      ],
      "Add new account": [
        "Banka hesabı"
      ],
      "Wire method: Bitcoin": [
        ""
      ],
      "SegWit 1": [
        ""
      ],
      "SegWit 2": [
        ""
      ],
      "Delete selected accounts from the database": [
        ""
      ],
      "Wire method: x-taler-bank": [
        ""
      ],
      "Account name": [
        "Hesap"
      ],
      "Wire method: IBAN": [
        ""
      ],
      "Other accounts": [
        "Banka hesabı"
      ],
      "Path": [
        ""
      ],
      "There are no accounts yet, add more pressing the + sign": [
        ""
      ],
      "You need to associate a bank account to receive revenue.": [
        ""
      ],
      "Without this the you won't be able to create new orders.": [
        ""
      ],
      "The bank account has been successfully deleted.": [
        ""
      ],
      "Could not delete the bank account": [
        ""
      ],
      "Could not update account": [
        ""
      ],
      "Could not delete account": [
        ""
      ],
      "Pending KYC verification": [
        ""
      ],
      "Exchange": [
        ""
      ],
      "Reason": [
        ""
      ],
      "Pending KYC process, click here to complete": [
        ""
      ],
      "The Payment Service Provider requires an account verification.": [
        ""
      ],
      "Http Status": [
        ""
      ],
      "No pending kyc verification!": [
        ""
      ],
      "Change value to unknown date": [
        ""
      ],
      "Change value to empty": [
        ""
      ],
      "Change value to never": [
        ""
      ],
      "Never": [
        ""
      ],
      "days": [
        ""
      ],
      "hours": [
        ""
      ],
      "minutes": [
        ""
      ],
      "seconds": [
        ""
      ],
      "Forever": [
        ""
      ],
      "%1$sM": [
        ""
      ],
      "%1$sY": [
        ""
      ],
      "%1$sd": [
        ""
      ],
      "%1$sh": [
        ""
      ],
      "%1$smin": [
        ""
      ],
      "%1$ssec": [
        ""
      ],
      "Country": [
        "Ülke"
      ],
      "Building number": [
        "Bina numarası"
      ],
      "Building name": [
        "Bina adı"
      ],
      "Street": [
        "Sokak"
      ],
      "Post code": [
        "Posta kodu"
      ],
      "Town location": [
        "Kasaba konumu"
      ],
      "Town": [
        "Kasaba"
      ],
      "District": [
        "Semt"
      ],
      "Country subdivision": [
        ""
      ],
      "Description": [
        ""
      ],
      "Enter description or id": [
        ""
      ],
      "no match found with that description or id": [
        ""
      ],
      "You must enter a valid product identifier.": [
        ""
      ],
      "Quantity must be greater than 0!": [
        ""
      ],
      "This quantity exceeds remaining stock. Currently, only %1$s units remain unreserved in stock.": [
        ""
      ],
      "Search product": [
        ""
      ],
      "Quantity": [
        ""
      ],
      "How many products will be added": [
        ""
      ],
      "Add from inventory": [
        ""
      ],
      "Image must be smaller than 1 MB": [
        ""
      ],
      "Add": [
        ""
      ],
      "Remove": [
        ""
      ],
      "Invalid": [
        ""
      ],
      "This product has %1$s applicable taxes configured.": [
        ""
      ],
      "No taxes configured for this product.": [
        ""
      ],
      "Amount": [
        "Miktar"
      ],
      "Taxes can be in currencies that differ from the main currency used by the merchant.": [
        ""
      ],
      "Enter currency and value separated with a colon, e.g. &quot;USD:2.3&quot;.": [
        ""
      ],
      "Legal name of the tax, e.g. VAT or import duties.": [
        ""
      ],
      "Add tax to the tax list": [
        ""
      ],
      "Describe and add a product that is not in the inventory list": [
        ""
      ],
      "Add custom product": [
        ""
      ],
      "Complete information of the product": [
        ""
      ],
      "Must be a number": [
        ""
      ],
      "Must be grater than 0": [
        ""
      ],
      "Image": [
        ""
      ],
      "Photo of the product.": [
        ""
      ],
      "Full product description.": [
        ""
      ],
      "Unit": [
        ""
      ],
      "Name of the product unit.": [
        ""
      ],
      "Price": [
        "Fiyat"
      ],
      "Amount in the current currency.": [
        ""
      ],
      "How many products will be added.": [
        ""
      ],
      "Taxes": [
        ""
      ],
      "Unit price": [
        ""
      ],
      "Total price": [
        ""
      ],
      "Must be greater than 0": [
        ""
      ],
      "Refund deadline can't be before pay deadline": [
        ""
      ],
      "Wire transfer deadline can't be before refund deadline": [
        ""
      ],
      "Wire transfer deadline can't be before pay deadline": [
        ""
      ],
      "Must have a refund deadline": [
        "Geri ödeme son tarihi"
      ],
      "Auto refund can't be after refund deadline": [
        ""
      ],
      "Must be in the future": [
        ""
      ],
      "Simple": [
        ""
      ],
      "Advanced": [
        ""
      ],
      "Manage products in order": [
        ""
      ],
      "%1$s products with a total price of %2$s.": [
        ""
      ],
      "Manage list of products in the order.": [
        ""
      ],
      "Remove this product from the order.": [
        ""
      ],
      "Total product price added up": [
        ""
      ],
      "Amount to be paid by the customer": [
        ""
      ],
      "Order price": [
        ""
      ],
      "Final order price": [
        ""
      ],
      "Summary": [
        "Özet"
      ],
      "Title of the order to be shown to the customer": [
        ""
      ],
      "Shipping and fulfillment": [
        ""
      ],
      "Delivery date": [
        "Teslim tarihi"
      ],
      "Deadline for physical delivery assured by the merchant.": [
        ""
      ],
      "Location": [
        ""
      ],
      "Address where the products will be delivered": [
        ""
      ],
      "Fulfillment URL": [
        "Gönderim URL'si"
      ],
      "URL to which the user will be redirected after successful payment.": [
        ""
      ],
      "Taler payment options": [
        ""
      ],
      "Override default Taler payment settings for this order": [
        ""
      ],
      "Payment time": [
        ""
      ],
      "Time for the customer to pay for the offer before it expires. Inventory products will be reserved until this deadline. Time start to run after the order is created.": [
        ""
      ],
      "Default": [
        ""
      ],
      "Refund time": [
        "İade edildi"
      ],
      "Time while the order can be refunded by the merchant. Time starts after the order is created.": [
        ""
      ],
      "Wire transfer time": [
        ""
      ],
      "Time for the exchange to make the wire transfer. Time starts after the order is created.": [
        ""
      ],
      "Auto-refund time": [
        ""
      ],
      "Time until which the wallet will automatically check for refunds without user interaction.": [
        ""
      ],
      "Maximum fee": [
        "Azami ücret"
      ],
      "Maximum fees the merchant is willing to cover for this order. Higher deposit fees must be covered in full by the consumer.": [
        ""
      ],
      "Create token": [
        ""
      ],
      "If the order ID is easy to guess the token will prevent users to steal orders from others.": [
        ""
      ],
      "Minimum age required": [
        ""
      ],
      "Any value greater than 0 will limit the coins able be used to pay this contract. If empty the age restriction will be defined by the products": [
        ""
      ],
      "Min age defined by the producs is %1$s": [
        ""
      ],
      "No product with age restriction in this order": [
        ""
      ],
      "Additional information": [
        ""
      ],
      "Custom information to be included in the contract for this order.": [
        ""
      ],
      "You must enter a value in JavaScript Object Notation (JSON).": [
        ""
      ],
      "Custom field name": [
        "Bina adı"
      ],
      "Disabled": [
        ""
      ],
      "No deadline": [
        "Geri ödeme son tarihi"
      ],
      "Deadline at %1$s": [
        ""
      ],
      "Could not create order": [
        ""
      ],
      "No exchange would accept a payment because of KYC requirements.": [
        ""
      ],
      "No more stock for product with ID \"%1$s\".": [
        ""
      ],
      "Orders": [
        ""
      ],
      "Create order": [
        "Oluşturulma"
      ],
      "Load first page": [
        ""
      ],
      "Date": [
        "Tarih"
      ],
      "Refund": [
        ""
      ],
      "copy url": [
        ""
      ],
      "Load more orders after the last one": [
        ""
      ],
      "Load next page": [
        ""
      ],
      "No orders have been found matching your query!": [
        ""
      ],
      "Duplicated": [
        ""
      ],
      "This value exceed the refundable amount": [
        ""
      ],
      "Amount to be refunded": [
        ""
      ],
      "Max refundable:": [
        ""
      ],
      "Requested by the customer": [
        ""
      ],
      "Other": [
        ""
      ],
      "Why this order is being refunded": [
        ""
      ],
      "More information to give context": [
        ""
      ],
      "Contract terms": [
        ""
      ],
      "Human-readable description of the whole purchase": [
        ""
      ],
      "Total price for the transaction": [
        ""
      ],
      "URL for this purchase": [
        ""
      ],
      "Max fee": [
        "Azami ücret"
      ],
      "Maximum total deposit fee accepted by the merchant for this contract": [
        ""
      ],
      "Created at": [
        "Oluşturulma"
      ],
      "Time when this contract was generated": [
        ""
      ],
      "Refund deadline": [
        "Geri ödeme son tarihi"
      ],
      "After this deadline has passed no refunds will be accepted": [
        ""
      ],
      "Payment deadline": [
        ""
      ],
      "After this deadline, the merchant won't accept payments for the contract": [
        ""
      ],
      "Wire transfer deadline": [
        ""
      ],
      "Transfer deadline for the exchange": [
        ""
      ],
      "Time indicating when the order should be delivered": [
        ""
      ],
      "Where the order will be delivered": [
        ""
      ],
      "Auto-refund delay": [
        ""
      ],
      "How long the wallet should try to get an automatic refund for the purchase": [
        ""
      ],
      "Extra info": [
        ""
      ],
      "Extra data that is only interpreted by the merchant frontend": [
        ""
      ],
      "Order": [
        ""
      ],
      "Claimed": [
        ""
      ],
      "Claimed at": [
        "Oluşturulma"
      ],
      "Timeline": [
        ""
      ],
      "Payment details": [
        ""
      ],
      "Order status": [
        ""
      ],
      "Product list": [
        ""
      ],
      "Paid": [
        ""
      ],
      "Wired": [
        ""
      ],
      "Refunded": [
        "İade edildi"
      ],
      "Refund order": [
        "İade edildi"
      ],
      "Not refundable": [
        ""
      ],
      "Next event in": [
        ""
      ],
      "Refunded amount": [
        ""
      ],
      "Refund taken": [
        ""
      ],
      "Status URL": [
        ""
      ],
      "Refund URI": [
        ""
      ],
      "Unpaid": [
        ""
      ],
      "Pay at": [
        ""
      ],
      "Order status URL": [
        ""
      ],
      "Payment URI": [
        ""
      ],
      "Unknown order status. This is an error, please contact the administrator.": [
        ""
      ],
      "Back": [
        ""
      ],
      "Refund created successfully": [
        ""
      ],
      "Could not create the refund": [
        ""
      ],
      "There are pending KYC requirements.": [
        ""
      ],
      "Missing ID": [
        ""
      ],
      "Not found": [
        ""
      ],
      "Select date to show nearby orders": [
        ""
      ],
      "Only show paid orders": [
        ""
      ],
      "New": [
        ""
      ],
      "Only show orders with refunds": [
        ""
      ],
      "Only display orders that have already been paid for by buyers and for which the wire transfer by the payment service provider is still pending": [
        ""
      ],
      "Not wired": [
        ""
      ],
      "Only display orders that have already been transferred by the payment service provider": [
        ""
      ],
      "Completed": [
        ""
      ],
      "Remove all filters": [
        ""
      ],
      "Clear date filter": [
        ""
      ],
      "Jump to date (%1$s)": [
        ""
      ],
      "Jump to order with the given product ID": [
        ""
      ],
      "Order id": [
        ""
      ],
      "Invalid. Please enter characters and numbers only": [
        ""
      ],
      "Just letters and numbers from 2 to 7": [
        ""
      ],
      "The size of the key must be 32 characters": [
        ""
      ],
      "Internal ID on the system": [
        ""
      ],
      "Useful to identify the device physically": [
        ""
      ],
      "Verification algorithm": [
        ""
      ],
      "Algorithm used to verify transactions in offline mode": [
        ""
      ],
      "Device key": [
        ""
      ],
      "Be sure to choose a password hard to guess, alternatively use the random generator": [
        ""
      ],
      "Your device needs to match exactly the same value": [
        ""
      ],
      "Generate random secret key": [
        ""
      ],
      "Random": [
        ""
      ],
      "You can scan the next QR code with your device or save the key before continuing.": [
        ""
      ],
      "Device added successfully": [
        ""
      ],
      "Could not add device": [
        ""
      ],
      "OTP devices": [
        ""
      ],
      "Add new devices": [
        ""
      ],
      "Load more devices before the first one": [
        ""
      ],
      "Delete selected devices from the database": [
        ""
      ],
      "Load more devices after the last one": [
        ""
      ],
      "There are no devices to list yet, add more by pressing the + sign": [
        ""
      ],
      "The device was successfully deleted": [
        ""
      ],
      "Could not delete the device": [
        ""
      ],
      "Device:": [
        ""
      ],
      "Algorithm to use to verify transactions in offline mode": [
        ""
      ],
      "Not modified": [
        ""
      ],
      "Be sure to be very hard to guess or use the random generator": [
        ""
      ],
      "Your device need to have exactly the same value": [
        ""
      ],
      "Change key": [
        ""
      ],
      "Could not update template": [
        ""
      ],
      "Template id is unknown": [
        ""
      ],
      "The provided information is inconsistent with the current state of the template": [
        ""
      ],
      "Click here to configure the stock of the product, leave it as is and the backend will not control stock.": [
        ""
      ],
      "Manage stock": [
        ""
      ],
      "This product has been configured without stock control": [
        ""
      ],
      "Infinite": [
        ""
      ],
      "Shrinkage cannot exceed the current stock and incoming supplies (maximum %1$s)": [
        ""
      ],
      "Incoming": [
        ""
      ],
      "Lost": [
        ""
      ],
      "Current": [
        ""
      ],
      "Remove stock control for this product": [
        ""
      ],
      "without stock": [
        ""
      ],
      "Next restock": [
        ""
      ],
      "Warehouse address": [
        ""
      ],
      "Add element to the list": [
        ""
      ],
      "Invalid amount": [
        "Geçerlilik"
      ],
      "Product identification to use in URLs (for internal use only).": [
        ""
      ],
      "Illustration of the product for customers.": [
        ""
      ],
      "Product description for customers.": [
        ""
      ],
      "Age restriction": [
        ""
      ],
      "Is this product restricted for customer below certain age?": [
        ""
      ],
      "Minimum age of the customer": [
        ""
      ],
      "Unit name": [
        ""
      ],
      "Unit describing quantity of product sold (e.g. 2 kilograms, 5 liters, 3 items, 5 meters) for customers.": [
        ""
      ],
      "Example: kg, items or liters": [
        ""
      ],
      "Price per unit": [
        ""
      ],
      "Sale price for customers, including taxes, for above units of the product.": [
        ""
      ],
      "Stock": [
        ""
      ],
      "Inventory for products with finite supply (for internal use only).": [
        ""
      ],
      "Taxes included in the product price, exposed to customers.": [
        ""
      ],
      "Categories": [
        ""
      ],
      "Search by category description or id": [
        ""
      ],
      "Categories where this product will be listed on.": [
        ""
      ],
      "Product created successfully": [
        ""
      ],
      "Could not create product": [
        ""
      ],
      "Inventory": [
        ""
      ],
      "Add product to inventory": [
        ""
      ],
      "Sales": [
        ""
      ],
      "Sold": [
        ""
      ],
      "Free": [
        ""
      ],
      "Go to product update page": [
        ""
      ],
      "Update": [
        ""
      ],
      "Remove this product from the database": [
        ""
      ],
      "Load more products after the last one": [
        ""
      ],
      "Update the product with new price": [
        ""
      ],
      "Update product with new price": [
        ""
      ],
      "Confirm update": [
        ""
      ],
      "lost can't be greater that current + incoming (max %1$s)": [
        ""
      ],
      "Add more elements to the inventory": [
        ""
      ],
      "Report elements lost in the inventory": [
        ""
      ],
      "New price for the product": [
        ""
      ],
      "The are value with errors": [
        ""
      ],
      "Update product with new stock and price": [
        ""
      ],
      "There are no products to list yet, add more by pressing the + sign": [
        ""
      ],
      "Jump to product with the given product ID": [
        ""
      ],
      "Product id": [
        ""
      ],
      "Product updated successfully": [
        ""
      ],
      "Could not update the product": [
        ""
      ],
      "Product \"%1$s\" (ID: %2$s) has been deleted": [
        ""
      ],
      "Could not delete the product": [
        ""
      ],
      "If you delete the product named %1$s (ID: %2$s ), the stock and related information will be lost": [
        ""
      ],
      "Deleting an product can't be undone.": [
        ""
      ],
      "Product id:": [
        ""
      ],
      "Product (ID: %1$s) has been updated": [
        ""
      ],
      "Could not update product": [
        ""
      ],
      "Must be greater that 0": [
        ""
      ],
      "Too short": [
        ""
      ],
      "Identifier": [
        ""
      ],
      "Name of the template in URLs.": [
        ""
      ],
      "Describe what this template stands for": [
        ""
      ],
      "If specified here, this template will create orders with the same summary": [
        ""
      ],
      "Summary is editable": [
        ""
      ],
      "Allow the user to change the summary.": [
        ""
      ],
      "If specified here, this template will create orders with the same price": [
        ""
      ],
      "Amount is editable": [
        ""
      ],
      "Allow the user to select the amount to pay.": [
        ""
      ],
      "Currency is editable": [
        ""
      ],
      "Allow the user to change currency.": [
        ""
      ],
      "Supported currencies": [
        ""
      ],
      "Supported currencies: %1$s": [
        ""
      ],
      "Minimum age": [
        "Asgari yaş"
      ],
      "Is this contract restricted to some age?": [
        ""
      ],
      "Payment timeout": [
        ""
      ],
      "How much time the customer has to complete the payment once the order was created.": [
        ""
      ],
      "OTP device": [
        ""
      ],
      "Use to verify transactions in offline mode.": [
        ""
      ],
      "No OTP device.": [
        ""
      ],
      "Add one first": [
        ""
      ],
      "No device": [
        ""
      ],
      "Template has been created": [
        ""
      ],
      "Could not create template": [
        ""
      ],
      "Templates": [
        ""
      ],
      "Add new templates": [
        ""
      ],
      "Load more templates before the first one": [
        ""
      ],
      "Delete selected templates from the database": [
        ""
      ],
      "Use template to create new order": [
        ""
      ],
      "Use template": [
        ""
      ],
      "Generate a QR code for the template.": [
        ""
      ],
      "Load more templates after the last one": [
        ""
      ],
      "There are no templates to list yet, add more by pressing the + sign": [
        ""
      ],
      "Jump to template with the given template ID": [
        ""
      ],
      "Template identification": [
        ""
      ],
      "Template \"%1$s\" (ID: %2$s) has been deleted": [
        ""
      ],
      "Failed to delete template": [
        ""
      ],
      "If you delete the template %1$s (ID: %2$s) you may loose information": [
        ""
      ],
      "Deleting an template": [
        ""
      ],
      "can't be undone": [
        ""
      ],
      "Print": [
        ""
      ],
      "If specified, this template will create order with the same summary": [
        ""
      ],
      "If specified, this template will create orders with the same price": [
        ""
      ],
      "How much time has the customer to complete the payment once the order was created.": [
        ""
      ],
      "Template (ID: %1$s) has been updated": [
        ""
      ],
      "An amount is required": [
        ""
      ],
      "An order summary is required": [
        ""
      ],
      "New order from template": [
        ""
      ],
      "Amount of the order": [
        ""
      ],
      "Order summary": [
        ""
      ],
      "Could not create order from template": [
        ""
      ],
      "You need your access token to perform the operation": [
        ""
      ],
      "You are updating the access token from instance with id \"%1$s\"": [
        ""
      ],
      "This instance does not have authentication token.": [
        ""
      ],
      "You can leave it empty if there is another layer of security.": [
        ""
      ],
      "Current access token": [
        ""
      ],
      "Clearing the access token will mean public access to the instance.": [
        ""
      ],
      "Clear token": [
        ""
      ],
      "Confirm change": [
        ""
      ],
      "Failed to clear token": [
        ""
      ],
      "Failed to set new token": [
        ""
      ],
      "Slug": [
        ""
      ],
      "Token family slug to use in URLs (for internal use only)": [
        ""
      ],
      "Kind": [
        ""
      ],
      "Token family kind": [
        ""
      ],
      "User-readable token family name": [
        ""
      ],
      "Token family description for customers": [
        ""
      ],
      "Valid After": [
        "Geçerlilik"
      ],
      "Token family can issue tokens after this date": [
        ""
      ],
      "Valid Before": [
        ""
      ],
      "Token family can issue tokens until this date": [
        ""
      ],
      "Duration": [
        ""
      ],
      "Validity duration of a issued token": [
        ""
      ],
      "Token family created successfully.": [
        ""
      ],
      "Could not create token family.": [
        ""
      ],
      "Token Families": [
        ""
      ],
      "Add token family": [
        ""
      ],
      "Go to token family update page": [
        ""
      ],
      "Remove this token family from the database": [
        ""
      ],
      "There are no token families yet, add the first one by pressing the + sign.": [
        ""
      ],
      "Token family updated successfully": [
        ""
      ],
      "Could not update the token family": [
        ""
      ],
      "Token family \"%1$s\" (SLUG: %2$s) has been deleted": [
        ""
      ],
      "Failed to delete token family": [
        ""
      ],
      "If you delete the %1$s token family (Slug: %2$s), all issued tokens will become invalid.": [
        ""
      ],
      "Deleting a token family %1$s .": [
        ""
      ],
      "Token Family: %1$s": [
        ""
      ],
      "Could not update token family": [
        ""
      ],
      "Please check the ID; it does not appear to be valid.": [
        ""
      ],
      "Must have 52 characters, current %1$s": [
        ""
      ],
      "URL doesn't have the right format": [
        ""
      ],
      "Credited bank account": [
        ""
      ],
      "Select an account": [
        "Banka hesabı"
      ],
      "Bank account of the merchant where the payment was received": [
        ""
      ],
      "Wire transfer ID": [
        ""
      ],
      "Unique identifier of the wire transfer used by the exchange, must be 52 characters long": [
        ""
      ],
      "Exchange URL": [
        ""
      ],
      "Base URL of the exchange that made the transfer, should have been in the wire transfer subject": [
        ""
      ],
      "Amount credited": [
        ""
      ],
      "Actual amount that was wired to the merchant's bank account": [
        ""
      ],
      "Wire transfer informed successfully": [
        ""
      ],
      "Could not inform transfer": [
        ""
      ],
      "Wire transfers": [
        ""
      ],
      "Add new transfer": [
        ""
      ],
      "Load more wire transfers preceding the first one": [
        ""
      ],
      "Credit": [
        "Kredi"
      ],
      "Confirmed": [
        ""
      ],
      "Verified": [
        ""
      ],
      "Executed on": [
        ""
      ],
      "yes": [
        ""
      ],
      "no": [
        ""
      ],
      "never": [
        ""
      ],
      "unknown": [
        ""
      ],
      "Delete selected transfer from the database": [
        ""
      ],
      "Load more transfers after the last one": [
        ""
      ],
      "There are no transfers to list yet, add more by pressing the + sign": [
        ""
      ],
      "All accounts": [
        "Hesap"
      ],
      "Filter by account address": [
        ""
      ],
      "Only show wire transfers confirmed by the merchant": [
        ""
      ],
      "Only show wire transfers claimed by the exchange": [
        ""
      ],
      "Unverified": [
        ""
      ],
      "Wire transfer \"%1$s...\" has been deleted": [
        ""
      ],
      "Failed to delete transfer": [
        ""
      ],
      "Must be business or individual": [
        ""
      ],
      "Pay delay can't be greater than wire transfer delay": [
        ""
      ],
      "Max 7 lines": [
        ""
      ],
      "Doesn't match": [
        ""
      ],
      "Enable access control": [
        ""
      ],
      "Choose if the backend server should authenticate access.": [
        ""
      ],
      "Access control is not yet decided. This instance can't be created.": [
        ""
      ],
      "Authorization must be handled externally.": [
        ""
      ],
      "Authorization is handled by the backend server.": [
        ""
      ],
      "Need to complete marked fields and choose authorization method": [
        ""
      ],
      "Name of the instance in URLs. The 'default' instance is special in that it is used to administer other instances.": [
        ""
      ],
      "Business name": [
        ""
      ],
      "Legal name of the business represented by this instance.": [
        ""
      ],
      "Email": [
        ""
      ],
      "Contact email": [
        ""
      ],
      "Website URL": [
        ""
      ],
      "URL.": [
        ""
      ],
      "Logo": [
        ""
      ],
      "Logo image.": [
        ""
      ],
      "Physical location of the merchant.": [
        ""
      ],
      "Jurisdiction": [
        ""
      ],
      "Jurisdiction for legal disputes with the merchant.": [
        ""
      ],
      "Pay transaction fee": [
        ""
      ],
      "Cover the transaction cost or pass it on to the user.": [
        ""
      ],
      "Default payment delay": [
        ""
      ],
      "Time customers have to pay an order before the offer expires by default.": [
        ""
      ],
      "Default wire transfer delay": [
        ""
      ],
      "Maximum time an exchange is allowed to delay wiring funds to the merchant, enabling it to aggregate smaller payments into larger wire transfers and reducing wire fees.": [
        ""
      ],
      "Instance id": [
        ""
      ],
      "Failed to update instance": [
        ""
      ],
      "Must be \"pay\" or \"refund\"": [
        ""
      ],
      "Must be one of '%1$s'": [
        ""
      ],
      "Webhook ID to use": [
        ""
      ],
      "Event": [
        ""
      ],
      "Pay": [
        ""
      ],
      "The event of the webhook: why the webhook is used": [
        ""
      ],
      "Method": [
        ""
      ],
      "GET": [
        ""
      ],
      "POST": [
        ""
      ],
      "PUT": [
        ""
      ],
      "PATCH": [
        ""
      ],
      "HEAD": [
        ""
      ],
      "Method used by the webhook": [
        ""
      ],
      "URL": [
        ""
      ],
      "URL of the webhook where the customer will be redirected": [
        ""
      ],
      "The text below supports the %1$s template engine. Any string between %2$s and %3$s will be replaced with the value of the corresponding variable.": [
        ""
      ],
      "For example %1$s will be replaced with the the order's price": [
        ""
      ],
      "The short list of variables are:": [
        ""
      ],
      "order's description": [
        ""
      ],
      "order's price": [
        ""
      ],
      "order's unique identification": [
        ""
      ],
      "the amount that was being refunded": [
        ""
      ],
      "the reason entered by the merchant staff for granting the refund": [
        ""
      ],
      "time of the refund in nanoseconds since 1970": [
        ""
      ],
      "Http body": [
        ""
      ],
      "Body template by the webhook": [
        ""
      ],
      "Webhook create successfully": [
        ""
      ],
      "Could not create the webhook": [
        ""
      ],
      "Could not create webhook": [
        ""
      ],
      "Webhooks": [
        ""
      ],
      "Add new webhooks": [
        ""
      ],
      "Load more webhooks before the first one": [
        ""
      ],
      "Event type": [
        ""
      ],
      "Delete selected webhook from the database": [
        ""
      ],
      "Load more webhooks after the last one": [
        ""
      ],
      "There are no webhooks to list yet, add more by pressing the + sign": [
        ""
      ],
      "Webhook deleted successfully": [
        ""
      ],
      "Could not delete the webhook": [
        ""
      ],
      "Header": [
        ""
      ],
      "Header template of the webhook": [
        ""
      ],
      "Body": [
        ""
      ],
      "Webhook updated": [
        ""
      ],
      "Could not update webhook": [
        ""
      ],
      "Language": [
        ""
      ],
      "Advance order creation": [
        ""
      ],
      "Shows more options in the order creation form": [
        ""
      ],
      "Advance instance settings": [
        ""
      ],
      "Shows more options in the instance settings form": [
        ""
      ],
      "Date format": [
        ""
      ],
      "How the date is going to be displayed": [
        ""
      ],
      "Developer mode": [
        ""
      ],
      "Shows more options and tools which are not intended for general audience.": [
        ""
      ],
      "Total products": [
        "Ürünler"
      ],
      "Delete selected category from the database": [
        ""
      ],
      "There are no categories yet, add more pressing the + sign": [
        ""
      ],
      "Category delete successfully": [
        ""
      ],
      "Could not delete the category": [
        ""
      ],
      "Invalid. Please insert only characters and numbers": [
        ""
      ],
      "Category name": [
        ""
      ],
      "Category added successfully": [
        ""
      ],
      "Could not add category": [
        ""
      ],
      "Id:": [
        ""
      ],
      "Name of the category": [
        ""
      ],
      "Products": [
        "Ürünler"
      ],
      "Search by product description or id": [
        ""
      ],
      "Products that this category will list.": [
        ""
      ],
      "Could not update category": [
        ""
      ],
      "Category ID is unknown": [
        ""
      ],
      "Without this the merchant backend will refuse to create new orders.": [
        ""
      ],
      "Hide for today": [
        ""
      ],
      "KYC verification needed": [
        ""
      ],
      "Some transfers are on hold until the KYC process is completed. Visit the KYC section in the left panel for more information": [
        ""
      ],
      "Configuration": [
        ""
      ],
      "OTP Devices": [
        ""
      ],
      "Settings": [
        ""
      ],
      "Access token": [
        ""
      ],
      "Connection": [
        ""
      ],
      "Interface": [
        ""
      ],
      "List": [
        ""
      ],
      "Log out": [
        ""
      ],
      "Failed to create instance": [
        ""
      ],
      "checking compatibility with server...": [
        ""
      ],
      "Contacting the server failed": [
        ""
      ],
      "The server version is not supported": [
        ""
      ],
      "Supported version \"%1$s\", server version \"%2$s\".": [
        ""
      ],
      "Deleting": [
        ""
      ],
      "Changing": [
        ""
      ],
      "Manage access token": [
        ""
      ],
      "Business Name": [
        ""
      ],
      "Order ID": [
        ""
      ],
      "Payment URL": [
        ""
      ]
    }
  },
  "domain": "messages",
  "plural_forms": "nplurals=2; plural=n != 1;",
  "lang": "tr",
  "completeness": 6
};

strings['sv'] = {
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=2; plural=n != 1;",
        "lang": "sv"
      },
      "The request reached a timeout, check your connection.": [
        ""
      ],
      "The request was cancelled.": [
        ""
      ],
      "A lot of request were made to the same server and this action was throttled.": [
        ""
      ],
      "The response of the request is malformed.": [
        ""
      ],
      "Could not complete the request due to a network problem.": [
        ""
      ],
      "Unexpected request error.": [
        ""
      ],
      "Unexpected error.": [
        ""
      ],
      "Cancel": [
        "Avbryt"
      ],
      "%1$s": [
        "%1$s"
      ],
      "Close": [
        ""
      ],
      "Continue": [
        ""
      ],
      "Clear": [
        ""
      ],
      "Confirm": [
        "Bekräfta"
      ],
      "Required": [
        "Obligatoriskt"
      ],
      "Letter must be a JSON string": [
        ""
      ],
      "JSON string is invalid": [
        ""
      ],
      "Import": [
        ""
      ],
      "Importing an account from the bank": [
        ""
      ],
      "You can export your account settings from the Libeufin Bank's account profile. Paste the content in the next field.": [
        ""
      ],
      "Account information": [
        ""
      ],
      "Correct form": [
        ""
      ],
      "Comparing account details": [
        ""
      ],
      "Testing against the account info URL succeeded but the account information reported is different with the account details form.": [
        ""
      ],
      "Field": [
        ""
      ],
      "In the form": [
        ""
      ],
      "Reported": [
        ""
      ],
      "Type": [
        ""
      ],
      "IBAN": [
        ""
      ],
      "Address": [
        ""
      ],
      "Host": [
        ""
      ],
      "Account id": [
        ""
      ],
      "Owner's name": [
        ""
      ],
      "Account": [
        ""
      ],
      "Bank host": [
        ""
      ],
      "Bank account": [
        ""
      ],
      "BIC": [
        ""
      ],
      "Ok": [
        ""
      ],
      "Validate bank account: %1$s": [
        ""
      ],
      "You need to make a bank transfer with the specified subject to validate that you are the owner of the account.": [
        ""
      ],
      "Step 1:": [
        ""
      ],
      "Copy this string and paste it into the field 'Subject' (resp. 'Purpose') in your banking app or bank website": [
        ""
      ],
      "Subject": [
        ""
      ],
      "Step 2:": [
        ""
      ],
      "Copy and paste this IBAN and the name into the receiver fields in your banking app or website": [
        ""
      ],
      "Receiver name": [
        ""
      ],
      "Step 3:": [
        ""
      ],
      "Select the smallest possible amount for the wire transfer in your banking app or on your online banking website.": [
        ""
      ],
      "Make sure ALL data is correct, including the subject and you are using your selected bank account. You can use the copy buttons (%1$s) to prevent typing errors or the \"payto://\" URI below to copy just one value.": [
        ""
      ],
      "Alternatively, if your bank already supports the standard 'PayTo URI', you can use this %1$s link instead": [
        ""
      ],
      "If you delete the instance named %1$s (ID: %2$s), the merchant will no longer be able to process orders or refunds": [
        ""
      ],
      "This action deletes the instance private key, but preserves all transaction data. You can still access that data after deleting the instance.": [
        ""
      ],
      "Deleting an instance %1$s .": [
        ""
      ],
      "If you purge the instance named %1$s (ID: %2$s), you will also delete all it&apos;s transaction data.": [
        ""
      ],
      "The instance will disappear from your list, and you will no longer be able to access it&apos;s data.": [
        ""
      ],
      "Purging an instance %1$s .": [
        ""
      ],
      "Is not the same as the current access token": [
        ""
      ],
      "Can't be the same as the old token": [
        ""
      ],
      "Is not the same": [
        ""
      ],
      "You are updating the access token for the instance with ID %1$s": [
        ""
      ],
      "Old access token": [
        ""
      ],
      "Access token currently in use": [
        ""
      ],
      "New access token": [
        ""
      ],
      "Next access token to be used": [
        ""
      ],
      "Repeat access token": [
        ""
      ],
      "Confirm the same access token": [
        ""
      ],
      "Clearing the access token will mean public access to the instance": [
        ""
      ],
      "Can't be the same as the old access token": [
        ""
      ],
      "You are setting the access token for the new instance": [
        ""
      ],
      "With external authorization method no check will be done by the merchant backend": [
        ""
      ],
      "Set external authorization": [
        ""
      ],
      "Set access token": [
        ""
      ],
      "Operation in progress...": [
        ""
      ],
      "The operation will be automatically canceled after %1$s seconds": [
        ""
      ],
      "Your password is incorrect": [
        ""
      ],
      "Your instance cannot be found": [
        ""
      ],
      "Login required": [
        ""
      ],
      "Please enter your access token for %1$s.": [
        ""
      ],
      "Access Token": [
        ""
      ],
      "Instances": [
        ""
      ],
      "Delete": [
        ""
      ],
      "Add new instance": [
        ""
      ],
      "ID": [
        ""
      ],
      "Name": [
        ""
      ],
      "Edit": [
        ""
      ],
      "Purge": [
        ""
      ],
      "There are no instances yet, add more pressing the + sign": [
        ""
      ],
      "Only show active instances": [
        ""
      ],
      "Active": [
        ""
      ],
      "Only show deleted instances": [
        ""
      ],
      "Deleted": [
        ""
      ],
      "Show all instances": [
        ""
      ],
      "All": [
        ""
      ],
      "Instance \"%1$s\" (ID: %2$s) has been deleted": [
        ""
      ],
      "Failed to delete instance": [
        ""
      ],
      "Instance '%1$s' (ID: %2$s) has been purged": [
        ""
      ],
      "Failed to purge instance": [
        ""
      ],
      "Loading...": [
        ""
      ],
      "This is not a valid bitcoin address.": [
        ""
      ],
      "This is not a valid Ethereum address.": [
        ""
      ],
      "This is not a valid host.": [
        ""
      ],
      "IBAN numbers usually have more that 4 digits": [
        ""
      ],
      "IBAN numbers usually have less that 34 digits": [
        ""
      ],
      "IBAN country code not found": [
        ""
      ],
      "IBAN number is invalid, checksum is wrong": [
        ""
      ],
      "Choose one...": [
        ""
      ],
      "Method to use for wire transfer": [
        ""
      ],
      "Routing": [
        ""
      ],
      "Routing number.": [
        ""
      ],
      "Account number.": [
        ""
      ],
      "Code": [
        ""
      ],
      "Business Identifier Code.": [
        ""
      ],
      "International Bank Account Number.": [
        ""
      ],
      "Unified Payment Interface.": [
        ""
      ],
      "Bitcoin protocol.": [
        ""
      ],
      "Ethereum protocol.": [
        ""
      ],
      "Interledger protocol.": [
        ""
      ],
      "Bank host.": [
        ""
      ],
      "Without scheme and may include subpath:": [
        ""
      ],
      "Bank account.": [
        ""
      ],
      "Legal name of the person holding the account.": [
        ""
      ],
      "It should match the bank account name.": [
        ""
      ],
      "Invalid url": [
        ""
      ],
      "URL must end with a '/'": [
        ""
      ],
      "URL must not contain params": [
        ""
      ],
      "URL must not hash param": [
        ""
      ],
      "The request to check the revenue API failed.": [
        ""
      ],
      "Server replied with \"bad request\".": [
        ""
      ],
      "Unauthorized, check credentials.": [
        ""
      ],
      "The endpoint does not seem to be a Taler Revenue API.": [
        ""
      ],
      "The request was made correctly, but the bank's server did not respond with the appropriate value for 'credit_account', so we cannot confirm that it is the same bank account.": [
        ""
      ],
      "Account:": [
        ""
      ],
      "If the bank supports Taler Revenue API then you can add the endpoint URL below to keep the revenue information in sync.": [
        ""
      ],
      "Endpoint URL": [
        ""
      ],
      "From where the merchant can download information about incoming wire transfers to this account": [
        ""
      ],
      "Auth type": [
        ""
      ],
      "Choose the authentication type for the account info URL": [
        ""
      ],
      "Without authentication": [
        ""
      ],
      "With password": [
        ""
      ],
      "With token": [
        ""
      ],
      "Do not change": [
        ""
      ],
      "Username": [
        ""
      ],
      "Username to access the account information.": [
        ""
      ],
      "Password": [
        ""
      ],
      "Password to access the account information.": [
        ""
      ],
      "Token": [
        ""
      ],
      "Access token to access the account information.": [
        ""
      ],
      "Match": [
        ""
      ],
      "Check where the information match against the server info.": [
        ""
      ],
      "Not verified": [
        ""
      ],
      "Last test was ok": [
        ""
      ],
      "Last test failed": [
        ""
      ],
      "Compare info from server with account form": [
        ""
      ],
      "Test": [
        ""
      ],
      "Need to complete marked fields": [
        ""
      ],
      "Confirm operation": [
        ""
      ],
      "Account details": [
        ""
      ],
      "Import from bank": [
        ""
      ],
      "Could not create account": [
        ""
      ],
      "No 'default' instance configured yet.": [
        ""
      ],
      "Create a 'default' instance to begin using the merchant backoffice.": [
        ""
      ],
      "Bank accounts": [
        ""
      ],
      "Add new account": [
        ""
      ],
      "Wire method: Bitcoin": [
        ""
      ],
      "SegWit 1": [
        ""
      ],
      "SegWit 2": [
        ""
      ],
      "Delete selected accounts from the database": [
        ""
      ],
      "Wire method: x-taler-bank": [
        ""
      ],
      "Account name": [
        ""
      ],
      "Wire method: IBAN": [
        ""
      ],
      "Other accounts": [
        ""
      ],
      "Path": [
        ""
      ],
      "There are no accounts yet, add more pressing the + sign": [
        ""
      ],
      "You need to associate a bank account to receive revenue.": [
        ""
      ],
      "Without this the you won't be able to create new orders.": [
        ""
      ],
      "The bank account has been successfully deleted.": [
        ""
      ],
      "Could not delete the bank account": [
        ""
      ],
      "Could not update account": [
        ""
      ],
      "Could not delete account": [
        ""
      ],
      "Pending KYC verification": [
        ""
      ],
      "Exchange": [
        ""
      ],
      "Reason": [
        ""
      ],
      "Pending KYC process, click here to complete": [
        ""
      ],
      "The Payment Service Provider requires an account verification.": [
        ""
      ],
      "Http Status": [
        ""
      ],
      "No pending kyc verification!": [
        ""
      ],
      "Change value to unknown date": [
        ""
      ],
      "Change value to empty": [
        ""
      ],
      "Change value to never": [
        ""
      ],
      "Never": [
        ""
      ],
      "days": [
        ""
      ],
      "hours": [
        ""
      ],
      "minutes": [
        ""
      ],
      "seconds": [
        ""
      ],
      "Forever": [
        ""
      ],
      "%1$sM": [
        ""
      ],
      "%1$sY": [
        ""
      ],
      "%1$sd": [
        ""
      ],
      "%1$sh": [
        ""
      ],
      "%1$smin": [
        ""
      ],
      "%1$ssec": [
        ""
      ],
      "Country": [
        ""
      ],
      "Building number": [
        ""
      ],
      "Building name": [
        ""
      ],
      "Street": [
        ""
      ],
      "Post code": [
        ""
      ],
      "Town location": [
        ""
      ],
      "Town": [
        ""
      ],
      "District": [
        ""
      ],
      "Country subdivision": [
        ""
      ],
      "Description": [
        ""
      ],
      "Enter description or id": [
        ""
      ],
      "no match found with that description or id": [
        ""
      ],
      "You must enter a valid product identifier.": [
        ""
      ],
      "Quantity must be greater than 0!": [
        ""
      ],
      "This quantity exceeds remaining stock. Currently, only %1$s units remain unreserved in stock.": [
        ""
      ],
      "Search product": [
        ""
      ],
      "Quantity": [
        ""
      ],
      "How many products will be added": [
        ""
      ],
      "Add from inventory": [
        ""
      ],
      "Image must be smaller than 1 MB": [
        ""
      ],
      "Add": [
        ""
      ],
      "Remove": [
        ""
      ],
      "Invalid": [
        ""
      ],
      "This product has %1$s applicable taxes configured.": [
        ""
      ],
      "No taxes configured for this product.": [
        ""
      ],
      "Amount": [
        ""
      ],
      "Taxes can be in currencies that differ from the main currency used by the merchant.": [
        ""
      ],
      "Enter currency and value separated with a colon, e.g. &quot;USD:2.3&quot;.": [
        ""
      ],
      "Legal name of the tax, e.g. VAT or import duties.": [
        ""
      ],
      "Add tax to the tax list": [
        ""
      ],
      "Describe and add a product that is not in the inventory list": [
        ""
      ],
      "Add custom product": [
        ""
      ],
      "Complete information of the product": [
        ""
      ],
      "Must be a number": [
        ""
      ],
      "Must be grater than 0": [
        ""
      ],
      "Image": [
        ""
      ],
      "Photo of the product.": [
        ""
      ],
      "Full product description.": [
        ""
      ],
      "Unit": [
        ""
      ],
      "Name of the product unit.": [
        ""
      ],
      "Price": [
        ""
      ],
      "Amount in the current currency.": [
        ""
      ],
      "How many products will be added.": [
        ""
      ],
      "Taxes": [
        ""
      ],
      "Unit price": [
        ""
      ],
      "Total price": [
        ""
      ],
      "Must be greater than 0": [
        ""
      ],
      "Refund deadline can't be before pay deadline": [
        ""
      ],
      "Wire transfer deadline can't be before refund deadline": [
        ""
      ],
      "Wire transfer deadline can't be before pay deadline": [
        ""
      ],
      "Must have a refund deadline": [
        ""
      ],
      "Auto refund can't be after refund deadline": [
        ""
      ],
      "Must be in the future": [
        ""
      ],
      "Simple": [
        ""
      ],
      "Advanced": [
        ""
      ],
      "Manage products in order": [
        ""
      ],
      "%1$s products with a total price of %2$s.": [
        ""
      ],
      "Manage list of products in the order.": [
        ""
      ],
      "Remove this product from the order.": [
        ""
      ],
      "Total product price added up": [
        ""
      ],
      "Amount to be paid by the customer": [
        ""
      ],
      "Order price": [
        ""
      ],
      "Final order price": [
        ""
      ],
      "Summary": [
        ""
      ],
      "Title of the order to be shown to the customer": [
        ""
      ],
      "Shipping and fulfillment": [
        ""
      ],
      "Delivery date": [
        ""
      ],
      "Deadline for physical delivery assured by the merchant.": [
        ""
      ],
      "Location": [
        ""
      ],
      "Address where the products will be delivered": [
        ""
      ],
      "Fulfillment URL": [
        ""
      ],
      "URL to which the user will be redirected after successful payment.": [
        ""
      ],
      "Taler payment options": [
        ""
      ],
      "Override default Taler payment settings for this order": [
        ""
      ],
      "Payment time": [
        ""
      ],
      "Time for the customer to pay for the offer before it expires. Inventory products will be reserved until this deadline. Time start to run after the order is created.": [
        ""
      ],
      "Default": [
        ""
      ],
      "Refund time": [
        ""
      ],
      "Time while the order can be refunded by the merchant. Time starts after the order is created.": [
        ""
      ],
      "Wire transfer time": [
        ""
      ],
      "Time for the exchange to make the wire transfer. Time starts after the order is created.": [
        ""
      ],
      "Auto-refund time": [
        ""
      ],
      "Time until which the wallet will automatically check for refunds without user interaction.": [
        ""
      ],
      "Maximum fee": [
        ""
      ],
      "Maximum fees the merchant is willing to cover for this order. Higher deposit fees must be covered in full by the consumer.": [
        ""
      ],
      "Create token": [
        ""
      ],
      "If the order ID is easy to guess the token will prevent users to steal orders from others.": [
        ""
      ],
      "Minimum age required": [
        ""
      ],
      "Any value greater than 0 will limit the coins able be used to pay this contract. If empty the age restriction will be defined by the products": [
        ""
      ],
      "Min age defined by the producs is %1$s": [
        ""
      ],
      "No product with age restriction in this order": [
        ""
      ],
      "Additional information": [
        ""
      ],
      "Custom information to be included in the contract for this order.": [
        ""
      ],
      "You must enter a value in JavaScript Object Notation (JSON).": [
        ""
      ],
      "Custom field name": [
        ""
      ],
      "Disabled": [
        ""
      ],
      "No deadline": [
        ""
      ],
      "Deadline at %1$s": [
        ""
      ],
      "Could not create order": [
        ""
      ],
      "No exchange would accept a payment because of KYC requirements.": [
        ""
      ],
      "No more stock for product with ID \"%1$s\".": [
        ""
      ],
      "Orders": [
        ""
      ],
      "Create order": [
        ""
      ],
      "Load first page": [
        ""
      ],
      "Date": [
        ""
      ],
      "Refund": [
        ""
      ],
      "copy url": [
        ""
      ],
      "Load more orders after the last one": [
        ""
      ],
      "Load next page": [
        ""
      ],
      "No orders have been found matching your query!": [
        ""
      ],
      "Duplicated": [
        ""
      ],
      "This value exceed the refundable amount": [
        ""
      ],
      "Amount to be refunded": [
        ""
      ],
      "Max refundable:": [
        ""
      ],
      "Requested by the customer": [
        ""
      ],
      "Other": [
        ""
      ],
      "Why this order is being refunded": [
        ""
      ],
      "More information to give context": [
        ""
      ],
      "Contract terms": [
        ""
      ],
      "Human-readable description of the whole purchase": [
        ""
      ],
      "Total price for the transaction": [
        ""
      ],
      "URL for this purchase": [
        ""
      ],
      "Max fee": [
        ""
      ],
      "Maximum total deposit fee accepted by the merchant for this contract": [
        ""
      ],
      "Created at": [
        ""
      ],
      "Time when this contract was generated": [
        ""
      ],
      "Refund deadline": [
        ""
      ],
      "After this deadline has passed no refunds will be accepted": [
        ""
      ],
      "Payment deadline": [
        ""
      ],
      "After this deadline, the merchant won't accept payments for the contract": [
        ""
      ],
      "Wire transfer deadline": [
        ""
      ],
      "Transfer deadline for the exchange": [
        ""
      ],
      "Time indicating when the order should be delivered": [
        ""
      ],
      "Where the order will be delivered": [
        ""
      ],
      "Auto-refund delay": [
        ""
      ],
      "How long the wallet should try to get an automatic refund for the purchase": [
        ""
      ],
      "Extra info": [
        ""
      ],
      "Extra data that is only interpreted by the merchant frontend": [
        ""
      ],
      "Order": [
        ""
      ],
      "Claimed": [
        ""
      ],
      "Claimed at": [
        ""
      ],
      "Timeline": [
        ""
      ],
      "Payment details": [
        ""
      ],
      "Order status": [
        ""
      ],
      "Product list": [
        ""
      ],
      "Paid": [
        ""
      ],
      "Wired": [
        ""
      ],
      "Refunded": [
        ""
      ],
      "Refund order": [
        ""
      ],
      "Not refundable": [
        ""
      ],
      "Next event in": [
        ""
      ],
      "Refunded amount": [
        ""
      ],
      "Refund taken": [
        ""
      ],
      "Status URL": [
        ""
      ],
      "Refund URI": [
        ""
      ],
      "Unpaid": [
        ""
      ],
      "Pay at": [
        ""
      ],
      "Order status URL": [
        ""
      ],
      "Payment URI": [
        ""
      ],
      "Unknown order status. This is an error, please contact the administrator.": [
        ""
      ],
      "Back": [
        ""
      ],
      "Refund created successfully": [
        ""
      ],
      "Could not create the refund": [
        ""
      ],
      "There are pending KYC requirements.": [
        ""
      ],
      "Missing ID": [
        ""
      ],
      "Not found": [
        ""
      ],
      "Select date to show nearby orders": [
        ""
      ],
      "Only show paid orders": [
        ""
      ],
      "New": [
        ""
      ],
      "Only show orders with refunds": [
        ""
      ],
      "Only display orders that have already been paid for by buyers and for which the wire transfer by the payment service provider is still pending": [
        ""
      ],
      "Not wired": [
        ""
      ],
      "Only display orders that have already been transferred by the payment service provider": [
        ""
      ],
      "Completed": [
        ""
      ],
      "Remove all filters": [
        ""
      ],
      "Clear date filter": [
        ""
      ],
      "Jump to date (%1$s)": [
        ""
      ],
      "Jump to order with the given product ID": [
        ""
      ],
      "Order id": [
        ""
      ],
      "Invalid. Please enter characters and numbers only": [
        ""
      ],
      "Just letters and numbers from 2 to 7": [
        ""
      ],
      "The size of the key must be 32 characters": [
        ""
      ],
      "Internal ID on the system": [
        ""
      ],
      "Useful to identify the device physically": [
        ""
      ],
      "Verification algorithm": [
        ""
      ],
      "Algorithm used to verify transactions in offline mode": [
        ""
      ],
      "Device key": [
        ""
      ],
      "Be sure to choose a password hard to guess, alternatively use the random generator": [
        ""
      ],
      "Your device needs to match exactly the same value": [
        ""
      ],
      "Generate random secret key": [
        ""
      ],
      "Random": [
        ""
      ],
      "You can scan the next QR code with your device or save the key before continuing.": [
        ""
      ],
      "Device added successfully": [
        ""
      ],
      "Could not add device": [
        ""
      ],
      "OTP devices": [
        ""
      ],
      "Add new devices": [
        ""
      ],
      "Load more devices before the first one": [
        ""
      ],
      "Delete selected devices from the database": [
        ""
      ],
      "Load more devices after the last one": [
        ""
      ],
      "There are no devices to list yet, add more by pressing the + sign": [
        ""
      ],
      "The device was successfully deleted": [
        ""
      ],
      "Could not delete the device": [
        ""
      ],
      "Device:": [
        ""
      ],
      "Algorithm to use to verify transactions in offline mode": [
        ""
      ],
      "Not modified": [
        ""
      ],
      "Be sure to be very hard to guess or use the random generator": [
        ""
      ],
      "Your device need to have exactly the same value": [
        ""
      ],
      "Change key": [
        ""
      ],
      "Could not update template": [
        ""
      ],
      "Template id is unknown": [
        ""
      ],
      "The provided information is inconsistent with the current state of the template": [
        ""
      ],
      "Click here to configure the stock of the product, leave it as is and the backend will not control stock.": [
        ""
      ],
      "Manage stock": [
        ""
      ],
      "This product has been configured without stock control": [
        ""
      ],
      "Infinite": [
        ""
      ],
      "Shrinkage cannot exceed the current stock and incoming supplies (maximum %1$s)": [
        ""
      ],
      "Incoming": [
        ""
      ],
      "Lost": [
        ""
      ],
      "Current": [
        ""
      ],
      "Remove stock control for this product": [
        ""
      ],
      "without stock": [
        ""
      ],
      "Next restock": [
        ""
      ],
      "Warehouse address": [
        ""
      ],
      "Add element to the list": [
        ""
      ],
      "Invalid amount": [
        ""
      ],
      "Product identification to use in URLs (for internal use only).": [
        ""
      ],
      "Illustration of the product for customers.": [
        ""
      ],
      "Product description for customers.": [
        ""
      ],
      "Age restriction": [
        ""
      ],
      "Is this product restricted for customer below certain age?": [
        ""
      ],
      "Minimum age of the customer": [
        ""
      ],
      "Unit name": [
        ""
      ],
      "Unit describing quantity of product sold (e.g. 2 kilograms, 5 liters, 3 items, 5 meters) for customers.": [
        ""
      ],
      "Example: kg, items or liters": [
        ""
      ],
      "Price per unit": [
        ""
      ],
      "Sale price for customers, including taxes, for above units of the product.": [
        ""
      ],
      "Stock": [
        ""
      ],
      "Inventory for products with finite supply (for internal use only).": [
        ""
      ],
      "Taxes included in the product price, exposed to customers.": [
        ""
      ],
      "Categories": [
        ""
      ],
      "Search by category description or id": [
        ""
      ],
      "Categories where this product will be listed on.": [
        ""
      ],
      "Product created successfully": [
        ""
      ],
      "Could not create product": [
        ""
      ],
      "Inventory": [
        ""
      ],
      "Add product to inventory": [
        ""
      ],
      "Sales": [
        ""
      ],
      "Sold": [
        ""
      ],
      "Free": [
        ""
      ],
      "Go to product update page": [
        ""
      ],
      "Update": [
        ""
      ],
      "Remove this product from the database": [
        ""
      ],
      "Load more products after the last one": [
        ""
      ],
      "Update the product with new price": [
        ""
      ],
      "Update product with new price": [
        ""
      ],
      "Confirm update": [
        ""
      ],
      "lost can't be greater that current + incoming (max %1$s)": [
        ""
      ],
      "Add more elements to the inventory": [
        ""
      ],
      "Report elements lost in the inventory": [
        ""
      ],
      "New price for the product": [
        ""
      ],
      "The are value with errors": [
        ""
      ],
      "Update product with new stock and price": [
        ""
      ],
      "There are no products to list yet, add more by pressing the + sign": [
        ""
      ],
      "Jump to product with the given product ID": [
        ""
      ],
      "Product id": [
        ""
      ],
      "Product updated successfully": [
        ""
      ],
      "Could not update the product": [
        ""
      ],
      "Product \"%1$s\" (ID: %2$s) has been deleted": [
        ""
      ],
      "Could not delete the product": [
        ""
      ],
      "If you delete the product named %1$s (ID: %2$s ), the stock and related information will be lost": [
        ""
      ],
      "Deleting an product can't be undone.": [
        ""
      ],
      "Product id:": [
        ""
      ],
      "Product (ID: %1$s) has been updated": [
        ""
      ],
      "Could not update product": [
        ""
      ],
      "Must be greater that 0": [
        ""
      ],
      "Too short": [
        ""
      ],
      "Identifier": [
        ""
      ],
      "Name of the template in URLs.": [
        ""
      ],
      "Describe what this template stands for": [
        ""
      ],
      "If specified here, this template will create orders with the same summary": [
        ""
      ],
      "Summary is editable": [
        ""
      ],
      "Allow the user to change the summary.": [
        ""
      ],
      "If specified here, this template will create orders with the same price": [
        ""
      ],
      "Amount is editable": [
        ""
      ],
      "Allow the user to select the amount to pay.": [
        ""
      ],
      "Currency is editable": [
        ""
      ],
      "Allow the user to change currency.": [
        ""
      ],
      "Supported currencies": [
        ""
      ],
      "Supported currencies: %1$s": [
        ""
      ],
      "Minimum age": [
        ""
      ],
      "Is this contract restricted to some age?": [
        ""
      ],
      "Payment timeout": [
        ""
      ],
      "How much time the customer has to complete the payment once the order was created.": [
        ""
      ],
      "OTP device": [
        ""
      ],
      "Use to verify transactions in offline mode.": [
        ""
      ],
      "No OTP device.": [
        ""
      ],
      "Add one first": [
        ""
      ],
      "No device": [
        ""
      ],
      "Template has been created": [
        ""
      ],
      "Could not create template": [
        ""
      ],
      "Templates": [
        ""
      ],
      "Add new templates": [
        ""
      ],
      "Load more templates before the first one": [
        ""
      ],
      "Delete selected templates from the database": [
        ""
      ],
      "Use template to create new order": [
        ""
      ],
      "Use template": [
        ""
      ],
      "Generate a QR code for the template.": [
        ""
      ],
      "Load more templates after the last one": [
        ""
      ],
      "There are no templates to list yet, add more by pressing the + sign": [
        ""
      ],
      "Jump to template with the given template ID": [
        ""
      ],
      "Template identification": [
        ""
      ],
      "Template \"%1$s\" (ID: %2$s) has been deleted": [
        ""
      ],
      "Failed to delete template": [
        ""
      ],
      "If you delete the template %1$s (ID: %2$s) you may loose information": [
        ""
      ],
      "Deleting an template": [
        ""
      ],
      "can't be undone": [
        ""
      ],
      "Print": [
        ""
      ],
      "If specified, this template will create order with the same summary": [
        ""
      ],
      "If specified, this template will create orders with the same price": [
        ""
      ],
      "How much time has the customer to complete the payment once the order was created.": [
        ""
      ],
      "Template (ID: %1$s) has been updated": [
        ""
      ],
      "An amount is required": [
        ""
      ],
      "An order summary is required": [
        ""
      ],
      "New order from template": [
        ""
      ],
      "Amount of the order": [
        ""
      ],
      "Order summary": [
        ""
      ],
      "Could not create order from template": [
        ""
      ],
      "You need your access token to perform the operation": [
        ""
      ],
      "You are updating the access token from instance with id \"%1$s\"": [
        ""
      ],
      "This instance does not have authentication token.": [
        ""
      ],
      "You can leave it empty if there is another layer of security.": [
        ""
      ],
      "Current access token": [
        ""
      ],
      "Clearing the access token will mean public access to the instance.": [
        ""
      ],
      "Clear token": [
        ""
      ],
      "Confirm change": [
        ""
      ],
      "Failed to clear token": [
        ""
      ],
      "Failed to set new token": [
        ""
      ],
      "Slug": [
        ""
      ],
      "Token family slug to use in URLs (for internal use only)": [
        ""
      ],
      "Kind": [
        ""
      ],
      "Token family kind": [
        ""
      ],
      "User-readable token family name": [
        ""
      ],
      "Token family description for customers": [
        ""
      ],
      "Valid After": [
        ""
      ],
      "Token family can issue tokens after this date": [
        ""
      ],
      "Valid Before": [
        ""
      ],
      "Token family can issue tokens until this date": [
        ""
      ],
      "Duration": [
        ""
      ],
      "Validity duration of a issued token": [
        ""
      ],
      "Token family created successfully.": [
        ""
      ],
      "Could not create token family.": [
        ""
      ],
      "Token Families": [
        ""
      ],
      "Add token family": [
        ""
      ],
      "Go to token family update page": [
        ""
      ],
      "Remove this token family from the database": [
        ""
      ],
      "There are no token families yet, add the first one by pressing the + sign.": [
        ""
      ],
      "Token family updated successfully": [
        ""
      ],
      "Could not update the token family": [
        ""
      ],
      "Token family \"%1$s\" (SLUG: %2$s) has been deleted": [
        ""
      ],
      "Failed to delete token family": [
        ""
      ],
      "If you delete the %1$s token family (Slug: %2$s), all issued tokens will become invalid.": [
        ""
      ],
      "Deleting a token family %1$s .": [
        ""
      ],
      "Token Family: %1$s": [
        ""
      ],
      "Could not update token family": [
        ""
      ],
      "Please check the ID; it does not appear to be valid.": [
        ""
      ],
      "Must have 52 characters, current %1$s": [
        ""
      ],
      "URL doesn't have the right format": [
        ""
      ],
      "Credited bank account": [
        ""
      ],
      "Select an account": [
        ""
      ],
      "Bank account of the merchant where the payment was received": [
        ""
      ],
      "Wire transfer ID": [
        ""
      ],
      "Unique identifier of the wire transfer used by the exchange, must be 52 characters long": [
        ""
      ],
      "Exchange URL": [
        ""
      ],
      "Base URL of the exchange that made the transfer, should have been in the wire transfer subject": [
        ""
      ],
      "Amount credited": [
        ""
      ],
      "Actual amount that was wired to the merchant's bank account": [
        ""
      ],
      "Wire transfer informed successfully": [
        ""
      ],
      "Could not inform transfer": [
        ""
      ],
      "Wire transfers": [
        ""
      ],
      "Add new transfer": [
        ""
      ],
      "Load more wire transfers preceding the first one": [
        ""
      ],
      "Credit": [
        ""
      ],
      "Confirmed": [
        ""
      ],
      "Verified": [
        ""
      ],
      "Executed on": [
        ""
      ],
      "yes": [
        ""
      ],
      "no": [
        ""
      ],
      "never": [
        ""
      ],
      "unknown": [
        ""
      ],
      "Delete selected transfer from the database": [
        ""
      ],
      "Load more transfers after the last one": [
        ""
      ],
      "There are no transfers to list yet, add more by pressing the + sign": [
        ""
      ],
      "All accounts": [
        ""
      ],
      "Filter by account address": [
        ""
      ],
      "Only show wire transfers confirmed by the merchant": [
        ""
      ],
      "Only show wire transfers claimed by the exchange": [
        ""
      ],
      "Unverified": [
        ""
      ],
      "Wire transfer \"%1$s...\" has been deleted": [
        ""
      ],
      "Failed to delete transfer": [
        ""
      ],
      "Must be business or individual": [
        ""
      ],
      "Pay delay can't be greater than wire transfer delay": [
        ""
      ],
      "Max 7 lines": [
        ""
      ],
      "Doesn't match": [
        ""
      ],
      "Enable access control": [
        ""
      ],
      "Choose if the backend server should authenticate access.": [
        ""
      ],
      "Access control is not yet decided. This instance can't be created.": [
        ""
      ],
      "Authorization must be handled externally.": [
        ""
      ],
      "Authorization is handled by the backend server.": [
        ""
      ],
      "Need to complete marked fields and choose authorization method": [
        ""
      ],
      "Name of the instance in URLs. The 'default' instance is special in that it is used to administer other instances.": [
        ""
      ],
      "Business name": [
        ""
      ],
      "Legal name of the business represented by this instance.": [
        ""
      ],
      "Email": [
        ""
      ],
      "Contact email": [
        ""
      ],
      "Website URL": [
        ""
      ],
      "URL.": [
        ""
      ],
      "Logo": [
        ""
      ],
      "Logo image.": [
        ""
      ],
      "Physical location of the merchant.": [
        ""
      ],
      "Jurisdiction": [
        ""
      ],
      "Jurisdiction for legal disputes with the merchant.": [
        ""
      ],
      "Pay transaction fee": [
        ""
      ],
      "Cover the transaction cost or pass it on to the user.": [
        ""
      ],
      "Default payment delay": [
        ""
      ],
      "Time customers have to pay an order before the offer expires by default.": [
        ""
      ],
      "Default wire transfer delay": [
        ""
      ],
      "Maximum time an exchange is allowed to delay wiring funds to the merchant, enabling it to aggregate smaller payments into larger wire transfers and reducing wire fees.": [
        ""
      ],
      "Instance id": [
        ""
      ],
      "Failed to update instance": [
        ""
      ],
      "Must be \"pay\" or \"refund\"": [
        ""
      ],
      "Must be one of '%1$s'": [
        ""
      ],
      "Webhook ID to use": [
        ""
      ],
      "Event": [
        ""
      ],
      "Pay": [
        ""
      ],
      "The event of the webhook: why the webhook is used": [
        ""
      ],
      "Method": [
        ""
      ],
      "GET": [
        ""
      ],
      "POST": [
        ""
      ],
      "PUT": [
        ""
      ],
      "PATCH": [
        ""
      ],
      "HEAD": [
        ""
      ],
      "Method used by the webhook": [
        ""
      ],
      "URL": [
        ""
      ],
      "URL of the webhook where the customer will be redirected": [
        ""
      ],
      "The text below supports the %1$s template engine. Any string between %2$s and %3$s will be replaced with the value of the corresponding variable.": [
        ""
      ],
      "For example %1$s will be replaced with the the order's price": [
        ""
      ],
      "The short list of variables are:": [
        ""
      ],
      "order's description": [
        ""
      ],
      "order's price": [
        ""
      ],
      "order's unique identification": [
        ""
      ],
      "the amount that was being refunded": [
        ""
      ],
      "the reason entered by the merchant staff for granting the refund": [
        ""
      ],
      "time of the refund in nanoseconds since 1970": [
        ""
      ],
      "Http body": [
        ""
      ],
      "Body template by the webhook": [
        ""
      ],
      "Webhook create successfully": [
        ""
      ],
      "Could not create the webhook": [
        ""
      ],
      "Could not create webhook": [
        ""
      ],
      "Webhooks": [
        ""
      ],
      "Add new webhooks": [
        ""
      ],
      "Load more webhooks before the first one": [
        ""
      ],
      "Event type": [
        ""
      ],
      "Delete selected webhook from the database": [
        ""
      ],
      "Load more webhooks after the last one": [
        ""
      ],
      "There are no webhooks to list yet, add more by pressing the + sign": [
        ""
      ],
      "Webhook deleted successfully": [
        ""
      ],
      "Could not delete the webhook": [
        ""
      ],
      "Header": [
        ""
      ],
      "Header template of the webhook": [
        ""
      ],
      "Body": [
        ""
      ],
      "Webhook updated": [
        ""
      ],
      "Could not update webhook": [
        ""
      ],
      "Language": [
        ""
      ],
      "Advance order creation": [
        ""
      ],
      "Shows more options in the order creation form": [
        ""
      ],
      "Advance instance settings": [
        ""
      ],
      "Shows more options in the instance settings form": [
        ""
      ],
      "Date format": [
        ""
      ],
      "How the date is going to be displayed": [
        ""
      ],
      "Developer mode": [
        ""
      ],
      "Shows more options and tools which are not intended for general audience.": [
        ""
      ],
      "Total products": [
        ""
      ],
      "Delete selected category from the database": [
        ""
      ],
      "There are no categories yet, add more pressing the + sign": [
        ""
      ],
      "Category delete successfully": [
        ""
      ],
      "Could not delete the category": [
        ""
      ],
      "Invalid. Please insert only characters and numbers": [
        ""
      ],
      "Category name": [
        ""
      ],
      "Category added successfully": [
        ""
      ],
      "Could not add category": [
        ""
      ],
      "Id:": [
        ""
      ],
      "Name of the category": [
        ""
      ],
      "Products": [
        ""
      ],
      "Search by product description or id": [
        ""
      ],
      "Products that this category will list.": [
        ""
      ],
      "Could not update category": [
        ""
      ],
      "Category ID is unknown": [
        ""
      ],
      "Without this the merchant backend will refuse to create new orders.": [
        ""
      ],
      "Hide for today": [
        ""
      ],
      "KYC verification needed": [
        ""
      ],
      "Some transfers are on hold until the KYC process is completed. Visit the KYC section in the left panel for more information": [
        ""
      ],
      "Configuration": [
        ""
      ],
      "OTP Devices": [
        ""
      ],
      "Settings": [
        ""
      ],
      "Access token": [
        ""
      ],
      "Connection": [
        ""
      ],
      "Interface": [
        ""
      ],
      "List": [
        ""
      ],
      "Log out": [
        ""
      ],
      "Failed to create instance": [
        ""
      ],
      "checking compatibility with server...": [
        ""
      ],
      "Contacting the server failed": [
        ""
      ],
      "The server version is not supported": [
        ""
      ],
      "Supported version \"%1$s\", server version \"%2$s\".": [
        ""
      ],
      "Deleting": [
        ""
      ],
      "Changing": [
        ""
      ],
      "Manage access token": [
        ""
      ],
      "Business Name": [
        ""
      ],
      "Order ID": [
        ""
      ],
      "Payment URL": [
        ""
      ]
    }
  },
  "domain": "messages",
  "plural_forms": "nplurals=2; plural=n != 1;",
  "lang": "sv",
  "completeness": 0
};

strings['it'] = {
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=2; plural=n != 1;",
        "lang": "it"
      },
      "The request reached a timeout, check your connection.": [
        ""
      ],
      "The request was cancelled.": [
        ""
      ],
      "A lot of request were made to the same server and this action was throttled.": [
        ""
      ],
      "The response of the request is malformed.": [
        ""
      ],
      "Could not complete the request due to a network problem.": [
        ""
      ],
      "Unexpected request error.": [
        ""
      ],
      "Unexpected error.": [
        ""
      ],
      "Cancel": [
        ""
      ],
      "%1$s": [
        ""
      ],
      "Close": [
        ""
      ],
      "Continue": [
        ""
      ],
      "Clear": [
        ""
      ],
      "Confirm": [
        "Confermare"
      ],
      "Required": [
        "Obbligatorio"
      ],
      "Letter must be a JSON string": [
        ""
      ],
      "JSON string is invalid": [
        ""
      ],
      "Import": [
        ""
      ],
      "Importing an account from the bank": [
        ""
      ],
      "You can export your account settings from the Libeufin Bank's account profile. Paste the content in the next field.": [
        ""
      ],
      "Account information": [
        ""
      ],
      "Correct form": [
        ""
      ],
      "Comparing account details": [
        ""
      ],
      "Testing against the account info URL succeeded but the account information reported is different with the account details form.": [
        ""
      ],
      "Field": [
        ""
      ],
      "In the form": [
        ""
      ],
      "Reported": [
        ""
      ],
      "Type": [
        ""
      ],
      "IBAN": [
        ""
      ],
      "Address": [
        ""
      ],
      "Host": [
        ""
      ],
      "Account id": [
        "Importo"
      ],
      "Owner's name": [
        ""
      ],
      "Account": [
        ""
      ],
      "Bank host": [
        ""
      ],
      "Bank account": [
        ""
      ],
      "BIC": [
        ""
      ],
      "Ok": [
        ""
      ],
      "Validate bank account: %1$s": [
        ""
      ],
      "You need to make a bank transfer with the specified subject to validate that you are the owner of the account.": [
        ""
      ],
      "Step 1:": [
        ""
      ],
      "Copy this string and paste it into the field 'Subject' (resp. 'Purpose') in your banking app or bank website": [
        ""
      ],
      "Subject": [
        "Soggetto"
      ],
      "Step 2:": [
        ""
      ],
      "Copy and paste this IBAN and the name into the receiver fields in your banking app or website": [
        ""
      ],
      "Receiver name": [
        ""
      ],
      "Step 3:": [
        ""
      ],
      "Select the smallest possible amount for the wire transfer in your banking app or on your online banking website.": [
        ""
      ],
      "Make sure ALL data is correct, including the subject and you are using your selected bank account. You can use the copy buttons (%1$s) to prevent typing errors or the \"payto://\" URI below to copy just one value.": [
        ""
      ],
      "Alternatively, if your bank already supports the standard 'PayTo URI', you can use this %1$s link instead": [
        ""
      ],
      "If you delete the instance named %1$s (ID: %2$s), the merchant will no longer be able to process orders or refunds": [
        ""
      ],
      "This action deletes the instance private key, but preserves all transaction data. You can still access that data after deleting the instance.": [
        ""
      ],
      "Deleting an instance %1$s .": [
        ""
      ],
      "If you purge the instance named %1$s (ID: %2$s), you will also delete all it&apos;s transaction data.": [
        ""
      ],
      "The instance will disappear from your list, and you will no longer be able to access it&apos;s data.": [
        ""
      ],
      "Purging an instance %1$s .": [
        ""
      ],
      "Is not the same as the current access token": [
        ""
      ],
      "Can't be the same as the old token": [
        ""
      ],
      "Is not the same": [
        ""
      ],
      "You are updating the access token for the instance with ID %1$s": [
        ""
      ],
      "Old access token": [
        ""
      ],
      "Access token currently in use": [
        ""
      ],
      "New access token": [
        ""
      ],
      "Next access token to be used": [
        ""
      ],
      "Repeat access token": [
        ""
      ],
      "Confirm the same access token": [
        ""
      ],
      "Clearing the access token will mean public access to the instance": [
        ""
      ],
      "Can't be the same as the old access token": [
        ""
      ],
      "You are setting the access token for the new instance": [
        ""
      ],
      "With external authorization method no check will be done by the merchant backend": [
        ""
      ],
      "Set external authorization": [
        ""
      ],
      "Set access token": [
        ""
      ],
      "Operation in progress...": [
        ""
      ],
      "The operation will be automatically canceled after %1$s seconds": [
        ""
      ],
      "Your password is incorrect": [
        ""
      ],
      "Your instance cannot be found": [
        ""
      ],
      "Login required": [
        ""
      ],
      "Please enter your access token for %1$s.": [
        ""
      ],
      "Access Token": [
        ""
      ],
      "Instances": [
        ""
      ],
      "Delete": [
        ""
      ],
      "Add new instance": [
        ""
      ],
      "ID": [
        ""
      ],
      "Name": [
        ""
      ],
      "Edit": [
        ""
      ],
      "Purge": [
        ""
      ],
      "There are no instances yet, add more pressing the + sign": [
        ""
      ],
      "Only show active instances": [
        ""
      ],
      "Active": [
        ""
      ],
      "Only show deleted instances": [
        ""
      ],
      "Deleted": [
        ""
      ],
      "Show all instances": [
        ""
      ],
      "All": [
        ""
      ],
      "Instance \"%1$s\" (ID: %2$s) has been deleted": [
        ""
      ],
      "Failed to delete instance": [
        ""
      ],
      "Instance '%1$s' (ID: %2$s) has been purged": [
        ""
      ],
      "Failed to purge instance": [
        ""
      ],
      "Loading...": [
        ""
      ],
      "This is not a valid bitcoin address.": [
        ""
      ],
      "This is not a valid Ethereum address.": [
        ""
      ],
      "This is not a valid host.": [
        ""
      ],
      "IBAN numbers usually have more that 4 digits": [
        ""
      ],
      "IBAN numbers usually have less that 34 digits": [
        ""
      ],
      "IBAN country code not found": [
        ""
      ],
      "IBAN number is invalid, checksum is wrong": [
        ""
      ],
      "Choose one...": [
        ""
      ],
      "Method to use for wire transfer": [
        ""
      ],
      "Routing": [
        ""
      ],
      "Routing number.": [
        ""
      ],
      "Account number.": [
        ""
      ],
      "Code": [
        ""
      ],
      "Business Identifier Code.": [
        ""
      ],
      "International Bank Account Number.": [
        ""
      ],
      "Unified Payment Interface.": [
        ""
      ],
      "Bitcoin protocol.": [
        ""
      ],
      "Ethereum protocol.": [
        ""
      ],
      "Interledger protocol.": [
        ""
      ],
      "Bank host.": [
        ""
      ],
      "Without scheme and may include subpath:": [
        ""
      ],
      "Bank account.": [
        ""
      ],
      "Legal name of the person holding the account.": [
        ""
      ],
      "It should match the bank account name.": [
        ""
      ],
      "Invalid url": [
        ""
      ],
      "URL must end with a '/'": [
        ""
      ],
      "URL must not contain params": [
        ""
      ],
      "URL must not hash param": [
        ""
      ],
      "The request to check the revenue API failed.": [
        ""
      ],
      "Server replied with \"bad request\".": [
        ""
      ],
      "Unauthorized, check credentials.": [
        ""
      ],
      "The endpoint does not seem to be a Taler Revenue API.": [
        ""
      ],
      "The request was made correctly, but the bank's server did not respond with the appropriate value for 'credit_account', so we cannot confirm that it is the same bank account.": [
        ""
      ],
      "Account:": [
        "Conto:"
      ],
      "If the bank supports Taler Revenue API then you can add the endpoint URL below to keep the revenue information in sync.": [
        ""
      ],
      "Endpoint URL": [
        ""
      ],
      "From where the merchant can download information about incoming wire transfers to this account": [
        ""
      ],
      "Auth type": [
        ""
      ],
      "Choose the authentication type for the account info URL": [
        ""
      ],
      "Without authentication": [
        ""
      ],
      "With password": [
        ""
      ],
      "With token": [
        ""
      ],
      "Do not change": [
        ""
      ],
      "Username": [
        ""
      ],
      "Username to access the account information.": [
        ""
      ],
      "Password": [
        ""
      ],
      "Password to access the account information.": [
        ""
      ],
      "Token": [
        ""
      ],
      "Access token to access the account information.": [
        ""
      ],
      "Match": [
        ""
      ],
      "Check where the information match against the server info.": [
        ""
      ],
      "Not verified": [
        ""
      ],
      "Last test was ok": [
        ""
      ],
      "Last test failed": [
        ""
      ],
      "Compare info from server with account form": [
        ""
      ],
      "Test": [
        ""
      ],
      "Need to complete marked fields": [
        ""
      ],
      "Confirm operation": [
        ""
      ],
      "Account details": [
        ""
      ],
      "Import from bank": [
        ""
      ],
      "Could not create account": [
        ""
      ],
      "No 'default' instance configured yet.": [
        ""
      ],
      "Create a 'default' instance to begin using the merchant backoffice.": [
        ""
      ],
      "Bank accounts": [
        ""
      ],
      "Add new account": [
        ""
      ],
      "Wire method: Bitcoin": [
        ""
      ],
      "SegWit 1": [
        ""
      ],
      "SegWit 2": [
        ""
      ],
      "Delete selected accounts from the database": [
        ""
      ],
      "Wire method: x-taler-bank": [
        ""
      ],
      "Account name": [
        ""
      ],
      "Wire method: IBAN": [
        ""
      ],
      "Other accounts": [
        ""
      ],
      "Path": [
        ""
      ],
      "There are no accounts yet, add more pressing the + sign": [
        ""
      ],
      "You need to associate a bank account to receive revenue.": [
        ""
      ],
      "Without this the you won't be able to create new orders.": [
        ""
      ],
      "The bank account has been successfully deleted.": [
        ""
      ],
      "Could not delete the bank account": [
        ""
      ],
      "Could not update account": [
        ""
      ],
      "Could not delete account": [
        ""
      ],
      "Pending KYC verification": [
        ""
      ],
      "Exchange": [
        ""
      ],
      "Reason": [
        ""
      ],
      "Pending KYC process, click here to complete": [
        ""
      ],
      "The Payment Service Provider requires an account verification.": [
        ""
      ],
      "Http Status": [
        ""
      ],
      "No pending kyc verification!": [
        ""
      ],
      "Change value to unknown date": [
        ""
      ],
      "Change value to empty": [
        ""
      ],
      "Change value to never": [
        ""
      ],
      "Never": [
        ""
      ],
      "days": [
        ""
      ],
      "hours": [
        ""
      ],
      "minutes": [
        ""
      ],
      "seconds": [
        ""
      ],
      "Forever": [
        ""
      ],
      "%1$sM": [
        ""
      ],
      "%1$sY": [
        ""
      ],
      "%1$sd": [
        ""
      ],
      "%1$sh": [
        ""
      ],
      "%1$smin": [
        ""
      ],
      "%1$ssec": [
        ""
      ],
      "Country": [
        ""
      ],
      "Building number": [
        ""
      ],
      "Building name": [
        ""
      ],
      "Street": [
        ""
      ],
      "Post code": [
        ""
      ],
      "Town location": [
        ""
      ],
      "Town": [
        ""
      ],
      "District": [
        ""
      ],
      "Country subdivision": [
        ""
      ],
      "Description": [
        ""
      ],
      "Enter description or id": [
        ""
      ],
      "no match found with that description or id": [
        ""
      ],
      "You must enter a valid product identifier.": [
        ""
      ],
      "Quantity must be greater than 0!": [
        ""
      ],
      "This quantity exceeds remaining stock. Currently, only %1$s units remain unreserved in stock.": [
        ""
      ],
      "Search product": [
        ""
      ],
      "Quantity": [
        ""
      ],
      "How many products will be added": [
        ""
      ],
      "Add from inventory": [
        ""
      ],
      "Image must be smaller than 1 MB": [
        ""
      ],
      "Add": [
        ""
      ],
      "Remove": [
        ""
      ],
      "Invalid": [
        ""
      ],
      "This product has %1$s applicable taxes configured.": [
        ""
      ],
      "No taxes configured for this product.": [
        ""
      ],
      "Amount": [
        "Importo"
      ],
      "Taxes can be in currencies that differ from the main currency used by the merchant.": [
        ""
      ],
      "Enter currency and value separated with a colon, e.g. &quot;USD:2.3&quot;.": [
        ""
      ],
      "Legal name of the tax, e.g. VAT or import duties.": [
        ""
      ],
      "Add tax to the tax list": [
        ""
      ],
      "Describe and add a product that is not in the inventory list": [
        ""
      ],
      "Add custom product": [
        ""
      ],
      "Complete information of the product": [
        ""
      ],
      "Must be a number": [
        ""
      ],
      "Must be grater than 0": [
        ""
      ],
      "Image": [
        ""
      ],
      "Photo of the product.": [
        ""
      ],
      "Full product description.": [
        ""
      ],
      "Unit": [
        ""
      ],
      "Name of the product unit.": [
        ""
      ],
      "Price": [
        ""
      ],
      "Amount in the current currency.": [
        ""
      ],
      "How many products will be added.": [
        ""
      ],
      "Taxes": [
        ""
      ],
      "Unit price": [
        ""
      ],
      "Total price": [
        ""
      ],
      "Must be greater than 0": [
        ""
      ],
      "Refund deadline can't be before pay deadline": [
        ""
      ],
      "Wire transfer deadline can't be before refund deadline": [
        ""
      ],
      "Wire transfer deadline can't be before pay deadline": [
        ""
      ],
      "Must have a refund deadline": [
        ""
      ],
      "Auto refund can't be after refund deadline": [
        ""
      ],
      "Must be in the future": [
        ""
      ],
      "Simple": [
        ""
      ],
      "Advanced": [
        ""
      ],
      "Manage products in order": [
        ""
      ],
      "%1$s products with a total price of %2$s.": [
        ""
      ],
      "Manage list of products in the order.": [
        ""
      ],
      "Remove this product from the order.": [
        ""
      ],
      "Total product price added up": [
        ""
      ],
      "Amount to be paid by the customer": [
        ""
      ],
      "Order price": [
        ""
      ],
      "Final order price": [
        ""
      ],
      "Summary": [
        ""
      ],
      "Title of the order to be shown to the customer": [
        ""
      ],
      "Shipping and fulfillment": [
        ""
      ],
      "Delivery date": [
        ""
      ],
      "Deadline for physical delivery assured by the merchant.": [
        ""
      ],
      "Location": [
        ""
      ],
      "Address where the products will be delivered": [
        ""
      ],
      "Fulfillment URL": [
        ""
      ],
      "URL to which the user will be redirected after successful payment.": [
        ""
      ],
      "Taler payment options": [
        ""
      ],
      "Override default Taler payment settings for this order": [
        ""
      ],
      "Payment time": [
        ""
      ],
      "Time for the customer to pay for the offer before it expires. Inventory products will be reserved until this deadline. Time start to run after the order is created.": [
        ""
      ],
      "Default": [
        ""
      ],
      "Refund time": [
        "Rimborsato"
      ],
      "Time while the order can be refunded by the merchant. Time starts after the order is created.": [
        ""
      ],
      "Wire transfer time": [
        ""
      ],
      "Time for the exchange to make the wire transfer. Time starts after the order is created.": [
        ""
      ],
      "Auto-refund time": [
        ""
      ],
      "Time until which the wallet will automatically check for refunds without user interaction.": [
        ""
      ],
      "Maximum fee": [
        ""
      ],
      "Maximum fees the merchant is willing to cover for this order. Higher deposit fees must be covered in full by the consumer.": [
        ""
      ],
      "Create token": [
        ""
      ],
      "If the order ID is easy to guess the token will prevent users to steal orders from others.": [
        ""
      ],
      "Minimum age required": [
        ""
      ],
      "Any value greater than 0 will limit the coins able be used to pay this contract. If empty the age restriction will be defined by the products": [
        ""
      ],
      "Min age defined by the producs is %1$s": [
        ""
      ],
      "No product with age restriction in this order": [
        ""
      ],
      "Additional information": [
        ""
      ],
      "Custom information to be included in the contract for this order.": [
        ""
      ],
      "You must enter a value in JavaScript Object Notation (JSON).": [
        ""
      ],
      "Custom field name": [
        ""
      ],
      "Disabled": [
        ""
      ],
      "No deadline": [
        ""
      ],
      "Deadline at %1$s": [
        ""
      ],
      "Could not create order": [
        ""
      ],
      "No exchange would accept a payment because of KYC requirements.": [
        ""
      ],
      "No more stock for product with ID \"%1$s\".": [
        ""
      ],
      "Orders": [
        ""
      ],
      "Create order": [
        ""
      ],
      "Load first page": [
        ""
      ],
      "Date": [
        "Data"
      ],
      "Refund": [
        ""
      ],
      "copy url": [
        ""
      ],
      "Load more orders after the last one": [
        ""
      ],
      "Load next page": [
        ""
      ],
      "No orders have been found matching your query!": [
        ""
      ],
      "Duplicated": [
        ""
      ],
      "This value exceed the refundable amount": [
        ""
      ],
      "Amount to be refunded": [
        ""
      ],
      "Max refundable:": [
        ""
      ],
      "Requested by the customer": [
        ""
      ],
      "Other": [
        ""
      ],
      "Why this order is being refunded": [
        ""
      ],
      "More information to give context": [
        ""
      ],
      "Contract terms": [
        ""
      ],
      "Human-readable description of the whole purchase": [
        ""
      ],
      "Total price for the transaction": [
        ""
      ],
      "URL for this purchase": [
        ""
      ],
      "Max fee": [
        ""
      ],
      "Maximum total deposit fee accepted by the merchant for this contract": [
        ""
      ],
      "Created at": [
        ""
      ],
      "Time when this contract was generated": [
        ""
      ],
      "Refund deadline": [
        ""
      ],
      "After this deadline has passed no refunds will be accepted": [
        ""
      ],
      "Payment deadline": [
        ""
      ],
      "After this deadline, the merchant won't accept payments for the contract": [
        ""
      ],
      "Wire transfer deadline": [
        ""
      ],
      "Transfer deadline for the exchange": [
        ""
      ],
      "Time indicating when the order should be delivered": [
        ""
      ],
      "Where the order will be delivered": [
        ""
      ],
      "Auto-refund delay": [
        ""
      ],
      "How long the wallet should try to get an automatic refund for the purchase": [
        ""
      ],
      "Extra info": [
        ""
      ],
      "Extra data that is only interpreted by the merchant frontend": [
        ""
      ],
      "Order": [
        ""
      ],
      "Claimed": [
        ""
      ],
      "Claimed at": [
        ""
      ],
      "Timeline": [
        ""
      ],
      "Payment details": [
        ""
      ],
      "Order status": [
        ""
      ],
      "Product list": [
        ""
      ],
      "Paid": [
        ""
      ],
      "Wired": [
        ""
      ],
      "Refunded": [
        "Rimborsato"
      ],
      "Refund order": [
        "Rimborsato"
      ],
      "Not refundable": [
        ""
      ],
      "Next event in": [
        ""
      ],
      "Refunded amount": [
        ""
      ],
      "Refund taken": [
        ""
      ],
      "Status URL": [
        ""
      ],
      "Refund URI": [
        ""
      ],
      "Unpaid": [
        ""
      ],
      "Pay at": [
        ""
      ],
      "Order status URL": [
        ""
      ],
      "Payment URI": [
        ""
      ],
      "Unknown order status. This is an error, please contact the administrator.": [
        ""
      ],
      "Back": [
        "Indietro"
      ],
      "Refund created successfully": [
        ""
      ],
      "Could not create the refund": [
        ""
      ],
      "There are pending KYC requirements.": [
        ""
      ],
      "Missing ID": [
        ""
      ],
      "Not found": [
        ""
      ],
      "Select date to show nearby orders": [
        ""
      ],
      "Only show paid orders": [
        ""
      ],
      "New": [
        ""
      ],
      "Only show orders with refunds": [
        ""
      ],
      "Only display orders that have already been paid for by buyers and for which the wire transfer by the payment service provider is still pending": [
        ""
      ],
      "Not wired": [
        ""
      ],
      "Only display orders that have already been transferred by the payment service provider": [
        ""
      ],
      "Completed": [
        ""
      ],
      "Remove all filters": [
        ""
      ],
      "Clear date filter": [
        ""
      ],
      "Jump to date (%1$s)": [
        ""
      ],
      "Jump to order with the given product ID": [
        ""
      ],
      "Order id": [
        ""
      ],
      "Invalid. Please enter characters and numbers only": [
        ""
      ],
      "Just letters and numbers from 2 to 7": [
        ""
      ],
      "The size of the key must be 32 characters": [
        ""
      ],
      "Internal ID on the system": [
        ""
      ],
      "Useful to identify the device physically": [
        ""
      ],
      "Verification algorithm": [
        ""
      ],
      "Algorithm used to verify transactions in offline mode": [
        ""
      ],
      "Device key": [
        ""
      ],
      "Be sure to choose a password hard to guess, alternatively use the random generator": [
        ""
      ],
      "Your device needs to match exactly the same value": [
        ""
      ],
      "Generate random secret key": [
        ""
      ],
      "Random": [
        ""
      ],
      "You can scan the next QR code with your device or save the key before continuing.": [
        ""
      ],
      "Device added successfully": [
        ""
      ],
      "Could not add device": [
        ""
      ],
      "OTP devices": [
        ""
      ],
      "Add new devices": [
        ""
      ],
      "Load more devices before the first one": [
        ""
      ],
      "Delete selected devices from the database": [
        ""
      ],
      "Load more devices after the last one": [
        ""
      ],
      "There are no devices to list yet, add more by pressing the + sign": [
        ""
      ],
      "The device was successfully deleted": [
        ""
      ],
      "Could not delete the device": [
        ""
      ],
      "Device:": [
        ""
      ],
      "Algorithm to use to verify transactions in offline mode": [
        ""
      ],
      "Not modified": [
        ""
      ],
      "Be sure to be very hard to guess or use the random generator": [
        ""
      ],
      "Your device need to have exactly the same value": [
        ""
      ],
      "Change key": [
        ""
      ],
      "Could not update template": [
        ""
      ],
      "Template id is unknown": [
        ""
      ],
      "The provided information is inconsistent with the current state of the template": [
        ""
      ],
      "Click here to configure the stock of the product, leave it as is and the backend will not control stock.": [
        ""
      ],
      "Manage stock": [
        ""
      ],
      "This product has been configured without stock control": [
        ""
      ],
      "Infinite": [
        ""
      ],
      "Shrinkage cannot exceed the current stock and incoming supplies (maximum %1$s)": [
        ""
      ],
      "Incoming": [
        ""
      ],
      "Lost": [
        ""
      ],
      "Current": [
        ""
      ],
      "Remove stock control for this product": [
        ""
      ],
      "without stock": [
        ""
      ],
      "Next restock": [
        ""
      ],
      "Warehouse address": [
        ""
      ],
      "Add element to the list": [
        ""
      ],
      "Invalid amount": [
        ""
      ],
      "Product identification to use in URLs (for internal use only).": [
        ""
      ],
      "Illustration of the product for customers.": [
        ""
      ],
      "Product description for customers.": [
        ""
      ],
      "Age restriction": [
        ""
      ],
      "Is this product restricted for customer below certain age?": [
        ""
      ],
      "Minimum age of the customer": [
        ""
      ],
      "Unit name": [
        ""
      ],
      "Unit describing quantity of product sold (e.g. 2 kilograms, 5 liters, 3 items, 5 meters) for customers.": [
        ""
      ],
      "Example: kg, items or liters": [
        ""
      ],
      "Price per unit": [
        ""
      ],
      "Sale price for customers, including taxes, for above units of the product.": [
        ""
      ],
      "Stock": [
        ""
      ],
      "Inventory for products with finite supply (for internal use only).": [
        ""
      ],
      "Taxes included in the product price, exposed to customers.": [
        ""
      ],
      "Categories": [
        ""
      ],
      "Search by category description or id": [
        ""
      ],
      "Categories where this product will be listed on.": [
        ""
      ],
      "Product created successfully": [
        ""
      ],
      "Could not create product": [
        ""
      ],
      "Inventory": [
        ""
      ],
      "Add product to inventory": [
        ""
      ],
      "Sales": [
        ""
      ],
      "Sold": [
        ""
      ],
      "Free": [
        ""
      ],
      "Go to product update page": [
        ""
      ],
      "Update": [
        ""
      ],
      "Remove this product from the database": [
        ""
      ],
      "Load more products after the last one": [
        ""
      ],
      "Update the product with new price": [
        ""
      ],
      "Update product with new price": [
        ""
      ],
      "Confirm update": [
        ""
      ],
      "lost can't be greater that current + incoming (max %1$s)": [
        ""
      ],
      "Add more elements to the inventory": [
        ""
      ],
      "Report elements lost in the inventory": [
        ""
      ],
      "New price for the product": [
        ""
      ],
      "The are value with errors": [
        ""
      ],
      "Update product with new stock and price": [
        ""
      ],
      "There are no products to list yet, add more by pressing the + sign": [
        ""
      ],
      "Jump to product with the given product ID": [
        ""
      ],
      "Product id": [
        ""
      ],
      "Product updated successfully": [
        ""
      ],
      "Could not update the product": [
        ""
      ],
      "Product \"%1$s\" (ID: %2$s) has been deleted": [
        ""
      ],
      "Could not delete the product": [
        ""
      ],
      "If you delete the product named %1$s (ID: %2$s ), the stock and related information will be lost": [
        ""
      ],
      "Deleting an product can't be undone.": [
        ""
      ],
      "Product id:": [
        ""
      ],
      "Product (ID: %1$s) has been updated": [
        ""
      ],
      "Could not update product": [
        ""
      ],
      "Must be greater that 0": [
        ""
      ],
      "Too short": [
        ""
      ],
      "Identifier": [
        ""
      ],
      "Name of the template in URLs.": [
        ""
      ],
      "Describe what this template stands for": [
        ""
      ],
      "If specified here, this template will create orders with the same summary": [
        ""
      ],
      "Summary is editable": [
        ""
      ],
      "Allow the user to change the summary.": [
        ""
      ],
      "If specified here, this template will create orders with the same price": [
        ""
      ],
      "Amount is editable": [
        ""
      ],
      "Allow the user to select the amount to pay.": [
        ""
      ],
      "Currency is editable": [
        ""
      ],
      "Allow the user to change currency.": [
        ""
      ],
      "Supported currencies": [
        ""
      ],
      "Supported currencies: %1$s": [
        ""
      ],
      "Minimum age": [
        ""
      ],
      "Is this contract restricted to some age?": [
        ""
      ],
      "Payment timeout": [
        ""
      ],
      "How much time the customer has to complete the payment once the order was created.": [
        ""
      ],
      "OTP device": [
        ""
      ],
      "Use to verify transactions in offline mode.": [
        ""
      ],
      "No OTP device.": [
        ""
      ],
      "Add one first": [
        ""
      ],
      "No device": [
        ""
      ],
      "Template has been created": [
        ""
      ],
      "Could not create template": [
        ""
      ],
      "Templates": [
        ""
      ],
      "Add new templates": [
        ""
      ],
      "Load more templates before the first one": [
        ""
      ],
      "Delete selected templates from the database": [
        ""
      ],
      "Use template to create new order": [
        ""
      ],
      "Use template": [
        ""
      ],
      "Generate a QR code for the template.": [
        ""
      ],
      "Load more templates after the last one": [
        ""
      ],
      "There are no templates to list yet, add more by pressing the + sign": [
        ""
      ],
      "Jump to template with the given template ID": [
        ""
      ],
      "Template identification": [
        ""
      ],
      "Template \"%1$s\" (ID: %2$s) has been deleted": [
        ""
      ],
      "Failed to delete template": [
        ""
      ],
      "If you delete the template %1$s (ID: %2$s) you may loose information": [
        ""
      ],
      "Deleting an template": [
        ""
      ],
      "can't be undone": [
        ""
      ],
      "Print": [
        ""
      ],
      "If specified, this template will create order with the same summary": [
        ""
      ],
      "If specified, this template will create orders with the same price": [
        ""
      ],
      "How much time has the customer to complete the payment once the order was created.": [
        ""
      ],
      "Template (ID: %1$s) has been updated": [
        ""
      ],
      "An amount is required": [
        ""
      ],
      "An order summary is required": [
        ""
      ],
      "New order from template": [
        ""
      ],
      "Amount of the order": [
        ""
      ],
      "Order summary": [
        ""
      ],
      "Could not create order from template": [
        ""
      ],
      "You need your access token to perform the operation": [
        ""
      ],
      "You are updating the access token from instance with id \"%1$s\"": [
        ""
      ],
      "This instance does not have authentication token.": [
        ""
      ],
      "You can leave it empty if there is another layer of security.": [
        ""
      ],
      "Current access token": [
        ""
      ],
      "Clearing the access token will mean public access to the instance.": [
        ""
      ],
      "Clear token": [
        ""
      ],
      "Confirm change": [
        ""
      ],
      "Failed to clear token": [
        ""
      ],
      "Failed to set new token": [
        ""
      ],
      "Slug": [
        ""
      ],
      "Token family slug to use in URLs (for internal use only)": [
        ""
      ],
      "Kind": [
        ""
      ],
      "Token family kind": [
        ""
      ],
      "User-readable token family name": [
        ""
      ],
      "Token family description for customers": [
        ""
      ],
      "Valid After": [
        ""
      ],
      "Token family can issue tokens after this date": [
        ""
      ],
      "Valid Before": [
        ""
      ],
      "Token family can issue tokens until this date": [
        ""
      ],
      "Duration": [
        ""
      ],
      "Validity duration of a issued token": [
        ""
      ],
      "Token family created successfully.": [
        ""
      ],
      "Could not create token family.": [
        ""
      ],
      "Token Families": [
        ""
      ],
      "Add token family": [
        ""
      ],
      "Go to token family update page": [
        ""
      ],
      "Remove this token family from the database": [
        ""
      ],
      "There are no token families yet, add the first one by pressing the + sign.": [
        ""
      ],
      "Token family updated successfully": [
        ""
      ],
      "Could not update the token family": [
        ""
      ],
      "Token family \"%1$s\" (SLUG: %2$s) has been deleted": [
        ""
      ],
      "Failed to delete token family": [
        ""
      ],
      "If you delete the %1$s token family (Slug: %2$s), all issued tokens will become invalid.": [
        ""
      ],
      "Deleting a token family %1$s .": [
        ""
      ],
      "Token Family: %1$s": [
        ""
      ],
      "Could not update token family": [
        ""
      ],
      "Please check the ID; it does not appear to be valid.": [
        ""
      ],
      "Must have 52 characters, current %1$s": [
        ""
      ],
      "URL doesn't have the right format": [
        ""
      ],
      "Credited bank account": [
        ""
      ],
      "Select an account": [
        ""
      ],
      "Bank account of the merchant where the payment was received": [
        ""
      ],
      "Wire transfer ID": [
        ""
      ],
      "Unique identifier of the wire transfer used by the exchange, must be 52 characters long": [
        ""
      ],
      "Exchange URL": [
        ""
      ],
      "Base URL of the exchange that made the transfer, should have been in the wire transfer subject": [
        ""
      ],
      "Amount credited": [
        ""
      ],
      "Actual amount that was wired to the merchant's bank account": [
        ""
      ],
      "Wire transfer informed successfully": [
        ""
      ],
      "Could not inform transfer": [
        ""
      ],
      "Wire transfers": [
        ""
      ],
      "Add new transfer": [
        ""
      ],
      "Load more wire transfers preceding the first one": [
        ""
      ],
      "Credit": [
        ""
      ],
      "Confirmed": [
        ""
      ],
      "Verified": [
        ""
      ],
      "Executed on": [
        ""
      ],
      "yes": [
        ""
      ],
      "no": [
        ""
      ],
      "never": [
        ""
      ],
      "unknown": [
        ""
      ],
      "Delete selected transfer from the database": [
        ""
      ],
      "Load more transfers after the last one": [
        ""
      ],
      "There are no transfers to list yet, add more by pressing the + sign": [
        ""
      ],
      "All accounts": [
        ""
      ],
      "Filter by account address": [
        ""
      ],
      "Only show wire transfers confirmed by the merchant": [
        ""
      ],
      "Only show wire transfers claimed by the exchange": [
        ""
      ],
      "Unverified": [
        ""
      ],
      "Wire transfer \"%1$s...\" has been deleted": [
        ""
      ],
      "Failed to delete transfer": [
        ""
      ],
      "Must be business or individual": [
        ""
      ],
      "Pay delay can't be greater than wire transfer delay": [
        ""
      ],
      "Max 7 lines": [
        ""
      ],
      "Doesn't match": [
        ""
      ],
      "Enable access control": [
        ""
      ],
      "Choose if the backend server should authenticate access.": [
        ""
      ],
      "Access control is not yet decided. This instance can't be created.": [
        ""
      ],
      "Authorization must be handled externally.": [
        ""
      ],
      "Authorization is handled by the backend server.": [
        ""
      ],
      "Need to complete marked fields and choose authorization method": [
        ""
      ],
      "Name of the instance in URLs. The 'default' instance is special in that it is used to administer other instances.": [
        ""
      ],
      "Business name": [
        ""
      ],
      "Legal name of the business represented by this instance.": [
        ""
      ],
      "Email": [
        ""
      ],
      "Contact email": [
        ""
      ],
      "Website URL": [
        ""
      ],
      "URL.": [
        ""
      ],
      "Logo": [
        ""
      ],
      "Logo image.": [
        ""
      ],
      "Physical location of the merchant.": [
        ""
      ],
      "Jurisdiction": [
        ""
      ],
      "Jurisdiction for legal disputes with the merchant.": [
        ""
      ],
      "Pay transaction fee": [
        ""
      ],
      "Cover the transaction cost or pass it on to the user.": [
        ""
      ],
      "Default payment delay": [
        ""
      ],
      "Time customers have to pay an order before the offer expires by default.": [
        ""
      ],
      "Default wire transfer delay": [
        ""
      ],
      "Maximum time an exchange is allowed to delay wiring funds to the merchant, enabling it to aggregate smaller payments into larger wire transfers and reducing wire fees.": [
        ""
      ],
      "Instance id": [
        ""
      ],
      "Failed to update instance": [
        ""
      ],
      "Must be \"pay\" or \"refund\"": [
        ""
      ],
      "Must be one of '%1$s'": [
        ""
      ],
      "Webhook ID to use": [
        ""
      ],
      "Event": [
        ""
      ],
      "Pay": [
        ""
      ],
      "The event of the webhook: why the webhook is used": [
        ""
      ],
      "Method": [
        ""
      ],
      "GET": [
        ""
      ],
      "POST": [
        ""
      ],
      "PUT": [
        ""
      ],
      "PATCH": [
        ""
      ],
      "HEAD": [
        ""
      ],
      "Method used by the webhook": [
        ""
      ],
      "URL": [
        ""
      ],
      "URL of the webhook where the customer will be redirected": [
        ""
      ],
      "The text below supports the %1$s template engine. Any string between %2$s and %3$s will be replaced with the value of the corresponding variable.": [
        ""
      ],
      "For example %1$s will be replaced with the the order's price": [
        ""
      ],
      "The short list of variables are:": [
        ""
      ],
      "order's description": [
        ""
      ],
      "order's price": [
        ""
      ],
      "order's unique identification": [
        ""
      ],
      "the amount that was being refunded": [
        ""
      ],
      "the reason entered by the merchant staff for granting the refund": [
        ""
      ],
      "time of the refund in nanoseconds since 1970": [
        ""
      ],
      "Http body": [
        ""
      ],
      "Body template by the webhook": [
        ""
      ],
      "Webhook create successfully": [
        ""
      ],
      "Could not create the webhook": [
        ""
      ],
      "Could not create webhook": [
        ""
      ],
      "Webhooks": [
        ""
      ],
      "Add new webhooks": [
        ""
      ],
      "Load more webhooks before the first one": [
        ""
      ],
      "Event type": [
        ""
      ],
      "Delete selected webhook from the database": [
        ""
      ],
      "Load more webhooks after the last one": [
        ""
      ],
      "There are no webhooks to list yet, add more by pressing the + sign": [
        ""
      ],
      "Webhook deleted successfully": [
        ""
      ],
      "Could not delete the webhook": [
        ""
      ],
      "Header": [
        ""
      ],
      "Header template of the webhook": [
        ""
      ],
      "Body": [
        ""
      ],
      "Webhook updated": [
        ""
      ],
      "Could not update webhook": [
        ""
      ],
      "Language": [
        ""
      ],
      "Advance order creation": [
        ""
      ],
      "Shows more options in the order creation form": [
        ""
      ],
      "Advance instance settings": [
        ""
      ],
      "Shows more options in the instance settings form": [
        ""
      ],
      "Date format": [
        ""
      ],
      "How the date is going to be displayed": [
        ""
      ],
      "Developer mode": [
        ""
      ],
      "Shows more options and tools which are not intended for general audience.": [
        ""
      ],
      "Total products": [
        ""
      ],
      "Delete selected category from the database": [
        ""
      ],
      "There are no categories yet, add more pressing the + sign": [
        ""
      ],
      "Category delete successfully": [
        ""
      ],
      "Could not delete the category": [
        ""
      ],
      "Invalid. Please insert only characters and numbers": [
        ""
      ],
      "Category name": [
        ""
      ],
      "Category added successfully": [
        ""
      ],
      "Could not add category": [
        ""
      ],
      "Id:": [
        ""
      ],
      "Name of the category": [
        ""
      ],
      "Products": [
        ""
      ],
      "Search by product description or id": [
        ""
      ],
      "Products that this category will list.": [
        ""
      ],
      "Could not update category": [
        ""
      ],
      "Category ID is unknown": [
        ""
      ],
      "Without this the merchant backend will refuse to create new orders.": [
        ""
      ],
      "Hide for today": [
        ""
      ],
      "KYC verification needed": [
        ""
      ],
      "Some transfers are on hold until the KYC process is completed. Visit the KYC section in the left panel for more information": [
        ""
      ],
      "Configuration": [
        ""
      ],
      "OTP Devices": [
        ""
      ],
      "Settings": [
        "Impostazioni"
      ],
      "Access token": [
        ""
      ],
      "Connection": [
        ""
      ],
      "Interface": [
        ""
      ],
      "List": [
        ""
      ],
      "Log out": [
        ""
      ],
      "Failed to create instance": [
        ""
      ],
      "checking compatibility with server...": [
        ""
      ],
      "Contacting the server failed": [
        ""
      ],
      "The server version is not supported": [
        ""
      ],
      "Supported version \"%1$s\", server version \"%2$s\".": [
        ""
      ],
      "Deleting": [
        ""
      ],
      "Changing": [
        ""
      ],
      "Manage access token": [
        ""
      ],
      "Business Name": [
        ""
      ],
      "Order ID": [
        ""
      ],
      "Payment URL": [
        ""
      ]
    }
  },
  "domain": "messages",
  "plural_forms": "nplurals=2; plural=n != 1;",
  "lang": "it",
  "completeness": 1
};

strings['fr'] = {
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=2; plural=(n!=1);",
        "lang": "fr"
      },
      "The request reached a timeout, check your connection.": [
        "La demande a dépassé le temps de traitement maximum, vérifiez votre connexion."
      ],
      "The request was cancelled.": [
        "La demande a été annulée."
      ],
      "A lot of request were made to the same server and this action was throttled.": [
        "Un grand nombre de demandes ont été adressées au même serveur et cette action a été ralentie."
      ],
      "The response of the request is malformed.": [
        "La réponse de la demande est malformée."
      ],
      "Could not complete the request due to a network problem.": [
        "La demande n'a pas pu être traitée en raison d'un problème de réseau."
      ],
      "Unexpected request error.": [
        "Erreur inattendue sur la requête."
      ],
      "Unexpected error.": [
        "Erreur inattendue."
      ],
      "Cancel": [
        "Annuler"
      ],
      "%1$s": [
        "%1$s"
      ],
      "Close": [
        "Fermer"
      ],
      "Continue": [
        "Continuer"
      ],
      "Clear": [
        "Effacer"
      ],
      "Confirm": [
        "Confirmer"
      ],
      "Required": [
        "Obligatoire"
      ],
      "Letter must be a JSON string": [
        "La lettre doit être une chaîne JSON"
      ],
      "JSON string is invalid": [
        "La chaine JSON est invalide"
      ],
      "Import": [
        "Importer"
      ],
      "Importing an account from the bank": [
        "Importer un compte depuis la banque"
      ],
      "You can export your account settings from the Libeufin Bank's account profile. Paste the content in the next field.": [
        "Vous pouvez exporter les paramètres de votre compte à partir du profil Libeufin de compte de votre banque. Collez le contenu dans le champ suivant."
      ],
      "Account information": [
        "Informations sur le compte"
      ],
      "Correct form": [
        "Formulaire correct"
      ],
      "Comparing account details": [
        "Comparaison des détails du compte"
      ],
      "Testing against the account info URL succeeded but the account information reported is different with the account details form.": [
        "Le test de l'URL des informations du compte a réussi mais les informations sur le compte retournées sont différentes de celles du formulaire des détails du compte."
      ],
      "Field": [
        "Champs"
      ],
      "In the form": [
        "Dans le formulaire"
      ],
      "Reported": [
        "Rapporté"
      ],
      "Type": [
        "Type"
      ],
      "IBAN": [
        "IBAN"
      ],
      "Address": [
        "Adresse"
      ],
      "Host": [
        "Hôte"
      ],
      "Account id": [
        "Identifiant de compte"
      ],
      "Owner's name": [
        "Nom du propriétaire"
      ],
      "Account": [
        "Compte"
      ],
      "Bank host": [
        "Serveur de la banque"
      ],
      "Bank account": [
        "Compte bancaire"
      ],
      "BIC": [
        "Code BIC"
      ],
      "Ok": [
        "OK"
      ],
      "Validate bank account: %1$s": [
        "Valider le compte bancaire : %1$s"
      ],
      "You need to make a bank transfer with the specified subject to validate that you are the owner of the account.": [
        "Vous devez effectuer un virement bancaire avec l'objet spécifié pour valider que vous êtes bien le propriétaire du compte."
      ],
      "Step 1:": [
        "Étape 1 :"
      ],
      "Copy this string and paste it into the field 'Subject' (resp. 'Purpose') in your banking app or bank website": [
        "Copiez ce code et collez-le dans le champ référence/objet de votre application bancaire ou du site web de votre banque"
      ],
      "Subject": [
        "Référence"
      ],
      "Step 2:": [
        "Étape 2 :"
      ],
      "Copy and paste this IBAN and the name into the receiver fields in your banking app or website": [
        "Copiez et collez l'IBAN et le nom dans les champs destinataires de votre application bancaire ou de votre site web"
      ],
      "Receiver name": [
        "Nom du bénéficiaire"
      ],
      "Step 3:": [
        "Étape 3 :"
      ],
      "Select the smallest possible amount for the wire transfer in your banking app or on your online banking website.": [
        ""
      ],
      "Make sure ALL data is correct, including the subject and you are using your selected bank account. You can use the copy buttons (%1$s) to prevent typing errors or the \"payto://\" URI below to copy just one value.": [
        "Assurez-vous que TOUTES les données sont correctes, y compris la référence, et que vous utilisez le compte bancaire sélectionné. Vous pouvez utiliser les boutons de copie (%1$s) pour éviter les erreurs de frappe ou l'URI « payto:// » ci-dessous pour copier une seule valeur."
      ],
      "Alternatively, if your bank already supports the standard 'PayTo URI', you can use this %1$s link instead": [
        "Alternativement, si votre banque prend en charge les URI de type PayTO, vous pouvez utiliser ce %1$s lien à la place"
      ],
      "If you delete the instance named %1$s (ID: %2$s), the merchant will no longer be able to process orders or refunds": [
        "Si vous supprimez l'instance nommée %1$s (ID : %2$s), le commerçant ne sera plus en mesure de traiter les commandes ou les remboursements"
      ],
      "This action deletes the instance private key, but preserves all transaction data. You can still access that data after deleting the instance.": [
        "Cette action supprime la clé privée de l'instance, mais préserve toutes les données de transaction. Vous pouvez toujours accéder à ces données après avoir supprimé l'instance."
      ],
      "Deleting an instance %1$s .": [
        "Suppression d'une instance %1$s ."
      ],
      "If you purge the instance named %1$s (ID: %2$s), you will also delete all it&apos;s transaction data.": [
        "Si vous purgez l'instance nommée %1$s (ID : %2$s), vous supprimerez également toutes ses données de transaction."
      ],
      "The instance will disappear from your list, and you will no longer be able to access it&apos;s data.": [
        "L'instance disparaîtra de votre liste et vous ne pourrez plus accéder à ses données."
      ],
      "Purging an instance %1$s .": [
        "Purge d'une instance %1$s ."
      ],
      "Is not the same as the current access token": [
        "N'est pas identique au jeton d'accès actuel"
      ],
      "Can't be the same as the old token": [
        "Il ne peut pas s'agir du même jeton que l'ancien"
      ],
      "Is not the same": [
        "N'est pas le même"
      ],
      "You are updating the access token for the instance with ID %1$s": [
        "Vous mettez à jour le jeton d'accès de l'instance dont l'identifiant est %1$s"
      ],
      "Old access token": [
        "Ancien jeton d'accès"
      ],
      "Access token currently in use": [
        "Jeton d'accès actuellement utilisé"
      ],
      "New access token": [
        "Nouveau jeton d'accès"
      ],
      "Next access token to be used": [
        "Prochain jeton d'accès utilisé"
      ],
      "Repeat access token": [
        "Répétez le jeton d'accès"
      ],
      "Confirm the same access token": [
        "Confirmez le même jeton d'accès"
      ],
      "Clearing the access token will mean public access to the instance": [
        "L'effacement du jeton d'accès signifie que l'instance est accessible à tout le monde"
      ],
      "Can't be the same as the old access token": [
        "Ne peut pas être le même que l'ancien jeton d'accès"
      ],
      "You are setting the access token for the new instance": [
        "Vous définissez le jeton d'accès pour la nouvelle instance"
      ],
      "With external authorization method no check will be done by the merchant backend": [
        "Avec la méthode d'autorisation externe, aucun contrôle n'est effectué par le backend du commerçant"
      ],
      "Set external authorization": [
        "Définir une autorisation externe"
      ],
      "Set access token": [
        "Définir un jeton d'accès"
      ],
      "Operation in progress...": [
        "Opération en cours..."
      ],
      "The operation will be automatically canceled after %1$s seconds": [
        "L'opération sera automatiquement annulée après %1$s secondes"
      ],
      "Your password is incorrect": [
        "Votre mot de passe est incorrect"
      ],
      "Your instance cannot be found": [
        "Votre instance n'a pas été trouvée"
      ],
      "Login required": [
        "Login obligatoire"
      ],
      "Please enter your access token for %1$s.": [
        "Veuillez saisir votre code d'accès pour %1$s."
      ],
      "Access Token": [
        "Jeton d'accès"
      ],
      "Instances": [
        "Instances"
      ],
      "Delete": [
        "Effacer"
      ],
      "Add new instance": [
        "Ajouter une nouvelle instance"
      ],
      "ID": [
        "Identifiant"
      ],
      "Name": [
        "Nom"
      ],
      "Edit": [
        "Editer"
      ],
      "Purge": [
        "Purger"
      ],
      "There are no instances yet, add more pressing the + sign": [
        "Il n'y a pas encore d'instance, ajoutez-en une en appuyant sur le signe +"
      ],
      "Only show active instances": [
        "N'afficher que les instances actives"
      ],
      "Active": [
        "Actives"
      ],
      "Only show deleted instances": [
        "N'afficher que les instances effacées"
      ],
      "Deleted": [
        "Effacées"
      ],
      "Show all instances": [
        "Afficher toutes les instances"
      ],
      "All": [
        "Toutes"
      ],
      "Instance \"%1$s\" (ID: %2$s) has been deleted": [
        "L'instance \"%1$s\" (identifiant : %2$s) a été effacée"
      ],
      "Failed to delete instance": [
        "Echec de la suppression de l'instance"
      ],
      "Instance '%1$s' (ID: %2$s) has been purged": [
        "L'instance '%1$s' (identifiant : %2$s) a été totalement purgée"
      ],
      "Failed to purge instance": [
        "Echec de la purge de l'instance"
      ],
      "Loading...": [
        "Chargement ..."
      ],
      "This is not a valid bitcoin address.": [
        "Ceci n'est pas une adresse Bitcoin valide."
      ],
      "This is not a valid Ethereum address.": [
        "Ceci n'est pas une adresse Ethereum valide."
      ],
      "This is not a valid host.": [
        "Ceci n'est pas un serveur valide."
      ],
      "IBAN numbers usually have more that 4 digits": [
        "Les numéros IBAN comportent généralement plus de 4 chiffres"
      ],
      "IBAN numbers usually have less that 34 digits": [
        "Les numéros IBAN comportent généralement moins de 34 chiffres"
      ],
      "IBAN country code not found": [
        "Le code pays de l'IBAN n'a pas été trouvé"
      ],
      "IBAN number is invalid, checksum is wrong": [
        "Le numéro IBAN n'est pas valide, la somme de contrôle est erronée"
      ],
      "Choose one...": [
        "Choisissez un ..."
      ],
      "Method to use for wire transfer": [
        "Méthode à utiliser pour le virement bancaire"
      ],
      "Routing": [
        "Code bancaire"
      ],
      "Routing number.": [
        "Code bancaire."
      ],
      "Account number.": [
        "Numéro de compte."
      ],
      "Code": [
        "Code"
      ],
      "Business Identifier Code.": [
        "Code BIC de la banque."
      ],
      "International Bank Account Number.": [
        "Code IBAN."
      ],
      "Unified Payment Interface.": [
        "Interface de paiement unifiée (Unified Payment Interface)."
      ],
      "Bitcoin protocol.": [
        "Protocole Bitcoin."
      ],
      "Ethereum protocol.": [
        "Protocole Ethereum."
      ],
      "Interledger protocol.": [
        "Protocole Interledger."
      ],
      "Bank host.": [
        "Serveur de la banque."
      ],
      "Without scheme and may include subpath:": [
        "Sans schéma et peut inclure un sous-chemin :"
      ],
      "Bank account.": [
        "Compte en banque."
      ],
      "Legal name of the person holding the account.": [
        "Nom légal de la personne titulaire du compte."
      ],
      "It should match the bank account name.": [
        "Il doit correspondre au nom du titulaire du compte bancaire."
      ],
      "Invalid url": [
        "URL invalide"
      ],
      "URL must end with a '/'": [
        "L'URL doit terminer avec un '/'"
      ],
      "URL must not contain params": [
        "L'URL ne doit pas contenir de parametres"
      ],
      "URL must not hash param": [
        "L'URL ne doit pas comporter de paramètre de hachage"
      ],
      "The request to check the revenue API failed.": [
        "Echec de la requête à l'API de verification des revenus."
      ],
      "Server replied with \"bad request\".": [
        "Le serveur a répondu \"Mauvaise requête\"."
      ],
      "Unauthorized, check credentials.": [
        "Non autorisé, vérifiez les informations d'identification."
      ],
      "The endpoint does not seem to be a Taler Revenue API.": [
        "Ce endpoint ne semble par ressembler à une URL Taler Revenue."
      ],
      "The request was made correctly, but the bank's server did not respond with the appropriate value for 'credit_account', so we cannot confirm that it is the same bank account.": [
        "La requête a abouti mais le serveur n'a pas répondu au 'credit_account' et nous ne pouvons donc pas vérifier que le compte est le même."
      ],
      "Account:": [
        "Compte :"
      ],
      "If the bank supports Taler Revenue API then you can add the endpoint URL below to keep the revenue information in sync.": [
        "Si la banque supporte l'API Taler Revenue, vous pouvez ajouter l'URL du endpoint ci-dessous pour garder les informations sur les revenus synchronisées."
      ],
      "Endpoint URL": [
        "URL du endpoint"
      ],
      "From where the merchant can download information about incoming wire transfers to this account": [
        "Endroit d'où le commerçant peut télécharger des informations sur les virements entrants sur ce compte"
      ],
      "Auth type": [
        "Type d'authentification"
      ],
      "Choose the authentication type for the account info URL": [
        "Choisir le type d'authentification pour l'URL des info sur le compte"
      ],
      "Without authentication": [
        "Aucune authentification"
      ],
      "With password": [
        "Avec mot de passe"
      ],
      "With token": [
        "Avec jeton"
      ],
      "Do not change": [
        "Ne pas changer"
      ],
      "Username": [
        "Nom d'utilisateur"
      ],
      "Username to access the account information.": [
        "Nom d'utilisateur pour accéder aux informations sur le compte."
      ],
      "Password": [
        "Mot de passe"
      ],
      "Password to access the account information.": [
        "Mot de passe pour accéder aux informations du compte."
      ],
      "Token": [
        "Jeton"
      ],
      "Access token to access the account information.": [
        "Jeton d'accès pour accéder aux informations du compte."
      ],
      "Match": [
        "Correspondance"
      ],
      "Check where the information match against the server info.": [
        "Vérifier si les informations correspondent à celles du serveur."
      ],
      "Not verified": [
        "Non vérifié"
      ],
      "Last test was ok": [
        "Le dernier test était OK"
      ],
      "Last test failed": [
        "Le dernier test a échoué"
      ],
      "Compare info from server with account form": [
        "Comparer les informations du serveur avec le formulaire de compte"
      ],
      "Test": [
        "Test"
      ],
      "Need to complete marked fields": [
        "Besoin de compléter les champs marqués"
      ],
      "Confirm operation": [
        "Confirmez l'opération"
      ],
      "Account details": [
        "Détails du compte"
      ],
      "Import from bank": [
        "Importer depuis la banque"
      ],
      "Could not create account": [
        "Impossible de créer le compte"
      ],
      "No 'default' instance configured yet.": [
        "Aucune instance 'par défaut' n'a encore été configurée."
      ],
      "Create a 'default' instance to begin using the merchant backoffice.": [
        "Créer une instance 'par défaut' pour commencer à utiliser le backoffice du commerçant."
      ],
      "Bank accounts": [
        "Comptes bancaires"
      ],
      "Add new account": [
        "Ajouter un nouveau compte"
      ],
      "Wire method: Bitcoin": [
        "Méthode de virement : Bitcoin"
      ],
      "SegWit 1": [
        "SegWit 1"
      ],
      "SegWit 2": [
        "SegWit 2"
      ],
      "Delete selected accounts from the database": [
        "Supprimer les comptes sélectionnés de la base de données"
      ],
      "Wire method: x-taler-bank": [
        "Méthode de virement : x-taler-bank"
      ],
      "Account name": [
        "Nom du compte"
      ],
      "Wire method: IBAN": [
        "Méthode de virement : IBAN"
      ],
      "Other accounts": [
        "Autres comptes"
      ],
      "Path": [
        "Chemin"
      ],
      "There are no accounts yet, add more pressing the + sign": [
        "Il n'y a pas encore de compte, ajoutez-en un en appuyant sur le signe +"
      ],
      "You need to associate a bank account to receive revenue.": [
        "Vous devez associer un compte bancaire pour recevoir des fonds."
      ],
      "Without this the you won't be able to create new orders.": [
        "Sans cela, vous ne pourrez pas créer de nouvelles commandes."
      ],
      "The bank account has been successfully deleted.": [
        "Le compte bancaire a été supprimé avec succès."
      ],
      "Could not delete the bank account": [
        "Impossible de supprimer le compte bancaire"
      ],
      "Could not update account": [
        "Impossible de mettre à jour le compte"
      ],
      "Could not delete account": [
        "Impossible de supprimer le compte"
      ],
      "Pending KYC verification": [
        "En attente de vérification KYC"
      ],
      "Exchange": [
        "Exchange"
      ],
      "Reason": [
        "Référence"
      ],
      "Pending KYC process, click here to complete": [
        "En attente de la procédure KYC, cliquez ici pour terminer"
      ],
      "The Payment Service Provider requires an account verification.": [
        "L'exchange requière une vérification du compte."
      ],
      "Http Status": [
        "Status HTTP"
      ],
      "No pending kyc verification!": [
        "Aucune vérification kyc en cours !"
      ],
      "Change value to unknown date": [
        "Changer la valeur pour une date inconnue"
      ],
      "Change value to empty": [
        "Changer la valeur pour vide"
      ],
      "Change value to never": [
        "Changer la valeur pour jamais"
      ],
      "Never": [
        "Jamais"
      ],
      "days": [
        "jours"
      ],
      "hours": [
        "heures"
      ],
      "minutes": [
        "minutes"
      ],
      "seconds": [
        "secondes"
      ],
      "Forever": [
        "Pour toujours"
      ],
      "%1$sM": [
        "%1$sM"
      ],
      "%1$sY": [
        "%1$sY"
      ],
      "%1$sd": [
        "%1$sd"
      ],
      "%1$sh": [
        "%1$sh"
      ],
      "%1$smin": [
        "%1$smin"
      ],
      "%1$ssec": [
        "%1$ss"
      ],
      "Country": [
        "Pays"
      ],
      "Building number": [
        "Numéro de bâtiment"
      ],
      "Building name": [
        "Nom du bâtiment"
      ],
      "Street": [
        "Rue"
      ],
      "Post code": [
        "Code postal"
      ],
      "Town location": [
        "Localisation de la ville"
      ],
      "Town": [
        "Ville"
      ],
      "District": [
        "District"
      ],
      "Country subdivision": [
        "Subdivision du pays"
      ],
      "Description": [
        "Description"
      ],
      "Enter description or id": [
        "Entrez soit une description soit un identifiant"
      ],
      "no match found with that description or id": [
        "aucune correspondance de trouvée pour cette description ou cet identifiant"
      ],
      "You must enter a valid product identifier.": [
        "Vous devez entrer un identifiant de produit valide."
      ],
      "Quantity must be greater than 0!": [
        "La quantité doit être supérieure à 0 !"
      ],
      "This quantity exceeds remaining stock. Currently, only %1$s units remain unreserved in stock.": [
        "Cette quantité dépasse le stock restant. Actuellement, il ne reste que %1$s unités non réservées en stock."
      ],
      "Search product": [
        "Recherche produit"
      ],
      "Quantity": [
        "Quantité"
      ],
      "How many products will be added": [
        "Combien de produits seront ajoutés"
      ],
      "Add from inventory": [
        "Ajouter depuis l'inventaire"
      ],
      "Image must be smaller than 1 MB": [
        "La taille de l'image doit être inférieure à 1Mo"
      ],
      "Add": [
        "Ajouter"
      ],
      "Remove": [
        "Effacer"
      ],
      "Invalid": [
        "Invalide"
      ],
      "This product has %1$s applicable taxes configured.": [
        "Ce produit a %1$s taxes applicables configurées."
      ],
      "No taxes configured for this product.": [
        "Aucune taxe n'est configurée pour ce produit."
      ],
      "Amount": [
        "Montant"
      ],
      "Taxes can be in currencies that differ from the main currency used by the merchant.": [
        "Les taxes peuvent être libellées dans des monnaies différentes de la monnaie principale utilisée par le commerçant."
      ],
      "Enter currency and value separated with a colon, e.g. &quot;USD:2.3&quot;.": [
        "Saisissez la devise et la valeur séparées par deux points, par exemple &quot;USD:2.3&quot ;."
      ],
      "Legal name of the tax, e.g. VAT or import duties.": [
        "Nom légal de la taxe, par exemple TVA ou droits d'importation."
      ],
      "Add tax to the tax list": [
        "Ajouter une taxe à la liste des taxes"
      ],
      "Describe and add a product that is not in the inventory list": [
        "Décrire et ajouter un produit qui ne figure pas dans la liste d'inventaire"
      ],
      "Add custom product": [
        "Ajouter un produit personnalisé"
      ],
      "Complete information of the product": [
        "Informations complètes sur le produit"
      ],
      "Must be a number": [
        "Doit être un nombre"
      ],
      "Must be grater than 0": [
        "Doit être supérieur à 0"
      ],
      "Image": [
        "Image"
      ],
      "Photo of the product.": [
        "Photo du produit."
      ],
      "Full product description.": [
        "Description détaillée du produit."
      ],
      "Unit": [
        "Unité"
      ],
      "Name of the product unit.": [
        "Nom de l'unité du produit."
      ],
      "Price": [
        "Prix"
      ],
      "Amount in the current currency.": [
        "Montant dans la monnaie courante."
      ],
      "How many products will be added.": [
        "Combien de produits seront ajoutés."
      ],
      "Taxes": [
        "Taxes"
      ],
      "Unit price": [
        "Prix unitaire"
      ],
      "Total price": [
        "Prix total"
      ],
      "Must be greater than 0": [
        "Doit être supérieur à 0"
      ],
      "Refund deadline can't be before pay deadline": [
        "La date limite de remboursement ne peut pas être antérieure à la date limite de paiement"
      ],
      "Wire transfer deadline can't be before refund deadline": [
        "La date limite de virement ne peut pas être antérieure à la date limite de remboursement"
      ],
      "Wire transfer deadline can't be before pay deadline": [
        "La date limite de virement ne peut pas être antérieure à la date limite de paiement"
      ],
      "Must have a refund deadline": [
        "Le délai de remboursement doit être fixé"
      ],
      "Auto refund can't be after refund deadline": [
        "Le remboursement automatique ne peut pas être effectué après la date limite de remboursement"
      ],
      "Must be in the future": [
        "Doit être dans le future"
      ],
      "Simple": [
        "Simple"
      ],
      "Advanced": [
        "Avancé"
      ],
      "Manage products in order": [
        "Gérer les produits dans l'ordre"
      ],
      "%1$s products with a total price of %2$s.": [
        "%1$s produits pour un prix total de %2$s."
      ],
      "Manage list of products in the order.": [
        "Gérer la liste des produits dans la commande."
      ],
      "Remove this product from the order.": [
        "Retirer ce produit de la commande."
      ],
      "Total product price added up": [
        "Prix total des produits additionnés"
      ],
      "Amount to be paid by the customer": [
        "Montant à payer par le client"
      ],
      "Order price": [
        "Prix de la commande"
      ],
      "Final order price": [
        "Prix final de la commande"
      ],
      "Summary": [
        "Résumé"
      ],
      "Title of the order to be shown to the customer": [
        "Titre de la commande à afficher au client"
      ],
      "Shipping and fulfillment": [
        "Expédition et traitement des commandes"
      ],
      "Delivery date": [
        "Date de livraison"
      ],
      "Deadline for physical delivery assured by the merchant.": [
        "Date limite de livraison physique assurée par le commerçant."
      ],
      "Location": [
        "Emplacement"
      ],
      "Address where the products will be delivered": [
        "Adresse de livraison des produits"
      ],
      "Fulfillment URL": [
        "URL du service de traitement des commandes"
      ],
      "URL to which the user will be redirected after successful payment.": [
        "URL vers laquelle l'utilisateur sera redirigé après un paiement réussi."
      ],
      "Taler payment options": [
        "Options de paiement Taler"
      ],
      "Override default Taler payment settings for this order": [
        "Remplacer les paramètres de paiement par défaut de Taler pour cette commande"
      ],
      "Payment time": [
        "Délai de paiement"
      ],
      "Time for the customer to pay for the offer before it expires. Inventory products will be reserved until this deadline. Time start to run after the order is created.": [
        "Délai accordé au client pour payer l'offre avant qu'elle n'expire. Les produits en stock seront réservés jusqu'à cette date. Le temps commence à courir après la création de la commande."
      ],
      "Default": [
        "Par défaut"
      ],
      "Refund time": [
        "Délai de remboursement"
      ],
      "Time while the order can be refunded by the merchant. Time starts after the order is created.": [
        "Délai pendant lequel la commande peut être remboursée par le commerçant. Le temps commence à la création de la commande."
      ],
      "Wire transfer time": [
        "Délai de virement"
      ],
      "Time for the exchange to make the wire transfer. Time starts after the order is created.": [
        "Temps nécessaire à l'exchange pour effectuer le virement. Le temps commence à la création de la commande."
      ],
      "Auto-refund time": [
        "Délai de remboursement automatique"
      ],
      "Time until which the wallet will automatically check for refunds without user interaction.": [
        "Délai jusqu'auquel le portefeuille vérifiera automatiquement les remboursements sans intervention de l'utilisateur."
      ],
      "Maximum fee": [
        "Frais maximums"
      ],
      "Maximum fees the merchant is willing to cover for this order. Higher deposit fees must be covered in full by the consumer.": [
        "Frais maximums que le commerçant est prêt à couvrir pour cette commande. Les frais de dépôt plus élevés doivent être intégralement pris en charge par le consommateur."
      ],
      "Create token": [
        "Créer un jeton"
      ],
      "If the order ID is easy to guess the token will prevent users to steal orders from others.": [
        "Si l'identifiant de la commande est facile à deviner, le jeton empêchera les utilisateurs de voler les commandes d'autres personnes."
      ],
      "Minimum age required": [
        "Âge minimum requis"
      ],
      "Any value greater than 0 will limit the coins able be used to pay this contract. If empty the age restriction will be defined by the products": [
        "Toute valeur supérieure à 0 limitera le type de pièces pouvant être utilisées pour payer ce contrat. Si la valeur est vide, la restriction d'âge sera définie par les produits"
      ],
      "Min age defined by the producs is %1$s": [
        "L'âge minimum défini par les produits est de %1$s"
      ],
      "No product with age restriction in this order": [
        "Aucun produit avec restriction d'âge dans cette commande"
      ],
      "Additional information": [
        "Informations complémentaires"
      ],
      "Custom information to be included in the contract for this order.": [
        "Informations personnalisées à inclure dans le contrat pour cette commande."
      ],
      "You must enter a value in JavaScript Object Notation (JSON).": [
        "Vous devez saisir une valeur en JavaScript Object Notation (JSON)."
      ],
      "Custom field name": [
        "Nom du champ personnalisé"
      ],
      "Disabled": [
        "Désactivé"
      ],
      "No deadline": [
        "Aucune date limite"
      ],
      "Deadline at %1$s": [
        "Date limite à %1$s"
      ],
      "Could not create order": [
        "Impossible de créer la commande"
      ],
      "No exchange would accept a payment because of KYC requirements.": [
        "Aucun exchange ne pourra accepter le paiement en raison des exigences en matière de connaissance du client (procédure KYC)."
      ],
      "No more stock for product with ID \"%1$s\".": [
        "Il n'y a plus de stock pour le produit dont l'identifiant est \"%1$s\"."
      ],
      "Orders": [
        "Commandes"
      ],
      "Create order": [
        "Créer une commande"
      ],
      "Load first page": [
        "Charger la première page"
      ],
      "Date": [
        "Date"
      ],
      "Refund": [
        "Remboursement"
      ],
      "copy url": [
        "copiez l'URL"
      ],
      "Load more orders after the last one": [
        "Charger d'autres commandes après la dernière"
      ],
      "Load next page": [
        "Charger la page suivante"
      ],
      "No orders have been found matching your query!": [
        "Aucune commande n'a été trouvée correspondant à votre requête !"
      ],
      "Duplicated": [
        "Dupliqué"
      ],
      "This value exceed the refundable amount": [
        "Cette valeur dépasse le montant remboursable"
      ],
      "Amount to be refunded": [
        "Montant à rembourser"
      ],
      "Max refundable:": [
        "Remboursement maximum :"
      ],
      "Requested by the customer": [
        "A la demande du client"
      ],
      "Other": [
        "Autre"
      ],
      "Why this order is being refunded": [
        "Pourquoi cette commande est-elle remboursée"
      ],
      "More information to give context": [
        "Plus d'informations pour situer le contexte"
      ],
      "Contract terms": [
        "Conditions du contrat"
      ],
      "Human-readable description of the whole purchase": [
        "Description en langage courant de l'ensemble de la transaction"
      ],
      "Total price for the transaction": [
        "Prix total de la transaction"
      ],
      "URL for this purchase": [
        "URL de cet achat"
      ],
      "Max fee": [
        "Frais maximums"
      ],
      "Maximum total deposit fee accepted by the merchant for this contract": [
        "Montant total maximum des frais de dépôt acceptés par le commerçant pour ce contrat"
      ],
      "Created at": [
        "Créé à"
      ],
      "Time when this contract was generated": [
        "Heure à laquelle ce contrat a été généré"
      ],
      "Refund deadline": [
        "Date limite de remboursement"
      ],
      "After this deadline has passed no refunds will be accepted": [
        "Passé ce délai, aucun remboursement ne sera accepté"
      ],
      "Payment deadline": [
        "Date limite de paiement"
      ],
      "After this deadline, the merchant won't accept payments for the contract": [
        "Passé ce délai, le commerçant n'acceptera plus de paiements pour le contrat"
      ],
      "Wire transfer deadline": [
        "Date limite pour les virements"
      ],
      "Transfer deadline for the exchange": [
        "Date limite de transfert pour l'exchange"
      ],
      "Time indicating when the order should be delivered": [
        "Date à laquelle la commande doit être livrée"
      ],
      "Where the order will be delivered": [
        "Lieu de livraison de la commande"
      ],
      "Auto-refund delay": [
        "Délai de remboursement automatique"
      ],
      "How long the wallet should try to get an automatic refund for the purchase": [
        "Combien de temps le portefeuille doit-il essayer d'obtenir un remboursement automatique de l'achat"
      ],
      "Extra info": [
        "Infos supplémentaires"
      ],
      "Extra data that is only interpreted by the merchant frontend": [
        "Données supplémentaires qui ne sont interprétées que par le frontend du commerçant"
      ],
      "Order": [
        "Commande"
      ],
      "Claimed": [
        "Idées pour les projets réclamées"
      ],
      "Claimed at": [
        "Réclamée à"
      ],
      "Timeline": [
        "Calendrier"
      ],
      "Payment details": [
        "Modalités de paiement"
      ],
      "Order status": [
        "Statut de la commande"
      ],
      "Product list": [
        "Liste des produits"
      ],
      "Paid": [
        "Payée"
      ],
      "Wired": [
        "Virement effectué"
      ],
      "Refunded": [
        "Remboursée"
      ],
      "Refund order": [
        "Rembourser la commande"
      ],
      "Not refundable": [
        "Non remboursable"
      ],
      "Next event in": [
        "Nouvel évènement dans"
      ],
      "Refunded amount": [
        "Montant remboursé"
      ],
      "Refund taken": [
        "Remboursement pris"
      ],
      "Status URL": [
        "URL de l'état"
      ],
      "Refund URI": [
        "URI de remboursement"
      ],
      "Unpaid": [
        "Non payé"
      ],
      "Pay at": [
        "Payer à"
      ],
      "Order status URL": [
        "URL de l'état de la commande"
      ],
      "Payment URI": [
        "URI de payment"
      ],
      "Unknown order status. This is an error, please contact the administrator.": [
        "Statut de la commande inconnu. Il s'agit d'une erreur, veuillez contacter l'administrateur."
      ],
      "Back": [
        "Retour"
      ],
      "Refund created successfully": [
        "Création réussie du remboursement"
      ],
      "Could not create the refund": [
        "Le remboursement n'a pas pu être créé"
      ],
      "There are pending KYC requirements.": [
        "Des exigences en matière de connaissance du client (KYC) sont en suspens."
      ],
      "Missing ID": [
        "Identifiant manquant"
      ],
      "Not found": [
        "Non trouvé"
      ],
      "Select date to show nearby orders": [
        "Sélectionnez la date pour afficher les commandes proches"
      ],
      "Only show paid orders": [
        "Afficher uniquement les commandes payées"
      ],
      "New": [
        "Nouveau"
      ],
      "Only show orders with refunds": [
        "Afficher uniquement les commandes avec remboursement"
      ],
      "Only display orders that have already been paid for by buyers and for which the wire transfer by the payment service provider is still pending": [
        "N'afficher que les commandes pour lesquelles les clients ont payé, mais pour lesquelles les virements du fournisseur de paiement sont encore en attente."
      ],
      "Not wired": [
        "Virement non effectué"
      ],
      "Only display orders that have already been transferred by the payment service provider": [
        "N'afficher que les commandes pour lesquelles les clients ont payé, mais pour lesquelles les virements du fournisseur de paiement sont encore en attente."
      ],
      "Completed": [
        "Terminé"
      ],
      "Remove all filters": [
        "Effacer tous les filtres"
      ],
      "Clear date filter": [
        "Effacer le filtre de date"
      ],
      "Jump to date (%1$s)": [
        "Aller à la date (%1$s)"
      ],
      "Jump to order with the given product ID": [
        "Aller à la commande en fonction d'un identifiant de produit"
      ],
      "Order id": [
        "Identifiant de commande"
      ],
      "Invalid. Please enter characters and numbers only": [
        "Invalide. Veuillez insérer uniquement des caractères et des chiffres"
      ],
      "Just letters and numbers from 2 to 7": [
        "Juste des lettres et des chiffres de 2 à 7"
      ],
      "The size of the key must be 32 characters": [
        "La taille de la clé doit être 32"
      ],
      "Internal ID on the system": [
        "Identifiant interne au système"
      ],
      "Useful to identify the device physically": [
        "Utile pour identifier physiquement l'appareil"
      ],
      "Verification algorithm": [
        "Algorithme de vérification"
      ],
      "Algorithm used to verify transactions in offline mode": [
        "Algorithme à utiliser pour vérifier la transaction en mode hors ligne"
      ],
      "Device key": [
        "Clé de l'appareil"
      ],
      "Be sure to choose a password hard to guess, alternatively use the random generator": [
        "Veillez à ce qu'il soit très difficile à deviner ou utilisez le générateur aléatoire"
      ],
      "Your device needs to match exactly the same value": [
        "Votre appareil doit avoir exactement la même valeur"
      ],
      "Generate random secret key": [
        "Générer une clé secrète aléatoire"
      ],
      "Random": [
        "Aléatoire"
      ],
      "You can scan the next QR code with your device or save the key before continuing.": [
        "Vous pouvez scanner le code QR suivant avec votre appareil ou enregistrer la clé avant de continuer."
      ],
      "Device added successfully": [
        "Appareil ajouté avec succès"
      ],
      "Could not add device": [
        "Impossible d'ajouter l'appareil"
      ],
      "OTP devices": [
        "Appareil OTP"
      ],
      "Add new devices": [
        "Ajouter de nouveaux appareils"
      ],
      "Load more devices before the first one": [
        "Ajouter d'autres appareils avant le premier"
      ],
      "Delete selected devices from the database": [
        "Supprimer les appareils sélectionnés de la base de données"
      ],
      "Load more devices after the last one": [
        "Ajouter d'autres appareils après le dernier"
      ],
      "There are no devices to list yet, add more by pressing the + sign": [
        "Il n'y a pas encore d'appareil, ajoutez-en un en appuyant sur le signe +"
      ],
      "The device was successfully deleted": [
        "Le compte bancaire a été supprimé avec succès."
      ],
      "Could not delete the device": [
        "Impossible de supprimer l'appareil"
      ],
      "Device:": [
        "Appareil :"
      ],
      "Algorithm to use to verify transactions in offline mode": [
        "Algorithme à utiliser pour vérifier la transaction en mode hors ligne"
      ],
      "Not modified": [
        "Pas de modification"
      ],
      "Be sure to be very hard to guess or use the random generator": [
        "Veillez à ce qu'il soit très difficile à deviner ou utilisez le générateur aléatoire"
      ],
      "Your device need to have exactly the same value": [
        "Votre appareil doit avoir exactement la même valeur"
      ],
      "Change key": [
        "Changer la clé"
      ],
      "Could not update template": [
        "Impossible de changer le modèle"
      ],
      "Template id is unknown": [
        "Identifiant du modèle inconnu"
      ],
      "The provided information is inconsistent with the current state of the template": [
        "Les informations fournies ne correspondent pas à l'état actuel du modèle"
      ],
      "Click here to configure the stock of the product, leave it as is and the backend will not control stock.": [
        "Cliquez ici pour configurer le stock du produit, laissez-le tel quel et le backend ne contrôlera pas le stock."
      ],
      "Manage stock": [
        "Gestion du stock"
      ],
      "This product has been configured without stock control": [
        "Ce produit a été configuré sans contrôle du stock"
      ],
      "Infinite": [
        "Infini"
      ],
      "Shrinkage cannot exceed the current stock and incoming supplies (maximum %1$s)": [
        "La perte ne peut pas être supérieure au courant et à l'arrivée (max %1$s)"
      ],
      "Incoming": [
        "Arrivées"
      ],
      "Lost": [
        "Perte"
      ],
      "Current": [
        "Courant"
      ],
      "Remove stock control for this product": [
        "Supprimer le contrôle des stocks pour ce produit"
      ],
      "without stock": [
        "sans stock"
      ],
      "Next restock": [
        "Prochain réapprovisionnement"
      ],
      "Warehouse address": [
        "Adresse de l'entrepôt"
      ],
      "Add element to the list": [
        "Ajouter l'élément à la liste"
      ],
      "Invalid amount": [
        "Montant invalide"
      ],
      "Product identification to use in URLs (for internal use only).": [
        "Identification du produit à utiliser dans les URL (à usage interne uniquement)."
      ],
      "Illustration of the product for customers.": [
        "Illustration du produit pour les clients."
      ],
      "Product description for customers.": [
        "Description du produit pour les clients."
      ],
      "Age restriction": [
        "Restriction d'âge"
      ],
      "Is this product restricted for customer below certain age?": [
        "Ce produit est-il interdit aux personnes en dessous d'un certain âge ?"
      ],
      "Minimum age of the customer": [
        "Âge minimum du client"
      ],
      "Unit name": [
        "Nom de l'unité"
      ],
      "Unit describing quantity of product sold (e.g. 2 kilograms, 5 liters, 3 items, 5 meters) for customers.": [
        "Unité décrivant la quantité de produits vendus (par exemple, 2 kilogrammes, 5 litres, 3 articles, 5 mètres) aux clients."
      ],
      "Example: kg, items or liters": [
        "Exemple : kg, pièces ou litres"
      ],
      "Price per unit": [
        "Prix à l'unité"
      ],
      "Sale price for customers, including taxes, for above units of the product.": [
        "Prix de vente TTC des produits pour les clients, correspondant aux unités de produits ci-dessus."
      ],
      "Stock": [
        "Stock"
      ],
      "Inventory for products with finite supply (for internal use only).": [
        "Inventaire des produits dont l'offre est limitée (à usage interne uniquement)."
      ],
      "Taxes included in the product price, exposed to customers.": [
        "Taxes incluses dans le prix du produit, communiquées aux clients."
      ],
      "Categories": [
        "Catégories"
      ],
      "Search by category description or id": [
        "Recherche par description de catégorie ou par identifiant"
      ],
      "Categories where this product will be listed on.": [
        "Catégories dans lesquelles ce produit sera rangé."
      ],
      "Product created successfully": [
        "Produit créé avec succès"
      ],
      "Could not create product": [
        "Impossible de créer le produit"
      ],
      "Inventory": [
        "Inventaire"
      ],
      "Add product to inventory": [
        "Ajouter le produit à l'inventaire"
      ],
      "Sales": [
        "Ventes"
      ],
      "Sold": [
        "Vendu"
      ],
      "Free": [
        "Libre"
      ],
      "Go to product update page": [
        "Aller à la page de modification du produit"
      ],
      "Update": [
        "Modification"
      ],
      "Remove this product from the database": [
        "Effacer ce produit de la base de données"
      ],
      "Load more products after the last one": [
        "Ajouter des produits après le dernier"
      ],
      "Update the product with new price": [
        "Modifier le prix du produit"
      ],
      "Update product with new price": [
        "Modifier le prix du produit"
      ],
      "Confirm update": [
        "Confirmer la modification"
      ],
      "lost can't be greater that current + incoming (max %1$s)": [
        "La perte ne peut pas être supérieure au courant et à l'arrivée (max %1$s)"
      ],
      "Add more elements to the inventory": [
        "Ajouter d'autres éléments à l'inventaire"
      ],
      "Report elements lost in the inventory": [
        "Signaler les éléments perdus dans l'inventaire"
      ],
      "New price for the product": [
        "Nouveau prix pour le produit"
      ],
      "The are value with errors": [
        "Certaines valeurs ont des erreurs"
      ],
      "Update product with new stock and price": [
        "Changer le stock et le prix du produit"
      ],
      "There are no products to list yet, add more by pressing the + sign": [
        "Il n'y a pas encore de produits, ajoutez-en en appuyant sur le signe +"
      ],
      "Jump to product with the given product ID": [
        "Aller directement au produit avec son identifiant"
      ],
      "Product id": [
        "Identifiant du produit"
      ],
      "Product updated successfully": [
        "Modification du produit réussie"
      ],
      "Could not update the product": [
        "Modification du produit impossible"
      ],
      "Product \"%1$s\" (ID: %2$s) has been deleted": [
        "Le produit \"%1$s\" (identifiant : %2$s) a été effacé"
      ],
      "Could not delete the product": [
        "Impossible d'effacer le produit"
      ],
      "If you delete the product named %1$s (ID: %2$s ), the stock and related information will be lost": [
        "Si vous effacez le produit nommé %1$s (identifiant : %2$s), le stock et toutes les informations liées seront perdus"
      ],
      "Deleting an product can't be undone.": [
        "La suppression d'un produit est irréversible."
      ],
      "Product id:": [
        "Identifiant du produit :"
      ],
      "Product (ID: %1$s) has been updated": [
        "Le produit (identifiant: %1$s) a été modifié"
      ],
      "Could not update product": [
        "Modification du produit impossible"
      ],
      "Must be greater that 0": [
        "Doit être supérieur à 0"
      ],
      "Too short": [
        "Trop court"
      ],
      "Identifier": [
        "Identifiant"
      ],
      "Name of the template in URLs.": [
        "Nom du modèle dans les URLs."
      ],
      "Describe what this template stands for": [
        "Décrire ce que ce modèle représente"
      ],
      "If specified here, this template will create orders with the same summary": [
        "S'il est utilisé, ce modèle créera des commandes avec le même résumé."
      ],
      "Summary is editable": [
        "Le résumé est éditable"
      ],
      "Allow the user to change the summary.": [
        "Autoriser l'utilisateur à modifier le résumé."
      ],
      "If specified here, this template will create orders with the same price": [
        "S'il est utilisé, ce modèle créera des commandes avec le même prix."
      ],
      "Amount is editable": [
        "Le montant est modifiable"
      ],
      "Allow the user to select the amount to pay.": [
        "Permettre à l'utilisateur de sélectionner le montant à payer."
      ],
      "Currency is editable": [
        "La devise est modifiable"
      ],
      "Allow the user to change currency.": [
        "Permettre à l'utilisateur de changer de devise."
      ],
      "Supported currencies": [
        "Devises prises en charge"
      ],
      "Supported currencies: %1$s": [
        "Devises prises en charge : %1$s"
      ],
      "Minimum age": [
        "Age minimum"
      ],
      "Is this contract restricted to some age?": [
        "Ce contrat doit-il contenir une limite d'âge ?"
      ],
      "Payment timeout": [
        "Délai de paiement"
      ],
      "How much time the customer has to complete the payment once the order was created.": [
        "De combien de temps le client dispose-t-il pour effectuer le paiement une fois la commande créée ?"
      ],
      "OTP device": [
        "Appareil OTP"
      ],
      "Use to verify transactions in offline mode.": [
        "A utiliser pour vérifier la transaction en mode hors ligne."
      ],
      "No OTP device.": [
        "Aucun appareil OTP."
      ],
      "Add one first": [
        "Ajouter un premier élément"
      ],
      "No device": [
        "Aucun appareil"
      ],
      "Template has been created": [
        "Le modèle a été créé"
      ],
      "Could not create template": [
        "Impossible de créer le modèle"
      ],
      "Templates": [
        "Modèles"
      ],
      "Add new templates": [
        "Ajouter de nouveaux modèles"
      ],
      "Load more templates before the first one": [
        "Ajouter de nouveaux modèles avant le premier"
      ],
      "Delete selected templates from the database": [
        "Effacer les modèles sélectionnés de la base de données"
      ],
      "Use template to create new order": [
        "Utiliser le modèle pour créer une nouvelle commande"
      ],
      "Use template": [
        "Utiliser le modèle"
      ],
      "Generate a QR code for the template.": [
        "Créer le code QR pour le modèle"
      ],
      "Load more templates after the last one": [
        "Ajouter des modèles après le dernier"
      ],
      "There are no templates to list yet, add more by pressing the + sign": [
        "Il n'y a pas encore de modèles, ajoutez-en en appuyant sur le signe +"
      ],
      "Jump to template with the given template ID": [
        "Aller directement au modèle avec cet identifiant"
      ],
      "Template identification": [
        "Identification du modèle"
      ],
      "Template \"%1$s\" (ID: %2$s) has been deleted": [
        "Le modèle \"%1$s\" (identifiant : %2$s) a été effacé"
      ],
      "Failed to delete template": [
        "Impossible d'effacer le modèle"
      ],
      "If you delete the template %1$s (ID: %2$s) you may loose information": [
        "Si vous effacez le modèle %1$s (identifiant : %2$s) vous pourriez perdre des informations"
      ],
      "Deleting an template": [
        "Suppression d'un modèle"
      ],
      "can't be undone": [
        "ne peut être annulé"
      ],
      "Print": [
        "Imprimer"
      ],
      "If specified, this template will create order with the same summary": [
        "S'il est utilisé, ce modèle créera des commandes avec le même résumé."
      ],
      "If specified, this template will create orders with the same price": [
        "S'il est utilisé, ce modèle créera des commandes avec le même prix."
      ],
      "How much time has the customer to complete the payment once the order was created.": [
        "De combien de temps dispose le client pour effectuer le paiement une fois la commande créée ?"
      ],
      "Template (ID: %1$s) has been updated": [
        "Modèle (identifiant : %1$s) a été modifié"
      ],
      "An amount is required": [
        "Montant obligatoire"
      ],
      "An order summary is required": [
        "Un résumé de la commande est obligatoire"
      ],
      "New order from template": [
        "Nouvelle commande à partir d'un modèle"
      ],
      "Amount of the order": [
        "Montant de la commande"
      ],
      "Order summary": [
        "Résumé de la commande"
      ],
      "Could not create order from template": [
        "Impossible de créer une commande à partir du modèle"
      ],
      "You need your access token to perform the operation": [
        "Vous avez besoin de votre jeton d'accès pour effectuer l'opération"
      ],
      "You are updating the access token from instance with id \"%1$s\"": [
        "Vous modifiez le jeton d'accès de l'instance dont l'identifiant est \"%1$s\""
      ],
      "This instance does not have authentication token.": [
        "Cette instance n'a pas de jeton d'authentification."
      ],
      "You can leave it empty if there is another layer of security.": [
        "Vous pouvez le laisser vide s'il y a un autre niveau de sécurité."
      ],
      "Current access token": [
        "Jeton d'accès actuel"
      ],
      "Clearing the access token will mean public access to the instance.": [
        "L'effacement du jeton d'accès signifie que l'instance est accessible à tous."
      ],
      "Clear token": [
        "Effacer le jeton"
      ],
      "Confirm change": [
        "Confirmer le changement"
      ],
      "Failed to clear token": [
        "Echec de la suppression du jeton"
      ],
      "Failed to set new token": [
        "Échec de la création d'un nouveau jeton"
      ],
      "Slug": [
        "Type"
      ],
      "Token family slug to use in URLs (for internal use only)": [
        "Type de famille de jetons à utiliser dans les URL (à usage interne uniquement)"
      ],
      "Kind": [
        "Type"
      ],
      "Token family kind": [
        "Type de famille de jeton"
      ],
      "User-readable token family name": [
        "Nom de famille du jeton compréhensible par l'utilisateur"
      ],
      "Token family description for customers": [
        "Description de la famille de jetons pour les clients"
      ],
      "Valid After": [
        "Valide à partir de"
      ],
      "Token family can issue tokens after this date": [
        "La famille de jetons peut émettre des jetons après cette date."
      ],
      "Valid Before": [
        "Valide jusqu'à"
      ],
      "Token family can issue tokens until this date": [
        "La famille de jetons peut émettre des jetons jusqu'à cette date"
      ],
      "Duration": [
        "Durée"
      ],
      "Validity duration of a issued token": [
        "Durée de validité d'un jeton émis"
      ],
      "Token family created successfully.": [
        "Création réussie de la famille de jetons"
      ],
      "Could not create token family.": [
        "Impossible de créer la famille de jetons"
      ],
      "Token Families": [
        "Familles de jetons"
      ],
      "Add token family": [
        "Ajouter une famille de jetons"
      ],
      "Go to token family update page": [
        "Aller à la page de modification des familles de jetons"
      ],
      "Remove this token family from the database": [
        "Effacer cette famille de jetons de la base de données"
      ],
      "There are no token families yet, add the first one by pressing the + sign.": [
        "Il n'y a pas encore de familles de jetons, ajoutez la première en appuyant sur le signe +."
      ],
      "Token family updated successfully": [
        "Modification réussie de la famille de jetons"
      ],
      "Could not update the token family": [
        "Modification de la famille de jetons impossible"
      ],
      "Token family \"%1$s\" (SLUG: %2$s) has been deleted": [
        "Famille de jetons \"%1$s\" (type : %2$s) a été effacée"
      ],
      "Failed to delete token family": [
        "Échec de la suppression de la famille de jetons"
      ],
      "If you delete the %1$s token family (Slug: %2$s), all issued tokens will become invalid.": [
        "Si vous supprimez la famille de jetons %1$s ( Catégorie : %2$s), tous les jetons émis deviendront invalides."
      ],
      "Deleting a token family %1$s .": [
        "Suppression de la famille de jetons %1$s ."
      ],
      "Token Family: %1$s": [
        "Famille de jetons : %1$s"
      ],
      "Could not update token family": [
        "Impossible de mettre à jour la famille de jetons"
      ],
      "Please check the ID; it does not appear to be valid.": [
        "Vérifiez l'identifiant, il ne semble pas valide"
      ],
      "Must have 52 characters, current %1$s": [
        "Doit contenir 52 caractères, actuellement %1$s"
      ],
      "URL doesn't have the right format": [
        "L'URL n'a pas le format correct"
      ],
      "Credited bank account": [
        "Compte bancaire crédité"
      ],
      "Select an account": [
        "Compte en banque"
      ],
      "Bank account of the merchant where the payment was received": [
        "Compte bancaire du commerçant où le paiement a été reçu"
      ],
      "Wire transfer ID": [
        "Identifiant du virement bancaire"
      ],
      "Unique identifier of the wire transfer used by the exchange, must be 52 characters long": [
        "Identifiant du virement utilisé par l'exchange, doit être composé de 52 caractères"
      ],
      "Exchange URL": [
        "URL de l'exchange"
      ],
      "Base URL of the exchange that made the transfer, should have been in the wire transfer subject": [
        "L'URL de base de l'exchange qui a effectué le transfert aurait dû figurer dans l'objet du virement"
      ],
      "Amount credited": [
        "Montant crédité"
      ],
      "Actual amount that was wired to the merchant's bank account": [
        "Montant réel viré sur le compte bancaire du commerçant"
      ],
      "Wire transfer informed successfully": [
        "Virement bancaire informé avec succès"
      ],
      "Could not inform transfer": [
        "Impossible d'informer le virement"
      ],
      "Wire transfers": [
        "Identifiant du virement bancaire"
      ],
      "Add new transfer": [
        "Ajouter un nouveau virement"
      ],
      "Load more wire transfers preceding the first one": [
        "Ajouter des virements avant le premier"
      ],
      "Credit": [
        "Crédit"
      ],
      "Confirmed": [
        "Confirmé"
      ],
      "Verified": [
        "Vérifié"
      ],
      "Executed on": [
        "Exécuté le"
      ],
      "yes": [
        "oui"
      ],
      "no": [
        "non"
      ],
      "never": [
        "jamais"
      ],
      "unknown": [
        "inconnu"
      ],
      "Delete selected transfer from the database": [
        "Effacer le virement de la base de données"
      ],
      "Load more transfers after the last one": [
        "Ajouter des virements après le dernier"
      ],
      "There are no transfers to list yet, add more by pressing the + sign": [
        "Il n'y a pas encore de virements, ajoutez-en en appuyant sur le signe +"
      ],
      "All accounts": [
        "Tous les comptes"
      ],
      "Filter by account address": [
        "Filtre sur l'adresse du compte"
      ],
      "Only show wire transfers confirmed by the merchant": [
        "Afficher uniquement les virements bancaires confirmés par le commerçant"
      ],
      "Only show wire transfers claimed by the exchange": [
        "N'afficher que les virements bancaires réclamés par l'exchange"
      ],
      "Unverified": [
        "Non vérifié"
      ],
      "Wire transfer \"%1$s...\" has been deleted": [
        "Le virement bancaire \"%1$s\" a été effacé"
      ],
      "Failed to delete transfer": [
        "Échec de la suppression du virement"
      ],
      "Must be business or individual": [
        "Il doit s'agir d'une entreprise ou d'un particulier"
      ],
      "Pay delay can't be greater than wire transfer delay": [
        "Le délai de paiement ne peut être supérieur au délai de virement"
      ],
      "Max 7 lines": [
        "7 lignes maximum"
      ],
      "Doesn't match": [
        "Ne correspond pas"
      ],
      "Enable access control": [
        "Activer le contrôle d'accès"
      ],
      "Choose if the backend server should authenticate access.": [
        "Choisissez si le serveur backend doit authentifier l'accès."
      ],
      "Access control is not yet decided. This instance can't be created.": [
        "Le contrôle d'accès n'est pas encore défini. Cette instance ne peut pas être créée."
      ],
      "Authorization must be handled externally.": [
        "L'autorisation doit être gérée en externe."
      ],
      "Authorization is handled by the backend server.": [
        "L'autorisation doit être gérée par le serveur backend."
      ],
      "Need to complete marked fields and choose authorization method": [
        "Il faut remplir les champs marqués et choisir la méthode d'autorisation"
      ],
      "Name of the instance in URLs. The 'default' instance is special in that it is used to administer other instances.": [
        "Nom de l'instance dans les URL. L'instance 'default' est particulière en ce sens qu'elle est utilisée pour administrer les autres instances."
      ],
      "Business name": [
        "Nom de l'entreprise"
      ],
      "Legal name of the business represented by this instance.": [
        "Nom légal de l'entreprise représentée par cette instance."
      ],
      "Email": [
        "Email"
      ],
      "Contact email": [
        "Email de contact"
      ],
      "Website URL": [
        "URL du site web"
      ],
      "URL.": [
        "URL."
      ],
      "Logo": [
        "Logo"
      ],
      "Logo image.": [
        "Image du Logo."
      ],
      "Physical location of the merchant.": [
        "Localisation physique du commerçant."
      ],
      "Jurisdiction": [
        "Juridiction"
      ],
      "Jurisdiction for legal disputes with the merchant.": [
        "Juridiction compétente pour les litiges avec le commerçant."
      ],
      "Pay transaction fee": [
        "Payer les frais de transaction"
      ],
      "Cover the transaction cost or pass it on to the user.": [
        ""
      ],
      "Default payment delay": [
        "Délai de paiement par défaut"
      ],
      "Time customers have to pay an order before the offer expires by default.": [
        "Temps par défaut qu'ont les clients pour payer une commande avant que celle ci n'expire."
      ],
      "Default wire transfer delay": [
        "Délai par défaut pour les virements bancaires"
      ],
      "Maximum time an exchange is allowed to delay wiring funds to the merchant, enabling it to aggregate smaller payments into larger wire transfers and reducing wire fees.": [
        "Durée maximale pendant laquelle un exchange est autorisé à retarder le transfert de fonds au commerçant, ce qui lui permet de regrouper les petits paiements en virements plus importants et de réduire les frais de virement."
      ],
      "Instance id": [
        "Identifiant d'instance"
      ],
      "Failed to update instance": [
        "Echec de la modification de l'instance"
      ],
      "Must be \"pay\" or \"refund\"": [
        "Doit être \"payer\" ou \"rembourser\""
      ],
      "Must be one of '%1$s'": [
        "Doit être l'un des éléments suivants '%1$s'"
      ],
      "Webhook ID to use": [
        "Identifiant du Webhook à utiliser"
      ],
      "Event": [
        "Evènement"
      ],
      "Pay": [
        "Payer"
      ],
      "The event of the webhook: why the webhook is used": [
        "L'événement du webhook : pourquoi le webhook est-il utilisé"
      ],
      "Method": [
        "Méthode"
      ],
      "GET": [
        "GET"
      ],
      "POST": [
        "POST"
      ],
      "PUT": [
        "PUT"
      ],
      "PATCH": [
        "PATCH"
      ],
      "HEAD": [
        "HEAD"
      ],
      "Method used by the webhook": [
        "Méthode utilisée par le webhook"
      ],
      "URL": [
        "URL"
      ],
      "URL of the webhook where the customer will be redirected": [
        "URL du webhook où le client sera redirigé"
      ],
      "The text below supports the %1$s template engine. Any string between %2$s and %3$s will be replaced with the value of the corresponding variable.": [
        "Le texte ci-dessous prend en charge le moteur de modèle %1$s. Toute chaîne comprise entre %2$s et %3$s sera remplacée par la valeur de la variable correspondante."
      ],
      "For example %1$s will be replaced with the the order's price": [
        "Par exemple, %1$s sera remplacé par le prix de la commande"
      ],
      "The short list of variables are:": [
        "La liste restreinte des variables est la suivante :"
      ],
      "order's description": [
        "description de la commande"
      ],
      "order's price": [
        "prix de la commande"
      ],
      "order's unique identification": [
        "identification unique de la commande"
      ],
      "the amount that was being refunded": [
        "le montant remboursé"
      ],
      "the reason entered by the merchant staff for granting the refund": [
        "la raison invoquée par le personnel du commerçant pour accorder le remboursement"
      ],
      "time of the refund in nanoseconds since 1970": [
        "instant du remboursement en nanosecondes depuis 1970"
      ],
      "Http body": [
        "body HTTP"
      ],
      "Body template by the webhook": [
        "Modèle de body pour le webhook"
      ],
      "Webhook create successfully": [
        "Création réussie du webhook"
      ],
      "Could not create the webhook": [
        "Creation du webhook impossible"
      ],
      "Could not create webhook": [
        "Creation de webhook impossible"
      ],
      "Webhooks": [
        "Webhooks"
      ],
      "Add new webhooks": [
        "Ajouter de nouveaux webhooks"
      ],
      "Load more webhooks before the first one": [
        "Ajouter des webhooks avant le premier"
      ],
      "Event type": [
        "Type d'évènement"
      ],
      "Delete selected webhook from the database": [
        "Supprimer le webhook sélectionné de la base de données"
      ],
      "Load more webhooks after the last one": [
        "Ajouter des webhooks après le dernier"
      ],
      "There are no webhooks to list yet, add more by pressing the + sign": [
        "Il n'y a pas encore de webhook, ajoutez-en en appuyant sur le signe +"
      ],
      "Webhook deleted successfully": [
        "Suppression réussie du webhook"
      ],
      "Could not delete the webhook": [
        "Impossible de supprimer le webhook"
      ],
      "Header": [
        "Entête"
      ],
      "Header template of the webhook": [
        "Modèle d'entête pour le webhook"
      ],
      "Body": [
        "Corps"
      ],
      "Webhook updated": [
        "Webhook modifié"
      ],
      "Could not update webhook": [
        "Impossible de modifier le webhook"
      ],
      "Language": [
        "Langue"
      ],
      "Advance order creation": [
        "Création de commandes à l'avance"
      ],
      "Shows more options in the order creation form": [
        "Affiche plus d'options dans le formulaire de création de commande"
      ],
      "Advance instance settings": [
        "Configuration avancée de l'instance"
      ],
      "Shows more options in the instance settings form": [
        "Affiche plus d'options dans le formulaire de configuration de l'instance"
      ],
      "Date format": [
        "Format de la date"
      ],
      "How the date is going to be displayed": [
        "Comment la date sera affichée"
      ],
      "Developer mode": [
        "Mode dévelopeur"
      ],
      "Shows more options and tools which are not intended for general audience.": [
        "Présente davantage d'options et d'outils qui ne sont pas destinés au grand public."
      ],
      "Total products": [
        "Total des produits"
      ],
      "Delete selected category from the database": [
        "Supprimer la catégorie sélectionnée de la base de données"
      ],
      "There are no categories yet, add more pressing the + sign": [
        "Il n'y a pas encore de catégorie, ajoutez-en en appuyant sur le signe +"
      ],
      "Category delete successfully": [
        "Suppression de la catégorie réussie"
      ],
      "Could not delete the category": [
        "Impossible de supprimer la catégorie"
      ],
      "Invalid. Please insert only characters and numbers": [
        "Invalide. Veuillez insérer uniquement des caractères et des chiffres"
      ],
      "Category name": [
        "Nom de la catégorie"
      ],
      "Category added successfully": [
        "Ajout de la catégorie réussi"
      ],
      "Could not add category": [
        "Impossible d'ajouter la catégorie"
      ],
      "Id:": [
        "Identifiant :"
      ],
      "Name of the category": [
        "Nom de la catégorie"
      ],
      "Products": [
        "Produits"
      ],
      "Search by product description or id": [
        "Recherche par description ou identifiant de produit"
      ],
      "Products that this category will list.": [
        "Produits que cette catégorie énumère."
      ],
      "Could not update category": [
        "Impossible de modifier la catégorie"
      ],
      "Category ID is unknown": [
        "Identifiant de catégorie inconnu"
      ],
      "Without this the merchant backend will refuse to create new orders.": [
        "Sans cela, le backend du commerçant refusera de créer de nouvelles commandes."
      ],
      "Hide for today": [
        "Cacher pour aujourd'hui"
      ],
      "KYC verification needed": [
        "Vérification de l'identité du client (KYC) nécessaire"
      ],
      "Some transfers are on hold until the KYC process is completed. Visit the KYC section in the left panel for more information": [
        "Certains transferts sont suspendus jusqu'à ce que la procédure de vérification de l'identité du client (KYC) soit achevée. Pour plus d'informations, consultez la section KYC dans le panneau de gauche"
      ],
      "Configuration": [
        "Configuration"
      ],
      "OTP Devices": [
        "Appareils OTP"
      ],
      "Settings": [
        "Configuration"
      ],
      "Access token": [
        "Jeton d'accès"
      ],
      "Connection": [
        "Connexion"
      ],
      "Interface": [
        "Interface"
      ],
      "List": [
        "Liste"
      ],
      "Log out": [
        "Logout"
      ],
      "Failed to create instance": [
        "Impossible de créer une instance"
      ],
      "checking compatibility with server...": [
        "vérification de la compatibilité avec le serveur..."
      ],
      "Contacting the server failed": [
        "Impossible de contacter le serveur"
      ],
      "The server version is not supported": [
        "La version du serveur n'est pas supportée"
      ],
      "Supported version \"%1$s\", server version \"%2$s\".": [
        "Version supportée \"%1$s\", version du serveur \"%2$s\"."
      ],
      "Deleting": [
        "Suppression"
      ],
      "Changing": [
        "Modification"
      ],
      "Manage access token": [
        "Gérer le jeton d'accès"
      ],
      "Business Name": [
        "Nom de l'entreprise"
      ],
      "Order ID": [
        "Identifiant de la commande"
      ],
      "Payment URL": [
        "URL de paiement"
      ]
    }
  },
  "domain": "messages",
  "plural_forms": "nplurals=2; plural=(n!=1);",
  "lang": "fr",
  "completeness": 99
};

strings['es'] = {
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=2; plural=n != 1;",
        "lang": "es"
      },
      "The request reached a timeout, check your connection.": [
        "El pedido a terminado en tiempo agotado, verifique su conexión."
      ],
      "The request was cancelled.": [
        "El pedido a sido cancelado."
      ],
      "A lot of request were made to the same server and this action was throttled.": [
        "Se hicieron muchos pedidos al mismo servidor y esta acción a sido limitada."
      ],
      "The response of the request is malformed.": [
        "La respuesta del pedido no esta bien formada."
      ],
      "Could not complete the request due to a network problem.": [
        "No se pudo completar el pedido por problemas de red."
      ],
      "Unexpected request error.": [
        "Error inesperado en el pedido."
      ],
      "Unexpected error.": [
        "Error inesperado."
      ],
      "Cancel": [
        "Cancelar"
      ],
      "%1$s": [
        "%1$s"
      ],
      "Close": [
        "Cerrar"
      ],
      "Continue": [
        "Continuar"
      ],
      "Clear": [
        "Limpiar"
      ],
      "Confirm": [
        "Confirmar"
      ],
      "Required": [
        "Requerido"
      ],
      "Letter must be a JSON string": [
        "Letter debe ser una cadena JSON"
      ],
      "JSON string is invalid": [
        "La cadena JSON es invalida"
      ],
      "Import": [
        "Importar"
      ],
      "Importing an account from the bank": [
        "Importando una cuenta desde el banco"
      ],
      "You can export your account settings from the Libeufin Bank's account profile. Paste the content in the next field.": [
        "Puedes exportar una configuracion de cuenta desde el perfil de cuenta del banco Libeufin. Pega el contenido en el próximo campo."
      ],
      "Account information": [
        "Información de la cuenta"
      ],
      "Correct form": [
        "Formulario correcto"
      ],
      "Comparing account details": [
        "Comparando detalle de cuentas"
      ],
      "Testing against the account info URL succeeded but the account information reported is different with the account details form.": [
        "Verificando contra la URL de información de cuenta exitoso pero la información de la cuenta reportada es diferente a los detalles de cuenta en el formulario."
      ],
      "Field": [
        "Campo"
      ],
      "In the form": [
        "En el formulario"
      ],
      "Reported": [
        "Reportado"
      ],
      "Type": [
        "Tipo"
      ],
      "IBAN": [
        "IBAN"
      ],
      "Address": [
        "Dirección"
      ],
      "Host": [
        "Host"
      ],
      "Account id": [
        "Identificacion de cuenta"
      ],
      "Owner's name": [
        "Nombre del dueno"
      ],
      "Account": [
        "Cuenta"
      ],
      "Bank host": [
        "Host del banco"
      ],
      "Bank account": [
        "Cuenta bancaria"
      ],
      "BIC": [
        "BIC"
      ],
      "Ok": [
        "Ok"
      ],
      "Validate bank account: %1$s": [
        "Validar cuenta bancaria: %1$s"
      ],
      "You need to make a bank transfer with the specified subject to validate that you are the owner of the account.": [
        "Necesitas hacer una transferencia bancaria con el asunto especificado para validar que eres el propietario de la cuenta."
      ],
      "Step 1:": [
        "Paso 1:"
      ],
      "Copy this string and paste it into the field 'Subject' (resp. 'Purpose') in your banking app or bank website": [
        "Copia este codigo y pegalo en el campo asunto de to aplicacion bancaria o sitio de banco"
      ],
      "Subject": [
        "Asunto"
      ],
      "Step 2:": [
        "Paso 2:"
      ],
      "Copy and paste this IBAN and the name into the receiver fields in your banking app or website": [
        "Copia y pega este IBAN y el nombre en el campo destinatario en to aplicaion bancaria o sitio web"
      ],
      "Receiver name": [
        "Fecha de entrega"
      ],
      "Step 3:": [
        "Paso 3:"
      ],
      "Select the smallest possible amount for the wire transfer in your banking app or on your online banking website.": [
        ""
      ],
      "Make sure ALL data is correct, including the subject and you are using your selected bank account. You can use the copy buttons (%1$s) to prevent typing errors or the \"payto://\" URI below to copy just one value.": [
        "Asegurate que TODA la informacion es correcta, incuyendo el asunto y que estas usando la cuenta indicada. Puedes usar el boton copiar (%1$s) para prevenir errores de tipeo o el \"payto://\" URI debajo para copiar un solo valor."
      ],
      "Alternatively, if your bank already supports the standard 'PayTo URI', you can use this %1$s link instead": [
        "Alternativamente si tu banco soporta PayTo URI, puedes usar este %1$s link"
      ],
      "If you delete the instance named %1$s (ID: %2$s), the merchant will no longer be able to process orders or refunds": [
        "Si eliminas la instancia con nombre %1$s (ID: %2$s), el comerciante no podrá procesar ordenes o rembolsos"
      ],
      "This action deletes the instance private key, but preserves all transaction data. You can still access that data after deleting the instance.": [
        "Esta accion elimina la clave privada de la instancia pero preserva toda la información transaccional. Podrás acceder a esa información después de borrar la instancia."
      ],
      "Deleting an instance %1$s .": [
        "Borrando la instancia %1$s."
      ],
      "If you purge the instance named %1$s (ID: %2$s), you will also delete all it&apos;s transaction data.": [
        "Si purgas la instancia con nombre %1$s (ID: %2$s), tambien borraras toda su informacion transaccional."
      ],
      "The instance will disappear from your list, and you will no longer be able to access it&apos;s data.": [
        "La instancia desaparecera de tu lista, y no volveras a tener acceso a su informacion."
      ],
      "Purging an instance %1$s .": [
        "Purgando la instancia %1$s."
      ],
      "Is not the same as the current access token": [
        "no es el mismo que el token de acceso actual"
      ],
      "Can't be the same as the old token": [
        "no puede ser igual al viejo token"
      ],
      "Is not the same": [
        "no son iguales"
      ],
      "You are updating the access token for the instance with ID %1$s": [
        "Está actualizando el token de acceso para la instancia con id %1$s"
      ],
      "Old access token": [
        "Token de acceso viejo"
      ],
      "Access token currently in use": [
        "acceder al token en uso actualmente"
      ],
      "New access token": [
        "Nuevo token de acceso"
      ],
      "Next access token to be used": [
        "siguiente token de acceso a usar"
      ],
      "Repeat access token": [
        "Repetir token de acceso"
      ],
      "Confirm the same access token": [
        "confirmar el mismo token de acceso"
      ],
      "Clearing the access token will mean public access to the instance": [
        "Limpiar el token de acceso significa acceso público a la instancia"
      ],
      "Can't be the same as the old access token": [
        "no puede ser igual al anterior token de acceso"
      ],
      "You are setting the access token for the new instance": [
        "Está estableciendo el token de acceso para la nueva instancia"
      ],
      "With external authorization method no check will be done by the merchant backend": [
        "Con el método de autorización externa no se hará ninguna revisión por el backend del comerciante"
      ],
      "Set external authorization": [
        "Establecer autorización externa"
      ],
      "Set access token": [
        "Establecer token de acceso"
      ],
      "Operation in progress...": [
        "Operación en progreso..."
      ],
      "The operation will be automatically canceled after %1$s seconds": [
        "La operación será automáticamente cancelada luego de %1$s segundos"
      ],
      "Your password is incorrect": [
        "La constrasena es incorrecta"
      ],
      "Your instance cannot be found": [
        "Orden no encontrada"
      ],
      "Login required": [
        "Login necesario"
      ],
      "Please enter your access token for %1$s.": [
        "Por favor, introduzca su clave de acceso por %1$s."
      ],
      "Access Token": [
        "Token de acceso"
      ],
      "Instances": [
        "Instancias"
      ],
      "Delete": [
        "Borrar"
      ],
      "Add new instance": [
        "agregar nueva instancia"
      ],
      "ID": [
        "ID"
      ],
      "Name": [
        "Nombre"
      ],
      "Edit": [
        "Editar"
      ],
      "Purge": [
        "Purgar"
      ],
      "There are no instances yet, add more pressing the + sign": [
        "Todavía no hay instancias, agregue más presionando el signo +"
      ],
      "Only show active instances": [
        "Solo mostrar instancias activas"
      ],
      "Active": [
        "Activo"
      ],
      "Only show deleted instances": [
        "Mostrar solo instancias eliminadas"
      ],
      "Deleted": [
        "Eliminado"
      ],
      "Show all instances": [
        "Mostrar todas las instancias"
      ],
      "All": [
        "Todo"
      ],
      "Instance \"%1$s\" (ID: %2$s) has been deleted": [
        "La instancia '%1$s' (ID: %2$s) fue eliminada"
      ],
      "Failed to delete instance": [
        "Fallo al eliminar instancia"
      ],
      "Instance '%1$s' (ID: %2$s) has been purged": [
        "Instance '%1$s' (ID: %2$s) ha sido deshabilitada"
      ],
      "Failed to purge instance": [
        "Fallo al purgar la instancia"
      ],
      "Loading...": [
        "Cargando..."
      ],
      "This is not a valid bitcoin address.": [
        "Esta no es una dirección de bitcoin válida."
      ],
      "This is not a valid Ethereum address.": [
        "Esta no es una dirección de Ethereum válida."
      ],
      "This is not a valid host.": [
        "Esta no es una dirección de bitcoin válida."
      ],
      "IBAN numbers usually have more that 4 digits": [
        "Los números IBAN usualmente tienen mas de 4 digitos"
      ],
      "IBAN numbers usually have less that 34 digits": [
        "Los números IBAN usualmente tienen menos de 34 digitos"
      ],
      "IBAN country code not found": [
        "Código de pais de IBAN no encontrado"
      ],
      "IBAN number is invalid, checksum is wrong": [
        "El número IBAN no es válido, falló la verificación"
      ],
      "Choose one...": [
        "Elija uno..."
      ],
      "Method to use for wire transfer": [
        "Método a usar para la transferencia"
      ],
      "Routing": [
        "Enrutamiento"
      ],
      "Routing number.": [
        "Número de enrutamiento."
      ],
      "Account number.": [
        "Numero de cuenta"
      ],
      "Code": [
        "Código"
      ],
      "Business Identifier Code.": [
        "Código de identificación de la empresa."
      ],
      "International Bank Account Number.": [
        "Número de cuenta bancaria."
      ],
      "Unified Payment Interface.": [
        "Interfaz de pago unificado."
      ],
      "Bitcoin protocol.": [
        "Protocolo Bitcoin."
      ],
      "Ethereum protocol.": [
        "Protocolo Ethereum."
      ],
      "Interledger protocol.": [
        "Protocolo Interledger."
      ],
      "Bank host.": [
        "Host del banco."
      ],
      "Without scheme and may include subpath:": [
        "Sin el esquema y puede incluir subpath:"
      ],
      "Bank account.": [
        "Cuenta bancaria."
      ],
      "Legal name of the person holding the account.": [
        "Nombre legal de la persona titular de la cuenta."
      ],
      "It should match the bank account name.": [
        "Deberia coincidir con el nombre de la cuenta."
      ],
      "Invalid url": [
        "formato inválido"
      ],
      "URL must end with a '/'": [
        "La URL que debe terminar con '/'"
      ],
      "URL must not contain params": [
        "La URL no debe contener parametros"
      ],
      "URL must not hash param": [
        "La URL no debe contener hash"
      ],
      "The request to check the revenue API failed.": [
        "El pedido para verificar el API fallo."
      ],
      "Server replied with \"bad request\".": [
        "El servidor responde con un código de error."
      ],
      "Unauthorized, check credentials.": [
        "Sin autorizacion, revisar credenciales."
      ],
      "The endpoint does not seem to be a Taler Revenue API.": [
        "El endpoint no parece pertenecer al API Taler Revenue."
      ],
      "The request was made correctly, but the bank's server did not respond with the appropriate value for 'credit_account', so we cannot confirm that it is the same bank account.": [
        "El request tuvo exito pero el server no respondio con el campo \"credit_account\" asi que no podemos verificar que sea la misma cuenta."
      ],
      "Account:": [
        "Cuenta:"
      ],
      "If the bank supports Taler Revenue API then you can add the endpoint URL below to keep the revenue information in sync.": [
        "Si el banco soporta el API Taler Revenue entonces puedes agregar el endpoint URL debajo para mantener la informacion de ganancias sincronizada."
      ],
      "Endpoint URL": [
        "URL endpoint"
      ],
      "From where the merchant can download information about incoming wire transfers to this account": [
        "Desde donde el comerciante puede descargar informacion acerca de las transferencias bancarias entrantes hacia esta cuenta"
      ],
      "Auth type": [
        "Tipo de evento"
      ],
      "Choose the authentication type for the account info URL": [
        "Verifica el tipo de autenticacion para la URL de infomacion de cuenta"
      ],
      "Without authentication": [
        "Sin autenticacion"
      ],
      "With password": [
        "Con contrasena"
      ],
      "With token": [
        "Crear token"
      ],
      "Do not change": [
        "URL del proveedor"
      ],
      "Username": [
        "Usuario"
      ],
      "Username to access the account information.": [
        "Usuario para cceder a la informacion de la cuenta."
      ],
      "Password": [
        "Contrasena"
      ],
      "Password to access the account information.": [
        "Contrasena para acceder a la informacion de la cuenta."
      ],
      "Token": [
        "Token"
      ],
      "Access token to access the account information.": [
        "Token de acceso para acceder a la informacion de la cuenta."
      ],
      "Match": [
        "Coincidencia"
      ],
      "Check where the information match against the server info.": [
        "Verifica si la informacion con el servidor coincide."
      ],
      "Not verified": [
        "Sin verificar"
      ],
      "Last test was ok": [
        "La ultima prueba fue exitosa"
      ],
      "Last test failed": [
        "La ultima prueba fallo"
      ],
      "Compare info from server with account form": [
        "Comparar la informacion del servidor con el formulario de la cuenta"
      ],
      "Test": [
        "Probar"
      ],
      "Need to complete marked fields": [
        "Necesita completar los campos marcados"
      ],
      "Confirm operation": [
        "Confirmado"
      ],
      "Account details": [
        "Dirección de cuenta"
      ],
      "Import from bank": [
        "Importar desde el banco"
      ],
      "Could not create account": [
        "no se pudo crear el producto"
      ],
      "No 'default' instance configured yet.": [
        "No se ha configurado una instancia por 'defecto' todavía."
      ],
      "Create a 'default' instance to begin using the merchant backoffice.": [
        "Cree una instancia \"por defecto\" para empezar a utilizar el backoffice comerciante."
      ],
      "Bank accounts": [
        "Cuenta bancaria"
      ],
      "Add new account": [
        "Abono en cuenta bancaria"
      ],
      "Wire method: Bitcoin": [
        "Método de transferencia"
      ],
      "SegWit 1": [
        ""
      ],
      "SegWit 2": [
        ""
      ],
      "Delete selected accounts from the database": [
        "eliminar transferencia seleccionada de la base de datos"
      ],
      "Wire method: x-taler-bank": [
        "Método de transferencia"
      ],
      "Account name": [
        "Dirección de la cuenta"
      ],
      "Wire method: IBAN": [
        "Método de transferencia"
      ],
      "Other accounts": [
        "Cuenta objetivo"
      ],
      "Path": [
        ""
      ],
      "There are no accounts yet, add more pressing the + sign": [
        "No existen productos todavía, añadir más pulsando el símbolo +"
      ],
      "You need to associate a bank account to receive revenue.": [
        "URI que especifica la cuenta bancaria para acreditar los ingresos."
      ],
      "Without this the you won't be able to create new orders.": [
        "Sin esto, no podrá crear nuevos pedidos."
      ],
      "The bank account has been successfully deleted.": [
        "La cuenta bancaria se ha eliminado correctamente."
      ],
      "Could not delete the bank account": [
        "No se pudo eliminar la cuenta bancaria"
      ],
      "Could not update account": [
        "No se ha podido actualizar la cuenta"
      ],
      "Could not delete account": [
        "No se pudo eliminar la cuenta"
      ],
      "Pending KYC verification": [
        "Verificación KYC pendiente"
      ],
      "Exchange": [
        "Exchange"
      ],
      "Reason": [
        "Razón"
      ],
      "Pending KYC process, click here to complete": [
        "Proceso de KYC pendiente, click aqui para completar"
      ],
      "The Payment Service Provider requires an account verification.": [
        "El exchange require una verificacion de la cuenta."
      ],
      "Http Status": [
        "Estado http"
      ],
      "No pending kyc verification!": [
        "¡No hay verificación kyc pendiente!"
      ],
      "Change value to unknown date": [
        "cambiar valor a fecha desconocida"
      ],
      "Change value to empty": [
        "cambiar valor a vacío"
      ],
      "Change value to never": [
        "cambiar valor a nunca"
      ],
      "Never": [
        "nunca"
      ],
      "days": [
        "días"
      ],
      "hours": [
        "horas"
      ],
      "minutes": [
        "minutos"
      ],
      "seconds": [
        "segundos"
      ],
      "Forever": [
        "por siempre"
      ],
      "%1$sM": [
        "%1$sM"
      ],
      "%1$sY": [
        "%1$sA"
      ],
      "%1$sd": [
        "%1$sd"
      ],
      "%1$sh": [
        "%1$sh"
      ],
      "%1$smin": [
        "%1$smin"
      ],
      "%1$ssec": [
        "%1$sseg"
      ],
      "Country": [
        "País"
      ],
      "Building number": [
        "Número de edificio"
      ],
      "Building name": [
        "Nombre de edificio"
      ],
      "Street": [
        "Calle"
      ],
      "Post code": [
        "Código postal"
      ],
      "Town location": [
        "Ubicación de ciudad"
      ],
      "Town": [
        "Ciudad"
      ],
      "District": [
        "Distrito"
      ],
      "Country subdivision": [
        "Subdivisión de país"
      ],
      "Description": [
        "Descripción"
      ],
      "Enter description or id": [
        "Insertar un ID para el pedido"
      ],
      "no match found with that description or id": [
        "no se encontraron productos con esa descripción"
      ],
      "You must enter a valid product identifier.": [
        "Debe ingresar un identificador de producto válido."
      ],
      "Quantity must be greater than 0!": [
        "¡Cantidad debe ser mayor que 0!"
      ],
      "This quantity exceeds remaining stock. Currently, only %1$s units remain unreserved in stock.": [
        "Esta cantidad excede las existencias restantes. Actualmente, solo quedan %1$s unidades sin reservar en las existencias."
      ],
      "Search product": [
        "Productos de inventario"
      ],
      "Quantity": [
        "Cantidad"
      ],
      "How many products will be added": [
        "cuántos productos serán agregados"
      ],
      "Add from inventory": [
        "Agregar del inventario"
      ],
      "Image must be smaller than 1 MB": [
        "La imagen debe ser mas chica que 1 MB"
      ],
      "Add": [
        "Agregar"
      ],
      "Remove": [
        "Eliminar"
      ],
      "Invalid": [
        "no válido"
      ],
      "This product has %1$s applicable taxes configured.": [
        "Este producto tiene %1$s impuestos aplicables configurados."
      ],
      "No taxes configured for this product.": [
        "Ningun impuesto configurado para este producto."
      ],
      "Amount": [
        "Monto"
      ],
      "Taxes can be in currencies that differ from the main currency used by the merchant.": [
        "Impuestos pueden estar en divisas que difieren de la principal divisa usada por el comerciante."
      ],
      "Enter currency and value separated with a colon, e.g. &quot;USD:2.3&quot;.": [
        "Ingrese divisa y valor separado por dos puntos, e.g. &quot;USD:2.3&quot;."
      ],
      "Legal name of the tax, e.g. VAT or import duties.": [
        "Nombre legal del impuesto, e.g. IVA o arancel."
      ],
      "Add tax to the tax list": [
        "agregar impuesto a la lista de impuestos"
      ],
      "Describe and add a product that is not in the inventory list": [
        "describa y agregue un producto que no está en la lista de inventarios"
      ],
      "Add custom product": [
        "Agregue un producto personalizado"
      ],
      "Complete information of the product": [
        "Complete información del producto"
      ],
      "Must be a number": [
        "no es un número"
      ],
      "Must be grater than 0": [
        "debe ser mayor que 0"
      ],
      "Image": [
        "Imagen"
      ],
      "Photo of the product.": [
        "foto del producto."
      ],
      "Full product description.": [
        "descripción completa del producto"
      ],
      "Unit": [
        "Unidad"
      ],
      "Name of the product unit.": [
        "nombre de la unidad del producto."
      ],
      "Price": [
        "Precio"
      ],
      "Amount in the current currency.": [
        "Monto de la divisa actual."
      ],
      "How many products will be added.": [
        "Cuántos productos serán agregados."
      ],
      "Taxes": [
        "Impuestos"
      ],
      "Unit price": [
        "precio unitario"
      ],
      "Total price": [
        "Precio total"
      ],
      "Must be greater than 0": [
        "debe ser mayor que 0"
      ],
      "Refund deadline can't be before pay deadline": [
        "plazo de reembolso no puede ser antes que el plazo de pago"
      ],
      "Wire transfer deadline can't be before refund deadline": [
        "el plazo de la transferencia bancaria no puede ser antes que el plazo de reembolso"
      ],
      "Wire transfer deadline can't be before pay deadline": [
        "el plazo de la transferencia bancaria no puede ser antes que el plazo de pago"
      ],
      "Must have a refund deadline": [
        "debería tener un plazo de reembolso"
      ],
      "Auto refund can't be after refund deadline": [
        "reembolso automático no puede ser después qu el plazo de reembolso"
      ],
      "Must be in the future": [
        "deberían ser en el futuro"
      ],
      "Simple": [
        "Simple"
      ],
      "Advanced": [
        "Avanzado"
      ],
      "Manage products in order": [
        "Manejar productos en orden"
      ],
      "%1$s products with a total price of %2$s.": [
        "%1$s productos con un precio total de %2$s."
      ],
      "Manage list of products in the order.": [
        "Manejar lista de productos en la orden."
      ],
      "Remove this product from the order.": [
        "Remover este producto de la orden."
      ],
      "Total product price added up": [
        "precio total de producto agregado"
      ],
      "Amount to be paid by the customer": [
        "Monto a ser pagado por el cliente"
      ],
      "Order price": [
        "Precio de la orden"
      ],
      "Final order price": [
        "Precio final de la orden"
      ],
      "Summary": [
        "Resumen"
      ],
      "Title of the order to be shown to the customer": [
        "Título de la orden a ser mostrado al cliente"
      ],
      "Shipping and fulfillment": [
        "Envío y cumplimiento"
      ],
      "Delivery date": [
        "Fecha de entrega"
      ],
      "Deadline for physical delivery assured by the merchant.": [
        "Plazo para la entrega física asegurado por el comerciante."
      ],
      "Location": [
        "Ubicación"
      ],
      "Address where the products will be delivered": [
        "dirección a donde los productos serán entregados"
      ],
      "Fulfillment URL": [
        "URL de cumplimiento"
      ],
      "URL to which the user will be redirected after successful payment.": [
        "URL al cual el usuario será redirigido luego de pago exitoso."
      ],
      "Taler payment options": [
        "Opciones de pago de Taler"
      ],
      "Override default Taler payment settings for this order": [
        "Sobrescribir pagos por omisión de Taler para esta orden"
      ],
      "Payment time": [
        "Opciones de pago"
      ],
      "Time for the customer to pay for the offer before it expires. Inventory products will be reserved until this deadline. Time start to run after the order is created.": [
        "Plazo límite para que el cliente pague por la oferta antes de que expire. Productos del inventario serán reservados hasta este plazo límite."
      ],
      "Default": [
        "Importe por defecto"
      ],
      "Refund time": [
        "Reembolsado"
      ],
      "Time while the order can be refunded by the merchant. Time starts after the order is created.": [
        "Tiempo hasta el cual la orden puede ser reembolsada por el comerciante."
      ],
      "Wire transfer time": [
        "ID de la transferencia"
      ],
      "Time for the exchange to make the wire transfer. Time starts after the order is created.": [
        "Plazo para que el proveedor haga la transferencia bancaria."
      ],
      "Auto-refund time": [
        "Plazo de reembolso automático"
      ],
      "Time until which the wallet will automatically check for refunds without user interaction.": [
        "Tiempo hasta el cual la cartera será automáticamente revisada por reembolsos sin interacción por parte del usuario."
      ],
      "Maximum fee": [
        "Máxima tarifa de transferencia"
      ],
      "Maximum fees the merchant is willing to cover for this order. Higher deposit fees must be covered in full by the consumer.": [
        "Máxima tarifa de depósito que el comerciante esta dispuesto a cubir para esta orden. Mayores tarifas de depósito deben ser cubiertas completamente por el consumidor."
      ],
      "Create token": [
        "Crear token"
      ],
      "If the order ID is easy to guess the token will prevent users to steal orders from others.": [
        "Si el ID de orden es facil de adivinar el token previene que los usuarios puedan robar ordenes de otras personas."
      ],
      "Minimum age required": [
        "Edad mínima requerida"
      ],
      "Any value greater than 0 will limit the coins able be used to pay this contract. If empty the age restriction will be defined by the products": [
        "Cualquier valor superior a 0 limitará las monedas que se pueden utilizar para pagar este contrato. Si está vacío, la restricción de edad vendrá definida por los productos"
      ],
      "Min age defined by the producs is %1$s": [
        "La edad mínima definida por el producto es%1$s"
      ],
      "No product with age restriction in this order": [
        "No hay productos con restriccion de edad en esta orden"
      ],
      "Additional information": [
        "Información adicional"
      ],
      "Custom information to be included in the contract for this order.": [
        "Información personalizada que debe incluirse en el contrato para este pedido."
      ],
      "You must enter a value in JavaScript Object Notation (JSON).": [
        "Debes introducir un valor en JavaScript Object Notation (JSON)."
      ],
      "Custom field name": [
        "Nombre de edificio"
      ],
      "Disabled": [
        "Inhabilitado"
      ],
      "No deadline": [
        "Plazo de reembolso"
      ],
      "Deadline at %1$s": [
        "Expiracion en %1$s"
      ],
      "Could not create order": [
        "no se pudo crear la reserva"
      ],
      "No exchange would accept a payment because of KYC requirements.": [
        "Ningun exchange aceptara pagos debido a requerimientos de KYC."
      ],
      "No more stock for product with ID \"%1$s\".": [
        "No hay mas stock del producto con id \"%1$s\"."
      ],
      "Orders": [
        "Órdenes"
      ],
      "Create order": [
        "crear orden"
      ],
      "Load first page": [
        "Cargar primera pagina"
      ],
      "Date": [
        "Fecha"
      ],
      "Refund": [
        "Devolución"
      ],
      "copy url": [
        "copiar url"
      ],
      "Load more orders after the last one": [
        "cargue más transferencia luego de la última"
      ],
      "Load next page": [
        "Cargar siguiente pagina"
      ],
      "No orders have been found matching your query!": [
        "¡No se encontraron órdenes que emparejen su búsqueda!"
      ],
      "Duplicated": [
        "duplicado"
      ],
      "This value exceed the refundable amount": [
        "este monto excede el monto reembolsable"
      ],
      "Amount to be refunded": [
        "monto a ser reembolsado"
      ],
      "Max refundable:": [
        "Máximo reembolzable:"
      ],
      "Requested by the customer": [
        "pedido por el consumidor"
      ],
      "Other": [
        "otro"
      ],
      "Why this order is being refunded": [
        "por qué esta orden está siendo reembolsada"
      ],
      "More information to give context": [
        "más información para dar contexto"
      ],
      "Contract terms": [
        "Términos de contrato"
      ],
      "Human-readable description of the whole purchase": [
        "descripción legible de toda la compra"
      ],
      "Total price for the transaction": [
        "precio total de la transacción"
      ],
      "URL for this purchase": [
        "URL para esta compra"
      ],
      "Max fee": [
        "Máxima comisión"
      ],
      "Maximum total deposit fee accepted by the merchant for this contract": [
        "tasa máxima total de depósito aceptada por el comerciante para este contrato"
      ],
      "Created at": [
        "Creado en"
      ],
      "Time when this contract was generated": [
        "momento en que se generó este contrato"
      ],
      "Refund deadline": [
        "Plazo de reembolso"
      ],
      "After this deadline has passed no refunds will be accepted": [
        "pasado este plazo no se aceptarán devoluciones"
      ],
      "Payment deadline": [
        "Plazo de pago"
      ],
      "After this deadline, the merchant won't accept payments for the contract": [
        "pasado este plazo, el comerciante no aceptará pagos por el contrato"
      ],
      "Wire transfer deadline": [
        "Plazo de la transferencia bancaria"
      ],
      "Transfer deadline for the exchange": [
        "plazo de transferencia para el proveedor"
      ],
      "Time indicating when the order should be delivered": [
        "fecha en la que debe entregarse el pedido"
      ],
      "Where the order will be delivered": [
        "dónde se entregará el pedido"
      ],
      "Auto-refund delay": [
        "Plazo de reembolso automático"
      ],
      "How long the wallet should try to get an automatic refund for the purchase": [
        "cuánto tiempo debe intentar la cartera obtener el reembolso automático de la compra"
      ],
      "Extra info": [
        "Información adicional"
      ],
      "Extra data that is only interpreted by the merchant frontend": [
        "datos adicionales que solo son interpretados por la interfaz del comerciante"
      ],
      "Order": [
        "Orden"
      ],
      "Claimed": [
        "reclamado"
      ],
      "Claimed at": [
        "reclamado en"
      ],
      "Timeline": [
        "Cronología"
      ],
      "Payment details": [
        "Detalles de pago"
      ],
      "Order status": [
        "Estado de orden"
      ],
      "Product list": [
        "Lista de producto"
      ],
      "Paid": [
        "Pagado"
      ],
      "Wired": [
        "transferido"
      ],
      "Refunded": [
        "Reembolsado"
      ],
      "Refund order": [
        "reembolsado"
      ],
      "Not refundable": [
        "No reembolsable"
      ],
      "Next event in": [
        "Proximo evento en"
      ],
      "Refunded amount": [
        "Monto reembolsado"
      ],
      "Refund taken": [
        "Reembolsado"
      ],
      "Status URL": [
        "Estado de la URL"
      ],
      "Refund URI": [
        "URI de devolución"
      ],
      "Unpaid": [
        "impago"
      ],
      "Pay at": [
        "pagar en"
      ],
      "Order status URL": [
        "URL de estado de orden"
      ],
      "Payment URI": [
        "URI de pago"
      ],
      "Unknown order status. This is an error, please contact the administrator.": [
        "Estado de orden desconocido. Esto es un error, por favor contacte a su administrador."
      ],
      "Back": [
        "Regresar"
      ],
      "Refund created successfully": [
        "reembolzo creado satisfactoriamente"
      ],
      "Could not create the refund": [
        "No se pudo create el reembolso"
      ],
      "There are pending KYC requirements.": [
        "Hay requerimientos de KYC pendientes."
      ],
      "Missing ID": [
        "Falta id"
      ],
      "Not found": [
        "Orden no encontrada"
      ],
      "Select date to show nearby orders": [
        "seleccione la fecha para mostrar pedidos cercanos"
      ],
      "Only show paid orders": [
        "mostrar sólo pedidos pagados"
      ],
      "New": [
        "Nuevo"
      ],
      "Only show orders with refunds": [
        "mostrar solo pedidos con reembolso"
      ],
      "Only display orders that have already been paid for by buyers and for which the wire transfer by the payment service provider is still pending": [
        "mostrar sólo los pedidos en los que los clientes han pagado, pero los pagos por transferencia del proveedor de pago siguen pendientes"
      ],
      "Not wired": [
        "No transferido"
      ],
      "Only display orders that have already been transferred by the payment service provider": [
        "mostrar sólo los pedidos en los que los clientes han pagado, pero los pagos por transferencia del proveedor de pago siguen pendientes"
      ],
      "Completed": [
        "Eliminado"
      ],
      "Remove all filters": [
        "eliminar todos los filtros"
      ],
      "Clear date filter": [
        "borrar filtro de fechas"
      ],
      "Jump to date (%1$s)": [
        "Saltar a la fecha (%1$s)"
      ],
      "Jump to order with the given product ID": [
        "saltar al pedido con el ID de pedido proporcionado"
      ],
      "Order id": [
        "ID de la orden"
      ],
      "Invalid. Please enter characters and numbers only": [
        "Invalido. Solo caracteres y numero"
      ],
      "Just letters and numbers from 2 to 7": [
        "sólo letras y números del 2 al 7"
      ],
      "The size of the key must be 32 characters": [
        "el tamaño de la clave debe ser 32"
      ],
      "Internal ID on the system": [
        "Id interno en el sistema"
      ],
      "Useful to identify the device physically": [
        "Util para identificar dispositivos fisicos"
      ],
      "Verification algorithm": [
        "Algoritmo de verificación"
      ],
      "Algorithm used to verify transactions in offline mode": [
        "Algoritmo a utilizar para verificar la transacción en modo offline"
      ],
      "Device key": [
        "Clave del dispositivo"
      ],
      "Be sure to choose a password hard to guess, alternatively use the random generator": [
        "Asegurese de que sea muy dificil de adivinar o use el generado aleatorio"
      ],
      "Your device needs to match exactly the same value": [
        "Su dispositivo necesita tener exactamente el mismo valor"
      ],
      "Generate random secret key": [
        "generar clave secreta aleatoria"
      ],
      "Random": [
        "aleatorio"
      ],
      "You can scan the next QR code with your device or save the key before continuing.": [
        "Puede escanear el siguiente codigo QR con su dispositivo o guardar la clave antes de continuar."
      ],
      "Device added successfully": [
        "reembolzo creado satisfactoriamente"
      ],
      "Could not add device": [
        "no se pudo crear la reserva"
      ],
      "OTP devices": [
        "Dispositivo OTP"
      ],
      "Add new devices": [
        "cargar nuevas transferencias"
      ],
      "Load more devices before the first one": [
        "cargar más plantillas antes de la primera"
      ],
      "Delete selected devices from the database": [
        "eliminar la reserva seleccionada de la base de datos"
      ],
      "Load more devices after the last one": [
        "cargar más plantillas después de la última"
      ],
      "There are no devices to list yet, add more by pressing the + sign": [
        "Todavía no hay instancias, agregue más presionando el signo +"
      ],
      "The device was successfully deleted": [
        "La cuenta bancaria se ha eliminado correctamente."
      ],
      "Could not delete the device": [
        "no se pudo eliminar el producto"
      ],
      "Device:": [
        "Dispositivo:"
      ],
      "Algorithm to use to verify transactions in offline mode": [
        "Algoritmo a utilizar para verificar la transacción en modo offline"
      ],
      "Not modified": [
        "No transferido"
      ],
      "Be sure to be very hard to guess or use the random generator": [
        "Asegurese de que sea muy dificil de adivinar o use el generado aleatorio"
      ],
      "Your device need to have exactly the same value": [
        "Su dispositivo necesita tener exactamente el mismo valor"
      ],
      "Change key": [
        "Cambiar clave"
      ],
      "Could not update template": [
        "no se pudo actualizar el producto"
      ],
      "Template id is unknown": [
        "El id de la plantilla es desconocido"
      ],
      "The provided information is inconsistent with the current state of the template": [
        "La informacion provista es inconsistente con el estado actual de la plantilla"
      ],
      "Click here to configure the stock of the product, leave it as is and the backend will not control stock.": [
        "Pulse aquí para configurar el stock del producto, déjelo como está y el backend no controlará el stock."
      ],
      "Manage stock": [
        "Administrar stock"
      ],
      "This product has been configured without stock control": [
        "este producto se ha configurado sin control de existencias"
      ],
      "Infinite": [
        "Inifinito"
      ],
      "Shrinkage cannot exceed the current stock and incoming supplies (maximum %1$s)": [
        "la pérdida no puede ser mayor que la cantidad entrante actual (max %1$s )"
      ],
      "Incoming": [
        "Ingresando"
      ],
      "Lost": [
        "Perdido"
      ],
      "Current": [
        "Actual"
      ],
      "Remove stock control for this product": [
        "eliminar el control de existencias de este producto"
      ],
      "without stock": [
        "sin stock"
      ],
      "Next restock": [
        "Próximo reabastecimiento"
      ],
      "Warehouse address": [
        "Dirección de cuenta"
      ],
      "Add element to the list": [
        "agregar elemento a la lista"
      ],
      "Invalid amount": [
        "Importe fijo"
      ],
      "Product identification to use in URLs (for internal use only).": [
        "Identificación del producto para usar en las URL (solo para uso interno)."
      ],
      "Illustration of the product for customers.": [
        "ilustración del producto para los clientes."
      ],
      "Product description for customers.": [
        "Descripción del producto para los clientes."
      ],
      "Age restriction": [
        "Restricción de edad"
      ],
      "Is this product restricted for customer below certain age?": [
        "¿este producto está restringido para clientes menores de cierta edad?"
      ],
      "Minimum age of the customer": [
        "Edad mínima requerida"
      ],
      "Unit name": [
        "Unidad"
      ],
      "Unit describing quantity of product sold (e.g. 2 kilograms, 5 liters, 3 items, 5 meters) for customers.": [
        "Unidad que describe la cantidad de producto vendido (por ejemplo, 2 kilogramos, 5 litros, 3 artículos, 5 metros) para los clientes."
      ],
      "Example: kg, items or liters": [
        "Ejemplo: kg, item o litros"
      ],
      "Price per unit": [
        "Precio por unidad"
      ],
      "Sale price for customers, including taxes, for above units of the product.": [
        "Precio de venta para los clientes, impuestos incluidos, por encima de las unidades del producto."
      ],
      "Stock": [
        "Existencias"
      ],
      "Inventory for products with finite supply (for internal use only).": [
        "Inventario de productos para productos con suministro finito (sólo para uso interno)."
      ],
      "Taxes included in the product price, exposed to customers.": [
        "Impuestos incluidos en el precio del producto, expuestos a los clientes."
      ],
      "Categories": [
        "Categorias"
      ],
      "Search by category description or id": [
        "buscar productos por su descripción o ID"
      ],
      "Categories where this product will be listed on.": [
        "Dirección a donde los productos serán entregados."
      ],
      "Product created successfully": [
        "Producto creado con éxito"
      ],
      "Could not create product": [
        "No se pudo crear el producto"
      ],
      "Inventory": [
        "Inventario"
      ],
      "Add product to inventory": [
        "Añadir producto al inventario"
      ],
      "Sales": [
        "Ventas"
      ],
      "Sold": [
        "Vendido"
      ],
      "Free": [
        "Gratuito"
      ],
      "Go to product update page": [
        "ir a la página de actualización del producto"
      ],
      "Update": [
        "Actualizar"
      ],
      "Remove this product from the database": [
        "eliminar este producto de la base de datos"
      ],
      "Load more products after the last one": [
        "cargar más plantillas después de la última"
      ],
      "Update the product with new price": [
        "actualizar el producto con el nuevo precio"
      ],
      "Update product with new price": [
        "actualizar producto con nuevo precio"
      ],
      "Confirm update": [
        "Confirmado"
      ],
      "lost can't be greater that current + incoming (max %1$s)": [
        "la pérdida no puede ser mayor que la cantidad entrante actual (max %1$s )"
      ],
      "Add more elements to the inventory": [
        "añadir más elementos al inventario"
      ],
      "Report elements lost in the inventory": [
        "informar de elementos perdidos en el inventario"
      ],
      "New price for the product": [
        "nuevo precio para el producto"
      ],
      "The are value with errors": [
        "hay valores con errores"
      ],
      "Update product with new stock and price": [
        "actualizar el producto con nuevas existencias y precio"
      ],
      "There are no products to list yet, add more by pressing the + sign": [
        "No existen productos todavía, añadir más pulsando el símbolo +"
      ],
      "Jump to product with the given product ID": [
        "saltar al pedido con el ID de pedido proporcionado"
      ],
      "Product id": [
        "Id de producto"
      ],
      "Product updated successfully": [
        "producto actualizado correctamente"
      ],
      "Could not update the product": [
        "no se pudo actualizar el producto"
      ],
      "Product \"%1$s\" (ID: %2$s) has been deleted": [
        "La instancia '%1$s' (ID: %2$s) fue eliminada"
      ],
      "Could not delete the product": [
        "no se pudo eliminar el producto"
      ],
      "If you delete the product named %1$s (ID: %2$s ), the stock and related information will be lost": [
        "Si elimina el producto con nombre %1$s (ID: %2$s ), el stock y la informacion relacionada se perdera"
      ],
      "Deleting an product can't be undone.": [
        "El borrado de un producto no se puede restaurar."
      ],
      "Product id:": [
        "ID de producto:"
      ],
      "Product (ID: %1$s) has been updated": [
        "El producto (ID: %1$s) se ha actualizado"
      ],
      "Could not update product": [
        "No se pudo actualizar el producto"
      ],
      "Must be greater that 0": [
        "debe ser mayor que 0"
      ],
      "Too short": [
        "demasiado corta"
      ],
      "Identifier": [
        "Identificador"
      ],
      "Name of the template in URLs.": [
        "Nombre de la plantilla en las URL."
      ],
      "Describe what this template stands for": [
        "Describa lo que representa esta plantilla"
      ],
      "If specified here, this template will create orders with the same summary": [
        "Si se especifica, esta plantilla creará pedidos con el mismo resumen"
      ],
      "Summary is editable": [
        "El detalle es editable"
      ],
      "Allow the user to change the summary.": [
        "Permitir el usuario cambiar el detalle."
      ],
      "If specified here, this template will create orders with the same price": [
        "Si se especifica, esta plantilla creará pedidos con el mismo precio"
      ],
      "Amount is editable": [
        "Monto abonado"
      ],
      "Allow the user to select the amount to pay.": [
        "Permitir al usuario seleccionar el monto a pagar."
      ],
      "Currency is editable": [
        "La moneda es editable"
      ],
      "Allow the user to change currency.": [
        "Permitir al usuario cambiar la moneda."
      ],
      "Supported currencies": [
        "Monedas soportadas"
      ],
      "Supported currencies: %1$s": [
        "Monedas soportadas: %1$s"
      ],
      "Minimum age": [
        "Edad mínima"
      ],
      "Is this contract restricted to some age?": [
        "¿Este contrato está restringido a alguna edad?"
      ],
      "Payment timeout": [
        "Opciones de pago"
      ],
      "How much time the customer has to complete the payment once the order was created.": [
        "Cuánto tiempo tiene el cliente para completar el pago una vez creado el pedido."
      ],
      "OTP device": [
        "Dispositivo OTP"
      ],
      "Use to verify transactions in offline mode.": [
        "Algoritmo a utilizar para verificar la transacción en modo offline."
      ],
      "No OTP device.": [
        "Sin dispositivo OTP."
      ],
      "Add one first": [
        "Agregar uno primero"
      ],
      "No device": [
        "Sin dispotivo"
      ],
      "Template has been created": [
        "La plantilla fue creada"
      ],
      "Could not create template": [
        "no se pudo actualizar el producto"
      ],
      "Templates": [
        "Plantillas"
      ],
      "Add new templates": [
        "añadir nuevas plantillas"
      ],
      "Load more templates before the first one": [
        "cargar más plantillas antes de la primera"
      ],
      "Delete selected templates from the database": [
        "eliminar las plantillas seleccionadas de la base de datos"
      ],
      "Use template to create new order": [
        "utilizar la plantilla para crear un nuevo pedido"
      ],
      "Use template": [
        "añadir nuevas plantillas"
      ],
      "Generate a QR code for the template.": [
        "No se pudo create el reembolso"
      ],
      "Load more templates after the last one": [
        "cargar más plantillas después de la última"
      ],
      "There are no templates to list yet, add more by pressing the + sign": [
        "No hay propinas todavía, agregar mas presionando el signo +"
      ],
      "Jump to template with the given template ID": [
        "saltar al pedido con el ID de pedido proporcionado"
      ],
      "Template identification": [
        "Identificacion de plantilla"
      ],
      "Template \"%1$s\" (ID: %2$s) has been deleted": [
        "La instancia '%1$s' (ID: %2$s) fue eliminada"
      ],
      "Failed to delete template": [
        "Fallo al eliminar instancia"
      ],
      "If you delete the template %1$s (ID: %2$s) you may loose information": [
        "Si elimina la plantilla %1$s (ID: %2$s) perdera informacion"
      ],
      "Deleting an template": [
        "cargar nuevas transferencias"
      ],
      "can't be undone": [
        "no puede ser vacío"
      ],
      "Print": [
        "Imprimir"
      ],
      "If specified, this template will create order with the same summary": [
        "Si se especifica, esta plantilla creará pedidos con el mismo resumen"
      ],
      "If specified, this template will create orders with the same price": [
        "Si se especifica, esta plantilla creará pedidos con el mismo precio"
      ],
      "How much time has the customer to complete the payment once the order was created.": [
        "Cuánto tiempo tiene el cliente para completar el pago una vez creado el pedido."
      ],
      "Template (ID: %1$s) has been updated": [
        "Se ha actualizado la plantilla (ID: %1$s)"
      ],
      "An amount is required": [
        "Se requiere una cantidad"
      ],
      "An order summary is required": [
        "Se requiere resumen del pedido"
      ],
      "New order from template": [
        "cargar viejas transferencias"
      ],
      "Amount of the order": [
        "Importe del pedido"
      ],
      "Order summary": [
        "Estado de orden"
      ],
      "Could not create order from template": [
        "No se pudo create el reembolso"
      ],
      "You need your access token to perform the operation": [
        "Está estableciendo el token de acceso para la nueva instancia"
      ],
      "You are updating the access token from instance with id \"%1$s\"": [
        "Está actualizando el token de acceso para la instancia con id %1$s"
      ],
      "This instance does not have authentication token.": [
        "Esta instancia no tiene token de autenticacion."
      ],
      "You can leave it empty if there is another layer of security.": [
        "Puede dejar esto vacio si es que hay una ca"
      ],
      "Current access token": [
        "Establecer token de acceso"
      ],
      "Clearing the access token will mean public access to the instance.": [
        "Limpiar el token de acceso significa acceso público a la instancia."
      ],
      "Clear token": [
        "Borrar token"
      ],
      "Confirm change": [
        "Confirmar modificación"
      ],
      "Failed to clear token": [
        "Fallo al borrar el token"
      ],
      "Failed to set new token": [
        "No se ha podido establecer el nuevo token"
      ],
      "Slug": [
        ""
      ],
      "Token family slug to use in URLs (for internal use only)": [
        "Identificación del producto para usar en las URL (solo para uso interno)"
      ],
      "Kind": [
        ""
      ],
      "Token family kind": [
        ""
      ],
      "User-readable token family name": [
        ""
      ],
      "Token family description for customers": [
        "descripción del producto para los clientes"
      ],
      "Valid After": [
        "Válido hasta"
      ],
      "Token family can issue tokens after this date": [
        ""
      ],
      "Valid Before": [
        "formato inválido"
      ],
      "Token family can issue tokens until this date": [
        ""
      ],
      "Duration": [
        "Expiración"
      ],
      "Validity duration of a issued token": [
        ""
      ],
      "Token family created successfully.": [
        "reembolzo creado satisfactoriamente"
      ],
      "Could not create token family.": [
        "No se pudo create el reembolso"
      ],
      "Token Families": [
        ""
      ],
      "Add token family": [
        ""
      ],
      "Go to token family update page": [
        "ir a la página de actualización del producto"
      ],
      "Remove this token family from the database": [
        "eliminar este producto de la base de datos"
      ],
      "There are no token families yet, add the first one by pressing the + sign.": [
        "No hay propinas todavía, agregar mas presionando el signo +."
      ],
      "Token family updated successfully": [
        "Familia de tokens actualizada correctamente"
      ],
      "Could not update the token family": [
        "No se ha podido actualizar la familia de tokens"
      ],
      "Token family \"%1$s\" (SLUG: %2$s) has been deleted": [
        "Se ha eliminado la familia de tokens \"%1$s\" (SLUG: %2$s)"
      ],
      "Failed to delete token family": [
        "No se ha podido eliminar la familia de tokens"
      ],
      "If you delete the %1$s token family (Slug: %2$s), all issued tokens will become invalid.": [
        "Si elimina la familia de tokens %1$s (Slug: %2$s), todos los tokens emitidos no serán válidos."
      ],
      "Deleting a token family %1$s .": [
        "Eliminando una familia de tokens %1$s ."
      ],
      "Token Family: %1$s": [
        "Familia de tokens: %1$s"
      ],
      "Could not update token family": [
        "No se ha podido actualizar la familia de tokens"
      ],
      "Please check the ID; it does not appear to be valid.": [
        "comprueba el ID, parece no ser válido"
      ],
      "Must have 52 characters, current %1$s": [
        "debería tener 52 caracteres, actualmente %1$s"
      ],
      "URL doesn't have the right format": [
        "La URL no tiene el formato correcto"
      ],
      "Credited bank account": [
        "Abono en cuenta bancaria"
      ],
      "Select an account": [
        "Selecciona una cuenta"
      ],
      "Bank account of the merchant where the payment was received": [
        "Cuenta bancaria del comerciante donde se recibió el pago"
      ],
      "Wire transfer ID": [
        "ID de la transferencia"
      ],
      "Unique identifier of the wire transfer used by the exchange, must be 52 characters long": [
        "identificador único de la transferencia utilizado por el proveedor, debe tener 52 caracteres"
      ],
      "Exchange URL": [
        "URL del proveedor"
      ],
      "Base URL of the exchange that made the transfer, should have been in the wire transfer subject": [
        "URL base del proveedor que realizó la transferencia, debería haber estado en el asunto de la transferencia bancaria"
      ],
      "Amount credited": [
        "Monto abonado"
      ],
      "Actual amount that was wired to the merchant's bank account": [
        "Monto real que se transfirió a la cuenta bancaria del comerciante"
      ],
      "Wire transfer informed successfully": [
        "reembolzo creado satisfactoriamente"
      ],
      "Could not inform transfer": [
        "no se pudo informar la transferencia"
      ],
      "Wire transfers": [
        "ID de la transferencia"
      ],
      "Add new transfer": [
        "añadir nueva transferencia"
      ],
      "Load more wire transfers preceding the first one": [
        "cargar más transferencias antes de la primera"
      ],
      "Credit": [
        "Crédito"
      ],
      "Confirmed": [
        "Confirmado"
      ],
      "Verified": [
        "Verificado"
      ],
      "Executed on": [
        "Ejecutado en"
      ],
      "yes": [
        "si"
      ],
      "no": [
        "no"
      ],
      "never": [
        "nunca"
      ],
      "unknown": [
        "desconocido"
      ],
      "Delete selected transfer from the database": [
        "eliminar transferencia seleccionada de la base de datos"
      ],
      "Load more transfers after the last one": [
        "cargue más transferencia luego de la última"
      ],
      "There are no transfers to list yet, add more by pressing the + sign": [
        "No hay transferencias todavía, agregar mas presionando el signo +"
      ],
      "All accounts": [
        "Cuenta"
      ],
      "Filter by account address": [
        "filtrar por dirección de cuenta"
      ],
      "Only show wire transfers confirmed by the merchant": [
        "mostrar sólo las transferencias confirmadas por el comerciante"
      ],
      "Only show wire transfers claimed by the exchange": [
        "sólo muestran las transferencias reclamadas por el proveedor"
      ],
      "Unverified": [
        "Sin verificar"
      ],
      "Wire transfer \"%1$s...\" has been deleted": [
        "Se ha eliminado la transferencia bancaria \"%1$s...\""
      ],
      "Failed to delete transfer": [
        "Error al eliminar la transferencia"
      ],
      "Must be business or individual": [
        ""
      ],
      "Pay delay can't be greater than wire transfer delay": [
        ""
      ],
      "Max 7 lines": [
        "máximo 7 líneas"
      ],
      "Doesn't match": [
        ""
      ],
      "Enable access control": [
        "Administrar token de acceso"
      ],
      "Choose if the backend server should authenticate access.": [
        ""
      ],
      "Access control is not yet decided. This instance can't be created.": [
        ""
      ],
      "Authorization must be handled externally.": [
        ""
      ],
      "Authorization is handled by the backend server.": [
        ""
      ],
      "Need to complete marked fields and choose authorization method": [
        "Necesita completar campos marcados y escoger un método de autorización"
      ],
      "Name of the instance in URLs. The 'default' instance is special in that it is used to administer other instances.": [
        "Nombre de la instancia en URL. La instancia \"por defecto\" es especial, ya que se utiliza para administrar otras instancias."
      ],
      "Business name": [
        "Nombre del negocio"
      ],
      "Legal name of the business represented by this instance.": [
        "Nombre legal de la empresa representada por esta instancia."
      ],
      "Email": [
        "Correo eletrónico"
      ],
      "Contact email": [
        "Correo electrónico del contacto"
      ],
      "Website URL": [
        "URL de sitio web"
      ],
      "URL.": [
        "URL."
      ],
      "Logo": [
        "Logotipo"
      ],
      "Logo image.": [
        "Imagen del logotipo."
      ],
      "Physical location of the merchant.": [
        "Ubicación física del comerciante."
      ],
      "Jurisdiction": [
        "Jurisdicción"
      ],
      "Jurisdiction for legal disputes with the merchant.": [
        "Jurisdicción para disputas legales con el comerciante."
      ],
      "Pay transaction fee": [
        ""
      ],
      "Cover the transaction cost or pass it on to the user.": [
        ""
      ],
      "Default payment delay": [
        "Retraso del pago por defecto"
      ],
      "Time customers have to pay an order before the offer expires by default.": [
        "Tiempo que los clientes tienen para pagar un pedido antes de que caduque la oferta de forma predeterminada."
      ],
      "Default wire transfer delay": [
        "Retrazo de transferencia por omisión"
      ],
      "Maximum time an exchange is allowed to delay wiring funds to the merchant, enabling it to aggregate smaller payments into larger wire transfers and reducing wire fees.": [
        "Tiempo máximo que un proveedor puede retrasar la transferencia de fondos al comerciante, lo que le permite agrupar pagos más pequeños en transferencias más grandes y reducir las comisiones por transferencia."
      ],
      "Instance id": [
        "ID de instancia"
      ],
      "Failed to update instance": [
        "Fallo al crear la instancia"
      ],
      "Must be \"pay\" or \"refund\"": [
        ""
      ],
      "Must be one of '%1$s'": [
        "deberían ser iguales"
      ],
      "Webhook ID to use": [
        "ID de webhook a utilizar"
      ],
      "Event": [
        "Evento"
      ],
      "Pay": [
        ""
      ],
      "The event of the webhook: why the webhook is used": [
        "El evento del webhook: por qué se utiliza el webhook"
      ],
      "Method": [
        "Método"
      ],
      "GET": [
        ""
      ],
      "POST": [
        ""
      ],
      "PUT": [
        ""
      ],
      "PATCH": [
        ""
      ],
      "HEAD": [
        ""
      ],
      "Method used by the webhook": [
        "Método utilizado por el webhook"
      ],
      "URL": [
        "URL"
      ],
      "URL of the webhook where the customer will be redirected": [
        "URL del webhook al que se redirigirá al cliente"
      ],
      "The text below supports the %1$s template engine. Any string between %2$s and %3$s will be replaced with the value of the corresponding variable.": [
        ""
      ],
      "For example %1$s will be replaced with the the order's price": [
        ""
      ],
      "The short list of variables are:": [
        ""
      ],
      "order's description": [
        "descripción"
      ],
      "order's price": [
        "Precio de la orden"
      ],
      "order's unique identification": [
        ""
      ],
      "the amount that was being refunded": [
        "monto a ser reembolsado"
      ],
      "the reason entered by the merchant staff for granting the refund": [
        ""
      ],
      "time of the refund in nanoseconds since 1970": [
        ""
      ],
      "Http body": [
        ""
      ],
      "Body template by the webhook": [
        "Plantilla del cuerpo del webhook"
      ],
      "Webhook create successfully": [
        "el webhook ha sido borrado correctamente"
      ],
      "Could not create the webhook": [
        "no se ha podido eliminar el webhook"
      ],
      "Could not create webhook": [
        "no se ha podido eliminar el webhook"
      ],
      "Webhooks": [
        "Webhooks"
      ],
      "Add new webhooks": [
        "añadir nuevos webhooks"
      ],
      "Load more webhooks before the first one": [
        "cargar más webhooks antes del primero"
      ],
      "Event type": [
        "Tipo de evento"
      ],
      "Delete selected webhook from the database": [
        "eliminar el webhook seleccionado de la base de datos"
      ],
      "Load more webhooks after the last one": [
        "cargar más webhooks después del último"
      ],
      "There are no webhooks to list yet, add more by pressing the + sign": [
        "No hay webhooks todavía, añade más pulsando sobre el símbolo +"
      ],
      "Webhook deleted successfully": [
        "el webhook ha sido borrado correctamente"
      ],
      "Could not delete the webhook": [
        "no se ha podido eliminar el webhook"
      ],
      "Header": [
        "Cabecera"
      ],
      "Header template of the webhook": [
        "Plantilla de cabecera del webhook"
      ],
      "Body": [
        "Cuerpo"
      ],
      "Webhook updated": [
        "ID de webhook a utilizar"
      ],
      "Could not update webhook": [
        "no se ha podido eliminar el webhook"
      ],
      "Language": [
        ""
      ],
      "Advance order creation": [
        ""
      ],
      "Shows more options in the order creation form": [
        ""
      ],
      "Advance instance settings": [
        ""
      ],
      "Shows more options in the instance settings form": [
        ""
      ],
      "Date format": [
        "formato inválido"
      ],
      "How the date is going to be displayed": [
        ""
      ],
      "Developer mode": [
        ""
      ],
      "Shows more options and tools which are not intended for general audience.": [
        ""
      ],
      "Total products": [
        "Precio total"
      ],
      "Delete selected category from the database": [
        "eliminar transferencia seleccionada de la base de datos"
      ],
      "There are no categories yet, add more pressing the + sign": [
        "No hay propinas todavía, agregar mas presionando el signo +"
      ],
      "Category delete successfully": [
        "producto fue eliminado correctamente"
      ],
      "Could not delete the category": [
        "no se pudo eliminar el producto"
      ],
      "Invalid. Please insert only characters and numbers": [
        "Invalido. Solo caracteres y numero"
      ],
      "Category name": [
        ""
      ],
      "Category added successfully": [
        "producto fue eliminado correctamente"
      ],
      "Could not add category": [
        "no se pudo crear el producto"
      ],
      "Id:": [
        ""
      ],
      "Name of the category": [
        "El nombre de la categoría"
      ],
      "Products": [
        "Productos"
      ],
      "Search by product description or id": [
        "buscar productos por su descripción o ID"
      ],
      "Products that this category will list.": [
        ""
      ],
      "Could not update category": [
        "no se pudo actualizar el producto"
      ],
      "Category ID is unknown": [
        "El id de la plantilla es desconocido"
      ],
      "Without this the merchant backend will refuse to create new orders.": [
        ""
      ],
      "Hide for today": [
        "Ocultar por hoy"
      ],
      "KYC verification needed": [
        "Verificación KYC pendiente"
      ],
      "Some transfers are on hold until the KYC process is completed. Visit the KYC section in the left panel for more information": [
        ""
      ],
      "Configuration": [
        "Expiración"
      ],
      "OTP Devices": [
        "Dispositivos OTP"
      ],
      "Settings": [
        "Configuración"
      ],
      "Access token": [
        "Token de acceso"
      ],
      "Connection": [
        "Conexión"
      ],
      "Interface": [
        ""
      ],
      "List": [
        "Lista"
      ],
      "Log out": [
        "Salir"
      ],
      "Failed to create instance": [
        "Fallo al crear la instancia"
      ],
      "checking compatibility with server...": [
        ""
      ],
      "Contacting the server failed": [
        "No se pudo aceder al servidor"
      ],
      "The server version is not supported": [
        ""
      ],
      "Supported version \"%1$s\", server version \"%2$s\".": [
        ""
      ],
      "Deleting": [
        "Borrando"
      ],
      "Changing": [
        "Cambiando"
      ],
      "Manage access token": [
        "Administrar token de acceso"
      ],
      "Business Name": [
        "Nombre del negocio"
      ],
      "Order ID": [
        "ID de pedido"
      ],
      "Payment URL": [
        "URL de pago"
      ]
    }
  },
  "domain": "messages",
  "plural_forms": "nplurals=2; plural=n != 1;",
  "lang": "es",
  "completeness": 92
};

strings['en'] = {
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=2; plural=(n != 1);",
        "lang": ""
      },
      "The request reached a timeout, check your connection.": [
        ""
      ],
      "The request was cancelled.": [
        ""
      ],
      "A lot of request were made to the same server and this action was throttled.": [
        ""
      ],
      "The response of the request is malformed.": [
        ""
      ],
      "Could not complete the request due to a network problem.": [
        ""
      ],
      "Unexpected request error.": [
        ""
      ],
      "Unexpected error.": [
        ""
      ],
      "Cancel": [
        ""
      ],
      "%1$s": [
        ""
      ],
      "Close": [
        ""
      ],
      "Continue": [
        ""
      ],
      "Clear": [
        ""
      ],
      "Confirm": [
        ""
      ],
      "Required": [
        ""
      ],
      "Letter must be a JSON string": [
        ""
      ],
      "JSON string is invalid": [
        ""
      ],
      "Import": [
        ""
      ],
      "Importing an account from the bank": [
        ""
      ],
      "You can export your account settings from the Libeufin Bank's account profile. Paste the content in the next field.": [
        ""
      ],
      "Account information": [
        ""
      ],
      "Correct form": [
        ""
      ],
      "Comparing account details": [
        ""
      ],
      "Testing against the account info URL succeeded but the account information reported is different with the account details form.": [
        ""
      ],
      "Field": [
        ""
      ],
      "In the form": [
        ""
      ],
      "Reported": [
        ""
      ],
      "Type": [
        ""
      ],
      "IBAN": [
        ""
      ],
      "Address": [
        ""
      ],
      "Host": [
        ""
      ],
      "Account id": [
        ""
      ],
      "Owner's name": [
        ""
      ],
      "Account": [
        ""
      ],
      "Bank host": [
        ""
      ],
      "Bank account": [
        ""
      ],
      "BIC": [
        ""
      ],
      "Ok": [
        ""
      ],
      "Validate bank account: %1$s": [
        ""
      ],
      "You need to make a bank transfer with the specified subject to validate that you are the owner of the account.": [
        ""
      ],
      "Step 1:": [
        ""
      ],
      "Copy this string and paste it into the field 'Subject' (resp. 'Purpose') in your banking app or bank website": [
        ""
      ],
      "Subject": [
        ""
      ],
      "Step 2:": [
        ""
      ],
      "Copy and paste this IBAN and the name into the receiver fields in your banking app or website": [
        ""
      ],
      "Receiver name": [
        ""
      ],
      "Step 3:": [
        ""
      ],
      "Select the smallest possible amount for the wire transfer in your banking app or on your online banking website.": [
        ""
      ],
      "Make sure ALL data is correct, including the subject and you are using your selected bank account. You can use the copy buttons (%1$s) to prevent typing errors or the \"payto://\" URI below to copy just one value.": [
        ""
      ],
      "Alternatively, if your bank already supports the standard 'PayTo URI', you can use this %1$s link instead": [
        ""
      ],
      "If you delete the instance named %1$s (ID: %2$s), the merchant will no longer be able to process orders or refunds": [
        ""
      ],
      "This action deletes the instance private key, but preserves all transaction data. You can still access that data after deleting the instance.": [
        ""
      ],
      "Deleting an instance %1$s .": [
        ""
      ],
      "If you purge the instance named %1$s (ID: %2$s), you will also delete all it&apos;s transaction data.": [
        ""
      ],
      "The instance will disappear from your list, and you will no longer be able to access it&apos;s data.": [
        ""
      ],
      "Purging an instance %1$s .": [
        ""
      ],
      "Is not the same as the current access token": [
        ""
      ],
      "Can't be the same as the old token": [
        ""
      ],
      "Is not the same": [
        ""
      ],
      "You are updating the access token for the instance with ID %1$s": [
        ""
      ],
      "Old access token": [
        ""
      ],
      "Access token currently in use": [
        ""
      ],
      "New access token": [
        ""
      ],
      "Next access token to be used": [
        ""
      ],
      "Repeat access token": [
        ""
      ],
      "Confirm the same access token": [
        ""
      ],
      "Clearing the access token will mean public access to the instance": [
        ""
      ],
      "Can't be the same as the old access token": [
        ""
      ],
      "You are setting the access token for the new instance": [
        ""
      ],
      "With external authorization method no check will be done by the merchant backend": [
        ""
      ],
      "Set external authorization": [
        ""
      ],
      "Set access token": [
        ""
      ],
      "Operation in progress...": [
        ""
      ],
      "The operation will be automatically canceled after %1$s seconds": [
        ""
      ],
      "Your password is incorrect": [
        ""
      ],
      "Your instance cannot be found": [
        ""
      ],
      "Login required": [
        ""
      ],
      "Please enter your access token for %1$s.": [
        ""
      ],
      "Access Token": [
        ""
      ],
      "Instances": [
        ""
      ],
      "Delete": [
        ""
      ],
      "Add new instance": [
        ""
      ],
      "ID": [
        ""
      ],
      "Name": [
        ""
      ],
      "Edit": [
        ""
      ],
      "Purge": [
        ""
      ],
      "There are no instances yet, add more pressing the + sign": [
        ""
      ],
      "Only show active instances": [
        ""
      ],
      "Active": [
        ""
      ],
      "Only show deleted instances": [
        ""
      ],
      "Deleted": [
        ""
      ],
      "Show all instances": [
        ""
      ],
      "All": [
        ""
      ],
      "Instance \"%1$s\" (ID: %2$s) has been deleted": [
        ""
      ],
      "Failed to delete instance": [
        ""
      ],
      "Instance '%1$s' (ID: %2$s) has been purged": [
        ""
      ],
      "Failed to purge instance": [
        ""
      ],
      "Loading...": [
        ""
      ],
      "This is not a valid bitcoin address.": [
        ""
      ],
      "This is not a valid Ethereum address.": [
        ""
      ],
      "This is not a valid host.": [
        ""
      ],
      "IBAN numbers usually have more that 4 digits": [
        ""
      ],
      "IBAN numbers usually have less that 34 digits": [
        ""
      ],
      "IBAN country code not found": [
        ""
      ],
      "IBAN number is invalid, checksum is wrong": [
        ""
      ],
      "Choose one...": [
        ""
      ],
      "Method to use for wire transfer": [
        ""
      ],
      "Routing": [
        ""
      ],
      "Routing number.": [
        ""
      ],
      "Account number.": [
        ""
      ],
      "Code": [
        ""
      ],
      "Business Identifier Code.": [
        ""
      ],
      "International Bank Account Number.": [
        ""
      ],
      "Unified Payment Interface.": [
        ""
      ],
      "Bitcoin protocol.": [
        ""
      ],
      "Ethereum protocol.": [
        ""
      ],
      "Interledger protocol.": [
        ""
      ],
      "Bank host.": [
        ""
      ],
      "Without scheme and may include subpath:": [
        ""
      ],
      "Bank account.": [
        ""
      ],
      "Legal name of the person holding the account.": [
        ""
      ],
      "It should match the bank account name.": [
        ""
      ],
      "Invalid url": [
        ""
      ],
      "URL must end with a '/'": [
        ""
      ],
      "URL must not contain params": [
        ""
      ],
      "URL must not hash param": [
        ""
      ],
      "The request to check the revenue API failed.": [
        ""
      ],
      "Server replied with \"bad request\".": [
        ""
      ],
      "Unauthorized, check credentials.": [
        ""
      ],
      "The endpoint does not seem to be a Taler Revenue API.": [
        ""
      ],
      "The request was made correctly, but the bank's server did not respond with the appropriate value for 'credit_account', so we cannot confirm that it is the same bank account.": [
        ""
      ],
      "Account:": [
        ""
      ],
      "If the bank supports Taler Revenue API then you can add the endpoint URL below to keep the revenue information in sync.": [
        ""
      ],
      "Endpoint URL": [
        ""
      ],
      "From where the merchant can download information about incoming wire transfers to this account": [
        ""
      ],
      "Auth type": [
        ""
      ],
      "Choose the authentication type for the account info URL": [
        ""
      ],
      "Without authentication": [
        ""
      ],
      "With password": [
        ""
      ],
      "With token": [
        ""
      ],
      "Do not change": [
        ""
      ],
      "Username": [
        ""
      ],
      "Username to access the account information.": [
        ""
      ],
      "Password": [
        ""
      ],
      "Password to access the account information.": [
        ""
      ],
      "Token": [
        ""
      ],
      "Access token to access the account information.": [
        ""
      ],
      "Match": [
        ""
      ],
      "Check where the information match against the server info.": [
        ""
      ],
      "Not verified": [
        ""
      ],
      "Last test was ok": [
        ""
      ],
      "Last test failed": [
        ""
      ],
      "Compare info from server with account form": [
        ""
      ],
      "Test": [
        ""
      ],
      "Need to complete marked fields": [
        ""
      ],
      "Confirm operation": [
        ""
      ],
      "Account details": [
        ""
      ],
      "Import from bank": [
        ""
      ],
      "Could not create account": [
        ""
      ],
      "No 'default' instance configured yet.": [
        ""
      ],
      "Create a 'default' instance to begin using the merchant backoffice.": [
        ""
      ],
      "Bank accounts": [
        ""
      ],
      "Add new account": [
        ""
      ],
      "Wire method: Bitcoin": [
        ""
      ],
      "SegWit 1": [
        ""
      ],
      "SegWit 2": [
        ""
      ],
      "Delete selected accounts from the database": [
        ""
      ],
      "Wire method: x-taler-bank": [
        ""
      ],
      "Account name": [
        ""
      ],
      "Wire method: IBAN": [
        ""
      ],
      "Other accounts": [
        ""
      ],
      "Path": [
        ""
      ],
      "There are no accounts yet, add more pressing the + sign": [
        ""
      ],
      "You need to associate a bank account to receive revenue.": [
        ""
      ],
      "Without this the you won't be able to create new orders.": [
        ""
      ],
      "The bank account has been successfully deleted.": [
        ""
      ],
      "Could not delete the bank account": [
        ""
      ],
      "Could not update account": [
        ""
      ],
      "Could not delete account": [
        ""
      ],
      "Pending KYC verification": [
        ""
      ],
      "Exchange": [
        ""
      ],
      "Reason": [
        ""
      ],
      "Pending KYC process, click here to complete": [
        ""
      ],
      "The Payment Service Provider requires an account verification.": [
        ""
      ],
      "Http Status": [
        ""
      ],
      "No pending kyc verification!": [
        ""
      ],
      "Change value to unknown date": [
        ""
      ],
      "Change value to empty": [
        ""
      ],
      "Change value to never": [
        ""
      ],
      "Never": [
        ""
      ],
      "days": [
        ""
      ],
      "hours": [
        ""
      ],
      "minutes": [
        ""
      ],
      "seconds": [
        ""
      ],
      "Forever": [
        ""
      ],
      "%1$sM": [
        ""
      ],
      "%1$sY": [
        ""
      ],
      "%1$sd": [
        ""
      ],
      "%1$sh": [
        ""
      ],
      "%1$smin": [
        ""
      ],
      "%1$ssec": [
        ""
      ],
      "Country": [
        ""
      ],
      "Building number": [
        ""
      ],
      "Building name": [
        ""
      ],
      "Street": [
        ""
      ],
      "Post code": [
        ""
      ],
      "Town location": [
        ""
      ],
      "Town": [
        ""
      ],
      "District": [
        ""
      ],
      "Country subdivision": [
        ""
      ],
      "Description": [
        ""
      ],
      "Enter description or id": [
        ""
      ],
      "no match found with that description or id": [
        ""
      ],
      "You must enter a valid product identifier.": [
        ""
      ],
      "Quantity must be greater than 0!": [
        ""
      ],
      "This quantity exceeds remaining stock. Currently, only %1$s units remain unreserved in stock.": [
        ""
      ],
      "Search product": [
        ""
      ],
      "Quantity": [
        ""
      ],
      "How many products will be added": [
        ""
      ],
      "Add from inventory": [
        ""
      ],
      "Image must be smaller than 1 MB": [
        ""
      ],
      "Add": [
        ""
      ],
      "Remove": [
        ""
      ],
      "Invalid": [
        ""
      ],
      "This product has %1$s applicable taxes configured.": [
        ""
      ],
      "No taxes configured for this product.": [
        ""
      ],
      "Amount": [
        ""
      ],
      "Taxes can be in currencies that differ from the main currency used by the merchant.": [
        ""
      ],
      "Enter currency and value separated with a colon, e.g. &quot;USD:2.3&quot;.": [
        ""
      ],
      "Legal name of the tax, e.g. VAT or import duties.": [
        ""
      ],
      "Add tax to the tax list": [
        ""
      ],
      "Describe and add a product that is not in the inventory list": [
        ""
      ],
      "Add custom product": [
        ""
      ],
      "Complete information of the product": [
        ""
      ],
      "Must be a number": [
        ""
      ],
      "Must be grater than 0": [
        ""
      ],
      "Image": [
        ""
      ],
      "Photo of the product.": [
        ""
      ],
      "Full product description.": [
        ""
      ],
      "Unit": [
        ""
      ],
      "Name of the product unit.": [
        ""
      ],
      "Price": [
        ""
      ],
      "Amount in the current currency.": [
        ""
      ],
      "How many products will be added.": [
        ""
      ],
      "Taxes": [
        ""
      ],
      "Unit price": [
        ""
      ],
      "Total price": [
        ""
      ],
      "Must be greater than 0": [
        ""
      ],
      "Refund deadline can't be before pay deadline": [
        ""
      ],
      "Wire transfer deadline can't be before refund deadline": [
        ""
      ],
      "Wire transfer deadline can't be before pay deadline": [
        ""
      ],
      "Must have a refund deadline": [
        ""
      ],
      "Auto refund can't be after refund deadline": [
        ""
      ],
      "Must be in the future": [
        ""
      ],
      "Simple": [
        ""
      ],
      "Advanced": [
        ""
      ],
      "Manage products in order": [
        ""
      ],
      "%1$s products with a total price of %2$s.": [
        ""
      ],
      "Manage list of products in the order.": [
        ""
      ],
      "Remove this product from the order.": [
        ""
      ],
      "Total product price added up": [
        ""
      ],
      "Amount to be paid by the customer": [
        ""
      ],
      "Order price": [
        ""
      ],
      "Final order price": [
        ""
      ],
      "Summary": [
        ""
      ],
      "Title of the order to be shown to the customer": [
        ""
      ],
      "Shipping and fulfillment": [
        ""
      ],
      "Delivery date": [
        ""
      ],
      "Deadline for physical delivery assured by the merchant.": [
        ""
      ],
      "Location": [
        ""
      ],
      "Address where the products will be delivered": [
        ""
      ],
      "Fulfillment URL": [
        ""
      ],
      "URL to which the user will be redirected after successful payment.": [
        ""
      ],
      "Taler payment options": [
        ""
      ],
      "Override default Taler payment settings for this order": [
        ""
      ],
      "Payment time": [
        ""
      ],
      "Time for the customer to pay for the offer before it expires. Inventory products will be reserved until this deadline. Time start to run after the order is created.": [
        ""
      ],
      "Default": [
        ""
      ],
      "Refund time": [
        ""
      ],
      "Time while the order can be refunded by the merchant. Time starts after the order is created.": [
        ""
      ],
      "Wire transfer time": [
        ""
      ],
      "Time for the exchange to make the wire transfer. Time starts after the order is created.": [
        ""
      ],
      "Auto-refund time": [
        ""
      ],
      "Time until which the wallet will automatically check for refunds without user interaction.": [
        ""
      ],
      "Maximum fee": [
        ""
      ],
      "Maximum fees the merchant is willing to cover for this order. Higher deposit fees must be covered in full by the consumer.": [
        ""
      ],
      "Create token": [
        ""
      ],
      "If the order ID is easy to guess the token will prevent users to steal orders from others.": [
        ""
      ],
      "Minimum age required": [
        ""
      ],
      "Any value greater than 0 will limit the coins able be used to pay this contract. If empty the age restriction will be defined by the products": [
        ""
      ],
      "Min age defined by the producs is %1$s": [
        ""
      ],
      "No product with age restriction in this order": [
        ""
      ],
      "Additional information": [
        ""
      ],
      "Custom information to be included in the contract for this order.": [
        ""
      ],
      "You must enter a value in JavaScript Object Notation (JSON).": [
        ""
      ],
      "Custom field name": [
        ""
      ],
      "Disabled": [
        ""
      ],
      "No deadline": [
        ""
      ],
      "Deadline at %1$s": [
        ""
      ],
      "Could not create order": [
        ""
      ],
      "No exchange would accept a payment because of KYC requirements.": [
        ""
      ],
      "No more stock for product with ID \"%1$s\".": [
        ""
      ],
      "Orders": [
        ""
      ],
      "Create order": [
        ""
      ],
      "Load first page": [
        ""
      ],
      "Date": [
        ""
      ],
      "Refund": [
        ""
      ],
      "copy url": [
        ""
      ],
      "Load more orders after the last one": [
        ""
      ],
      "Load next page": [
        ""
      ],
      "No orders have been found matching your query!": [
        ""
      ],
      "Duplicated": [
        ""
      ],
      "This value exceed the refundable amount": [
        ""
      ],
      "Amount to be refunded": [
        ""
      ],
      "Max refundable:": [
        ""
      ],
      "Requested by the customer": [
        ""
      ],
      "Other": [
        ""
      ],
      "Why this order is being refunded": [
        ""
      ],
      "More information to give context": [
        ""
      ],
      "Contract terms": [
        ""
      ],
      "Human-readable description of the whole purchase": [
        ""
      ],
      "Total price for the transaction": [
        ""
      ],
      "URL for this purchase": [
        ""
      ],
      "Max fee": [
        ""
      ],
      "Maximum total deposit fee accepted by the merchant for this contract": [
        ""
      ],
      "Created at": [
        ""
      ],
      "Time when this contract was generated": [
        ""
      ],
      "Refund deadline": [
        ""
      ],
      "After this deadline has passed no refunds will be accepted": [
        ""
      ],
      "Payment deadline": [
        ""
      ],
      "After this deadline, the merchant won't accept payments for the contract": [
        ""
      ],
      "Wire transfer deadline": [
        ""
      ],
      "Transfer deadline for the exchange": [
        ""
      ],
      "Time indicating when the order should be delivered": [
        ""
      ],
      "Where the order will be delivered": [
        ""
      ],
      "Auto-refund delay": [
        ""
      ],
      "How long the wallet should try to get an automatic refund for the purchase": [
        ""
      ],
      "Extra info": [
        ""
      ],
      "Extra data that is only interpreted by the merchant frontend": [
        ""
      ],
      "Order": [
        ""
      ],
      "Claimed": [
        ""
      ],
      "Claimed at": [
        ""
      ],
      "Timeline": [
        ""
      ],
      "Payment details": [
        ""
      ],
      "Order status": [
        ""
      ],
      "Product list": [
        ""
      ],
      "Paid": [
        ""
      ],
      "Wired": [
        ""
      ],
      "Refunded": [
        ""
      ],
      "Refund order": [
        ""
      ],
      "Not refundable": [
        ""
      ],
      "Next event in": [
        ""
      ],
      "Refunded amount": [
        ""
      ],
      "Refund taken": [
        ""
      ],
      "Status URL": [
        ""
      ],
      "Refund URI": [
        ""
      ],
      "Unpaid": [
        ""
      ],
      "Pay at": [
        ""
      ],
      "Order status URL": [
        ""
      ],
      "Payment URI": [
        ""
      ],
      "Unknown order status. This is an error, please contact the administrator.": [
        ""
      ],
      "Back": [
        ""
      ],
      "Refund created successfully": [
        ""
      ],
      "Could not create the refund": [
        ""
      ],
      "There are pending KYC requirements.": [
        ""
      ],
      "Missing ID": [
        ""
      ],
      "Not found": [
        ""
      ],
      "Select date to show nearby orders": [
        ""
      ],
      "Only show paid orders": [
        ""
      ],
      "New": [
        ""
      ],
      "Only show orders with refunds": [
        ""
      ],
      "Only display orders that have already been paid for by buyers and for which the wire transfer by the payment service provider is still pending": [
        ""
      ],
      "Not wired": [
        ""
      ],
      "Only display orders that have already been transferred by the payment service provider": [
        ""
      ],
      "Completed": [
        ""
      ],
      "Remove all filters": [
        ""
      ],
      "Clear date filter": [
        ""
      ],
      "Jump to date (%1$s)": [
        ""
      ],
      "Jump to order with the given product ID": [
        ""
      ],
      "Order id": [
        ""
      ],
      "Invalid. Please enter characters and numbers only": [
        ""
      ],
      "Just letters and numbers from 2 to 7": [
        ""
      ],
      "The size of the key must be 32 characters": [
        ""
      ],
      "Internal ID on the system": [
        ""
      ],
      "Useful to identify the device physically": [
        ""
      ],
      "Verification algorithm": [
        ""
      ],
      "Algorithm used to verify transactions in offline mode": [
        ""
      ],
      "Device key": [
        ""
      ],
      "Be sure to choose a password hard to guess, alternatively use the random generator": [
        ""
      ],
      "Your device needs to match exactly the same value": [
        ""
      ],
      "Generate random secret key": [
        ""
      ],
      "Random": [
        ""
      ],
      "You can scan the next QR code with your device or save the key before continuing.": [
        ""
      ],
      "Device added successfully": [
        ""
      ],
      "Could not add device": [
        ""
      ],
      "OTP devices": [
        ""
      ],
      "Add new devices": [
        ""
      ],
      "Load more devices before the first one": [
        ""
      ],
      "Delete selected devices from the database": [
        ""
      ],
      "Load more devices after the last one": [
        ""
      ],
      "There are no devices to list yet, add more by pressing the + sign": [
        ""
      ],
      "The device was successfully deleted": [
        ""
      ],
      "Could not delete the device": [
        ""
      ],
      "Device:": [
        ""
      ],
      "Algorithm to use to verify transactions in offline mode": [
        ""
      ],
      "Not modified": [
        ""
      ],
      "Be sure to be very hard to guess or use the random generator": [
        ""
      ],
      "Your device need to have exactly the same value": [
        ""
      ],
      "Change key": [
        ""
      ],
      "Could not update template": [
        ""
      ],
      "Template id is unknown": [
        ""
      ],
      "The provided information is inconsistent with the current state of the template": [
        ""
      ],
      "Click here to configure the stock of the product, leave it as is and the backend will not control stock.": [
        ""
      ],
      "Manage stock": [
        ""
      ],
      "This product has been configured without stock control": [
        ""
      ],
      "Infinite": [
        ""
      ],
      "Shrinkage cannot exceed the current stock and incoming supplies (maximum %1$s)": [
        ""
      ],
      "Incoming": [
        ""
      ],
      "Lost": [
        ""
      ],
      "Current": [
        ""
      ],
      "Remove stock control for this product": [
        ""
      ],
      "without stock": [
        ""
      ],
      "Next restock": [
        ""
      ],
      "Warehouse address": [
        ""
      ],
      "Add element to the list": [
        ""
      ],
      "Invalid amount": [
        ""
      ],
      "Product identification to use in URLs (for internal use only).": [
        ""
      ],
      "Illustration of the product for customers.": [
        ""
      ],
      "Product description for customers.": [
        ""
      ],
      "Age restriction": [
        ""
      ],
      "Is this product restricted for customer below certain age?": [
        ""
      ],
      "Minimum age of the customer": [
        ""
      ],
      "Unit name": [
        ""
      ],
      "Unit describing quantity of product sold (e.g. 2 kilograms, 5 liters, 3 items, 5 meters) for customers.": [
        ""
      ],
      "Example: kg, items or liters": [
        ""
      ],
      "Price per unit": [
        ""
      ],
      "Sale price for customers, including taxes, for above units of the product.": [
        ""
      ],
      "Stock": [
        ""
      ],
      "Inventory for products with finite supply (for internal use only).": [
        ""
      ],
      "Taxes included in the product price, exposed to customers.": [
        ""
      ],
      "Categories": [
        ""
      ],
      "Search by category description or id": [
        ""
      ],
      "Categories where this product will be listed on.": [
        ""
      ],
      "Product created successfully": [
        ""
      ],
      "Could not create product": [
        ""
      ],
      "Inventory": [
        ""
      ],
      "Add product to inventory": [
        ""
      ],
      "Sales": [
        ""
      ],
      "Sold": [
        ""
      ],
      "Free": [
        ""
      ],
      "Go to product update page": [
        ""
      ],
      "Update": [
        ""
      ],
      "Remove this product from the database": [
        ""
      ],
      "Load more products after the last one": [
        ""
      ],
      "Update the product with new price": [
        ""
      ],
      "Update product with new price": [
        ""
      ],
      "Confirm update": [
        ""
      ],
      "lost can't be greater that current + incoming (max %1$s)": [
        ""
      ],
      "Add more elements to the inventory": [
        ""
      ],
      "Report elements lost in the inventory": [
        ""
      ],
      "New price for the product": [
        ""
      ],
      "The are value with errors": [
        ""
      ],
      "Update product with new stock and price": [
        ""
      ],
      "There are no products to list yet, add more by pressing the + sign": [
        ""
      ],
      "Jump to product with the given product ID": [
        ""
      ],
      "Product id": [
        ""
      ],
      "Product updated successfully": [
        ""
      ],
      "Could not update the product": [
        ""
      ],
      "Product \"%1$s\" (ID: %2$s) has been deleted": [
        ""
      ],
      "Could not delete the product": [
        ""
      ],
      "If you delete the product named %1$s (ID: %2$s ), the stock and related information will be lost": [
        ""
      ],
      "Deleting an product can't be undone.": [
        ""
      ],
      "Product id:": [
        ""
      ],
      "Product (ID: %1$s) has been updated": [
        ""
      ],
      "Could not update product": [
        ""
      ],
      "Must be greater that 0": [
        ""
      ],
      "Too short": [
        ""
      ],
      "Identifier": [
        ""
      ],
      "Name of the template in URLs.": [
        ""
      ],
      "Describe what this template stands for": [
        ""
      ],
      "If specified here, this template will create orders with the same summary": [
        ""
      ],
      "Summary is editable": [
        ""
      ],
      "Allow the user to change the summary.": [
        ""
      ],
      "If specified here, this template will create orders with the same price": [
        ""
      ],
      "Amount is editable": [
        ""
      ],
      "Allow the user to select the amount to pay.": [
        ""
      ],
      "Currency is editable": [
        ""
      ],
      "Allow the user to change currency.": [
        ""
      ],
      "Supported currencies": [
        ""
      ],
      "Supported currencies: %1$s": [
        ""
      ],
      "Minimum age": [
        ""
      ],
      "Is this contract restricted to some age?": [
        ""
      ],
      "Payment timeout": [
        ""
      ],
      "How much time the customer has to complete the payment once the order was created.": [
        ""
      ],
      "OTP device": [
        ""
      ],
      "Use to verify transactions in offline mode.": [
        ""
      ],
      "No OTP device.": [
        ""
      ],
      "Add one first": [
        ""
      ],
      "No device": [
        ""
      ],
      "Template has been created": [
        ""
      ],
      "Could not create template": [
        ""
      ],
      "Templates": [
        ""
      ],
      "Add new templates": [
        ""
      ],
      "Load more templates before the first one": [
        ""
      ],
      "Delete selected templates from the database": [
        ""
      ],
      "Use template to create new order": [
        ""
      ],
      "Use template": [
        ""
      ],
      "Generate a QR code for the template.": [
        ""
      ],
      "Load more templates after the last one": [
        ""
      ],
      "There are no templates to list yet, add more by pressing the + sign": [
        ""
      ],
      "Jump to template with the given template ID": [
        ""
      ],
      "Template identification": [
        ""
      ],
      "Template \"%1$s\" (ID: %2$s) has been deleted": [
        ""
      ],
      "Failed to delete template": [
        ""
      ],
      "If you delete the template %1$s (ID: %2$s) you may loose information": [
        ""
      ],
      "Deleting an template": [
        ""
      ],
      "can't be undone": [
        ""
      ],
      "Print": [
        ""
      ],
      "If specified, this template will create order with the same summary": [
        ""
      ],
      "If specified, this template will create orders with the same price": [
        ""
      ],
      "How much time has the customer to complete the payment once the order was created.": [
        ""
      ],
      "Template (ID: %1$s) has been updated": [
        ""
      ],
      "An amount is required": [
        ""
      ],
      "An order summary is required": [
        ""
      ],
      "New order from template": [
        ""
      ],
      "Amount of the order": [
        ""
      ],
      "Order summary": [
        ""
      ],
      "Could not create order from template": [
        ""
      ],
      "You need your access token to perform the operation": [
        ""
      ],
      "You are updating the access token from instance with id \"%1$s\"": [
        ""
      ],
      "This instance does not have authentication token.": [
        ""
      ],
      "You can leave it empty if there is another layer of security.": [
        ""
      ],
      "Current access token": [
        ""
      ],
      "Clearing the access token will mean public access to the instance.": [
        ""
      ],
      "Clear token": [
        ""
      ],
      "Confirm change": [
        ""
      ],
      "Failed to clear token": [
        ""
      ],
      "Failed to set new token": [
        ""
      ],
      "Slug": [
        ""
      ],
      "Token family slug to use in URLs (for internal use only)": [
        ""
      ],
      "Kind": [
        ""
      ],
      "Token family kind": [
        ""
      ],
      "User-readable token family name": [
        ""
      ],
      "Token family description for customers": [
        ""
      ],
      "Valid After": [
        ""
      ],
      "Token family can issue tokens after this date": [
        ""
      ],
      "Valid Before": [
        ""
      ],
      "Token family can issue tokens until this date": [
        ""
      ],
      "Duration": [
        ""
      ],
      "Validity duration of a issued token": [
        ""
      ],
      "Token family created successfully.": [
        ""
      ],
      "Could not create token family.": [
        ""
      ],
      "Token Families": [
        ""
      ],
      "Add token family": [
        ""
      ],
      "Go to token family update page": [
        ""
      ],
      "Remove this token family from the database": [
        ""
      ],
      "There are no token families yet, add the first one by pressing the + sign.": [
        ""
      ],
      "Token family updated successfully": [
        ""
      ],
      "Could not update the token family": [
        ""
      ],
      "Token family \"%1$s\" (SLUG: %2$s) has been deleted": [
        ""
      ],
      "Failed to delete token family": [
        ""
      ],
      "If you delete the %1$s token family (Slug: %2$s), all issued tokens will become invalid.": [
        ""
      ],
      "Deleting a token family %1$s .": [
        ""
      ],
      "Token Family: %1$s": [
        ""
      ],
      "Could not update token family": [
        ""
      ],
      "Please check the ID; it does not appear to be valid.": [
        ""
      ],
      "Must have 52 characters, current %1$s": [
        ""
      ],
      "URL doesn't have the right format": [
        ""
      ],
      "Credited bank account": [
        ""
      ],
      "Select an account": [
        ""
      ],
      "Bank account of the merchant where the payment was received": [
        ""
      ],
      "Wire transfer ID": [
        ""
      ],
      "Unique identifier of the wire transfer used by the exchange, must be 52 characters long": [
        ""
      ],
      "Exchange URL": [
        ""
      ],
      "Base URL of the exchange that made the transfer, should have been in the wire transfer subject": [
        ""
      ],
      "Amount credited": [
        ""
      ],
      "Actual amount that was wired to the merchant's bank account": [
        ""
      ],
      "Wire transfer informed successfully": [
        ""
      ],
      "Could not inform transfer": [
        ""
      ],
      "Wire transfers": [
        ""
      ],
      "Add new transfer": [
        ""
      ],
      "Load more wire transfers preceding the first one": [
        ""
      ],
      "Credit": [
        ""
      ],
      "Confirmed": [
        ""
      ],
      "Verified": [
        ""
      ],
      "Executed on": [
        ""
      ],
      "yes": [
        ""
      ],
      "no": [
        ""
      ],
      "never": [
        ""
      ],
      "unknown": [
        ""
      ],
      "Delete selected transfer from the database": [
        ""
      ],
      "Load more transfers after the last one": [
        ""
      ],
      "There are no transfers to list yet, add more by pressing the + sign": [
        ""
      ],
      "All accounts": [
        ""
      ],
      "Filter by account address": [
        ""
      ],
      "Only show wire transfers confirmed by the merchant": [
        ""
      ],
      "Only show wire transfers claimed by the exchange": [
        ""
      ],
      "Unverified": [
        ""
      ],
      "Wire transfer \"%1$s...\" has been deleted": [
        ""
      ],
      "Failed to delete transfer": [
        ""
      ],
      "Must be business or individual": [
        ""
      ],
      "Pay delay can't be greater than wire transfer delay": [
        ""
      ],
      "Max 7 lines": [
        ""
      ],
      "Doesn't match": [
        ""
      ],
      "Enable access control": [
        ""
      ],
      "Choose if the backend server should authenticate access.": [
        ""
      ],
      "Access control is not yet decided. This instance can't be created.": [
        ""
      ],
      "Authorization must be handled externally.": [
        ""
      ],
      "Authorization is handled by the backend server.": [
        ""
      ],
      "Need to complete marked fields and choose authorization method": [
        ""
      ],
      "Name of the instance in URLs. The 'default' instance is special in that it is used to administer other instances.": [
        ""
      ],
      "Business name": [
        ""
      ],
      "Legal name of the business represented by this instance.": [
        ""
      ],
      "Email": [
        ""
      ],
      "Contact email": [
        ""
      ],
      "Website URL": [
        ""
      ],
      "URL.": [
        ""
      ],
      "Logo": [
        ""
      ],
      "Logo image.": [
        ""
      ],
      "Physical location of the merchant.": [
        ""
      ],
      "Jurisdiction": [
        ""
      ],
      "Jurisdiction for legal disputes with the merchant.": [
        ""
      ],
      "Pay transaction fee": [
        ""
      ],
      "Cover the transaction cost or pass it on to the user.": [
        ""
      ],
      "Default payment delay": [
        ""
      ],
      "Time customers have to pay an order before the offer expires by default.": [
        ""
      ],
      "Default wire transfer delay": [
        ""
      ],
      "Maximum time an exchange is allowed to delay wiring funds to the merchant, enabling it to aggregate smaller payments into larger wire transfers and reducing wire fees.": [
        ""
      ],
      "Instance id": [
        ""
      ],
      "Failed to update instance": [
        ""
      ],
      "Must be \"pay\" or \"refund\"": [
        ""
      ],
      "Must be one of '%1$s'": [
        ""
      ],
      "Webhook ID to use": [
        ""
      ],
      "Event": [
        ""
      ],
      "Pay": [
        ""
      ],
      "The event of the webhook: why the webhook is used": [
        ""
      ],
      "Method": [
        ""
      ],
      "GET": [
        ""
      ],
      "POST": [
        ""
      ],
      "PUT": [
        ""
      ],
      "PATCH": [
        ""
      ],
      "HEAD": [
        ""
      ],
      "Method used by the webhook": [
        ""
      ],
      "URL": [
        ""
      ],
      "URL of the webhook where the customer will be redirected": [
        ""
      ],
      "The text below supports the %1$s template engine. Any string between %2$s and %3$s will be replaced with the value of the corresponding variable.": [
        ""
      ],
      "For example %1$s will be replaced with the the order's price": [
        ""
      ],
      "The short list of variables are:": [
        ""
      ],
      "order's description": [
        ""
      ],
      "order's price": [
        ""
      ],
      "order's unique identification": [
        ""
      ],
      "the amount that was being refunded": [
        ""
      ],
      "the reason entered by the merchant staff for granting the refund": [
        ""
      ],
      "time of the refund in nanoseconds since 1970": [
        ""
      ],
      "Http body": [
        ""
      ],
      "Body template by the webhook": [
        ""
      ],
      "Webhook create successfully": [
        ""
      ],
      "Could not create the webhook": [
        ""
      ],
      "Could not create webhook": [
        ""
      ],
      "Webhooks": [
        ""
      ],
      "Add new webhooks": [
        ""
      ],
      "Load more webhooks before the first one": [
        ""
      ],
      "Event type": [
        ""
      ],
      "Delete selected webhook from the database": [
        ""
      ],
      "Load more webhooks after the last one": [
        ""
      ],
      "There are no webhooks to list yet, add more by pressing the + sign": [
        ""
      ],
      "Webhook deleted successfully": [
        ""
      ],
      "Could not delete the webhook": [
        ""
      ],
      "Header": [
        ""
      ],
      "Header template of the webhook": [
        ""
      ],
      "Body": [
        ""
      ],
      "Webhook updated": [
        ""
      ],
      "Could not update webhook": [
        ""
      ],
      "Language": [
        ""
      ],
      "Advance order creation": [
        ""
      ],
      "Shows more options in the order creation form": [
        ""
      ],
      "Advance instance settings": [
        ""
      ],
      "Shows more options in the instance settings form": [
        ""
      ],
      "Date format": [
        ""
      ],
      "How the date is going to be displayed": [
        ""
      ],
      "Developer mode": [
        ""
      ],
      "Shows more options and tools which are not intended for general audience.": [
        ""
      ],
      "Total products": [
        ""
      ],
      "Delete selected category from the database": [
        ""
      ],
      "There are no categories yet, add more pressing the + sign": [
        ""
      ],
      "Category delete successfully": [
        ""
      ],
      "Could not delete the category": [
        ""
      ],
      "Invalid. Please insert only characters and numbers": [
        ""
      ],
      "Category name": [
        ""
      ],
      "Category added successfully": [
        ""
      ],
      "Could not add category": [
        ""
      ],
      "Id:": [
        ""
      ],
      "Name of the category": [
        ""
      ],
      "Products": [
        ""
      ],
      "Search by product description or id": [
        ""
      ],
      "Products that this category will list.": [
        ""
      ],
      "Could not update category": [
        ""
      ],
      "Category ID is unknown": [
        ""
      ],
      "Without this the merchant backend will refuse to create new orders.": [
        ""
      ],
      "Hide for today": [
        ""
      ],
      "KYC verification needed": [
        ""
      ],
      "Some transfers are on hold until the KYC process is completed. Visit the KYC section in the left panel for more information": [
        ""
      ],
      "Configuration": [
        ""
      ],
      "OTP Devices": [
        ""
      ],
      "Settings": [
        ""
      ],
      "Access token": [
        ""
      ],
      "Connection": [
        ""
      ],
      "Interface": [
        ""
      ],
      "List": [
        ""
      ],
      "Log out": [
        ""
      ],
      "Failed to create instance": [
        ""
      ],
      "checking compatibility with server...": [
        ""
      ],
      "Contacting the server failed": [
        ""
      ],
      "The server version is not supported": [
        ""
      ],
      "Supported version \"%1$s\", server version \"%2$s\".": [
        ""
      ],
      "Deleting": [
        ""
      ],
      "Changing": [
        ""
      ],
      "Manage access token": [
        ""
      ],
      "Business Name": [
        ""
      ],
      "Order ID": [
        ""
      ],
      "Payment URL": [
        ""
      ]
    }
  },
  "domain": "messages",
  "plural_forms": "nplurals=2; plural=(n != 1);",
  "lang": "",
  "completeness": 0
};

strings['de'] = {
  "locale_data": {
    "messages": {
      "": {
        "domain": "messages",
        "plural_forms": "nplurals=2; plural=n != 1;",
        "lang": "de"
      },
      "The request reached a timeout, check your connection.": [
        "Die Anfrage hat ihr Zeitlimit erreicht, überprüfen Sie bitte Ihre Internetverbindung."
      ],
      "The request was cancelled.": [
        "Die Anfrage wurde abgebrochen."
      ],
      "A lot of request were made to the same server and this action was throttled.": [
        "Es wurden gleichzeitig zu viele Anfragen an denselben Server gestellt, daher ist diese Aktion zurückgestellt worden."
      ],
      "The response of the request is malformed.": [
        "Die Antwort auf die Anfrage wird nicht richtig dargestellt."
      ],
      "Could not complete the request due to a network problem.": [
        "Die Anfrage konnte aufgrund eines Netzwerkproblems nicht abgeschlossen werden."
      ],
      "Unexpected request error.": [
        "Unerwarteter Fehler bei der Anfrage."
      ],
      "Unexpected error.": [
        "Unerwarteter Fehler."
      ],
      "Cancel": [
        "Abbrechen"
      ],
      "%1$s": [
        "%1$s"
      ],
      "Close": [
        "Schließen"
      ],
      "Continue": [
        "Weiter"
      ],
      "Clear": [
        "Leeren"
      ],
      "Confirm": [
        "Bestätigen"
      ],
      "Required": [
        "Erforderlich"
      ],
      "Letter must be a JSON string": [
        "Der Buchstabe muss eine JSON-Zeichenkette sein"
      ],
      "JSON string is invalid": [
        "Die JSON-Zeichenkette ist ungültig"
      ],
      "Import": [
        "Importieren"
      ],
      "Importing an account from the bank": [
        "Ein Konto von der Bank importieren"
      ],
      "You can export your account settings from the Libeufin Bank's account profile. Paste the content in the next field.": [
        "Sie können Ihre Kontoeinstellungen aus dem Kontoprofil der Libeufin-Bank exportieren. Fügen Sie den Inhalt im nächsten Feld ein."
      ],
      "Account information": [
        "Kontoinformationen"
      ],
      "Correct form": [
        "Korrektur der Angaben"
      ],
      "Comparing account details": [
        "Vergleich der Kontodetails"
      ],
      "Testing against the account info URL succeeded but the account information reported is different with the account details form.": [
        "Die Prüfung der Kontoinformationen-URL war erfolgreich, aber die gemeldeten Kontoinformationen unterscheiden sich von der Kontodetails-Erfassung."
      ],
      "Field": [
        "Feld"
      ],
      "In the form": [
        "Im Formular"
      ],
      "Reported": [
        "Gemeldet"
      ],
      "Type": [
        "Typ"
      ],
      "IBAN": [
        "IBAN"
      ],
      "Address": [
        "Adresse"
      ],
      "Host": [
        "Host"
      ],
      "Account id": [
        "Konto-ID"
      ],
      "Owner's name": [
        "Name des Kontoinhabers"
      ],
      "Account": [
        "Konto"
      ],
      "Bank host": [
        "Server der Bank (Bank host)"
      ],
      "Bank account": [
        "Bankkonto"
      ],
      "BIC": [
        "BIC"
      ],
      "Ok": [
        "OK"
      ],
      "Validate bank account: %1$s": [
        "Prüfung der Gültigkeit der Kontonummer: %1$s"
      ],
      "You need to make a bank transfer with the specified subject to validate that you are the owner of the account.": [
        "Sie müssen eine Banküberweisung mit dem angegebenen Buchungsvermerk ausführen, um zu bestätigen, dass Sie der Eigentümer des Kontos sind."
      ],
      "Step 1:": [
        "Schritt 1:"
      ],
      "Copy this string and paste it into the field 'Subject' (resp. 'Purpose') in your banking app or bank website": [
        "Kopieren Sie diese Zeichenkette ins Feld „Buchungsvermerk“ (bzw. „Verwendungszweck“) Ihrer Banking-App oder Online-Banking-Webseite"
      ],
      "Subject": [
        "Buchungsvermerk"
      ],
      "Step 2:": [
        "Schritt 2:"
      ],
      "Copy and paste this IBAN and the name into the receiver fields in your banking app or website": [
        "Kopieren Sie diese IBAN und fügen Sie diese mit dem Namen des Begünstigten in die entsprechenden Felder Ihrer Banking-App oder Ihrer Online-Banking-Website ein"
      ],
      "Receiver name": [
        "Name des Begünstigten"
      ],
      "Step 3:": [
        "Schritt 3:"
      ],
      "Select the smallest possible amount for the wire transfer in your banking app or on your online banking website.": [
        "Wählen Sie in Ihrer Banking-App oder Online-Banking-Website den kleinstmöglichen Betrag für eine Überweisung aus."
      ],
      "Make sure ALL data is correct, including the subject and you are using your selected bank account. You can use the copy buttons (%1$s) to prevent typing errors or the \"payto://\" URI below to copy just one value.": [
        "Vergewissern Sie sich, dass ALLE Daten und der Buchungsvermerk korrekt sind und Sie das richtige Bankkonto gewählt haben. Sie können die Daten übernehmen (%1$s), um Fehler durch eigenhändige Eingabe zu vermeiden, oder den \"payto://\" URI unten verwenden, um nur einen Wert zu kopieren."
      ],
      "Alternatively, if your bank already supports the standard 'PayTo URI', you can use this %1$s link instead": [
        "Falls Ihre Bank den technischen Standard „payto:// URI“ erlaubt, können Sie alternativ dazu den Link %1$s verwenden"
      ],
      "If you delete the instance named %1$s (ID: %2$s), the merchant will no longer be able to process orders or refunds": [
        "Wenn Sie die Instanz mit der Bezeichnung %1$s (ID: %2$s) löschen, wird der Verkäufer keine weiteren Bestellungen oder Rückerstattungen bearbeiten können"
      ],
      "This action deletes the instance private key, but preserves all transaction data. You can still access that data after deleting the instance.": [
        "Dieser Vorgang löscht den privaten Schlüssel der Instanz, sichert jedoch alle Transaktionsdaten. Sie können auch nach der Löschung der Instanz auf diese Daten weiterhin zugreifen."
      ],
      "Deleting an instance %1$s .": [
        "Löschung der Instanz %1$s ."
      ],
      "If you purge the instance named %1$s (ID: %2$s), you will also delete all it&apos;s transaction data.": [
        "Falls Sie die Instanz %1$s (ID: %2$s) vollständig verwerfen, löschen Sie damit auch unwiderruflich alle Transaktionsdaten."
      ],
      "The instance will disappear from your list, and you will no longer be able to access it&apos;s data.": [
        "Die Instanz wird von Ihrer Liste gestrichen und alle ihre Daten werden nicht mehr zugänglich sein."
      ],
      "Purging an instance %1$s .": [
        "Die Instanz %1$s wird endgültig verworfen."
      ],
      "Is not the same as the current access token": [
        "ist ohne Übereinstimmung mit dem bisherigen Zugangstoken"
      ],
      "Can't be the same as the old token": [
        "muss sich vom alten Zugangstoken unterscheiden"
      ],
      "Is not the same": [
        "ungleich"
      ],
      "You are updating the access token for the instance with ID %1$s": [
        "Sie erneuern den Zugangstoken für die Instanz mit der ID %1$s"
      ],
      "Old access token": [
        "Alter Zugangstoken"
      ],
      "Access token currently in use": [
        "Zugangstoken zurzeit in Verwendung"
      ],
      "New access token": [
        "Neuer Zugangstoken"
      ],
      "Next access token to be used": [
        "Folgender Zugangstoken für die zukünftige Verwendung"
      ],
      "Repeat access token": [
        "Eingabe des Zugangstokens wiederholen"
      ],
      "Confirm the same access token": [
        "Zugangstoken wiederholen"
      ],
      "Clearing the access token will mean public access to the instance": [
        "Den Zugangstoken zu löschen ermöglicht den freien Zugang zur Instanz für alle"
      ],
      "Can't be the same as the old access token": [
        "Muss sich vom alten Zugangstoken unterscheiden"
      ],
      "You are setting the access token for the new instance": [
        "Sie speichern den Zugangstoken für eine neue Instanz"
      ],
      "With external authorization method no check will be done by the merchant backend": [
        "Bei externen Verfahren der Berechtigungsprüfung erfolgt keine weitere Zugangsprüfung durch das Merchant-Backend"
      ],
      "Set external authorization": [
        "Externe Zugangsberechtigung festlegen"
      ],
      "Set access token": [
        "Zugangstoken festlegen"
      ],
      "Operation in progress...": [
        "Die Verarbeitung ist im Gange..."
      ],
      "The operation will be automatically canceled after %1$s seconds": [
        "Der Vorgang wird nach %1$s Sekunden automatisch abgebrochen"
      ],
      "Your password is incorrect": [
        "Ihr Passwort ist ungültig"
      ],
      "Your instance cannot be found": [
        "Ihre Instanz kann nicht gefunden werden"
      ],
      "Login required": [
        "Anmeldung erforderlich"
      ],
      "Please enter your access token for %1$s.": [
        "Bitte geben Sie Ihren Zugangstoken für %1$s ein."
      ],
      "Access Token": [
        "Zugangstoken"
      ],
      "Instances": [
        "Instanzen"
      ],
      "Delete": [
        "Löschen"
      ],
      "Add new instance": [
        "Neue Instanz hinzufügen"
      ],
      "ID": [
        "ID"
      ],
      "Name": [
        "Name"
      ],
      "Edit": [
        "Bearbeiten"
      ],
      "Purge": [
        "Vollständig verwerfen"
      ],
      "There are no instances yet, add more pressing the + sign": [
        "Es gibt noch keine Instanzen. Sie können diese mit dem +-Zeichen hinzufügen"
      ],
      "Only show active instances": [
        "Zeige nur aktive Instanzen"
      ],
      "Active": [
        "Aktiv"
      ],
      "Only show deleted instances": [
        "Zeige nur gelöschte Instanzen"
      ],
      "Deleted": [
        "Gelöscht"
      ],
      "Show all instances": [
        "Zeige alle Instanzen"
      ],
      "All": [
        "Alle"
      ],
      "Instance \"%1$s\" (ID: %2$s) has been deleted": [
        "Instanz \"%1$s\" (ID: %2$s) wurde gelöscht"
      ],
      "Failed to delete instance": [
        "Das Löschen der Instanz ist fehlgeschlagen"
      ],
      "Instance '%1$s' (ID: %2$s) has been purged": [
        "Instanz '%1$s' (ID: %2$s) wurde vollständig gelöscht"
      ],
      "Failed to purge instance": [
        "Die vollständige Löschung der Instanz ist fehlgeschlagen"
      ],
      "Loading...": [
        "Die Daten werden eingelesen..."
      ],
      "This is not a valid bitcoin address.": [
        "Dies ist keine zulässige Bitcoin-Adresse."
      ],
      "This is not a valid Ethereum address.": [
        "Dies ist keine zulässige Ethereum-Adresse."
      ],
      "This is not a valid host.": [
        "Dies ist kein gültiger Host."
      ],
      "IBAN numbers usually have more that 4 digits": [
        "Eine IBAN besteht normalerweise aus mehr als 4 Ziffern"
      ],
      "IBAN numbers usually have less that 34 digits": [
        "Eine IBAN besteht normalerweise aus weniger als 34 Ziffern"
      ],
      "IBAN country code not found": [
        "IBAN-Ländercode wurde nicht gefunden"
      ],
      "IBAN number is invalid, checksum is wrong": [
        "Die IBAN-Nummer ist ungültig, die Prüfsumme ist falsch"
      ],
      "Choose one...": [
        "Bitte wählen Sie aus..."
      ],
      "Method to use for wire transfer": [
        "Verfahren, das für die Überweisung genutzt werden soll"
      ],
      "Routing": [
        "Bankleitzahlensystem"
      ],
      "Routing number.": [
        "Bankleitzahl."
      ],
      "Account number.": [
        "Kontonummer."
      ],
      "Code": [
        "Code"
      ],
      "Business Identifier Code.": [
        "Business Identifier Code. (BIC)."
      ],
      "International Bank Account Number.": [
        "IBAN (Internationale Bankkontonummer)."
      ],
      "Unified Payment Interface.": [
        "Unified Payment Interface (UPI)."
      ],
      "Bitcoin protocol.": [
        "Bitcoin-Protokoll."
      ],
      "Ethereum protocol.": [
        "Ethereum-Protokoll."
      ],
      "Interledger protocol.": [
        "Interledger-Protokoll."
      ],
      "Bank host.": [
        "Bankenserver (Bank host)."
      ],
      "Without scheme and may include subpath:": [
        "Ohne Schema (kann einen Unterpfad enthalten):"
      ],
      "Bank account.": [
        "Bankkonto."
      ],
      "Legal name of the person holding the account.": [
        "Rechtsgültiger Name des Kontoinhabers."
      ],
      "It should match the bank account name.": [
        "Der Wert muss mit dem Namen des Inhabers oder des wirtschaftlich Berechtigten des Kontos übereinstimmen."
      ],
      "Invalid url": [
        "ungültige URL"
      ],
      "URL must end with a '/'": [
        "Die URL muss mit '/' enden"
      ],
      "URL must not contain params": [
        "Die URL darf keine Parameter enthalten"
      ],
      "URL must not hash param": [
        "Die URL darf keinen Hash-Parameter enthalten"
      ],
      "The request to check the revenue API failed.": [
        "Die Anfrage bei der Einzahlungs-Schnittstelle ist fehlgeschlagen."
      ],
      "Server replied with \"bad request\".": [
        "Der Server antwortete mit \"bad request\"."
      ],
      "Unauthorized, check credentials.": [
        "Unzulässiger Vorgang, bitte die Zugangsdaten (Token, Anmeldename, Passwort) überprüfen."
      ],
      "The endpoint does not seem to be a Taler Revenue API.": [
        "Die Gegenstelle scheint keine Taler-Einzahlungsschnittstelle (Taler Revenue API) zu sein."
      ],
      "The request was made correctly, but the bank's server did not respond with the appropriate value for 'credit_account', so we cannot confirm that it is the same bank account.": [
        "Die Anfrage war richtig gestellt, doch der Server der Bank antwortete nicht mit dem passenden Wert (für 'credit_account'), so dass wir nicht bestätigen können, dass es sich um dasselbe Bankkonto handelt."
      ],
      "Account:": [
        "Konto:"
      ],
      "If the bank supports Taler Revenue API then you can add the endpoint URL below to keep the revenue information in sync.": [
        "Wenn die kontoführende Bank die Schnittstelle Taler Revenue API unterstützt, können Sie die untenstehende Gegenstelle (endpoint URL) hinzufügen, um die Umsatzinformationen zwischen dem Backend und dem Bankkonto zu synchronisieren."
      ],
      "Endpoint URL": [
        "Gegenstelle (endpoint URL)"
      ],
      "From where the merchant can download information about incoming wire transfers to this account": [
        "Informationsquelle zu eingehenden Überweisungen auf dieses Bankkonto"
      ],
      "Auth type": [
        "Authentifizierungstyp"
      ],
      "Choose the authentication type for the account info URL": [
        "Wählen Sie den Authentifizierungstyp für die Kontoinformations-URL"
      ],
      "Without authentication": [
        "Ohne Authentifizierung"
      ],
      "With password": [
        "Mit Passwort"
      ],
      "With token": [
        "Mit Zugangstoken"
      ],
      "Do not change": [
        "Lassen Sie dies unverändert"
      ],
      "Username": [
        "Benutzername"
      ],
      "Username to access the account information.": [
        "Benutzername für den Zugriff auf die Kontoinformationen."
      ],
      "Password": [
        "Passwort"
      ],
      "Password to access the account information.": [
        "Passwort für den Zugriff auf die Kontoinformationen."
      ],
      "Token": [
        "Zugangstoken"
      ],
      "Access token to access the account information.": [
        "Zugangstoken für den Zugriff auf die Kontoinformationen."
      ],
      "Match": [
        "Übereinstimmung"
      ],
      "Check where the information match against the server info.": [
        "Prüfung der Übereinstimmung dieser Information und der Daten auf dem Server."
      ],
      "Not verified": [
        "Nicht überprüft"
      ],
      "Last test was ok": [
        "Der letzte Test war erfolgreich"
      ],
      "Last test failed": [
        "Der letzte Test ist fehlgeschlagen"
      ],
      "Compare info from server with account form": [
        "Vergleich der Information des Servers mit den Bankkonto-Formulardaten"
      ],
      "Test": [
        "Test"
      ],
      "Need to complete marked fields": [
        "Die gekennzeichneten Felder müssen ausgefüllt sein"
      ],
      "Confirm operation": [
        "Vorgang bestätigen"
      ],
      "Account details": [
        "Kontodetails"
      ],
      "Import from bank": [
        "Datenimport von der Bank"
      ],
      "Could not create account": [
        "Das Konto konnte nicht angelegt werden"
      ],
      "No 'default' instance configured yet.": [
        "Es wurde keine Standardinstanz konfiguriert."
      ],
      "Create a 'default' instance to begin using the merchant backoffice.": [
        "Erstellen Sie eine Standardinstanz, um das Merchant-Backend erstmalig zu verwenden."
      ],
      "Bank accounts": [
        "Bankkonten"
      ],
      "Add new account": [
        "Neues Konto hinzufügen"
      ],
      "Wire method: Bitcoin": [
        "Überweisungsmethode: Bitcoin"
      ],
      "SegWit 1": [
        "SegWit 1"
      ],
      "SegWit 2": [
        "SegWit 2"
      ],
      "Delete selected accounts from the database": [
        "Löschen ausgewählter Konten von der Datenbank"
      ],
      "Wire method: x-taler-bank": [
        "Überweisungsmethode: x-taler-bank"
      ],
      "Account name": [
        "Kontenbezeichnung (Name des Kontoinhabers)"
      ],
      "Wire method: IBAN": [
        "Überweisungsmethode: IBAN"
      ],
      "Other accounts": [
        "Andere Konten"
      ],
      "Path": [
        "Pfad"
      ],
      "There are no accounts yet, add more pressing the + sign": [
        "Es sind noch keine Konten angelegt, Sie können diese mit dem +-Zeichen hinzufügen"
      ],
      "You need to associate a bank account to receive revenue.": [
        "Sie müssen ein Bankkonto verknüpfen, um Einnahmen aus Überweisungen auf dieses Konto zu erhalten."
      ],
      "Without this the you won't be able to create new orders.": [
        "Ohne dies können Sie keine neuen Bestellungen erstellen."
      ],
      "The bank account has been successfully deleted.": [
        "Die Verknüpfung mit dem Bankkonto wurde erfolgreich gelöscht."
      ],
      "Could not delete the bank account": [
        "Die Verknüpfung mit dem Bankkonto konnte nicht gelöscht werden"
      ],
      "Could not update account": [
        "Das Konto konnte nicht aktualisiert werden"
      ],
      "Could not delete account": [
        "Die Kontoverbindung konnte nicht entfernt werden"
      ],
      "Pending KYC verification": [
        "Ausstehende KYC-Prüfung"
      ],
      "Exchange": [
        "Zahlungsdienstleister"
      ],
      "Reason": [
        "Buchungsvermerk"
      ],
      "Pending KYC process, click here to complete": [
        "Ausstehende KYC-Prüfung; klicken Sie hier, um den Vorgang abzuschließen"
      ],
      "The Payment Service Provider requires an account verification.": [
        "Der Zahlungsdienstleister verlangt eine Prüfung des Bankkontos."
      ],
      "Http Status": [
        "HTTP-Status"
      ],
      "No pending kyc verification!": [
        "Eine KYC-Prüfung ist nicht (mehr) ausstehend!"
      ],
      "Change value to unknown date": [
        "Wert auf unbekanntes Datum ändern"
      ],
      "Change value to empty": [
        "Wert annullieren"
      ],
      "Change value to never": [
        "Wert auf „nie“ setzen"
      ],
      "Never": [
        "Nie"
      ],
      "days": [
        "Tage"
      ],
      "hours": [
        "Stunden"
      ],
      "minutes": [
        "Minuten"
      ],
      "seconds": [
        "Sekunden"
      ],
      "Forever": [
        "Unbegrenzt"
      ],
      "%1$sM": [
        "%1$sM"
      ],
      "%1$sY": [
        "%1$sY"
      ],
      "%1$sd": [
        "%1$sd"
      ],
      "%1$sh": [
        "%1$sh"
      ],
      "%1$smin": [
        "%1$smin"
      ],
      "%1$ssec": [
        "%1$ssec"
      ],
      "Country": [
        "Land"
      ],
      "Building number": [
        "Zusätzliche Adressangabe"
      ],
      "Building name": [
        "Bezeichnung des Gebäudes"
      ],
      "Street": [
        "Str."
      ],
      "Post code": [
        "Postleitzahl"
      ],
      "Town location": [
        "Ortsteil"
      ],
      "Town": [
        "Ort"
      ],
      "District": [
        "Bezirk/Kanton"
      ],
      "Country subdivision": [
        "Region"
      ],
      "Description": [
        "Bezeichnung"
      ],
      "Enter description or id": [
        "Bezeichnung oder ID eingeben"
      ],
      "no match found with that description or id": [
        "Für diese Bezeichnung oder ID wurde nichts gefunden"
      ],
      "You must enter a valid product identifier.": [
        "Sie müssen eine gültige Artikelkennung eingeben."
      ],
      "Quantity must be greater than 0!": [
        "Die Menge muss größer als 0 sein!"
      ],
      "This quantity exceeds remaining stock. Currently, only %1$s units remain unreserved in stock.": [
        "Diese Menge überschreitet den Bestand. Momentan sind nur %1$s Stück im Inventar ohne Vorbestellung."
      ],
      "Search product": [
        "Artikel suchen"
      ],
      "Quantity": [
        "Menge"
      ],
      "How many products will be added": [
        "Wie viele Artikel hinzugefügt werden"
      ],
      "Add from inventory": [
        "Vom Bestand hinzufügen"
      ],
      "Image must be smaller than 1 MB": [
        "Die Bilddatei muss kleiner als 1 MB sein"
      ],
      "Add": [
        "Hinzufügen"
      ],
      "Remove": [
        "Entfernen"
      ],
      "Invalid": [
        "Ungültig"
      ],
      "This product has %1$s applicable taxes configured.": [
        "Für diesen Artikel ist ein Steuersatz von %1$s angelegt."
      ],
      "No taxes configured for this product.": [
        "Für diesen Artikel sind keine Steuern angelegt."
      ],
      "Amount": [
        "Betrag"
      ],
      "Taxes can be in currencies that differ from the main currency used by the merchant.": [
        "Steuern können in einer anderen Währung sein als die Hauptwährung, welche für Verkäufe normalerweise verwendet wird."
      ],
      "Enter currency and value separated with a colon, e.g. &quot;USD:2.3&quot;.": [
        "Geben Sie Währung und Betrag mit einem Doppelpunkt getrennt ein, z.B. &quot;EUR:2,3&quot;."
      ],
      "Legal name of the tax, e.g. VAT or import duties.": [
        "Offizielle Bezeichnung der Steuer, z.B. Mehrwertsteuer oder Einfuhrsteuer."
      ],
      "Add tax to the tax list": [
        "Steuersatz zur Liste der Steuern hinzufügen"
      ],
      "Describe and add a product that is not in the inventory list": [
        "Bisher nicht inventarisierte Artikel beschreiben und hinzufügen"
      ],
      "Add custom product": [
        "Individuellen Artikel hinzufügen"
      ],
      "Complete information of the product": [
        "Informationen zum Artikel vervollständigen"
      ],
      "Must be a number": [
        "Dieser Wert muss eine Nummer sein"
      ],
      "Must be grater than 0": [
        "Dieser Wert muss größer als Null sein"
      ],
      "Image": [
        "Bild"
      ],
      "Photo of the product.": [
        "Abbildung des Artikels."
      ],
      "Full product description.": [
        "Vollständige Artikelbeschreibung."
      ],
      "Unit": [
        "Einheit"
      ],
      "Name of the product unit.": [
        "Artikelname (Bezeichnung einer Einheit)."
      ],
      "Price": [
        "Preis"
      ],
      "Amount in the current currency.": [
        "Betrag in der aktuellen Währung."
      ],
      "How many products will be added.": [
        "Wie viele Artikel hinzugefügt werden."
      ],
      "Taxes": [
        "Steuern"
      ],
      "Unit price": [
        "Preis einer Einheit"
      ],
      "Total price": [
        "Gesamtpreis"
      ],
      "Must be greater than 0": [
        "Dieser Wert muss größer als Null sein"
      ],
      "Refund deadline can't be before pay deadline": [
        "Die Rückerstattungsfrist kann nicht vor der Zahlungsfrist liegen"
      ],
      "Wire transfer deadline can't be before refund deadline": [
        "Der Zeitraum der Überweisung kann nicht vor Ablauf der Rückerstattungsfrist enden"
      ],
      "Wire transfer deadline can't be before pay deadline": [
        "Die Überweisungsfrist kann nicht vor der Zahlungsfrist liegen"
      ],
      "Must have a refund deadline": [
        "Es sollte eine Rückerstattungsfrist geben"
      ],
      "Auto refund can't be after refund deadline": [
        "Eine automatische Rückerstattung kann nicht nach der Rückerstattungsfrist erfolgen"
      ],
      "Must be in the future": [
        "Dieser Wert sollte in der Zukunft liegen"
      ],
      "Simple": [
        "Einfach"
      ],
      "Advanced": [
        "Erweitert"
      ],
      "Manage products in order": [
        "Artikel in der Bestellung verwalten"
      ],
      "%1$s products with a total price of %2$s.": [
        "%1$s Artikel zu einer Gesamtsumme von %2$s."
      ],
      "Manage list of products in the order.": [
        "Liste der Artikel in der Bestellung verwalten."
      ],
      "Remove this product from the order.": [
        "Diesen Artikel aus der Bestellung entfernen."
      ],
      "Total product price added up": [
        "Gesamtpreis des Artikels hinzugerechnet"
      ],
      "Amount to be paid by the customer": [
        "Vom Kunden zu zahlender Betrag"
      ],
      "Order price": [
        "Bestellsumme"
      ],
      "Final order price": [
        "Endgültiger Bestellwert"
      ],
      "Summary": [
        "Zusammenfassung"
      ],
      "Title of the order to be shown to the customer": [
        "Bezeichnung der Bestellung, die den Kunden angezeigt wird"
      ],
      "Shipping and fulfillment": [
        "Versand und Lieferung"
      ],
      "Delivery date": [
        "Lieferdatum"
      ],
      "Deadline for physical delivery assured by the merchant.": [
        "Vom Händler zugesicherte Zustellfrist."
      ],
      "Location": [
        "Lieferadresse"
      ],
      "Address where the products will be delivered": [
        "Zustelladresse der Artikel"
      ],
      "Fulfillment URL": [
        "Adresse digitaler Dienstleistung (Fulfillment-URL)"
      ],
      "URL to which the user will be redirected after successful payment.": [
        "URL der von Kunden zu besuchenden Adresse nach erfolgter Bezahlung."
      ],
      "Taler payment options": [
        "Taler-Zahlungsoptionen"
      ],
      "Override default Taler payment settings for this order": [
        "Überschreibe die vorgegebenen Zahlungseinstellungen im Taler-System für diese Bestellung"
      ],
      "Payment time": [
        "Zahlungsdatum"
      ],
      "Time for the customer to pay for the offer before it expires. Inventory products will be reserved until this deadline. Time start to run after the order is created.": [
        "Zeitdauer, die Käufer haben, um das Angebot zu bezahlen, bevor es verfällt. Inventarisierte Artikel für das Angebot werden bis zum Fristablauf reserviert. Die Zeitdauer beginnt mit der Erstellzeit der Bestellungen."
      ],
      "Default": [
        "Standardeinstellung"
      ],
      "Refund time": [
        "Rückerstattungsdatum"
      ],
      "Time while the order can be refunded by the merchant. Time starts after the order is created.": [
        "Zeitdauer, in der die Bestellung vom Verkäufer erstattet werden kann. Die Zeitdauer beginnt mit der Erstellzeit der Bestellungen."
      ],
      "Wire transfer time": [
        "Zeitdauer der Überweisungen"
      ],
      "Time for the exchange to make the wire transfer. Time starts after the order is created.": [
        "Zeitdauer, in welcher der Zahlungsdienstleister Überweisungen ausführt. Die Zeitdauer beginnt mit der Erstellzeit der Bestellungen."
      ],
      "Auto-refund time": [
        "Zeitdauer für automatische Rückerstattungen"
      ],
      "Time until which the wallet will automatically check for refunds without user interaction.": [
        "Zeit, bis zu der das Wallet automatisch und ohne Benutzerinteraktion auf Rückerstattungen prüft."
      ],
      "Maximum fee": [
        "Maximale Gebührenhöhe"
      ],
      "Maximum fees the merchant is willing to cover for this order. Higher deposit fees must be covered in full by the consumer.": [
        "Die maximale Gebührenhöhe, die ein Verkäufer für eine Bestellung zu übernehmen gewillt ist. Einlösegebühren, die über dieser liegen, sind komplett von den Käufern zu übernehmen."
      ],
      "Create token": [
        "Token erzeugen"
      ],
      "If the order ID is easy to guess the token will prevent users to steal orders from others.": [
        "Wenn die ID der Bestellung leicht zu erraten ist, verhindert der Token, dass Benutzer Bestellungen von anderen „stehlen“ können."
      ],
      "Minimum age required": [
        "Mindestalter erforderlich"
      ],
      "Any value greater than 0 will limit the coins able be used to pay this contract. If empty the age restriction will be defined by the products": [
        "Eine Zahl größer als 0 schränkt ein, welches e-Geld zur Bezahlung dieses Kaufvertrags verwendet werden kann. Bleibt das Feld leer, werden Altersbeschränkungen durch die Artikel selbst festgelegt"
      ],
      "Min age defined by the producs is %1$s": [
        "Das Mindestalter für diesen Artikel ist %1$s"
      ],
      "No product with age restriction in this order": [
        "Es befindet sich kein Artikel mit einer Altersbeschränkung in dieser Bestellung"
      ],
      "Additional information": [
        "Zusätzliche Informationen"
      ],
      "Custom information to be included in the contract for this order.": [
        "Individuelle Informationen, die in den Kaufvertrag für diese Bestellung aufgenommen werden sollen."
      ],
      "You must enter a value in JavaScript Object Notation (JSON).": [
        "Sie müssen einen Wert im Format JavaScript Object Notation (JSON) eingeben."
      ],
      "Custom field name": [
        "Benutzerdefinierter Feldname"
      ],
      "Disabled": [
        "Deaktiviert"
      ],
      "No deadline": [
        "Keine Zahlungsfrist"
      ],
      "Deadline at %1$s": [
        "Frist bis %1$s"
      ],
      "Could not create order": [
        "Es war nicht möglich, eine Bestellung anzulegen"
      ],
      "No exchange would accept a payment because of KYC requirements.": [
        "Aufgrund vorgegebener KYC-Erfordernisse würde kein Zahlungsdienstleister eine Zahlung annehmen."
      ],
      "No more stock for product with ID \"%1$s\".": [
        "Es gibt keinen Bestand für den Artikel mit der ID \"%1$s\"."
      ],
      "Orders": [
        "Bestellungen"
      ],
      "Create order": [
        "Bestellung anlegen"
      ],
      "Load first page": [
        "Erste Seite laden"
      ],
      "Date": [
        "Datum"
      ],
      "Refund": [
        "Rückerstattung"
      ],
      "copy url": [
        "URL kopieren"
      ],
      "Load more orders after the last one": [
        "Weitere Bestellungen nach der letzten Bestellung laden"
      ],
      "Load next page": [
        "Nächste Seite laden"
      ],
      "No orders have been found matching your query!": [
        "Es wurden keine Bestellungen gefunden, die zu Ihrer Abfrage passen!"
      ],
      "Duplicated": [
        "Dupliziert"
      ],
      "This value exceed the refundable amount": [
        "Dieser Wert übersteigt den erstattungsfähigen Betrag"
      ],
      "Amount to be refunded": [
        "Zu erstattender Betrag"
      ],
      "Max refundable:": [
        "Maximale Rückerstattung:"
      ],
      "Requested by the customer": [
        "Von Käufern angefordert"
      ],
      "Other": [
        "Sonstiges"
      ],
      "Why this order is being refunded": [
        "Grund für die Erstattung dieser Bestellung"
      ],
      "More information to give context": [
        "Weitere Informationen für eine genauere Beschreibung"
      ],
      "Contract terms": [
        "Vertragsbedingungen"
      ],
      "Human-readable description of the whole purchase": [
        "Für Menschen lesbare Information über den gesamten Kaufvorgang"
      ],
      "Total price for the transaction": [
        "Gesamtpreis der Transaktion"
      ],
      "URL for this purchase": [
        "URL für diesen Kauf"
      ],
      "Max fee": [
        "Höchster Gebührenbetrag"
      ],
      "Maximum total deposit fee accepted by the merchant for this contract": [
        "Maximale Einlösegeühr, die ein Verkäufer für diesen Kaufvertrag selbst tragen will"
      ],
      "Created at": [
        "Erstellungsdatum"
      ],
      "Time when this contract was generated": [
        "Zeitpunkt, zu dem der Kaufvertrag erstellt wurde"
      ],
      "Refund deadline": [
        "Rückerstattungsfrist"
      ],
      "After this deadline has passed no refunds will be accepted": [
        "Nach Ablauf dieser Frist werden keine Rückerstattungen mehr akzeptiert"
      ],
      "Payment deadline": [
        "Zahlungsfrist"
      ],
      "After this deadline, the merchant won't accept payments for the contract": [
        "Nach Ablauf dieser Frist akzeptiert ein Verkäufer für den Vertrag keinen Rechnungsausgleich (Bezahlung durch Käufer)"
      ],
      "Wire transfer deadline": [
        "Überweisungsfrist"
      ],
      "Transfer deadline for the exchange": [
        "Frist der Überweisung vom Zahlungsdienstleister an das Bankkonto"
      ],
      "Time indicating when the order should be delivered": [
        "Zeitangabe, wann die Bestellung geliefert werden soll"
      ],
      "Where the order will be delivered": [
        "Zustelladresse der Bestellung"
      ],
      "Auto-refund delay": [
        "Zeitliche Verzögerung der automatischen Rückerstattung"
      ],
      "How long the wallet should try to get an automatic refund for the purchase": [
        "So lange sollte ein Wallet versuchen, eine automatische Rückerstattung auf den Kaufvertrag zu erhalten"
      ],
      "Extra info": [
        "Weitere Informationen"
      ],
      "Extra data that is only interpreted by the merchant frontend": [
        "Zusätzliche Daten, die nur im Verkäufer-Frontend verarbeitet werden"
      ],
      "Order": [
        "Bestellung"
      ],
      "Claimed": [
        "Schon für jemanden reserviert"
      ],
      "Claimed at": [
        "Reserviert für"
      ],
      "Timeline": [
        "Zeitleiste"
      ],
      "Payment details": [
        "Zahlungsdetails"
      ],
      "Order status": [
        "Bestellstatus"
      ],
      "Product list": [
        "Artikelliste"
      ],
      "Paid": [
        "Bezahlt"
      ],
      "Wired": [
        "Überwiesen"
      ],
      "Refunded": [
        "Rückerstattet"
      ],
      "Refund order": [
        "Bestellung rückerstatten"
      ],
      "Not refundable": [
        "Nicht erstattungsfähig"
      ],
      "Next event in": [
        "Nächster Event in"
      ],
      "Refunded amount": [
        "Rückerstattungsbetrag"
      ],
      "Refund taken": [
        "Erhaltener Rückerstattungsbetrag"
      ],
      "Status URL": [
        "Status-URL"
      ],
      "Refund URI": [
        "Rückerstattungs-URI"
      ],
      "Unpaid": [
        "Noch nicht bezahlt"
      ],
      "Pay at": [
        "Zahlung am"
      ],
      "Order status URL": [
        "Bestellstatus-URL"
      ],
      "Payment URI": [
        "Zahlungs-URI"
      ],
      "Unknown order status. This is an error, please contact the administrator.": [
        "Unbekannter Bestellstatus. Dies ist ein Fehler, bitte kontaktieren Sie den Administrator."
      ],
      "Back": [
        "Zurück"
      ],
      "Refund created successfully": [
        "Die Rückerstattung ist erfolgreich durchgeführt worden"
      ],
      "Could not create the refund": [
        "Die Rückerstattung konnte nicht erstellt werden"
      ],
      "There are pending KYC requirements.": [
        "Es sind noch KYC-Erfordernisse zu erfüllen (Prüfung der wirtschaftlich Berechtigten)."
      ],
      "Missing ID": [
        "Eine ID fehlt"
      ],
      "Not found": [
        "Nicht gefunden"
      ],
      "Select date to show nearby orders": [
        "Datum wählen, um Bestellungen des Zeitraums anzuzeigen"
      ],
      "Only show paid orders": [
        "Nur bezahlte Bestellungen anzeigen"
      ],
      "New": [
        "Neu"
      ],
      "Only show orders with refunds": [
        "Nur Bestellungen mit Rückerstattungen anzeigen"
      ],
      "Only display orders that have already been paid for by buyers and for which the wire transfer by the payment service provider is still pending": [
        "Nur von Käufern bereits bezahlte Bestellungen anzeigen, deren Überweisungen durch den Zahlungsdienstleister noch ausstehen"
      ],
      "Not wired": [
        "Nicht überwiesen"
      ],
      "Only display orders that have already been transferred by the payment service provider": [
        "Nur Aufträge anzeigen, die bereits vom Zahlungsdienstleister verarbeitet und transferiert wurden"
      ],
      "Completed": [
        "Ausgeführt"
      ],
      "Remove all filters": [
        "Alle Filter aufheben"
      ],
      "Clear date filter": [
        "Datumsfilter aufheben"
      ],
      "Jump to date (%1$s)": [
        "Springe zum Datum (%1$s)"
      ],
      "Jump to order with the given product ID": [
        "Zur Bestellung mit angegebener Artikel-ID gehen"
      ],
      "Order id": [
        "Bestellnummer"
      ],
      "Invalid. Please enter characters and numbers only": [
        "Ungültig. Bitte geben Sie nur Buchstaben und Zahlen ein"
      ],
      "Just letters and numbers from 2 to 7": [
        "Nur Buchstaben und Zahlen von 2 bis 7"
      ],
      "The size of the key must be 32 characters": [
        "Die Schlüssellänge muss 32 Zeichen betragen"
      ],
      "Internal ID on the system": [
        "Interne ID auf dem System"
      ],
      "Useful to identify the device physically": [
        "Dies ist nützlich, um das Gerät physisch zu identifizieren"
      ],
      "Verification algorithm": [
        "Überprüfungsalgorithmus"
      ],
      "Algorithm used to verify transactions in offline mode": [
        "Algorithmus zum Überprüfen von Transaktionen im Offline-Modus"
      ],
      "Device key": [
        "Geräteschlüssel (Device key)"
      ],
      "Be sure to choose a password hard to guess, alternatively use the random generator": [
        "Das Passwort muss schwer zu erraten sein - andernfalls verwenden Sie den Zufallszahlengenerator"
      ],
      "Your device needs to match exactly the same value": [
        "Ihr Gerät muss genau das gleiche Ergebnis anzeigen"
      ],
      "Generate random secret key": [
        "Zufälligen privaten Schlüssel erzeugen"
      ],
      "Random": [
        "Zufallsbasiert"
      ],
      "You can scan the next QR code with your device or save the key before continuing.": [
        "Sie können den nächsten QR-Code mit Ihrem Gerät scannen oder den Schlüssel speichern, bevor Sie fortfahren."
      ],
      "Device added successfully": [
        "Das Gerät wurde erfolgreich hinzugefügt"
      ],
      "Could not add device": [
        "Das Gerät konnte nicht hinzugefügt werden"
      ],
      "OTP devices": [
        "OTP-Geräte"
      ],
      "Add new devices": [
        "Neue Geräte hinzufügen"
      ],
      "Load more devices before the first one": [
        "Mehr Geräte vor ein erstes Gerät laden"
      ],
      "Delete selected devices from the database": [
        "Ausgewählte Geräte aus der Datenbank entfernen"
      ],
      "Load more devices after the last one": [
        "Mehr Geräte nach dem letzten Gerät laden"
      ],
      "There are no devices to list yet, add more by pressing the + sign": [
        "Es gibt noch keine Geräte. Sie können diese mit dem +-Zeichen hinzufügen"
      ],
      "The device was successfully deleted": [
        "Die Verknüpfung mit dem Bankkonto wurde erfolgreich gelöscht."
      ],
      "Could not delete the device": [
        "Das Gerät konnte nicht gelöscht werden"
      ],
      "Device:": [
        "Gerät:"
      ],
      "Algorithm to use to verify transactions in offline mode": [
        "Algorithmus zum Überprüfen von Transaktionen im Offline-Modus"
      ],
      "Not modified": [
        "Unverändert"
      ],
      "Be sure to be very hard to guess or use the random generator": [
        "Das Passwort muss schwer zu erraten sein oder aus einem Zufallszahlengenerator stammen"
      ],
      "Your device need to have exactly the same value": [
        "Ihr Gerät muss genau das gleiche Ergebnis anzeigen"
      ],
      "Change key": [
        "Schlüssel ändern"
      ],
      "Could not update template": [
        "Die Vorlage konnte nicht aktualisiert werden"
      ],
      "Template id is unknown": [
        "Die Vorlagen-ID ist unbekannt"
      ],
      "The provided information is inconsistent with the current state of the template": [
        "Die bereitgestellten Informationen stimmen nicht mit dem aktuellen Stand der Vorlage überein"
      ],
      "Click here to configure the stock of the product, leave it as is and the backend will not control stock.": [
        "Klicken Sie hier, um den Lagerbestand des Produkts zu konfigurieren. Falls dieses Programm den Bestand nicht überwachen soll, lassen Sie seine Grundeinstellung unverändert."
      ],
      "Manage stock": [
        "Bestand verwalten"
      ],
      "This product has been configured without stock control": [
        "Dieser Artikel wurde ohne Bestandskontrolle angelegt"
      ],
      "Infinite": [
        "Unbegrenzt"
      ],
      "Shrinkage cannot exceed the current stock and incoming supplies (maximum %1$s)": [
        "Der Schwund kann nicht größer sein als der aktuelle Bestand und die Menge der Zulieferung (max %1$s)"
      ],
      "Incoming": [
        "In der Zulieferung"
      ],
      "Lost": [
        "Schwund (Inventurdifferenz)"
      ],
      "Current": [
        "Aktuell"
      ],
      "Remove stock control for this product": [
        "Bestandskontrolle für diesen Artikel entfernen"
      ],
      "without stock": [
        "ohne Bestand"
      ],
      "Next restock": [
        "Nächstes Auffüllen des Bestands"
      ],
      "Warehouse address": [
        "Adresse des Lagers"
      ],
      "Add element to the list": [
        "Ein Element zur Liste hinzufügen"
      ],
      "Invalid amount": [
        "Kein gültiger Betrag"
      ],
      "Product identification to use in URLs (for internal use only).": [
        "Produktidentifikation für die Nutzung in URLs (nur für den internen Gebrauch)."
      ],
      "Illustration of the product for customers.": [
        "Abbildung des Artikels für Kunden."
      ],
      "Product description for customers.": [
        "Artikelbeschreibung, die Kunden zu sehen bekommen."
      ],
      "Age restriction": [
        "Altersbeschränkung"
      ],
      "Is this product restricted for customer below certain age?": [
        "Besteht für diesen Artikel eine Verkaufsbeschränkung unterhalb eines bestimmten Alters?"
      ],
      "Minimum age of the customer": [
        "Mindestalter von Käufern"
      ],
      "Unit name": [
        "Bezeichnung der Mengeneinheit"
      ],
      "Unit describing quantity of product sold (e.g. 2 kilograms, 5 liters, 3 items, 5 meters) for customers.": [
        "Die den Käufern angezeigte Mengeneinheit des Artikels im Verkauf (z.B. 2 Kilogramm, 5 Liter, 3 Stück, 5 Meter)."
      ],
      "Example: kg, items or liters": [
        "Beispiel: kg, Stück oder Liter"
      ],
      "Price per unit": [
        "Preis pro Mengeneinheit"
      ],
      "Sale price for customers, including taxes, for above units of the product.": [
        "Verkaufspreis an Endkunden, inklusive Steuern, für jede oben genannte Mengeneinheit des Artikels."
      ],
      "Stock": [
        "Bestand"
      ],
      "Inventory for products with finite supply (for internal use only).": [
        "Lagerbestand für Artikel mit begrenztem Vorrat (nur für den internen Gebrauch)."
      ],
      "Taxes included in the product price, exposed to customers.": [
        "Im Artikelpreis enthaltene Steuern, die den Käufern angezeigt werden."
      ],
      "Categories": [
        "Kategorien"
      ],
      "Search by category description or id": [
        "Nach Kategoriebeschreibung oder -ID suchen"
      ],
      "Categories where this product will be listed on.": [
        "Kategorien, in denen dieser Artikel aufgeführt sein wird."
      ],
      "Product created successfully": [
        "Der Artikel wurde erfolgreich angelegt"
      ],
      "Could not create product": [
        "Der Artikel konnte nicht angelegt werden"
      ],
      "Inventory": [
        "Lagerbestand"
      ],
      "Add product to inventory": [
        "Artikel zum Lagerbestand hinzufügen"
      ],
      "Sales": [
        "Sales"
      ],
      "Sold": [
        "Verkauft"
      ],
      "Free": [
        "Kostenlos"
      ],
      "Go to product update page": [
        "Zur Seite der Artikelaktualisierung gehen"
      ],
      "Update": [
        "Aktualisieren"
      ],
      "Remove this product from the database": [
        "Diesen Artikel aus der Datenbank entfernen"
      ],
      "Load more products after the last one": [
        "Weitere Artikel nach dem letzten laden"
      ],
      "Update the product with new price": [
        "Artikel mit neuem Preis aktualisieren"
      ],
      "Update product with new price": [
        "Artikelpreis aktualisieren"
      ],
      "Confirm update": [
        "Aktualisierung bestätigen"
      ],
      "lost can't be greater that current + incoming (max %1$s)": [
        "Der Schwund kann nicht größer sein als der aktuelle Bestand und die Menge der Zulieferung (max %1$s)"
      ],
      "Add more elements to the inventory": [
        "Mehr Elemente zum Lagerbestand hinzufügen"
      ],
      "Report elements lost in the inventory": [
        "Aus dem Lagerbestand verlorene Elemente melden"
      ],
      "New price for the product": [
        "Neuer Preis für den Artikel"
      ],
      "The are value with errors": [
        "Einige Werte sind fehlerhaft"
      ],
      "Update product with new stock and price": [
        "Artikel mit neuem Bestand und Preis aktualisieren"
      ],
      "There are no products to list yet, add more by pressing the + sign": [
        "Es gibt noch keine Artikel. Sie können diese mit dem +-Zeichen hinzufügen"
      ],
      "Jump to product with the given product ID": [
        "Zum Artikel mit der angegebenen Artikel-ID springen"
      ],
      "Product id": [
        "Artikel-ID"
      ],
      "Product updated successfully": [
        "Produkt erfolgreich aktualisiert"
      ],
      "Could not update the product": [
        "Der Artikel konnte nicht aktualisiert werden"
      ],
      "Product \"%1$s\" (ID: %2$s) has been deleted": [
        "Artikel \"%1$s\" (ID: %2$s) wurde gelöscht"
      ],
      "Could not delete the product": [
        "Der Artikel konnte nicht gelöscht werden"
      ],
      "If you delete the product named %1$s (ID: %2$s ), the stock and related information will be lost": [
        "Wenn Sie den Artikel mit der Bezeichnung %1$s (ID: %2$s) löschen, gehen Bestandsdaten und alle zugehörigen Informationen verloren"
      ],
      "Deleting an product can't be undone.": [
        "Das Löschen eines Artikels kann nicht rückgängig gemacht werden."
      ],
      "Product id:": [
        "Artikel-ID:"
      ],
      "Product (ID: %1$s) has been updated": [
        "Der Artikel (ID: %1$s) wurde aktualisiert"
      ],
      "Could not update product": [
        "Der Artikel konnte nicht aktualisiert werden"
      ],
      "Must be greater that 0": [
        "Dieser Wert muss größer als Null sein"
      ],
      "Too short": [
        "Zu kurz"
      ],
      "Identifier": [
        "Eindeutige Kennung"
      ],
      "Name of the template in URLs.": [
        "Name der Vorlage in URLs."
      ],
      "Describe what this template stands for": [
        "Beschreiben Sie, wofür diese Vorlage stehen soll"
      ],
      "If specified here, this template will create orders with the same summary": [
        "Falls angegeben, wird diese Vorlage Bestellungen mit der gleichen Zusammenfassung erzeugen"
      ],
      "Summary is editable": [
        "Die Zusammenfassung kann umgeschrieben werden"
      ],
      "Allow the user to change the summary.": [
        "Den Nutzern die Bearbeitung der Zusammenfassung erlauben."
      ],
      "If specified here, this template will create orders with the same price": [
        "Diese Vorlage wird - sofern hier angelegt - Bestellungen mit dem gleichen Preis erzeugen"
      ],
      "Amount is editable": [
        "Der Betrag lässt sich verändern"
      ],
      "Allow the user to select the amount to pay.": [
        "Den Nutzern die Auswahl des Zahlbetrags erlauben."
      ],
      "Currency is editable": [
        "Die Währung ist veränderbar"
      ],
      "Allow the user to change currency.": [
        "Den Nutzern die Änderung der Währung erlauben."
      ],
      "Supported currencies": [
        "Unterstützte Währungen"
      ],
      "Supported currencies: %1$s": [
        "Unterstützte Währungen: %1$s"
      ],
      "Minimum age": [
        "Mindestalter"
      ],
      "Is this contract restricted to some age?": [
        "Verlangt dieser Kaufvertrag ein bestimmtes Mindestalter?"
      ],
      "Payment timeout": [
        "Die Zahlungsfrist ist verstrichen nach"
      ],
      "How much time the customer has to complete the payment once the order was created.": [
        "Wie lange der Käufer Zeit hat, um zu bezahlen, nachdem die Bestellung angelegt wurde."
      ],
      "OTP device": [
        "OTP-Gerät"
      ],
      "Use to verify transactions in offline mode.": [
        "Zum Überprüfen von Transaktionen im Offline-Modus."
      ],
      "No OTP device.": [
        "Kein OTP-Gerät."
      ],
      "Add one first": [
        "Fügen Sie zuerst ein erstes hinzu"
      ],
      "No device": [
        "Kein Gerät"
      ],
      "Template has been created": [
        "Die Vorlage wurde erstellt"
      ],
      "Could not create template": [
        "Die Vorlage konnte nicht erstellt werden"
      ],
      "Templates": [
        "Vorlagen"
      ],
      "Add new templates": [
        "Neue Vorlagen hinzufügen"
      ],
      "Load more templates before the first one": [
        "Mehrere Vorlagen vor der ersten laden"
      ],
      "Delete selected templates from the database": [
        "Ausgewählte Vorlagen aus der Datenbank löschen"
      ],
      "Use template to create new order": [
        "Vorlage verwenden, um eine neue Bestellung anzulegen"
      ],
      "Use template": [
        "Vorlage verwenden"
      ],
      "Generate a QR code for the template.": [
        "QR-Code für die Vorlage erstellen"
      ],
      "Load more templates after the last one": [
        "Weitere Vorlagen nach der letzten laden"
      ],
      "There are no templates to list yet, add more by pressing the + sign": [
        "Es gibt noch keine Vorlagen. Sie können diese mit dem +-Zeichen hinzufügen"
      ],
      "Jump to template with the given template ID": [
        "Zur Vorlage mit der angegebenen Vorlagen-ID springen"
      ],
      "Template identification": [
        "Vorlagenidentifikation"
      ],
      "Template \"%1$s\" (ID: %2$s) has been deleted": [
        "Vorlage \"%1$s\" (ID: %2$s) wurde gelöscht"
      ],
      "Failed to delete template": [
        "Die Vorlage konnte nicht gelöscht werden"
      ],
      "If you delete the template %1$s (ID: %2$s) you may loose information": [
        "Wenn Sie die Vorlage %1$s (ID: %2$s) löschen, können Information verlorengehen"
      ],
      "Deleting an template": [
        "Die Vorlage wird gelöscht"
      ],
      "can't be undone": [
        "Das kann nicht rückgängig gemacht werden"
      ],
      "Print": [
        "Drucken"
      ],
      "If specified, this template will create order with the same summary": [
        "Falls angegeben, wird diese Vorlage Bestellungen mit der gleichen Zusammenfassung erzeugen"
      ],
      "If specified, this template will create orders with the same price": [
        "Falls angegeben, wird diese Vorlage Bestellungen mit dem gleichen Preis erzeugen"
      ],
      "How much time has the customer to complete the payment once the order was created.": [
        "Dies bestimmt die Zeitdauer zur Bezahlung nach einer erfolgten Bestellung."
      ],
      "Template (ID: %1$s) has been updated": [
        "Die Vorlage (ID: %1$s) wurde aktualisiert"
      ],
      "An amount is required": [
        "Ein Betrag ist erforderlich"
      ],
      "An order summary is required": [
        "Eine Zusammenfassung der Bestellung ist erforderlich"
      ],
      "New order from template": [
        "Neue Bestellung von Vorlage"
      ],
      "Amount of the order": [
        "Summe der Bestellung"
      ],
      "Order summary": [
        "Zusammenfassung der Bestellung"
      ],
      "Could not create order from template": [
        "Die Bestellung konnte aus der Vorlage nicht angelegt werden"
      ],
      "You need your access token to perform the operation": [
        "Sie benötigen Ihren Zugangstoken, um den Vorgang durchzuführen"
      ],
      "You are updating the access token from instance with id \"%1$s\"": [
        "Sie erneuern Ihren Zugangstoken für die Instanz mit der ID \"%1$s\""
      ],
      "This instance does not have authentication token.": [
        "Diese Instanz besitzt keine Authentifizierungstoken."
      ],
      "You can leave it empty if there is another layer of security.": [
        "Sie können dies leer lassen, wenn es eine weitere Sicherheitsmethode gibt."
      ],
      "Current access token": [
        "Derzeitiger Zugangstoken"
      ],
      "Clearing the access token will mean public access to the instance.": [
        "Das Löschen des Zugangstokens bedeutet öffentlichen Zugang zur Instanz."
      ],
      "Clear token": [
        "Zugangstoken löschen"
      ],
      "Confirm change": [
        "Änderung bestätigen"
      ],
      "Failed to clear token": [
        "Es war nicht möglich, den/die Token zu löschen"
      ],
      "Failed to set new token": [
        "Es war nicht möglich, einen neuen Zugangstoken festzuschreiben"
      ],
      "Slug": [
        "Slug (benutzer- und suchmaschinenfreundlicher Text als Bestandteil einer URL)"
      ],
      "Token family slug to use in URLs (for internal use only)": [
        "Slug der Token-Familie zur Benutzung in URLs (nur für interne Zwecke)"
      ],
      "Kind": [
        "Art"
      ],
      "Token family kind": [
        "Art der Tokenfamilie"
      ],
      "User-readable token family name": [
        "Benutzerfreundliche Bezeichnung der Tokenfamilie"
      ],
      "Token family description for customers": [
        "Tokenfamilien-Beschreibung für Kunden"
      ],
      "Valid After": [
        "Gültig nach"
      ],
      "Token family can issue tokens after this date": [
        "Die Tokenfamilie kann nach diesem Datum Token ausgeben"
      ],
      "Valid Before": [
        "Gültig vor"
      ],
      "Token family can issue tokens until this date": [
        "Die Tokenfamilie kann bis zu diesem Datum Token ausgeben"
      ],
      "Duration": [
        "Gültigkeitszeitraum"
      ],
      "Validity duration of a issued token": [
        "Zeitraum der Gültigkeit eines ausgestellten Tokens"
      ],
      "Token family created successfully.": [
        "Die Tokenfamilie wurde erfolgreich angelegt"
      ],
      "Could not create token family.": [
        "Die Tokenfamilie konnte nicht erstellt werden"
      ],
      "Token Families": [
        "Tokenfamilien"
      ],
      "Add token family": [
        "Tokenfamilie hinzufügen"
      ],
      "Go to token family update page": [
        "Zur Aktualisierungsseite für Tokenfamilien gehen"
      ],
      "Remove this token family from the database": [
        "Diese Token aus der Datenbank entfernen"
      ],
      "There are no token families yet, add the first one by pressing the + sign.": [
        "Es sind noch keine Token-Familien angelegt. Sie können diese mit dem +-Zeichen hinzufügen."
      ],
      "Token family updated successfully": [
        "Die Tokenfamilie wurde aktualisiert"
      ],
      "Could not update the token family": [
        "Die Tokenfamilie konnte nicht aktualisiert werden"
      ],
      "Token family \"%1$s\" (SLUG: %2$s) has been deleted": [
        "Die Tokenfamilie \"%1$s\" (SLUG: %2$s) wurde gelöscht"
      ],
      "Failed to delete token family": [
        "Das Löschen der Tokenfamilie ist fehlgeschlagen"
      ],
      "If you delete the %1$s token family (Slug: %2$s), all issued tokens will become invalid.": [
        "Wenn Sie die Tokenfamilie %1$s (SLUG: %2$s) löschen, werden alle ausgestellten Token ungültig."
      ],
      "Deleting a token family %1$s .": [
        "Tokenfamilie %1$s wird gelöscht."
      ],
      "Token Family: %1$s": [
        "Tokenfamilie: %1$s"
      ],
      "Could not update token family": [
        "Die Tokenfamilie konnte nicht aktualisiert werden"
      ],
      "Please check the ID; it does not appear to be valid.": [
        "Prüfen Sie die ID, da ihr Format ungültig scheint"
      ],
      "Must have 52 characters, current %1$s": [
        "Muss 52 Zeichen haben, aktuell %1$s"
      ],
      "URL doesn't have the right format": [
        "Die URL hat nicht das richtige Format"
      ],
      "Credited bank account": [
        "Bankkonro, das Gutschrift erhält"
      ],
      "Select an account": [
        "Wählen Sie ein Konto"
      ],
      "Bank account of the merchant where the payment was received": [
        "Bankkonto des Händlers mit Zahlungseingang"
      ],
      "Wire transfer ID": [
        "Überweisungs-ID"
      ],
      "Unique identifier of the wire transfer used by the exchange, must be 52 characters long": [
        "Der Buchungsvermerk muss 52 Zeichen lang sein, er ist der eindeutige Bezeichner der Transaktion durch den Zahlungsdienstleister"
      ],
      "Exchange URL": [
        "URL des Zahlungsdienstleisters (Exchange-URL)"
      ],
      "Base URL of the exchange that made the transfer, should have been in the wire transfer subject": [
        "Base URL des Zahlungsdienstleisters, der die Überweisung getätigt hat - diese sollte im Buchungsvermerk der Überweisung stehen"
      ],
      "Amount credited": [
        "Gutgeschriebener Betrag"
      ],
      "Actual amount that was wired to the merchant's bank account": [
        "Tatsächlicher Betrag, der auf das Bankkonto des Verkäufers überwiesen wurde"
      ],
      "Wire transfer informed successfully": [
        "Die Überweisung wurde erfolgreich vermittelt"
      ],
      "Could not inform transfer": [
        "Die Überweisung konnte nicht vermittelt werden"
      ],
      "Wire transfers": [
        "Überweisungs-ID"
      ],
      "Add new transfer": [
        "Neuen Transfer hinzufügen"
      ],
      "Load more wire transfers preceding the first one": [
        "Mehr Überweisungen vor der ersten laden"
      ],
      "Credit": [
        "Guthaben"
      ],
      "Confirmed": [
        "Bestätigt"
      ],
      "Verified": [
        "Verifiziert"
      ],
      "Executed on": [
        "Ausführungsdatum"
      ],
      "yes": [
        "ja"
      ],
      "no": [
        "nein"
      ],
      "never": [
        "nie"
      ],
      "unknown": [
        "unbekannt"
      ],
      "Delete selected transfer from the database": [
        "Ausgewählte Überweisung aus der Datenbank löschen"
      ],
      "Load more transfers after the last one": [
        "Mehr Überweisungen nach der letzten laden"
      ],
      "There are no transfers to list yet, add more by pressing the + sign": [
        "Es gibt noch keine Überweisungen. Sie können diese mit dem +-Zeichen hinzufügen"
      ],
      "All accounts": [
        "Alle Konten"
      ],
      "Filter by account address": [
        "Filtern nach Kontoadressen"
      ],
      "Only show wire transfers confirmed by the merchant": [
        "Nur vom Verkäufer bestätigte Überweisungen anzeigen"
      ],
      "Only show wire transfers claimed by the exchange": [
        "Nur solche Überweisungen anzeigen, die vom Zahlungsdienstleister beansprucht werden"
      ],
      "Unverified": [
        "Nicht verifiziert"
      ],
      "Wire transfer \"%1$s...\" has been deleted": [
        "Die Überweisung \"%1$s...\" wurde gelöscht"
      ],
      "Failed to delete transfer": [
        "Löschen der Überweisung fehlgeschlagen"
      ],
      "Must be business or individual": [
        "Muss geschäftlich oder privat sein"
      ],
      "Pay delay can't be greater than wire transfer delay": [
        "Der Zahlungsaufschub darf nicht über die Überweisungsverzögerung hinausgehen"
      ],
      "Max 7 lines": [
        "Höchstens 7 Zeilen"
      ],
      "Doesn't match": [
        "Dies stimmt nicht überein"
      ],
      "Enable access control": [
        "Zugriffskontrolle einschalten"
      ],
      "Choose if the backend server should authenticate access.": [
        "Wählen Sie, ob der Backend-Server den Zugriff authentifizieren soll."
      ],
      "Access control is not yet decided. This instance can't be created.": [
        "Die Entscheidung über die Zugriffskontrolle steht noch aus. Diese Instanz kann daher nicht erstellt werden."
      ],
      "Authorization must be handled externally.": [
        "Die Autorisierung muss extern durchgeführt werden."
      ],
      "Authorization is handled by the backend server.": [
        "Die Autorisierung erfolgt über den Backend-Server."
      ],
      "Need to complete marked fields and choose authorization method": [
        "Die gekennzeichneten Felder müssen ausgefüllt und eine Methode der Berechtigungsprüfung gewählt sein"
      ],
      "Name of the instance in URLs. The 'default' instance is special in that it is used to administer other instances.": [
        "Name der Instanz in den URLs. Die Instanz „default“ ist reserviert, weil sie zum Verwalten der anderen Instanzen genutzt wird."
      ],
      "Business name": [
        "Name (von Geschäft, Firma oder Verkaufsort)"
      ],
      "Legal name of the business represented by this instance.": [
        "Offizieller Handelsname (des Geschäfts, der Firma oder des Verkaufsorts), welchen diese Instanz repräsentieren soll."
      ],
      "Email": [
        "E-Mail"
      ],
      "Contact email": [
        "E-Mail-Adresse für allgemeinen Kontakt"
      ],
      "Website URL": [
        "Webseiten-URL"
      ],
      "URL.": [
        "URL."
      ],
      "Logo": [
        "Logo (Signet)"
      ],
      "Logo image.": [
        "Abbildung des Firmenlogos."
      ],
      "Physical location of the merchant.": [
        "Niederlassung (Firmensitz) des Händlers oder Verkäufers."
      ],
      "Jurisdiction": [
        "Gerichtsstand"
      ],
      "Jurisdiction for legal disputes with the merchant.": [
        "Gerichtsstand für rechtliche Auseinandersetzungen mit dem Händler oder Verkäufer."
      ],
      "Pay transaction fee": [
        "Transaktionsgebühren bezahlen"
      ],
      "Cover the transaction cost or pass it on to the user.": [
        "Die Kosten für die Transaktionen übernehmen die Verkäufer oder die Zahlenden."
      ],
      "Default payment delay": [
        "Normale Zahlungsfrist"
      ],
      "Time customers have to pay an order before the offer expires by default.": [
        "Zeitraum, in dem Käufer normalerweise bezahlen, bevor das Angebot regulär verfällt."
      ],
      "Default wire transfer delay": [
        "Gewöhnlicher Aufschub für Überweisungen"
      ],
      "Maximum time an exchange is allowed to delay wiring funds to the merchant, enabling it to aggregate smaller payments into larger wire transfers and reducing wire fees.": [
        "Zeitraum, in dem alle Kundenzahlungen zu einem Betrag zusammengefasst und vom Zahlungsdienstleister an das Bankkonto des Verkäufers überwiesen werden, um die Überweisungsgebühren zu reduzieren."
      ],
      "Instance id": [
        "Instanz-ID"
      ],
      "Failed to update instance": [
        "Die Instanz konnte nicht aktualisiert werden"
      ],
      "Must be \"pay\" or \"refund\"": [
        "Muss entweder „Zahlen“ oder „Erstatten“ sein"
      ],
      "Must be one of '%1$s'": [
        "Muss eines von '%1$s' sein"
      ],
      "Webhook ID to use": [
        "zu verwendende Webhook-ID"
      ],
      "Event": [
        "Event"
      ],
      "Pay": [
        "Bezahlen"
      ],
      "The event of the webhook: why the webhook is used": [
        "Event (Anlass, Bedingung) für einen Webhook, um durch diesen nachfolgende Prozesse auszulösen"
      ],
      "Method": [
        "Methode"
      ],
      "GET": [
        "GET"
      ],
      "POST": [
        "POST"
      ],
      "PUT": [
        "PUT"
      ],
      "PATCH": [
        "PATCH"
      ],
      "HEAD": [
        "HEAD"
      ],
      "Method used by the webhook": [
        "Methode, die der Webhook verwendet"
      ],
      "URL": [
        "URL"
      ],
      "URL of the webhook where the customer will be redirected": [
        "URL des Webhooks, zu der die Käufer nach der Bezahlung weitergeleitet werden"
      ],
      "The text below supports the %1$s template engine. Any string between %2$s and %3$s will be replaced with the value of the corresponding variable.": [
        "Der folgende Text unterstützt %1$s Vorlagen-Ersteller. Jede Zeichenkette zwischen %2$s und %3$s wird durch den Wert der entsprechenden Variablen ersetzt."
      ],
      "For example %1$s will be replaced with the the order's price": [
        "Beispielsweise wird %1$s durch den Bestellwert ersetzt"
      ],
      "The short list of variables are:": [
        "Einige der Variablen sind:"
      ],
      "order's description": [
        "Beschreibung der Bestellung"
      ],
      "order's price": [
        "Kosten der Bestellung"
      ],
      "order's unique identification": [
        "Laufende Nummer der Bestellung"
      ],
      "the amount that was being refunded": [
        "Der erstattete Betrag"
      ],
      "the reason entered by the merchant staff for granting the refund": [
        "Der vom Personal des Verkäufers angegebene Grund für die Gewährung einer Erstattung"
      ],
      "time of the refund in nanoseconds since 1970": [
        "Zeitpunkt der Erstattung in Nanosekunden seit 1970"
      ],
      "Http body": [
        "HTTP-Body"
      ],
      "Body template by the webhook": [
        "Vorlage für den Webhook-Body"
      ],
      "Webhook create successfully": [
        "Webhook wurde erfolgreich angelegt"
      ],
      "Could not create the webhook": [
        "Webhook konnte nicht angelegt werden"
      ],
      "Could not create webhook": [
        "Webhook konnte nicht angelegt werden"
      ],
      "Webhooks": [
        "Webhooks"
      ],
      "Add new webhooks": [
        "Neue Webhooks hinzufügen"
      ],
      "Load more webhooks before the first one": [
        "Mehr Webhooks vor dem ersten laden"
      ],
      "Event type": [
        "Event-Typ"
      ],
      "Delete selected webhook from the database": [
        "Ausgewählten Webhook aus der Datenbank löschen"
      ],
      "Load more webhooks after the last one": [
        "Mehr Webhooks nach dem letzten laden"
      ],
      "There are no webhooks to list yet, add more by pressing the + sign": [
        "Es gibt noch keine Webhooks. Sie können diese mit dem +-Zeichen hinzufügen"
      ],
      "Webhook deleted successfully": [
        "Webhook wurde erfolgreich gelöscht"
      ],
      "Could not delete the webhook": [
        "Webhook konnte nicht gelöscht werden"
      ],
      "Header": [
        "Header"
      ],
      "Header template of the webhook": [
        "Vorlage für den Webhook-Header"
      ],
      "Body": [
        "Body"
      ],
      "Webhook updated": [
        "Webhook aktualisiert"
      ],
      "Could not update webhook": [
        "Webhook konnte nicht aktualisiert werden"
      ],
      "Language": [
        "Sprache"
      ],
      "Advance order creation": [
        "Bestellformular ausweiten"
      ],
      "Shows more options in the order creation form": [
        "Zeigt mehr Optionen im Formular zur Erfassung von Bestellungen"
      ],
      "Advance instance settings": [
        "Anzeige der Einstellungen zu Instanzen ausweiten"
      ],
      "Shows more options in the instance settings form": [
        "Zeigt mehr Optionen in den Einstellungen zu Instanzen"
      ],
      "Date format": [
        "Datumsformat"
      ],
      "How the date is going to be displayed": [
        "Wie das Datum angezeigt wird"
      ],
      "Developer mode": [
        "Entwicklermodus"
      ],
      "Shows more options and tools which are not intended for general audience.": [
        "Zeigt mehr Optionen und Werkzeuge an, die nicht für die Allgemeinheit bestimmt sind."
      ],
      "Total products": [
        "Gesamte Artikelmenge"
      ],
      "Delete selected category from the database": [
        "Ausgewählte Kategorie aus der Datenbank löschen"
      ],
      "There are no categories yet, add more pressing the + sign": [
        "Es gibt noch keine Kategorien. Sie können diese mit dem +-Zeichen hinzufügen"
      ],
      "Category delete successfully": [
        "Die Kategorie wurde erfolgreich gelöscht"
      ],
      "Could not delete the category": [
        "Die Kategorie konnte nicht gelöscht werden"
      ],
      "Invalid. Please insert only characters and numbers": [
        "Ungültig. Bitte geben Sie nur Buchstaben und Zahlen ein"
      ],
      "Category name": [
        "Kategoriename"
      ],
      "Category added successfully": [
        "Die Kategorie wurde erfolgreich hinzugefügt"
      ],
      "Could not add category": [
        "Die Kategorie konnte nicht hinzugefügt werden"
      ],
      "Id:": [
        "ID:"
      ],
      "Name of the category": [
        "Bezeichnung der Kategorie"
      ],
      "Products": [
        "Artikel"
      ],
      "Search by product description or id": [
        "Nach Artikelbeschreibung oder Artikel-ID suchen"
      ],
      "Products that this category will list.": [
        "Artikel, die in dieser Kategorie gelistet sind."
      ],
      "Could not update category": [
        "Die Kategorie konnte nicht aktualisiert werden"
      ],
      "Category ID is unknown": [
        "Die ID der Kategorie ist nicht bekannt"
      ],
      "Without this the merchant backend will refuse to create new orders.": [
        "Ohne dies wird das Merchant-Backend die Anlage neuer Bestellungen ablehnen."
      ],
      "Hide for today": [
        "Für heute nicht anzeigen"
      ],
      "KYC verification needed": [
        "Eine KYC-Überprüfung ist notwendig (Know-Your-Customer)"
      ],
      "Some transfers are on hold until the KYC process is completed. Visit the KYC section in the left panel for more information": [
        "Einige Überweisungen werden bis zum Abschluss des KYC-Prozesses zurückgehalten. Gehen Sie zum KYC-Abschnitt im linken Bedienfeld für mehr Informationen"
      ],
      "Configuration": [
        "Einstellungen"
      ],
      "OTP Devices": [
        "OTP-Geräte"
      ],
      "Settings": [
        "Einstellungen"
      ],
      "Access token": [
        "Zugangstoken"
      ],
      "Connection": [
        "Verbindung"
      ],
      "Interface": [
        "Schnittstelle"
      ],
      "List": [
        "Auflisten"
      ],
      "Log out": [
        "Abmelden"
      ],
      "Failed to create instance": [
        "Das Erzeugen der Instanz ist fehlgeschlagen"
      ],
      "checking compatibility with server...": [
        "Prüfe Kompatibilität mit dem Server..."
      ],
      "Contacting the server failed": [
        "Der Kontaktversuch mit dem Server ist fehlgeschlagen"
      ],
      "The server version is not supported": [
        "Die Serverversion wird nicht unterstützt"
      ],
      "Supported version \"%1$s\", server version \"%2$s\".": [
        "Unterstützte Version \"%1$s\", Serverversion \"%2$s\"."
      ],
      "Deleting": [
        "löscht…"
      ],
      "Changing": [
        "ändern…"
      ],
      "Manage access token": [
        "Zugangstoken verwalten"
      ],
      "Business Name": [
        "Firmenname"
      ],
      "Order ID": [
        "Nummer der Bestellung"
      ],
      "Payment URL": [
        "Zahlungs-URL (Payment URL)"
      ]
    }
  },
  "domain": "messages",
  "plural_forms": "nplurals=2; plural=n != 1;",
  "lang": "de",
  "completeness": 100
};

